import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../context/Config/ConfigContext';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import WebhookRoundedIcon from '@mui/icons-material/WebhookRounded';
import SectionContainer from '../../global/SectionContainer';

function Webhook() {
  const { t } = useTranslation('global');
  const { themeMode } = useContext(ConfigContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <SectionContainer title={t('webhookPage.title') as string} text={t('webhookPage.setupWebhook') as string}
      icon={<AddRoundedIcon />} onClick={() => { }} disableButton
    >
      <Stack flexDirection="column" justifyContent="center" alignItems="center" gap={2} sx={{ mt: '100px', padding: '20px' }}>
        <WebhookRoundedIcon sx={{ fontSize: '60px', color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1' }} />
        <Box sx={{ textAlign: 'center' }}>
          <Typography align="center" sx={{
            fontSize: isMobile ? '16px' : '18px', fontFamily: 'Outfit', fontWeight: '300',
            color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1', maxWidth: '600px'
          }}>
            {t('webhookPage.comingSoon')}
          </Typography>
        </Box>
      </Stack>
    </SectionContainer>
  )
}

export default Webhook;
