// SalesMessageGenerator.tsx

import { useContext, useState, useRef, useEffect } from 'react';
import {
  Box, TextField, Button, MenuItem, Typography, CircularProgress, useMediaQuery, useTheme, Select
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import { ConfigContext } from '../../context/Config/ConfigContext';
import { showToast } from '../../components/Toast/toast';
import { fetchToOpenAI } from '../chat/services/OpenAIApi';

interface SalesMessageGeneratorProps {
  existingProspect?: any;
  onSave: (prospectData: any) => void;
}

const SalesMessageGenerator = ({ existingProspect, onSave }: SalesMessageGeneratorProps) => {
  const { themeMode } = useContext(ConfigContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Estados locales
  const [companyName, setCompanyName] = useState('');
  const [companyDescription, setCompanyDescription] = useState('');
  const [contactMethod, setContactMethod] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [salesAgent, setSalesAgent] = useState('');
  const [generatedMessage, setGeneratedMessage] = useState('');
  const [contactUrl, setContactUrl] = useState('');
  const [loading, setLoading] = useState(false);

  // Referencia para el scroll
  const messageRef = useRef<HTMLDivElement | null>(null);

  // Lista de agentes de ventas
  const salesAgents = [
    { name: 'Jocelyn Félix', title: 'Business Developer' },
    { name: 'Gerardo Rico', title: 'CEO' },
    { name: 'Ángel Ayala', title: 'Sales Representative' },
  ];

  useEffect(() => {
    if (existingProspect) {
      setCompanyName(existingProspect.companyName);
      setCompanyDescription(existingProspect.companyDescription);
      setContactMethod(existingProspect.contactMethod);
      setContactPerson(existingProspect.contactPerson);
      setSalesAgent(existingProspect.salesAgent);
      setGeneratedMessage(existingProspect.generatedMessage);
      setContactUrl(existingProspect.contactUrl || '');
    } else {
      setCompanyName('');
      setCompanyDescription('');
      setContactMethod('');
      setContactPerson('');
      setSalesAgent('');
      setGeneratedMessage('');
      setContactUrl(''); // Resetea la URL de contacto
    }
  }, [existingProspect]);

  // Función para copiar al portapapeles
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      showToast('success', '¡Mensaje copiado al portapapeles!', themeMode);
    }).catch(() => {
      showToast('error', 'Error copiando el mensaje', themeMode);
    });
  };

  const themee = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            fontFamily: 'Outfit',
            color: '#7c7c7c',
            backgroundColor: themeMode === "dark" ? "#181818" : "#fafafa",
            borderRadius: 15,
            border: themeMode === "dark" ? '1px solid #272727' : '1px solid #eeeeee',
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',
            },
            "& .MuiInputBase-input": {
              fontFamily: 'Outfit',
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 15,
          },
        },
      },
    }
  });

  // Lógica para el prompt con valores dinámicos y llamada a la API de OpenAI
  const generateSalesMessage = async () => {
    setLoading(true);

    // Datos del agente seleccionado
    const selectedAgent = salesAgents.find(agent => agent.name === salesAgent);

    const systemMessage = {
      role: "system",
      content: "Eres un asistente que genera mensajes personalizados para ventas de Fastalk, cautivando a la empresa."
    };

    const userMessage = {
      role: "user",
      content: `
Genera un mensaje de ventas personalizado dirigido a la empresa ${companyName}, que se especializa en ${companyDescription}.
El objetivo es ofrecer una solución que mejore la atención al cliente a través de Fastalk, una plataforma avanzada que utiliza inteligencia artificial para impulsar el servicio al cliente mediante chatbots personalizados y automatizados.

La plataforma de Fastalk no solo responde preguntas frecuentes, sino que también está diseñada para mejorar la eficiencia operativa y aumentar la satisfacción del cliente, gracias a las siguientes características:

- Pueden crear Chatbots impulsados con IA para su empresa que atienden 24/7: Atender a los clientes en cualquier momento, mejorando la experiencia de usuario con respuestas rápidas, precisas y personalizadas.
- Multicanalidad: Fastalk se puede integrar en múltiples canales, como la página web, aplicaciones móviles, y estamos desarrollando funcionalidad para integrar a Facebook Messenger, WhatsApp, e incluso Instagram, permitiendo a la empresa estar donde sus clientes están.
- Análisis de Interacciones: La plataforma ofrece reportes detallados sobre las interacciones con los clientes, identificando patrones, preguntas frecuentes y oportunidades de mejora.
- Métricas: Ofrece visión de las métricas que se tiene, como el % de dudas resueltas, de satisfacción de usuarios, sentimientos, número de conversaciones, número de mensajes, etc.
- Puedes integrar imágenes y videos al chatbot y envía esta multimedia dinámicamente a los clientes: El chatbot con IA decide cuándo enviar archivos multimedia como imágenes o videos dependiendo del flujo de la conversación con el usuario.

Menciona los beneficios personalizados para ${companyName}, según el tipo de empresa.
Si tienes otros detalles sobre el tipo de empresa, también puedes personalizar más beneficios específicos.

Este mensaje está dirigido a ${contactMethod === 'LinkedIn' ? `la persona ${contactPerson} en LinkedIn` : `el contacto por correo electrónico`} para ofrecer una solución que no solo mejora la atención al cliente, sino que también permite una mayor automatización, optimización y escalabilidad para la empresa.

Firma el mensaje como ${selectedAgent?.name}, quien ocupa el cargo de ${selectedAgent?.title}, e incluye la siguiente información de contacto:
-  contact@fastalk.app
- (668) 463 - 5207

Menciona los beneficios de manera breve, no lo separes por puntos.

También menciona que ellos pudieran crear un chatbot con IA para su empresa en 30 segundos y pueden ellos probarlo antes de siquiera crear una cuenta y que si deciden guardarlo pueden empezar un plan gratis de 14 días sin compromiso y sin ingresar tarjetas. También es importante que menciones que los planes comienzan desde 199 MXN al mes, pudiendo automatizar con IA un gran número de conversaciones con clientes.

También menciona que si están interesados en agendar una reunión para explicarles más detalles o tú sabes cómo ponerle. Que podemos agendar una cita. Todo profesional.

Es importante que menciones que las funcionalidades de integración a redes sociales aún están en desarrollo pero que sí se tendrán y también lo de la conexión a los sistemas mediante webhooks.

No me des el mensaje tan largo, házmelo breve pero personalizado y completo.

No me regreses el "asunto" por favor.

IMPORTANTE: Hazlo impresionante desde el principio, comienza con una pregunta personalizada para la empresa como, primero saluda y presentante y luego: Note que eres dueño de tal negociooooo no te pasa que te llegan muchos mensajes  y no puedes estar respondiendo? O asi una pregunta personalizada dependiendo del tipo de negocio, es importante que sea personalizada
`
    };

    const apiRequestBody = {
      model: "gpt-4o",
      messages: [systemMessage, userMessage],
    };

    try {
      const data = await fetchToOpenAI(apiRequestBody);
      const messageContent = data.choices[0].message.content;
      setGeneratedMessage(messageContent);
      setLoading(false);
      showToast('success', '¡Mensaje creado con éxito!', themeMode);

      // Hacer scroll al final
      if (messageRef.current) {
        messageRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (error) {
      showToast('error', `Error generando el mensaje`, themeMode);
      setLoading(false);
    }
  };

  const handleCopyMessage = () => {
    if (generatedMessage) {
      copyToClipboard(generatedMessage);
    }
  };
  const handleSaveProspect = () => {
    const prospectData = {
      companyName,
      companyDescription,
      contactMethod,
      contactPerson,
      generatedMessage,
      contactUrl,
    };
    onSave(prospectData);

    setTimeout(() => {
      setCompanyName('');
      setCompanyDescription('');
      setContactMethod('');
      setContactPerson('');
      setSalesAgent('');
      setGeneratedMessage('');
      setContactUrl('');
    }, 1000);
  };

  return (
    <Box sx={{
      p: 1, display: 'flex', flexDirection: 'column', gap: 4,
      backgroundColor: themeMode === 'dark' ? '#121212' : '#fff', mb: 4, borderRadius: 2,
      width: '100%'
    }}>
      <ThemeProvider theme={themee}>
        <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-18px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
          Nombre de la empresa
        </Typography>
        <TextField
          placeholder="Nombre de la empresa"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-18px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
          Descripción de la empresa
        </Typography>
        <TextField
          placeholder="Descripción de la empresa"
          multiline
          rows={4}
          value={companyDescription}
          onChange={(e) => setCompanyDescription(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-18px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
          URL de contacto
        </Typography>
        <TextField
          placeholder="URL de contacto (LinkedIn, página web, etc.)"
          value={contactUrl}
          onChange={(e) => setContactUrl(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-18px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
          Método de contacto
        </Typography>
        <Select
          placeholder="Método de contacto"
          value={contactMethod}
          displayEmpty
          onChange={(e) => setContactMethod(e.target.value)}
          fullWidth
          variant="outlined"
          sx={{
            color: themeMode === 'dark' ? '#ffffff' : '#181818',
            bgcolor: themeMode === 'dark' ? '#181818' : '#fafafa',
            '& .MuiSvgIcon-root': {
              color: themeMode === 'dark' ? '#ffffff' : '#4a3aff',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: themeMode === 'dark' ? '#272727' : '#eeeeee',
            },
            borderRadius: '13px',
            fontFamily: 'Outfit'
          }}
          MenuProps={{
            sx: {
              '& .MuiPaper-root': {
                bgcolor: themeMode === 'dark' ? '#181818' : '#fff',
                color: themeMode === 'dark' ? '#ffffff' : '#333',
                borderRadius: '15px',
                boxShadow: themeMode === "dark"
                  ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                  : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
              },
              '& .MuiMenu-list .MuiMenuItem-root': {
                '&:hover': {
                  bgcolor: themeMode === 'light' ? '#fafafa' : '',
                },
              },
            },
          }}
        >
          <MenuItem disabled value="" sx={{ fontFamily: 'Outfit' }}>
            Selecciona un método de contacto
          </MenuItem>
          <MenuItem value="Email" sx={{ fontFamily: 'Outfit' }}>Correo electrónico</MenuItem>
          <MenuItem value="LinkedIn" sx={{ fontFamily: 'Outfit' }}>LinkedIn</MenuItem>
        </Select>
        {contactMethod === 'LinkedIn' && (
          <>
            <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-18px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
              Nombre de la persona a contactar
            </Typography>
            <TextField
              placeholder="Nombre de la persona a contactar"
              value={contactPerson}
              onChange={(e) => setContactPerson(e.target.value)}
              fullWidth
              variant="outlined"
            />
          </>
        )}
        <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-18px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
          Selecciona un agente de ventas
        </Typography>
        <Select
          placeholder="Selecciona un agente de ventas"
          value={salesAgent}
          displayEmpty
          onChange={(e) => setSalesAgent(e.target.value)}
          fullWidth
          variant="outlined"
          sx={{
            color: themeMode === 'dark' ? '#ffffff' : '#181818',
            bgcolor: themeMode === 'dark' ? '#181818' : '#fafafa',
            '& .MuiSvgIcon-root': {
              color: themeMode === 'dark' ? '#ffffff' : '#4a3aff',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: themeMode === 'dark' ? '#272727' : '#eeeeee',
            },
            borderRadius: '13px',
            fontFamily: 'Outfit'
          }}
          MenuProps={{
            sx: {
              '& .MuiPaper-root': {
                bgcolor: themeMode === 'dark' ? '#181818' : '#fff',
                color: themeMode === 'dark' ? '#ffffff' : '#333',
                borderRadius: '15px',
                boxShadow: themeMode === "dark"
                  ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                  : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
              },
              '& .MuiMenu-list .MuiMenuItem-root': {
                '&:hover': {
                  bgcolor: themeMode === 'light' ? '#fafafa' : '',
                },
              },
            },
          }}
        >
          <MenuItem disabled value="" sx={{ fontFamily: 'Outfit' }}>
            Selecciona un agente de ventas
          </MenuItem>
          {salesAgents.map(agent => (
            <MenuItem key={agent.name} value={agent.name} sx={{ fontFamily: 'Outfit' }}>
              {agent.name} - {agent.title}
            </MenuItem>
          ))}
        </Select>
      </ThemeProvider>

      <Button
        variant="contained"
        onClick={generateSalesMessage}
        disabled={!companyName || !companyDescription || !contactMethod || !salesAgent || (contactMethod === 'LinkedIn' && !contactPerson) || loading}
        startIcon={loading ? <CircularProgress size="25px" /> : <AutoAwesomeRoundedIcon />}
        sx={{
          height: '50px',
          lineHeight: 1,
          maxWidth: '200px',
          color: '#fff',
          fontSize: isMobile ? '16px' : "16px",
          backgroundColor: '#4a3aff',
          fontWeight: "500",
          textTransform: "none",
          padding: "10px 20px",
          borderRadius: '100px',
          borderWidth: 1,
          borderStyle: 'solid',
          fontFamily: 'Outfit',
          borderColor: '#4a3aff',
          '&:hover': {
            borderColor: '#4a3aff',
            color: themeMode === 'dark' ? "white" : '#181818',
            backgroundColor: 'transparent',
            borderWidth: 1,
            borderStyle: 'solid',
          },
          '&:focus': {
            outline: 'none',
          },
          mt: 2,
        }}
      >
        {loading ? 'Generando...' : 'Generar mensaje'}
      </Button>

      {generatedMessage && (
        <>
          <Typography variant="body1" sx={{ mt: 2, color: themeMode === "dark" ? "#fafafa" : "#181818", whiteSpace: 'pre-line' }} ref={messageRef}>
            {generatedMessage}
          </Typography>
          <Button variant="outlined"
            onClick={handleCopyMessage}
            disabled={!generatedMessage}
            sx={{
              height: '50px',
              lineHeight: 1,
              maxWidth: '200px',
              color: themeMode === "dark" ? "#fafafa" : "#181818",
              fontSize: isMobile ? '16px' : "16px",
              backgroundColor: 'transparent',
              fontWeight: "500",
              textTransform: "none",
              padding: "10px 20px",
              borderRadius: '100px',
              borderWidth: 1,
              borderStyle: 'solid',
              fontFamily: 'Outfit',
              borderColor: '#4a3aff',
              mx: 'auto',
              '&:hover': {
                borderColor: '#4a3aff',
                color: themeMode === 'dark' ? "white" : '#181818',
                backgroundColor: 'transparent',
                borderWidth: 1,
                borderStyle: 'solid',
              },
              '&:focus': {
                outline: 'none',
              },
              mt: 2,
            }}
          >
            Copiar mensaje
          </Button>
          <Button variant="contained"
            onClick={handleSaveProspect}
            disabled={!generatedMessage}
            sx={{
              height: '50px',
              lineHeight: 1,
              maxWidth: '200px',
              color: '#fff',
              fontSize: isMobile ? '16px' : "16px",
              backgroundColor: '#4a3aff',
              fontWeight: "500",
              textTransform: "none",
              padding: "10px 20px",
              borderRadius: '100px',
              borderWidth: 1,
              borderStyle: 'solid',
              fontFamily: 'Outfit',
              borderColor: '#4a3aff',
              mx: 'auto',
              '&:hover': {
                borderColor: '#4a3aff',
                color: themeMode === 'dark' ? "white" : '#181818',
                backgroundColor: 'transparent',
                borderWidth: 1,
                borderStyle: 'solid',
              },
              '&:focus': {
                outline: 'none',
              },
            }}
          >
            Guardar Prospecto
          </Button>
        </>
      )}
    </Box>
  );
};

export default SalesMessageGenerator;
