import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../context/Config/ConfigContext';
import StoreContext from '../../context/Store/storeContext';
import { Box, Stack, Typography, ThemeProvider, createTheme, CircularProgress, TextField, Select, MenuItem, Grid, FormControl, IconButton, Button, useMediaQuery, useTheme, ListItemIcon, InputAdornment } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ImageRoundedIcon from '@mui/icons-material/ImageRounded';
import VideoLibraryRoundedIcon from '@mui/icons-material/VideoLibraryRounded';
import SectionContainer from '../../global/SectionContainer';
import DialogCommon from '../../components/DialogCommon/DialogCommon';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { collection, addDoc, query, where, getDocs, doc, deleteDoc, updateDoc, getDoc } from 'firebase/firestore';
import { auth, db, storage } from '../../services/firebase/firebase';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { showToast } from '../../components/Toast/toast';
import { motion } from 'framer-motion';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

export default function Media() {
  const { t } = useTranslation('global');
  const { themeMode } = useContext(ConfigContext);
  const { user, chatbots } = useContext(StoreContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [selectedMediaType, setSelectedMediaType] = useState<'image' | 'video' | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState('');
  const [description, setDescription] = useState('');
  const [media, setMedia] = useState<any[]>([]);
  const [loadingMedia, setLoadingMedia] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedMediaToDelete, setSelectedMediaToDelete] = useState<any>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedMediaToEdit, setSelectedMediaToEdit] = useState<any>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showAllImages, setShowAllImages] = useState(false);
  const [showAllVideos, setShowAllVideos] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedChatbotIds, setSelectedChatbotIds] = useState<string[]>([]);
  const [editChatbotIds, setEditChatbotIds] = useState<string[]>([]);
  const [imageSource, setImageSource] = useState<'upload' | 'url' | ''>('');

  const isSubscriptionCanceled = user.subscriptionStatus === 'canceled';

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setFile(null);
    setFileUrl('');
    setDescription('');
    setTimeout(() => {
      setSelectedMediaType(null);
    }, 200);
  };

  const handleOpenDeleteDialog = (mediaItem: any) => {
    setSelectedMediaToDelete(mediaItem);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setSelectedMediaToDelete(null);
  };

  const handleDeleteMedia = async () => {
    if (selectedMediaToDelete) {
      setIsDeleting(true);
      const mediaDocRef = doc(db, 'media', selectedMediaToDelete.id);

      try {
        const isExternalUrl = !selectedMediaToDelete.url.includes('firebase');

        if (selectedMediaToDelete.type === 'image' && !isExternalUrl) {
          const storageRef = ref(storage, `media/images/${selectedMediaToDelete.name}`);
          await deleteObject(storageRef);
        }

        await deleteDoc(mediaDocRef);

        setMedia(media.filter(item => item.id !== selectedMediaToDelete.id));
        handleCloseDeleteDialog();
        showToast('success', t('mediaPage.mediaDeleted'), themeMode);
      } catch (error) {
        console.error('Error deleting media:', error);
        showToast('error', t('mediaPage.errorDeletingMedia'), themeMode);
      } finally {
        setTimeout(() => {
          setIsDeleting(false);
        }, 200);
      }
    }
  };


  const handleEditMedia = async () => {
    if (selectedMediaToEdit) {
      setIsEditing(true);
      const mediaDocRef = doc(db, 'media', selectedMediaToEdit.id);
      try {
        await updateDoc(mediaDocRef, {
          description,
          chatbotIds: editChatbotIds,
        });
        setMedia(media.map(item => (item.id === selectedMediaToEdit.id ? { ...item, description, chatbotIds: editChatbotIds } : item)));
        handleCloseEditDialog();
        showToast('success', t('mediaPage.mediaUpdated'), themeMode);
      } catch (error) {
        console.error('Error updating media:', error);
        showToast('error', t('mediaPage.errorUpdatingMedia'), themeMode);
      } finally {
        setTimeout(() => {
          setIsEditing(false);
        }, 200)

      }
    }
  };

  useEffect(() => {
    const fetchMedia = async () => {
      if (user) {
        try {
          const q = query(collection(db, 'media'), where('ownerId', '==', auth.currentUser?.uid));
          const querySnapshot = await getDocs(q);
          const mediaData = querySnapshot.docs.map(async (mediaDoc) => {
            const data = mediaDoc.data();
            const chatbotDetails = await Promise.all(
              (data.chatbotIds || []).map(async (chatbotId: any) => {
                const chatbotDocRef = doc(db, 'chatbots', chatbotId);
                const chatbotDocSnapshot = await getDoc(chatbotDocRef);
                return chatbotDocSnapshot.data();
              })
            );
            return { ...data, chatbotDetails, id: mediaDoc.id };
          });
          const mediaWithChatbots = await Promise.all(mediaData);
          setMedia(mediaWithChatbots);
        } catch (error) {
          console.error('Error fetching media:', error);
        } finally {
          setLoadingMedia(false);
        }
      }
    };

    fetchMedia();
  }, [user]);

  const handleUpload = async () => {
    if (!selectedChatbotIds.length || !selectedMediaType || (!file && selectedMediaType === 'image' && !fileUrl)) return;

    setIsUploading(true);
    setLoadingMessage(t('mediaPage.uploading') as string);

    let mediaUrl = fileUrl;

    try {
      if (selectedMediaType === 'video' && fileUrl.includes('youtube.com')) {
        mediaUrl = convertToYouTubeEmbedUrl(fileUrl);
      }

      if (selectedMediaType === 'image' && imageSource === 'upload' && file) {
        const storageRef = ref(storage, `media/images/${file.name}`);
        const snapshot = await uploadBytes(storageRef, file);
        mediaUrl = await getDownloadURL(snapshot.ref);
      }

      const docRef = await addDoc(collection(db, 'media'), {
        ownerId: auth.currentUser?.uid,
        chatbotIds: selectedChatbotIds,
        type: selectedMediaType,
        url: mediaUrl,
        description: description,
        timestamp: new Date(),
        name: file ? file.name : fileUrl,
      });

      setLoadingMessage('');
      handleCloseDialog();
      setMedia(prev => [...prev, { id: docRef.id, type: selectedMediaType, url: mediaUrl, description, chatbotIds: selectedChatbotIds, name: file ? file.name : fileUrl }]);
      showToast('success', t('mediaPage.mediaUploaded'), themeMode);
      setTimeout(() => {
        setIsUploading(false);
      }, 200)

    } catch (error) {
      console.error('Error uploading media:', error);
      setTimeout(() => {
        setIsUploading(false);
      }, 200)
      setLoadingMessage('');
      showToast('error', t('mediaPage.errorUploadingMedia'), themeMode);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
      const reader = new FileReader();
      reader.onload = (e) => setFileUrl(e.target?.result as string);
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const [originalDescription, setOriginalDescription] = useState('');
  const [originalChatbotIds, setOriginalChatbotIds] = useState<string[]>([]);

  const handleOpenEditDialog = (mediaItem: any) => {
    setSelectedMediaToEdit(mediaItem);
    setDescription(mediaItem.description);
    setEditChatbotIds(mediaItem.chatbotIds || []);

    // Almacenar valores originales
    setOriginalDescription(mediaItem.description);
    setOriginalChatbotIds(mediaItem.chatbotIds || []);

    setEditDialogOpen(true);
  };

  const hasChanges = (): boolean => {
    return (
      description !== originalDescription ||
      editChatbotIds.length !== originalChatbotIds.length ||
      !editChatbotIds.every(id => originalChatbotIds.includes(id))
    );
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
    setSelectedMediaToEdit(null);
    setDescription('');
    setOriginalDescription('');
    setOriginalChatbotIds([]);
  };


  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = () => setIsDragging(true);
  const handleDragLeave = () => setIsDragging(false);
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      setFile(event.dataTransfer.files[0]);
      const reader = new FileReader();
      reader.onload = (e) => setFileUrl(e.target?.result as string);
      reader.readAsDataURL(event.dataTransfer.files[0]);
    }
  };

  const MAX_IMAGES = 10;
  const MAX_VIDEOS = 10;
  const CHARACTER_LIMIT = 80;

  const groupedMedia = {
    images: media.filter(item => item.type === 'image'),
    videos: media.filter(item => item.type === 'video'),
  };

  const isImageLimitReached = groupedMedia.images.length >= MAX_IMAGES;
  const isVideoLimitReached = groupedMedia.videos.length >= MAX_VIDEOS;
  const isMediaLimitReached = isImageLimitReached && isVideoLimitReached;
  const maxItemsToShowImage = 4;
  const maxItemsToShowVideo = 3;

  const isSaveDisabled =
    isUploading ||
    !selectedMediaType ||
    !fileUrl ||
    !description ||
    selectedChatbotIds.length === 0;

  const convertToYouTubeEmbedUrl = (url: string): string => {
    const urlObj = new URL(url);
    const videoId = urlObj.searchParams.get('v');
    return videoId ? `https://www.youtube.com/embed/${videoId}` : url;
  };

  const themee = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            fontFamily: 'Outfit',
            border: themeMode === "dark" ? '1px solid #272727' : '1px solid #eeeeee',
            backgroundColor: themeMode === "dark" ? "#181818" : "#fafafa",
            borderRadius: 15,
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#181818" : "#fafafa",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',
            },
            "& .MuiInputBase-input": {
              fontFamily: 'Outfit',
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiInputLabel-root": {
              fontFamily: 'Outfit',
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#4a3aff" : '#4a3aff',
            },
            "& .MuiInputLabel-root.Mui-focused + .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#4a3aff" : '#4a3aff'
            },
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 15,
          },
        },
      },
    }
  });

  return (
    <SectionContainer
      title={t('mediaPage.title') as string}
      text={t('mediaPage.uploadMedia') as string}
      icon={<AddRoundedIcon />}
      onClick={handleOpenDialog}
      disableButton={isMediaLimitReached || isSubscriptionCanceled}
    >
      {loadingMedia ? (
        <Stack flexDirection="column" justifyContent="center" alignItems="center" gap={2} sx={{ mt: '100px' }}>
          <CircularProgress sx={{ color: themeMode === "dark" ? '#fafafa' : '#4a3aff' }} />
        </Stack>
      ) : media.length === 0 ? (
        <Stack flexDirection="column" justifyContent="center" alignItems="center" gap={2} sx={{ mt: '100px', padding: '20px' }}>
          <ImageRoundedIcon sx={{ fontSize: '60px', color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1' }} />
          <Typography align="center" sx={{
            fontSize: isMobile ? '16px' : '18px', fontFamily: 'Outfit', fontWeight: '300',
            color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1', maxWidth: '600px'
          }}>
            {t('mediaPage.noMedia')}
          </Typography>
        </Stack>
      ) : (
        <Box sx={{ mt: '20px', mb: isMobile ? '70px' : '30px' }}>
          {groupedMedia.images.length > 0 && (
            <Box sx={{ mb: '20px' }}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
                <Typography variant="h6" sx={{ color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                  {t('mediaPage.images')}
                </Typography>
                <Typography variant="body2" sx={{ color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                  {`${groupedMedia.images.length}/${MAX_IMAGES}`}
                </Typography>
              </Stack>
              <Grid container spacing={2}>
                {(showAllImages ? groupedMedia.images : groupedMedia.images.slice(0, maxItemsToShowImage)).map((image: any, index: any) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <Box
                      sx={{
                        backgroundColor: themeMode === "dark" ? '#181818' : '#fff',
                        borderRadius: '15px',
                        padding: '16px',
                        boxShadow: themeMode === "dark"
                          ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                          : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box sx={{ position: 'relative', borderRadius: '15px', overflow: 'hidden', maxHeight: '200px', height: '200px' }}>
                        <img
                          src={image.url}
                          alt={image.description}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: '15px',
                          }}
                        />
                        <IconButton
                          sx={{
                            position: 'absolute',
                            top: 10,
                            right: 55,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            color: '#fff',
                            borderRadius: '50%',
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            },
                            '&:focus': {
                              outline: 'none',
                            },
                          }}
                          onClick={() => handleOpenEditDialog(image)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          sx={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            color: '#fff',
                            borderRadius: '50%',
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            },
                            '&:focus': {
                              outline: 'none',
                            },
                          }}
                          onClick={() => handleOpenDeleteDialog(image)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                      <Typography
                        align="center"
                        sx={{
                          mt: 1,
                          color: themeMode === "dark" ? '#fafafa' : '#181818',
                        }}
                      >
                        {image.description}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              {groupedMedia.images.length > maxItemsToShowImage && (
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                  <Button onClick={() => setShowAllImages(!showAllImages)} variant="contained"
                    sx={{
                      height: 'auto',
                      color: '#fff',
                      fontSize: isMobile ? '14px' : "14px",
                      fontWeight: "400",
                      textTransform: "none",
                      padding: "8px 15px",
                      borderRadius: '100px',
                      borderColor: "transparent",
                      borderWidth: 1,
                      borderStyle: 'solid',
                      fontFamily: 'Outfit',
                      backgroundColor: '#4a3aff',
                      textAlign: 'center',
                      mx: 'auto',
                      '&:hover': {
                        borderColor: '#4a3aff',
                        color: themeMode === "dark" ? "white" : '#4a3aff',
                        backgroundColor: 'transparent',
                        borderWidth: 1,
                        borderStyle: 'solid',
                        boxShadow: 'none'
                      },
                      '&:focus': {
                        outline: 'none',
                      },

                    }}
                  >
                    {showAllImages ? t('mediaPage.showLess') : t('mediaPage.showMore')}
                  </Button>
                </Box>
              )}
            </Box>
          )}

          {groupedMedia.videos.length > 0 && (
            <Box sx={{ mb: '20px' }}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
                <Typography variant="h6" sx={{ color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                  {t('mediaPage.videos')}
                </Typography>
                <Typography variant="body2" sx={{ color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                  {`${groupedMedia.videos.length}/${MAX_VIDEOS}`}
                </Typography>
              </Stack>
              <Grid container spacing={2}>
                {(showAllVideos ? groupedMedia.videos : groupedMedia.videos.slice(0, maxItemsToShowVideo)).map((video: any, index: any) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Box
                      sx={{
                        backgroundColor: themeMode === "dark" ? '#181818' : '#fff',
                        borderRadius: '15px',
                        padding: '16px',
                        boxShadow: themeMode === "dark"
                          ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                          : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box sx={{ position: 'relative', borderRadius: '15px', overflow: 'hidden', maxHeight: '200px', height: '200px' }}>
                        <iframe
                          src={video.url}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: '15px',
                          }}
                          frameBorder="0"
                          allowFullScreen
                        ></iframe>
                        <IconButton
                          sx={{
                            position: 'absolute',
                            top: 10,
                            right: 55,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            color: '#fff',
                            borderRadius: '50%',
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            },
                            '&:focus': {
                              outline: 'none',
                            },
                          }}
                          onClick={() => handleOpenEditDialog(video)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          sx={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            color: '#fff',
                            borderRadius: '50%',
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            },
                            '&:focus': {
                              outline: 'none',
                            },
                          }}
                          onClick={() => handleOpenDeleteDialog(video)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                      <Typography
                        align="center"
                        sx={{
                          mt: 1,
                          color: themeMode === "dark" ? '#fafafa' : '#181818',
                        }}
                      >
                        {video.description}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              {groupedMedia.videos.length > maxItemsToShowVideo && (
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                  <Button onClick={() => setShowAllVideos(!showAllVideos)} variant="contained"
                    sx={{
                      height: 'auto',
                      color: '#fff',
                      fontSize: "14px",
                      fontWeight: "400",
                      textTransform: "none",
                      padding: "8px 15px",
                      borderRadius: '100px',
                      borderColor: "transparent",
                      borderWidth: 1,
                      borderStyle: 'solid',
                      fontFamily: 'Outfit',
                      backgroundColor: '#4a3aff',
                      textAlign: 'center',
                      mx: 'auto',
                      '&:hover': {
                        borderColor: '#4a3aff',
                        color: themeMode === "dark" ? "white" : '#4a3aff',
                        backgroundColor: 'transparent',
                        borderWidth: 1,
                        borderStyle: 'solid',
                        boxShadow: 'none'
                      },
                      '&:focus': {
                        outline: 'none',
                      },
                    }}
                  >
                    {showAllVideos ? t('mediaPage.showLess') : t('mediaPage.showMore')}
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}

      <DialogCommon
        kTitle={t('mediaPage.uploadMedia')}
        kDisableActions={isUploading || !selectedMediaType}
        kMaxWidth={isUploading ? "xs" : "sm"}
        kMaxHeight={
          isUploading
            ? '350px'
            : selectedMediaType
              ? imageSource === 'upload'
                ? '750px'
                : '600px'
              : '400px'
        }

        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kEnableBorderRadius={true}
        kBorderRadius={'30px'}
        kAlignBtn="center"
        kKeepBtnSize
        kTopDivider
        kSlideTransition
        kOpenDialogLists={openDialog}
        kOnClose={handleCloseDialog}
        kOnPrimary={handleUpload}
        kOnSecondary={handleCloseDialog}
        kSecondaryBtnText={t('mediaPage.cancel') as string}
        kPrimaryBtnText={t('mediaPage.upload') as string}
        kDisableBtnSave={isSaveDisabled}
        kContent={
          isUploading ? (
            <Box sx={{ textAlign: 'center' }}>
              <CircularProgress sx={{ color: themeMode === "dark" ? '#fafafa' : '#4a3aff' }} />
              <motion.div
                key={loadingMessage}
                variants={{
                  hidden: { opacity: 0 },
                  visible: { opacity: 1 },
                }}
                initial="hidden"
                animate="visible"
                exit="exit"
                transition={{ duration: 0.5 }}
              >
                <Typography sx={{
                  mt: 2,
                  fontWeight: '300',
                  fontFamily: 'Outfit',
                  fontSize: '16px',
                  color: themeMode === "dark" ? "#fafafa" : "#181818"
                }}>
                  {loadingMessage}
                </Typography>
              </motion.div>
            </Box>
          ) : (
            <>
              {!selectedMediaType ? (
                <Stack flexDirection="column" justifyContent="center" alignItems="center" gap={3} sx={{ width: '100%', mb: '50px' }}>
                  <Typography sx={{ fontSize: '18px', color: themeMode === "dark" ? '#fafafa' : '#181818', textAlign: 'center', fontFamily: 'Outfit', fontWeight: '400' }}>
                    {t('mediaPage.selectMediaType')}
                  </Typography>

                  <Stack flexDirection="row" justifyContent="center" alignItems="center" gap={2} sx={{ width: '100%' }}>
                    <Box
                      onClick={() => !isImageLimitReached && setSelectedMediaType('image')}
                      sx={{
                        textAlign: 'center',
                        cursor: isImageLimitReached ? 'not-allowed' : 'pointer',
                        padding: '20px',
                        borderRadius: '15px',
                        border: `1px solid ${themeMode === "dark" ? "#4a3aff" : "#4a3aff"}`,
                        width: '150px',
                        opacity: isImageLimitReached ? 0.5 : 1,
                        transition: 'background-color 0.2s ease, color 0.2s ease',
                        '&:hover': {
                          backgroundColor: isImageLimitReached ? 'transparent' : '#4a3aff',
                          '& .MuiSvgIcon-root': {
                            color: isImageLimitReached ? '#4a3aff' : '#ffffff',
                            transition: 'color 0.3s ease',
                          },
                          '& .MuiTypography-root': {
                            color: isImageLimitReached ? themeMode === "dark" ? '#fafafa' : '#181818' : '#ffffff',
                            transition: 'color 0.2s ease',
                          },
                        },
                      }}
                    >
                      <ImageRoundedIcon sx={{ fontSize: '40px', color: themeMode === "dark" ? '#4a3aff' : '#4a3aff' }} />
                      <Typography sx={{ color: themeMode === "dark" ? '#fafafa' : '#181818' }}>{t('mediaPage.image')}</Typography>
                    </Box>
                    <Box
                      onClick={() => !isVideoLimitReached && setSelectedMediaType('video')}
                      sx={{
                        textAlign: 'center',
                        cursor: isVideoLimitReached ? 'not-allowed' : 'pointer',
                        padding: '20px',
                        borderRadius: '15px',
                        border: `1px solid ${themeMode === "dark" ? "#4a3aff" : "#4a3aff"}`,
                        width: '150px',
                        opacity: isVideoLimitReached ? 0.5 : 1,
                        transition: 'background-color 0.2s ease, color 0.2s ease',
                        '&:hover': {
                          backgroundColor: isVideoLimitReached ? 'transparent' : '#4a3aff',
                          '& .MuiSvgIcon-root': {
                            color: isVideoLimitReached ? '#4a3aff' : '#ffffff',
                            transition: 'color 0.3s ease',
                          },
                          '& .MuiTypography-root': {
                            color: isVideoLimitReached ? themeMode === "dark" ? '#fafafa' : '#181818' : '#ffffff',
                            transition: 'color 0.2s ease',
                          },
                        },
                      }}
                    >
                      <VideoLibraryRoundedIcon sx={{ fontSize: '40px', color: themeMode === "dark" ? '#4a3aff' : '#4a3aff' }} />
                      <Typography sx={{ color: themeMode === "dark" ? '#fafafa' : '#181818' }}>{t('mediaPage.video')}</Typography>
                    </Box>
                  </Stack>
                </Stack>
              ) : (
                <Stack flexDirection="column" justifyContent="center" alignItems="center" gap={2} sx={{ width: '100%' }}>
                  {selectedMediaType === 'image' ? (
                    <Typography sx={{ textAlign: 'center', fontFamily: 'Outfit', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                      {t('mediaPage.imageUploadDescriptionPlural')}
                    </Typography>
                  ) : (
                    <Typography sx={{ textAlign: 'center', fontFamily: 'Outfit', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                      {t('mediaPage.videoUploadDescriptionPlural')}
                    </Typography>
                  )}

                  {selectedMediaType === 'image' && (
                    <>
                      <FormControl fullWidth sx={{ mt: 2 }}>
                        <Select
                          value={imageSource}
                          onChange={(e) => setImageSource(e.target.value as 'upload' | 'url')}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          sx={{
                            color: themeMode === 'dark' ? '#ffffff' : '#181818',
                            bgcolor: themeMode === 'dark' ? '#181818' : '#fafafa',
                            '& .MuiSvgIcon-root': {
                              color: themeMode === 'dark' ? '#ffffff' : '#4a3aff',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: themeMode === 'dark' ? '#272727' : '#eeeeee',
                            },
                            borderRadius: '13px',
                          }}
                          MenuProps={{
                            sx: {
                              '& .MuiPaper-root': {
                                bgcolor: themeMode === 'dark' ? '#181818' : '#fff',
                                color: themeMode === 'dark' ? '#ffffff' : '#333',
                                borderRadius: '15px',
                                boxShadow: themeMode === "dark"
                                  ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                                  : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                              },
                              '& .MuiMenu-list .MuiMenuItem-root': {
                                '&:hover': {
                                  bgcolor: themeMode === 'light' ? '#fafafa' : '',
                                },
                              },
                            },
                          }}
                        >
                          <MenuItem disabled value="">
                            <em>{t('mediaPage.selectImageSource')}</em>
                          </MenuItem>
                          <MenuItem value="upload">{t('mediaPage.uploadImageFile')}</MenuItem>
                          <MenuItem value="url">{t('mediaPage.enterImageUrl')}</MenuItem>
                        </Select>
                      </FormControl>

                      {imageSource === 'upload' && (
                        <Box
                          sx={{
                            mt: 2,
                            width: '100%',
                            height: '200px',
                            borderRadius: '15px',
                            border: `2px dashed ${isDragging ? '#4a3aff' : themeMode === 'dark' ? '#666666' : '#999999'}`,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            overflow: 'hidden',
                            backgroundColor: themeMode === 'dark' ? '#181818' : '#fafafa',
                            position: 'relative',
                          }}
                          onDragEnter={handleDragEnter}
                          onDragLeave={handleDragLeave}
                          onDrop={handleDrop}
                        >
                          {!fileUrl ? (
                            <Stack alignItems="center">
                              <AddRoundedIcon sx={{ fontSize: '40px', color: themeMode === 'dark' ? '#4a3aff' : '#4a3aff' }} />
                              <Typography sx={{ color: themeMode === 'dark' ? '#666666' : '#999999' }}>
                                {t('mediaPage.uploadImageText')}
                              </Typography>
                            </Stack>
                          ) : (
                            <img
                              src={fileUrl}
                              alt="Preview"
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: '15px',
                              }}
                            />
                          )}
                          <input
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={handleFileChange}
                            style={{
                              position: 'absolute',
                              width: '100%',
                              height: '100%',
                              top: 0,
                              left: 0,
                              opacity: 0,
                              cursor: 'pointer',
                            }}
                          />
                        </Box>
                      )}

                      {imageSource === 'url' && (
                        <ThemeProvider theme={themee}>
                          <TextField
                            fullWidth
                            placeholder={t('mediaPage.imageUrlPlaceholder') as string}
                            value={fileUrl}
                            onChange={(e) => setFileUrl(e.target.value)}
                            sx={{ mt: 2 }}
                          />
                        </ThemeProvider>
                      )}
                    </>
                  )}

                  {selectedMediaType === 'video' && (
                    <ThemeProvider theme={themee}>
                      <TextField
                        fullWidth
                        placeholder={t('mediaPage.videoUrl') as string}
                        value={fileUrl}
                        onChange={(e) => setFileUrl(e.target.value)}
                        sx={{ mt: 2 }}
                      />
                    </ThemeProvider>
                  )}

                  <ThemeProvider theme={themee}>
                    <TextField
                      fullWidth
                      placeholder={selectedMediaType === 'image'
                        ? t('mediaPage.imageDescriptionPlaceholder') ?? ''
                        : t('mediaPage.videoDescriptionPlaceholder') ?? ''}
                      value={description}
                      onChange={(e) => {
                        if (e.target.value.length <= CHARACTER_LIMIT) {
                          setDescription(e.target.value);
                        }
                      }}
                      sx={{ mt: 2 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" sx={{ position: 'absolute', right: 8, bottom: 8 }}>
                            <Typography variant="caption" sx={{ color: description.length >= CHARACTER_LIMIT ? 'red' : '#999' }}>
                              {`${description.length}/${CHARACTER_LIMIT}`}
                            </Typography>
                          </InputAdornment>
                        ),
                        sx: {
                          position: 'relative',
                        },
                      }}
                    />
                  </ThemeProvider>

                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <Select
                      multiple
                      value={selectedChatbotIds}
                      onChange={(e) => setSelectedChatbotIds(e.target.value as string[])}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      sx={{
                        color: themeMode === 'dark' ? '#ffffff' : '#181818',
                        bgcolor: themeMode === 'dark' ? '#181818' : '#fafafa',
                        '& .MuiSvgIcon-root': {
                          color: themeMode === 'dark' ? '#ffffff' : '#4a3aff',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: themeMode === 'dark' ? '#272727' : '#eeeeee',
                        },
                        '& .MuiSelect-select:placeholder': {
                          color: themeMode === 'dark' ? '#666666' : '#999999',
                        },
                        borderRadius: '13px',
                      }}
                      renderValue={(selected) => {
                        if (!selected || selected.length === 0) {
                          return <span style={{ color: themeMode === 'dark' ? '#666666' : '#999999' }}>{t('mediaPage.selectPlaceholder')}</span>;
                        }
                        return selected.map((id) => chatbots.find((chatbot: any) => chatbot.id === id)?.companyName).join(', ');
                      }}
                      MenuProps={{
                        sx: {
                          '& .MuiPaper-root': {
                            bgcolor: themeMode === 'dark' ? '#181818' : '#fff',
                            color: themeMode === 'dark' ? '#ffffff' : '#333',
                            borderRadius: '15px',
                            boxShadow: themeMode === "dark"
                              ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                              : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                          },
                          '& .MuiMenu-list .MuiMenuItem-root': {
                            '&:hover': {
                              bgcolor: themeMode === 'light' ? '#fafafa' : '',
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>{t('mediaPage.selectPlaceholder')}</em>
                      </MenuItem>
                      {chatbots.map((chatbot: any) => (
                        <MenuItem key={chatbot.id} value={chatbot.id}>
                          <ListItemIcon>
                            {selectedChatbotIds.includes(chatbot.id) && (
                              <CheckCircleRoundedIcon sx={{ color: '#4a3aff' }} />
                            )}
                          </ListItemIcon>
                          {chatbot.companyName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>

              )}
            </>
          )
        }
      />


      <DialogCommon
        kTitle={t('mediaPage.deleteConfirm')}
        kDisableActions={isDeleting}
        kMaxWidth="xs"
        kMaxHeight="250px"
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kEnableBorderRadius={true}
        kBorderRadius="30px"
        kAlignBtn="center"
        kKeepBtnSize
        kTopDivider
        kSlideTransition
        kOpenDialogLists={deleteDialogOpen}
        kOnClose={handleCloseDeleteDialog}
        kOnPrimary={handleDeleteMedia}
        kOnSecondary={handleCloseDeleteDialog}
        kSecondaryBtnText={t('mediaPage.cancel') as string}
        kPrimaryBtnText={t('mediaPage.delete') as string}
        kContent={
          isDeleting ? (
            <Box sx={{ textAlign: 'center' }}>
              <CircularProgress sx={{ color: themeMode === "dark" ? '#fafafa' : '#4a3aff' }} />
            </Box>
          ) : (
            <Typography sx={{ color: themeMode === "dark" ? "#fafafa" : "#181818" }}>
              {t('mediaPage.deleteMessage')}
            </Typography>
          )
        }
      />

      <DialogCommon
        kTitle={t('mediaPage.editDescription')}
        kDisableActions={isEditing}
        kMaxWidth="sm"
        kMaxHeight="350px"
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kEnableBorderRadius={true}
        kBorderRadius="30px"
        kAlignBtn="center"
        kKeepBtnSize
        kTopDivider
        kSlideTransition
        kOpenDialogLists={editDialogOpen}
        kOnClose={handleCloseEditDialog}
        kOnPrimary={handleEditMedia}
        kOnSecondary={handleCloseEditDialog}
        kSecondaryBtnText={t('mediaPage.cancel') as string}
        kPrimaryBtnText={t('mediaPage.save') as string}
        kDisableBtnSave={!hasChanges() || isEditing}
        kContent={
          isEditing ? (
            <Box sx={{ textAlign: 'center' }}>
              <CircularProgress sx={{ color: themeMode === "dark" ? '#fafafa' : '#4a3aff' }} />
            </Box>
          ) : (
            <Stack flexDirection="column" justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
              <ThemeProvider theme={themee}>
                <TextField
                  fullWidth
                  placeholder={t('mediaPage.editDescriptionPlaceholder') as string}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  sx={{ mt: 2 }}
                />
              </ThemeProvider>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <Select
                  multiple
                  value={editChatbotIds}
                  onChange={(e) => setEditChatbotIds(e.target.value as string[])}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  sx={{
                    color: themeMode === 'dark' ? '#ffffff' : '#181818',
                    bgcolor: themeMode === 'dark' ? '#181818' : '#fafafa',
                    '& .MuiSvgIcon-root': {
                      color: themeMode === 'dark' ? '#ffffff' : '#4a3aff',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: themeMode === 'dark' ? '#272727' : '#eeeeee',
                    },
                    '& .MuiSelect-select:placeholder': {
                      color: themeMode === 'dark' ? '#666666' : '#999999',
                    },
                    borderRadius: '13px',
                  }}
                  renderValue={(selected) => {
                    if (!selected || selected.length === 0) {
                      return <span style={{ color: themeMode === 'dark' ? '#666666' : '#999999' }}>{t('mediaPage.selectPlaceholder')}</span>;
                    }

                    if (!chatbots || chatbots.length === 0) {
                      return "Loading...";
                    }

                    return selected.map((id) => {
                      const chatbot = chatbots.find((chatbot: any) => chatbot.id === id);
                      return chatbot ? chatbot.companyName : "Unknown";
                    }).join(', ');
                  }}
                  MenuProps={{
                    sx: {
                      '& .MuiPaper-root': {
                        bgcolor: themeMode === 'dark' ? '#181818' : '#fff',
                        color: themeMode === 'dark' ? '#ffffff' : '#333',
                        borderRadius: '15px',
                        boxShadow: themeMode === "dark"
                          ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                          : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                      },
                      '& .MuiMenu-list .MuiMenuItem-root': {
                        '&:hover': {
                          bgcolor: themeMode === 'light' ? '#fafafa' : '',
                        },
                      },
                    },
                  }}
                >
                  <MenuItem disabled value="">
                    <em>{t('mediaPage.selectPlaceholder')}</em>
                  </MenuItem>
                  {chatbots.map((chatbot: any) => (
                    <MenuItem key={chatbot.id} value={chatbot.id}>
                      <ListItemIcon>
                        {editChatbotIds.includes(chatbot.id) && (
                          <CheckCircleRoundedIcon sx={{ color: '#4a3aff' }} />
                        )}
                      </ListItemIcon>
                      {chatbot.companyName}
                    </MenuItem>
                  ))}
                </Select>


              </FormControl>
            </Stack>
          )
        }
      />


    </SectionContainer>
  );
}
