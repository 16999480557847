const initialState: {
  chatbots: Chatbot[];
  user: any;
} = {
  chatbots: [],
  user: null,
};


type Chatbot = {
  id: string;
  name: string;
};


const storeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'SET_CHATBOTS':
      return {
        ...state,
        chatbots: action.payload,
      };
    case 'ADD_CHATBOT':
      return {
        ...state,
        chatbots: [...state.chatbots, action.payload],
      };
    case 'UPDATE_CHATBOT':
      const updatedChatbots = state.chatbots.map(chatbot =>
        chatbot.id === action.payload.id ? action.payload : chatbot
      );
      return {
        ...state,
        chatbots: updatedChatbots,
      };
    case 'DELETE_CHATBOT':
      const filteredChatbots = state.chatbots.filter(chatbot => chatbot.id !== action.payload);
      return {
        ...state,
        chatbots: filteredChatbots,
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
      };
    case 'SET_NEEDS_UPDATE':
      return {
        ...state,
        needsUpdate: action.payload,
      };

    default:
      return state;
  }
};

export default storeReducer;
