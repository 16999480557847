import { Box, useMediaQuery, useTheme, IconButton, Stack } from "@mui/material";
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import ModeNightIcon from '@mui/icons-material/ModeNight';
import { useContext } from "react";
import { ConfigContext } from "../context/Config/ConfigContext";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { motion } from "framer-motion";

const ChatTopbar = ({ isScrolled, setIsSidebarOpenFromMobile }: any) => {
  const theme = useTheme();
  const { logo, lightModeLogo, themeMode, toggleTheme, loading,
    // url
  } = useContext(ConfigContext)

  const backgroundColor = themeMode === "dark" ? "#121212" : "#fff";
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  ;

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center"
      sx={{
        backgroundColor: backgroundColor,
        mx: 'auto',
        p: isScrolled ? 0 : 1,
        transition: 'padding 0.5s ease'
      }}
    >
      <Stack flexDirection="row" sx={{ pt: "10px", ml: isMobile ? '10px' : '20px' }} justifyContent="center" alignItems="center">
        <Stack flexDirection="row" justifyContent="start" alignItems="center">
          <IconButton
            sx={{ '&:focus': { outline: 'none' }, paddingTop: 0 }}
            onClick={() => {
              setIsSidebarOpenFromMobile(true);
            }}
          >
            <MenuRoundedIcon sx={{ fontSize: '26px', color: themeMode === "dark" ? "white" : 'black' }} />
          </IconButton>

        </Stack>
        {!loading &&
          <motion.div
            initial={{ scale: 0 }}  // Iniciar con un tamaño de 0
            animate={{ scale: 1 }} // Animar a su tamaño original
            transition={{ duration: 0.5 }} // Duración de la animación
          >
            {/* Aquí el resto de tu código */}
            <img
              src={themeMode === "dark" ? logo : lightModeLogo || logo}
              alt="logo"
              height={isMobile || isScrolled ? '35px' : '40px'}
              style={{ transition: 'height 0.5s ease', marginLeft: '10px' }}
            />
          </motion.div>
        }
      </Stack>
      <IconButton onClick={() => toggleTheme()} sx={{
        "&:focus": {
          outline: "none"
        },
        mr: isMobile ? '10px' : '20px'
      }}>
        {themeMode === "dark" ? <LightModeRoundedIcon sx={{ color: '#7c7c7c' }} /> : <ModeNightIcon sx={{ color: '#7c7c7c' }} />}
      </IconButton>

    </Box>
  );
};

export default ChatTopbar;
