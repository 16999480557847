import { useEffect, useState, useContext } from 'react';
import { Box, Stack, TextField, Typography, createTheme, ThemeProvider, IconButton, useTheme, useMediaQuery, CircularProgress, Button, InputAdornment, } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useRef } from 'react';
import { motion } from "framer-motion";
import DialogCommon from '../../components/DialogCommon/DialogCommon';
import { processMessageToChatGPT } from './services/chatLogic';
import { ConfigContext } from '../../context/Config/ConfigContext';
import ImageRoundedIcon from '@mui/icons-material/ImageRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { addDoc, collection, doc, getDoc, getDocs, increment, orderBy, query, runTransaction, serverTimestamp, updateDoc, where, writeBatch } from 'firebase/firestore';
import { db } from '../../services/firebase/firebase';
import { Timestamp } from "firebase/firestore";
import PrivacyPolicyES from '../../components/PrivacyPolicy/PrivacyPolicyES';
import PrivacyPolicyEN from '../../components/PrivacyPolicy/PrivacyPolicyEN';
import { updateGlobalMetrics } from '../../services/metrics/metricsUtils';
import { showToast } from '../../components/Toast/toast';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';

type CustomMessageModel = {
  message: string | undefined;
  sender: string;
  timestamp?: Timestamp;
  image?: string | null;
};

// interface SwiperElement extends Element {
//   swiper?: any;
// }

type MediaType = {
  id: string;
  url: string;
  type: string;
  title?: string;
  description?: string;
};

type IntegrationType = {
  id: string;
  platform: string;
  description: string;
  url: string;
};

type Chatbot = {
  assistantFirstMessage: string;
  autoResolveFAQ: boolean;
  businessType: string;
  chatbotFormality: string;
  chatbotPersonality: string
  chatbotTone: string;
  collectFeedback: boolean;
  companyName: string;
  creationDate: string;
  defaultLanguage: string;
  keepChatHistory: boolean;
  logo: string;
  lightModeLogo: string;
  nightMode: boolean;
  primaryColor: string;
  responseTime: string;
  systemInstructions: string;
  useEmojis: boolean;
  chatbotState: string;
  responseCount: number;
  startDemoDate: string;
  suggestion1: string;
  suggestion2: string;
  suggestion3: string;
  companyNumber: string;
  googleFormUrl: string;
  googleFormEntryName: string;
  googleFormEntryEmail: string;
  googleFormEntryMessage: string;
  feedbackInstructions: string;
  ownerId: string
};

function Chat(props: any) {

  const MESSAGE_LIMIT = 30;
  const [hasReachedMessageLimit, setHasReachedMessageLimit] = useState<boolean>(false);
  const [showSuggestions, setShowSuggestions] = React.useState<boolean>(false);
  const { chatbotId, conversationId } = useParams();
  const [user, setUser] = useState<any>(null);
  const [ownerId, setOwnerId] = useState('')
  const [userLoading, setUserLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      setUserLoading(true);
      if (ownerId) {
        const userDocRef = doc(db, 'users', ownerId);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          setUser(userDocSnap.data());
        } else {
          showToast('error', "No se encontró el documento del usuario.", themeMode);
        }
      }
      setUserLoading(false);
    };

    fetchUserData();
  }, [ownerId]);


  const checkSubscriptionType = () => {
    const restrictedTypes = ['basicMonthlyMXN', 'basicAnnualMXN', 'basicMonthlyUSD', 'basicAnnualUSD'];
    return user && restrictedTypes.includes(user.subscriptionType);
  };


  const { assistantFirstMessage, primaryColor, systemInstructions,
    useEmojis, chatbotTone, chatbotFormality, defaultLanguage, companyName,
    chatbotPersonality, businessType, loading, responseCount,
    suggestion1, suggestion2, suggestion3,
    feedbackInstructions,
    updateSystemInstructions,
    updateAssistantFirstMessage,
    updatePrimaryColor,
    updateCompanyLogo,
    updateCompanyLightModeLogo,
    updateCompanyName,
    updateChatbotTone,
    updateChatbotFormality,
    updateDefaultLanguage,
    updateAutoResolveFAQ,
    updateUseEmojis,
    updateResponseTime,
    updateChatbotPersonality,
    updateNightMode,
    updateBusinessType,
    updateChatbotState,
    setLoading,
    updateResponseCount,
    updateStartDemoDate,
    updateSuggestion1,
    updateSuggestion2,
    updateSuggestion3,
    updateCompanyNumber,
    updateFeedbackInstructions,
  } = useContext(ConfigContext);

  const startNewConversation = async (chatbotId: string, userId: string, ownerId: string, firstMessage: string) => {
    try {
      const conversationsRef = collection(db, 'conversations');
      const newConversationRef = await addDoc(conversationsRef, {
        userId,
        ownerId,
        firstMessage,
        firstMessageTimestamp: serverTimestamp(),
        chatbotId,
      });

      if (chatbotId) {
        const chatbotDocRef = doc(db, 'chatbots', chatbotId);

        await updateDoc(chatbotDocRef, {
          conversationCount: increment(1)
        });

        await updateGlobalMetrics({ newConversation: true });
      } else {
        showToast('error', 'El ID del chatbot no es válido.', themeMode)
      }

      return newConversationRef.id;
    } catch (error: any) {
      showToast('error', 'Error al crear una nueva conversación', themeMode)
      return null;
    }
  };

  useEffect(() => {
    const fetchConversationAndMessages = async () => {
      setLoading(true);
      if (chatbotId && props.uid && conversationId) {
        setShowStartNewConversationMessage(true);

        try {
          const conversationRef = doc(db, 'conversations', conversationId);
          const conversationSnapshot = await getDoc(conversationRef);

          if (!conversationSnapshot.exists()) {
            showToast('error', "No existe una conversación con ese ID.", themeMode);
            setLoading(false);
            return;
          }

          const conversationData = conversationSnapshot.data();
          if (conversationData.userId !== props.uid) {
            setMessages([{ message: "No tienes permiso para acceder a esta conversación.", sender: "system" }]);
            setLoading(false);
            return;
          }

          const messagesRef = collection(db, 'messages');
          const messagesQuery = query(messagesRef, where('conversationId', '==', conversationId), orderBy('timestamp'));
          const messagesSnapshot = await getDocs(messagesQuery);

          const loadedMessages = messagesSnapshot.docs.map(doc => {
            const data = doc.data();
            return {
              message: data.message,
              sender: data.sender,
              timestamp: data.timestamp.toDate(),
              image: data.image || null,
            };
          });

          const updatedMessages = [
            { message: assistantFirstMessage, sender: "ChatGPT" },
            ...loadedMessages,
          ];

          setMessages(updatedMessages);
        } catch (error) {
          console.error("Error loading the conversation:", error);
          showToast('error', "Error cargando la conversación.", themeMode);
        } finally {
          setLoading(false);
          setShowSuggestions(false);
        }
      } else {
        setMessages([]);
        setShowStartNewConversationMessage(false);
        setLoading(false);
        setShowSuggestions(true);
      }
    };

    fetchConversationAndMessages();
  }, [chatbotId, props.uid, conversationId, assistantFirstMessage]);


  function getWeekNumber(d: any) {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    const weekNo = Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
    return weekNo;
  }

  const updateMetrics = async (chatbotId: string, { isNewConversation = false, extractedData = false }: { isNewConversation?: boolean, extractedData?: boolean }) => {
    const date = new Date(); // Esto te dará la fecha y hora actual
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // En JavaScript, los meses van de 0 a 11
    const day = date.getDate();
    const week = getWeekNumber(date);

    // Prepara los ID de los documentos basados en los intervalos
    const dailyId = `daily-${year}-${month}-${day}`;
    const weeklyId = `weekly-${year}-${week}`;
    const monthlyId = `monthly-${year}-${month}`;

    // Creación o actualización de documentos para métricas diarias, semanales y mensuales
    const metricsRef = collection(db, 'chatbots', chatbotId, 'metrics');
    const dailyMetricsRef = doc(metricsRef, dailyId);
    const weeklyMetricsRef = doc(metricsRef, weeklyId);
    const monthlyMetricsRef = doc(metricsRef, monthlyId);

    // Datos para actualizar/crear
    const updateData = {
      totalMessages: increment(1),
      ...(isNewConversation && { totalConversations: increment(1) }),
      ...(extractedData && { extractedData: increment(1) })
    };

    // Batch para actualizar o crear los documentos necesarios
    const batch = writeBatch(db);
    batch.set(dailyMetricsRef, updateData, { merge: true });
    batch.set(weeklyMetricsRef, updateData, { merge: true });
    batch.set(monthlyMetricsRef, updateData, { merge: true });

    // Ejecuta el batch
    await batch.commit();
  };



  useEffect(() => {
    const fetchChatbot = async () => {
      setLoading(true)
      if (!chatbotId) {
        showToast('error', 'ID del chatbot no proporcionado.', themeMode)
        return;
      }
      const chatbotDocRef = doc(db, 'chatbots', chatbotId);
      const chatbotDocSnapshot = await getDoc(chatbotDocRef);
      if (chatbotDocSnapshot.exists()) {
        const data = chatbotDocSnapshot.data() as Chatbot;
        const ownerId = data.ownerId;
        setOwnerId(ownerId)
        // Actualizar el contexto con la información del chatbot
        updateSystemInstructions(data?.systemInstructions);
        updateAssistantFirstMessage(data?.assistantFirstMessage);
        updatePrimaryColor(data?.primaryColor);
        updateCompanyLogo(data?.logo);
        updateCompanyLightModeLogo(data?.lightModeLogo);
        updateCompanyName(data?.companyName);
        updateChatbotTone(data?.chatbotTone);
        updateChatbotFormality(data?.chatbotFormality);
        updateDefaultLanguage(data?.defaultLanguage);
        updateAutoResolveFAQ(data?.autoResolveFAQ);
        updateUseEmojis(data?.useEmojis);
        updateResponseTime(data?.responseTime);
        updateChatbotPersonality(data?.chatbotPersonality);
        updateNightMode(data?.nightMode);
        updateBusinessType(data?.businessType);
        updateChatbotState(data?.chatbotState);
        updateResponseCount(data?.responseCount)
        updateStartDemoDate(data?.startDemoDate)
        updateSuggestion1(data?.suggestion1)
        updateSuggestion2(data?.suggestion2)
        updateSuggestion3(data?.suggestion3)
        updateCompanyNumber(data?.companyNumber)
        updateFeedbackInstructions(data?.feedbackInstructions);
        setMessages([{ message: data.assistantFirstMessage, sender: "ChatGPT" }]);

        setLoading(false)

      } else {
        showToast('error', "No se encontró un chatbot con ese ID.", themeMode)
        setLoading(false);
      }
    }

    fetchChatbot();

  }, []);

  const [chatbotImages, setChatbotImages] = useState<MediaType[]>([]);
  const [chatbotVideos, setChatbotVideos] = useState<MediaType[]>([]);
  const [chatbotIntegrations, setChatbotIntegrations] = useState<IntegrationType[]>([]);

  useEffect(() => {
    const fetchChatbotMediaAndIntegrations = async () => {
      if (!chatbotId) {
        return;
      }

      try {
        const mediaRef = collection(db, 'media');
        const mediaQuery = query(mediaRef, where('chatbotIds', 'array-contains', chatbotId));
        const mediaSnapshot = await getDocs(mediaQuery);
        const mediaData: MediaType[] = mediaSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            url: data.url,
            type: data.type,
            title: data.title || '',
            description: data.description || '',
          } as MediaType;
        });

        const images = mediaData.filter((item) => item.type === 'image');
        const videos = mediaData.filter((item) => item.type === 'video');

        setChatbotImages(images);
        setChatbotVideos(videos);

        const integrationsRef = collection(db, 'integrations');
        const integrationsQuery = query(integrationsRef, where('chatbotIds', 'array-contains', chatbotId));
        const integrationsSnapshot = await getDocs(integrationsQuery);
        const integrationsData: IntegrationType[] = integrationsSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            platform: data.platform,
            description: data.description,
            url: data.url,
          } as IntegrationType;
        });
        setChatbotIntegrations(integrationsData);

      } catch (error) {
        showToast('error', "Error cargando media e integraciones.", themeMode);
      }
    };


    fetchChatbotMediaAndIntegrations();
  }, [chatbotId]);

  const { themeMode } = useContext(ConfigContext)

  const messageContainerRef = useRef<HTMLDivElement>(null);
  const [typing, setTyping] = useState(false)
  const [waitingForResponse, setWaitingForResponse] = useState(false)

  const [messages, setMessages] = useState<CustomMessageModel[]>([
    {
      "message": assistantFirstMessage,
      "sender": "ChatGPT"
    },

  ]);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === `/chat/${chatbotId}`) {
      setMessages([
        {
          message: assistantFirstMessage,
          sender: "ChatGPT",
        },
      ]);
      setCurrentConversationId(null);
    }
  }, [location, assistantFirstMessage, chatbotId]);

  const incrementResponseCountInFirestore = async (userId: string) => {
    if (!chatbotId || !userId) {
      showToast('error', 'ID del chatbot o ID del usuario no proporcionado.', themeMode)
      return;
    }

    // Referencia al documento del chatbot
    const chatbotDocRef = doc(db, 'chatbots', chatbotId);

    // Referencia al documento del usuario
    const userDocRef = doc(db, 'users', userId);

    try {
      // Inicia una transacción para asegurar la consistencia de los datos
      await runTransaction(db, async (transaction) => {
        // Incrementa el contador de respuestas del chatbot
        transaction.update(chatbotDocRef, { responseCount: increment(1) });

        // Incrementa el contador de respuestas del usuario
        transaction.update(userDocRef, { responseCount: increment(1) });
      });

      await updateGlobalMetrics({ newMessage: true });

    } catch (e: any) {
      showToast('error', 'Error incrementando los contadores de respuesta', themeMode)
    }
  };

  async function saveMessageToFirebase(conversationId: string, messageData: any) {
    try {
      const messagesRef = collection(db, 'messages');
      await addDoc(messagesRef, {
        ...messageData,
        conversationId,
      });
    } catch (error: any) {
      showToast('error', "Error guardando el mensaje en Firebase:", themeMode)
    }
  }

  const [currentConversationId, setCurrentConversationId] = useState<string | null>(null);
  const [showStartNewConversationMessage, setShowStartNewConversationMessage] = useState(false);

  const handleSent = async (message: any) => {
    setShowSuggestions(false);
    handleRemoveImage();

    if (messages.length >= MESSAGE_LIMIT) {
      setHasReachedMessageLimit(true);
      return;
    }

    // Verificar si los datos del usuario están cargados
    if (userLoading) {
      showToast('error', "Cargando datos del usuario, por favor espera.", themeMode);
      return;
    }

    if (!user) {
      showToast('error', "No se pudieron cargar los datos del usuario.", themeMode);
      return;
    }

    const today = new Date();
    const subscriptionEndDate = new Date(user.subscriptionEndDate?.seconds * 1000);
    const isSubscriptionActive = subscriptionEndDate > today;
    const isWithinResponseLimit = user.responseCount < user.responseLimit;

    if (!isSubscriptionActive || !isWithinResponseLimit) {
      setMessages(prevMessages => [
        ...prevMessages,
        {
          message: defaultLanguage == "es"
            ? "El chatbot no está disponible en este momento, inténtalo más tarde."
            : "The chatbot is not available at the moment, please try again later.",
          sender: "ChatGPT"
        }
      ]);
      return;
    }

    if ((message !== '' || selectedImage) && !waitingForResponse) {
      setWaitingForResponse(true);

      const newMessage: CustomMessageModel = {
        message: message,
        sender: "user",
        image: selectedImage || null,
      };

      // Actualiza los mensajes inmediatamente
      const newMessages = [...messages, newMessage];
      setMessages(newMessages);

      setTyping(true);
      setValue('');

      // Ahora realiza las operaciones asíncronas
      let conversationIdToUse = currentConversationId;
      if (!conversationIdToUse && chatbotId) {
        try {
          conversationIdToUse = await startNewConversation(chatbotId, props.uid, ownerId, message);
          if (conversationIdToUse) {
            setCurrentConversationId(conversationIdToUse);
          }
        } catch (error: any) {
          showToast('error', "Error creando nueva conversación:", themeMode);
          return;
        }
      }

      if (chatbotId && conversationIdToUse) {
        const messageData = {
          message: message,
          sender: "user",
          timestamp: serverTimestamp(),
          image: selectedImage || null,
        };
        await saveMessageToFirebase(conversationIdToUse, messageData);
        const isNewConversation = !currentConversationId;
        await updateMetrics(chatbotId, { isNewConversation });
      }

      await processMessageToChatGPT(
        newMessages,
        setMessages,
        setTyping,
        systemInstructions,
        useEmojis,
        chatbotTone,
        chatbotFormality,
        defaultLanguage,
        chatbotPersonality,
        businessType,
        chatbotId || "undefined",
        conversationIdToUse,
        feedbackInstructions,
        themeMode,
        chatbotImages,
        chatbotVideos,
        chatbotIntegrations,
        user
      );

      setWaitingForResponse(false);
      setSelectedImage(null);
      updateResponseCount(responseCount + 1);
      incrementResponseCountInFirestore(ownerId);
    }
  };


  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSent(value);
    }
  };

  useEffect(() => {
    messageContainerRef.current?.scrollTo({
      top: messageContainerRef.current.scrollHeight,
      behavior: 'smooth'
    });
  }, [messages]);

  useEffect(() => {
    const initSwiper = () => {
      const swiperContainer = document.getElementById('dynamic-swiper') as HTMLElement;
      if (swiperContainer) {
        const listType = swiperContainer.getAttribute('data-list-type');
        const items = getItemsByType(listType);

        if (items && items.length > 0) {
          // Limpiar el contenido existente
          swiperContainer.innerHTML = '';

          // Crear la estructura del Swiper
          const swiperWrapper = document.createElement('div');
          swiperWrapper.className = 'swiper-wrapper';

          items.forEach((item) => {
            const slide = document.createElement('div');
            slide.className = 'swiper-slide';
            slide.innerHTML = `
            <div class="card">
              <img src="${item.image}" alt="${item.title}">
              <h3>${item.title}</h3>
              <p>${item.description}</p>
              <div class="btn-container">
                <button class="more-info" onclick="window.open('${item.infoLink}', '_blank')">Más Información</button>
                <button class="buy-now" onclick="window.open('${item.buyLink}', '_blank')">Comprar Ahora</button>
              </div>
            </div>
          `;
            swiperWrapper.appendChild(slide);
          });

          swiperContainer.appendChild(swiperWrapper);

          const pagination = document.createElement('div');
          pagination.className = 'swiper-pagination';
          swiperContainer.appendChild(pagination);

          new Swiper(swiperContainer, {
            slidesPerView: 1,
            spaceBetween: 20,
            loop: false,
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            },

            breakpoints: {
              640: {
                slidesPerView: 2,
                spaceBetween: 20
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 30
              }
            }
          });
        }
      }
    };

    const timer = setTimeout(() => {
      initSwiper();
    }, 100);

    return () => clearTimeout(timer);
  }, [messages]);

  // Definición de los arrays de datos
  const servicesData = [
    {
      title: "Chatbots Inteligentes",
      description: "Diseñados para atender y sorprender a los clientes.",
      buyLink: "https://fastalk.app/buy/chatbots-inteligentes",
      infoLink: "https://fastalk.app/services/chatbots-inteligentes",
      image: "https://fastalk.app/images/multiple-users-features-mini-illustration-brix-templates.svg"
    },
    {
      title: "Potencia con IA Avanzada",
      description: "Potencia en cada conversación con tecnología IA avanzada.",
      buyLink: "https://fastalk.app/buy/potencia-ia-avanzada",
      infoLink: "https://fastalk.app/services/potencia-ia-avanzada",
      image: "https://fastalk.app/images/monthly-reports-features-mini-illustration-brix-templates.svg"
    },
    {
      title: "Personalización Total",
      description: "Personalización total para reflejar la marca y valores de cada empresa.",
      buyLink: "https://fastalk.app/buy/personalizacion-total",
      infoLink: "https://fastalk.app/services/personalizacion-total",
      image: "https://fastalk.app/images/granular-permissions-features-mini-illustration-brix-templates.svg"
    },
    {
      title: "Integración con Herramientas",
      description: "Integración perfecta con herramientas y sistemas existentes.",
      buyLink: "https://fastalk.app/buy/integracion-herramientas",
      infoLink: "https://fastalk.app/services/integracion-herramientas",
      image: "https://fastalk.app/images/integrations-features-mini-illustration-brix-templates.svg"
    },
    {
      title: "Análisis y Reportes",
      description: "Análisis y reportes en tiempo real del desempeño del chatbot.",
      buyLink: "https://fastalk.app/buy/analisis-reportes",
      infoLink: "https://fastalk.app/services/analisis-reportes",
      image: "https://fastalk.app/images/mobile-app-features-mini-illustration-brix-templates.svg"
    }
  ];

  const productsData = [
    {
      title: "Chatbot Básico",
      description: "Solución inicial para pequeñas empresas.",
      buyLink: "https://fastalk.app/buy/chatbot-basico",
      infoLink: "https://fastalk.app/products/chatbot-basico",
      image: "https://fastalk.app/images/product-basic-chatbot.svg"
    },
    {
      title: "Chatbot Pro",
      description: "Solución avanzada para empresas medianas.",
      buyLink: "https://fastalk.app/buy/chatbot-pro",
      infoLink: "https://fastalk.app/products/chatbot-pro",
      image: "https://fastalk.app/images/product-pro-chatbot.svg"
    },
    {
      title: "Chatbot Enterprise",
      description: "Solución completa para grandes corporaciones.",
      buyLink: "https://fastalk.app/buy/chatbot-enterprise",
      infoLink: "https://fastalk.app/products/chatbot-enterprise",
      image: "https://fastalk.app/images/product-enterprise-chatbot.svg"
    }
  ];

  const projectsData = [
    {
      title: "E-commerce Assistant",
      description: "Chatbot para tienda online de moda.",
      buyLink: "https://fastalk.app/contact",
      infoLink: "https://fastalk.app/projects/ecommerce-assistant",
      image: "https://fastalk.app/images/project-ecommerce.svg"
    },
    {
      title: "Soporte Técnico 24/7",
      description: "Chatbot para empresa de software.",
      buyLink: "https://fastalk.app/contact",
      infoLink: "https://fastalk.app/projects/soporte-tecnico",
      image: "https://fastalk.app/images/project-tech-support.svg"
    },
    {
      title: "Asistente de Reservas",
      description: "Chatbot para cadena hotelera.",
      buyLink: "https://fastalk.app/contact",
      infoLink: "https://fastalk.app/projects/asistente-reservas",
      image: "https://fastalk.app/images/project-hotel-booking.svg"
    }
  ];

  // Función para obtener los items según el tipo de lista
  const getItemsByType = (listType: string | null) => {
    switch (listType) {
      case 'products':
        return productsData;
      case 'services':
        return servicesData;
      case 'projects':
        return projectsData;
      default:
        return null;
    }
  };

  const exceededTokens = false

  const [value, setValue] = useState('')

  const navigate = useNavigate()

  const useResponsiveFontSize = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    return isMobile ? '14px' : '16px';
  };

  const fontSize = useResponsiveFontSize();

  const themee = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            fontFamily: 'Outfit',
            color: '#7c7c7c',
            border: themeMode === "dark" ? '1px solid #272727' : '1px solid #dcdcdc',
            backgroundColor: themeMode === "dark" ? "#181818" : "#f1eeee",
            borderRadius: 100,
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent !important" : 'transparent !important',
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',
            },
            "& .MuiInputBase-input": {
              fontFamily: 'Outfit',
              color: themeMode === "dark" ? "#fafafa" : '#121212',
              fontSize: fontSize, // Aplicación del tamaño de fuente basado en el dispositivo
            },
            "& .MuiInputLabel-root": {
              fontFamily: 'Outfit',
              color: themeMode === "dark" ? "#fafafa" : '#121212',
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: themeMode === "dark" ? "#fafafa" : '#121212',
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',
            },
            "& .MuiInputLabel-root.Mui-focused + .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',
            },
          }
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 100,
          },
        },
      },
    }
  });

  const uiTheme = useTheme();
  const isLarge = useMediaQuery(uiTheme.breakpoints.down('lg'));

  const isMobile = useMediaQuery(uiTheme.breakpoints.down('md'));

  const [open, setOpen] = useState(false)

  const handlePrivacyPolicyAcceptance = () => {
    localStorage.setItem('privacyPolicyAccepted', 'true');
  };

  const hasAcceptedPrivacyPolicy = () => {
    return localStorage.getItem('privacyPolicyAccepted') === 'true';
  };

  const openDialogModal = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (!hasAcceptedPrivacyPolicy()) {
      // Si no ha sido aceptada, abre el modal
      openDialogModal()
    }
  }, []);

  const handleAcceptPrivacyPolicy = () => {
    // Aquí puedes agregar lógica adicional si es necesario
    handlePrivacyPolicyAcceptance()
    setOpen(false);
  };

  const closeDialogModal = () => {
    setOpen(false);
  };

  // Estado para controlar la visibilidad del modal de políticas
  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(false);

  // Función para abrir el modal
  const handleOpenPolicyModal = () => {
    setIsPolicyModalOpen(true);
  };

  // Función para cerrar el modal
  const handleClosePolicyModal = () => {
    setIsPolicyModalOpen(false);
  };

  // Image upload

  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
  };


  if (loading) {
    return (
      <Box sx={{
        display: 'flex', mx: 'auto', alignItems: "center", justifyContent: 'center',
        backgroundColor: themeMode === "dark" ? '#121212' : '#fafafa',
        height: isMobile ? 'calc(100vh - 59px)' : 'calc(100vh - 59px)',
      }}>
        <CircularProgress sx={{ color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1' }} />
      </Box>
    );
  }

  return (
    <Box style={{
      position: "relative",
      width: "100%",
    }}
    >
      <Box
        style={{
          backgroundColor: themeMode === "dark" ? "#121212" : '#fafafa',
          height: isMobile ? 'calc(100vh - 67px)' : 'calc(100vh - 76px)',
          position: 'relative',
        }}>
        <Stack
          flexDirection="column"
          justifyContent="center"
          alignItems="inherit"
        >
          <Box
            key="message-container"
            ref={messageContainerRef}
            style={{
              backgroundColor: themeMode === "dark" ? "#121212" : '#fafafa',
              padding: isMobile ? 20 : 40,
              paddingBottom: 50,
              borderRadius: '20px',
              maxHeight: 'calc(100vh - 120px)',
              overflowY: 'auto',
            }}
            sx={{
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: themeMode === "dark" ? "#121212" : '#fafafa',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: themeMode !== "dark" ? 'lightgrey' : '#3A3A3A',
                borderRadius: '50px'
              }
            }}
          >
            {messages.map((message, i) => (
              <motion.div
                key={i}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.25 }}
              >
                <Box
                  key={i}
                  style={{
                    background: message.sender === "ChatGPT"
                      ? (themeMode === "dark" ? "#1d1d1d" : "white")
                      : primaryColor,
                    padding: "12px",
                    marginBottom: "20px",
                    color: message.sender === "ChatGPT"
                      ? (themeMode === "dark" ? "white" : "black")
                      : "white",
                    borderRadius: message.sender === "ChatGPT" ? "15px 15px 15px 0px" : "15px 15px 0px 15px",
                    textAlign: "left",
                    maxWidth: isMobile ? "80vw" : "50vw",
                    float: message.sender === "ChatGPT" ? "left" : "right",
                    clear: "both",
                    whiteSpace: "pre-wrap",
                    boxShadow: themeMode === "dark"
                      ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                      : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                  }}
                >
                  <div
                    style={{ fontSize: isMobile ? '14px' : '16px', fontFamily: 'Outfit', fontWeight: '300' }}
                    dangerouslySetInnerHTML={{
                      __html: (message?.message ?? "").replace(
                        /\*\*(.*?)\*\*/g,
                        '<span style="font-weight: 500;">$1</span>'
                      )
                    }}
                  />

                  {message.image && (
                    <Box mt={2}>
                      <img
                        src={message.image}
                        alt="Enviado"
                        style={{
                          maxWidth: '100%',
                          borderRadius: '10px',
                          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                        }}
                        onLoad={() => {
                          messageContainerRef.current?.scrollTo({
                            top: messageContainerRef.current.scrollHeight,
                            behavior: 'smooth'
                          });
                        }}
                      />
                    </Box>
                  )}
                </Box>
                <div style={{ clear: 'both' }}></div>
              </motion.div>
            ))}
          </Box>

          <Box
            position="absolute"
            bottom="0"
            width="100%"
            sx={{ zIndex: 999 }}
          >
            {
              typing && (
                <Box sx={{
                  display: 'flex',
                  backgroundColor: themeMode === "dark" ? "#1d1d1d" : "white",
                  borderRadius: "20px 20px 20px 0px",
                  width: '70px',
                  height: '35px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  ml: '20px',
                  mb: '10px',
                  boxShadow: themeMode === "dark"
                    ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                    : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                }}>
                  <div style={{ display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center' }}>
                    <motion.div
                      style={{
                        width: '8px',
                        height: '8px',
                        backgroundColor: themeMode === "dark" ? '#fafafa' : 'black',
                        borderRadius: '50%',
                      }}
                      initial={{ y: 0 }}
                      animate={{ y: [0, -4, 0] }}
                      transition={{
                        duration: 0.6,
                        repeat: Infinity,
                        ease: "easeInOut",
                        delay: 0
                      }}
                    />
                    <motion.div
                      style={{
                        width: '8px',
                        height: '8px',
                        backgroundColor: themeMode === "dark" ? '#fafafa' : 'black',
                        borderRadius: '50%',
                      }}
                      initial={{ y: 0 }}
                      animate={{ y: [0, -4, 0] }}
                      transition={{
                        duration: 0.6,
                        repeat: Infinity,
                        ease: "easeInOut",
                        delay: 0.15
                      }}
                    />
                    <motion.div
                      style={{
                        width: '8px',
                        height: '8px',
                        backgroundColor: themeMode === "dark" ? '#fafafa' : 'black',
                        borderRadius: '50%',
                      }}
                      initial={{ y: 0 }}
                      animate={{ y: [0, -4, 0] }}
                      transition={{
                        duration: 0.6,
                        repeat: Infinity,
                        ease: "easeInOut",
                        delay: 0.3
                      }}
                    />
                  </div>
                </Box>
              )
            }

            {!loading && showSuggestions && suggestion1 && (
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="end"
                mr={isMobile ? "20px" : "50px"} pb={2} gap={1}
              >
                <Typography sx={{
                  fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', mb: '10px',
                  width: isMobile ? '80vw' : '500px', textAlign: isMobile ? 'right' : 'right',
                  color: themeMode === "dark" ? 'white' : 'black'
                }}>
                  {
                    defaultLanguage === 'es' ? 'Sugerencias de preguntas:' : 'Question suggestions:'
                  }
                </Typography>
                <Button variant="outlined" sx={{
                  backgroundColor: 'transparent', border: '1px solid', textTransform: 'none',
                  borderColor: primaryColor, p: '10px 20px', borderRadius: '15px', fontFamily: 'Outfit',
                  fontSize: isMobile ? '14px' : '16px', maxWidth: isMobile ? '80vw' : '500px', textAlign: 'left',
                  '&:hover': { borderColor: primaryColor, backgroundColor: primaryColor, color: 'white' }, '&:focus': { outline: 'none' },
                  color: themeMode === "dark" ? 'white' : 'black', fontWeight: '300'
                }}
                  onClick={() => { handleSent(suggestion1); setShowSuggestions(false); }}>
                  {suggestion1}
                </Button>
                <Button variant="outlined" sx={{
                  backgroundColor: 'transparent', border: '1px solid', textTransform: 'none',
                  borderColor: primaryColor, p: '10px 20px', borderRadius: '15px', fontFamily: 'Outfit',
                  fontSize: isMobile ? '14px' : '16px', maxWidth: isMobile ? '80vw' : '500px', textAlign: 'left',
                  '&:hover': { borderColor: primaryColor, backgroundColor: primaryColor, color: 'white' }, '&:focus': { outline: 'none' },
                  color: themeMode === "dark" ? 'white' : 'black', fontWeight: '300'
                }}
                  onClick={() => { handleSent(suggestion2); setShowSuggestions(false); }}>
                  {suggestion2}
                </Button>
                <Button variant="outlined" sx={{
                  backgroundColor: 'transparent', border: '1px solid', textTransform: 'none',
                  borderColor: primaryColor, p: '10px 20px', borderRadius: '15px', fontFamily: 'Outfit',
                  fontSize: isMobile ? '14px' : '16px', maxWidth: isMobile ? '80vw' : '500px', textAlign: 'left',
                  '&:hover': { borderColor: primaryColor, backgroundColor: primaryColor, color: 'white' }, '&:focus': { outline: 'none' },
                  color: themeMode === "dark" ? 'white' : 'black', fontWeight: '300'
                }}
                  onClick={() => { handleSent(suggestion3); setShowSuggestions(false); }}>
                  {suggestion3}
                </Button>
              </Box>
            )}

            <Stack
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              py={1}
              pb={2}
              px={1}
              sx={{ backgroundColor: themeMode === "dark" ? "#121212" : '#fafafa', }}
            >
              {hasReachedMessageLimit ? (
                // Lo que se mostrará si se ha alcanzado el límite de mensajes
                <>
                  <Stack flexDirection="column" alignItems="center" justifyContent="center" gap={2}>
                    <Box>
                      <Typography sx={{
                        fontFamily: 'Outfit', fontWeight: '300',
                        textAlign: 'center', color: themeMode === "dark" ? "white" : 'black'
                      }}>
                        {defaultLanguage == "es" ? "Has alcanzado el límite de mensajes para esta conversación." : "You have reached the message limit for this conversation."}
                      </Typography>
                      <Typography sx={{
                        fontFamily: 'Outfit', fontWeight: '300',
                        textAlign: 'center', color: themeMode === "dark" ? "white" : 'black'
                      }}>
                        {defaultLanguage == "es" ? "Por favor, inicia una nueva conversación." : "Please start a new conversation."}
                      </Typography>
                    </Box>
                    <Button
                      sx={{
                        backgroundColor: primaryColor, color: 'white',
                        padding: "10px 20px", borderRadius: "100px", textTransform: 'none',
                        maxWidth: '250px',
                        border: 'solid 1px',
                        borderColor: primaryColor,
                        fontFamily: 'Outfit',
                        fontSize: '16px',
                        "&:hover": {
                          backgroundColor: 'transparent',
                          border: 'solid 1px',
                          borderColor: primaryColor,
                          color: themeMode === "dark" ? "white" : 'black'
                        },
                        "&:focus": {
                          outline: "none",
                          backgroundColor: primaryColor,
                        },
                      }}
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      {defaultLanguage == "es" ? "Iniciar nueva conversación" : "Start new conversation"}
                    </Button>
                  </Stack>


                </>
              ) : showStartNewConversationMessage ? (
                // Lo que se mostrará si se muestra el mensaje de iniciar nueva conversación
                <Stack flexDirection="column" alignItems="center" justifyContent="center" gap={2}>
                  <Button
                    sx={{
                      backgroundColor: primaryColor, color: 'white',
                      padding: "10px 20px", borderRadius: "100px", textTransform: 'none',
                      maxWidth: '250px',
                      border: 'solid 1px',
                      borderColor: primaryColor,
                      fontFamily: 'Outfit',
                      fontSize: '16px',
                      "&:hover": {
                        backgroundColor: 'transparent',
                        border: 'solid 1px',
                        borderColor: primaryColor,
                        color: themeMode === "dark" ? "white" : 'black'
                      },
                      "&:focus": {
                        outline: "none",
                        backgroundColor: primaryColor,
                      },
                    }}
                    onClick={() => {
                      navigate(`/chat/${chatbotId}`)
                    }}
                  >
                    {defaultLanguage == "es" ? "Iniciar nueva conversación" : "Start new conversation"}
                  </Button>
                </Stack>
              ) : (
                <Stack flexDirection="column">
                  {/* Previsualización de la imagen seleccionada */}
                  {selectedImage && (
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2, paddingLeft: 4 }}>
                      <img src={selectedImage} alt="Preview" style={{ maxHeight: '50px', borderRadius: '10px' }} />
                      <IconButton
                        onClick={handleRemoveImage}
                        sx={{
                          top: '-25px',
                          right: '25px',
                          backgroundColor: 'rgba(0,0,0,0.5)',
                          color: 'white',
                          '&:hover': { backgroundColor: 'rgba(0,0,0,0.7)' },
                          '&:focus': { outline: 'none' }
                        }}
                      >
                        <CloseRoundedIcon sx={{ fontSize: '20px' }} />
                      </IconButton>
                    </Box>
                  )}
                  <Stack flexDirection="row" justifyContent="center" alignItems="center" sx={{ maxWidth: '95vw' }}>
                    <ThemeProvider theme={themee}>
                      <TextField
                        fullWidth
                        onKeyDown={handleKeyDown}
                        autoComplete="off"
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        name="message"
                        value={value}
                        placeholder={defaultLanguage == "es" ? "Escribe cualquier pregunta" : "Write any question"}
                        error={exceededTokens}
                        onChange={(e: any) => setValue(e.target.value)}
                        sx={{
                          maxWidth: isLarge ? '100%' : '70%',
                          width: '100vw',
                          ml: isMobile ? '10px' : '20px',
                        }}
                        inputProps={{
                          maxLength: 400,
                        }}
                        InputProps={{
                          endAdornment: !userLoading && !checkSubscriptionType() && (
                            <InputAdornment position="end">
                              <IconButton
                                component="label"
                                style={{
                                  color: themeMode === "dark" ? "white" : 'black',
                                  outline: 'none',
                                  transition: 'transform 0.3s ease, background-color 0.3s ease',
                                }}
                                sx={{
                                  '&:hover': {
                                    transform: 'scale(1.1)',
                                  }
                                }}
                              >
                                <ImageRoundedIcon sx={{ fontSize: '25px', color: themeMode === "dark" ? "#ffffff80" : "#18181880" }} />
                                <input type="file" accept="image/*" hidden onChange={handleImageUpload} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ThemeProvider>

                    {/* Botón para enviar el mensaje */}
                    <IconButton
                      style={{
                        color: themeMode === "dark" ? "white" : 'black',
                        outline: 'none',
                        transition: 'transform 0.3s ease, background-color 0.3s ease',
                      }}
                      sx={{
                        '&:hover': {
                          transform: 'scale(1.1)',
                        }
                      }}
                      onClick={() => handleSent(value)}
                      disabled={userLoading || (!value && !selectedImage) || waitingForResponse}
                    >
                      {/* Aplica el estilo dinámico al SendIcon */}
                      <SendIcon sx={{
                        color: (value || selectedImage) && !waitingForResponse ? 'white' : 'lightgray',
                        border: 'none',
                        fontSize: '50px',
                        backgroundColor: (value || selectedImage) && !waitingForResponse ? primaryColor : 'transparent',
                        borderRadius: '100px',
                        p: '12px',
                        transition: 'transform 0.3s ease, background-color 0.3s ease',
                        transform: (value || selectedImage) && !waitingForResponse ? 'rotate(-20deg)' : 'none',
                      }} />
                    </IconButton>
                  </Stack>

                </Stack>
              )}
            </Stack>

          </Box >
        </Stack>
      </Box >


      <DialogCommon
        kTitle={defaultLanguage == "es" ? "Política de Privacidad" : "Privacy Policy"}
        kMaxWidth="sm"
        kMaxHeight={isMobile ? '380px' : '300px'}
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kEnableBorderRadius={true}
        kBorderRadius={isMobile ? '20px' : '30px'}
        kAlignBtn="center"
        kTopDivider
        kSlideTransition
        kOpenDialogLists={open}
        kOnClose={closeDialogModal}
        kOnPrimary={handleAcceptPrivacyPolicy}
        kOnSecondary={handleOpenPolicyModal}
        kSecondaryBtnText={defaultLanguage == "es" ? "Ver Políticas" : "View Policy"}
        kPrimaryBtnText={defaultLanguage == "es" ? "Aceptar" : "Accept"}
        kMainColor={primaryColor}
        kIsChatModal
        kContent={
          (
            <Typography sx={{ fontWeight: '300', fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "white" : 'black' }}>
              {
                defaultLanguage == "es"
                  ? "Antes de iniciar, es importante informarte que este chat está impulsado por IA, lo cual podría ocasionar errores ocasionales en las respuestas. Al interactuar con este sistema, aceptas que se registre el contenido de esta conversación con fines de mejora del servicio y calidad. Tus datos personales serán manejados y protegidos conforme a nuestra política de privacidad, la cual puedes consultar para mayor información."
                  : "Before we begin, please be aware that this chat is powered by AI, which may occasionally lead to errors in responses. By engaging with this system, you consent to the recording of this conversation for service improvement and quality purposes. Your personal data will be handled and protected in accordance with our privacy policy, which is available for further information."
              }
            </Typography>
          )
        }
      />



      <DialogCommon
        kTitle={defaultLanguage === "es" ? "Política de Privacidad" : "Privacy Policy"}
        kMaxWidth="sm"
        kMaxHeight={isMobile ? '750px' : '600px'}
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kEnableBorderRadius={true}
        kBorderRadius={isMobile ? '70px' : '70px'}
        kAlignBtn="center"
        kTopDivider
        kSlideTransition
        kOpenDialogLists={isPolicyModalOpen}
        kOnClose={handleClosePolicyModal}
        kOnPrimary={handleClosePolicyModal}
        kPrimaryBtnText={defaultLanguage === "es" ? "Cerrar" : "Close"}
        kMainColor={primaryColor}
        kIsChatModal
        kAlignItemsStart
        kContent={
          <Typography sx={{ whiteSpace: 'pre-line', fontWeight: '300', fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "white" : 'black' }}>
            {
              defaultLanguage === "es" ? (
                <PrivacyPolicyES companyName={companyName} isMobile={isMobile} themeMode={themeMode} />
              ) : (
                <PrivacyPolicyEN companyName={companyName} isMobile={isMobile} themeMode={themeMode} />
              )
            }

          </Typography>
        }
      />

    </Box >
  );
}

export default Chat;