import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../context/Config/ConfigContext';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import SectionContainer from '../../global/SectionContainer';

function Documentation() {
  const { t } = useTranslation('global');
  const { themeMode } = useContext(ConfigContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <SectionContainer title={t('documentationPage.title') as string}
    >
      <Stack flexDirection="column" justifyContent="center" alignItems="center" gap={2} sx={{ mt: '100px', padding: '20px' }}>
        <MenuBookRoundedIcon sx={{ fontSize: '60px', color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1' }} />
        <Box sx={{ textAlign: 'center' }}>
          <Typography align="center" sx={{
            fontSize: isMobile ? '16px' : '18px', fontFamily: 'Outfit', fontWeight: '300',
            color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1', maxWidth: '600px'
          }}>
            {t('documentationPage.comingSoon')}
          </Typography>
        </Box>
      </Stack>
    </SectionContainer>
  )
}

export default Documentation;
