import { useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";
import { Theme } from "@mui/material/styles";

// mui theme settings
export const themeSettings = (mode: 'dark' | 'light') => {
  const colors = {
    main: mode === 'dark' ? '#yourDarkColorHere' : '#yourLightColorHere', // replace with your desired colors
    background: mode === 'dark' ? '#121212' : '#fff',
    systemMessage: mode === 'dark' ? '#ffffff' : 'lightgray',
    button: mode === 'dark' ? '#yourDarkButtonColorHere' : '#yourLightButtonColorHere', // replace with your desired colors
  };

  return {
    palette: {
      mode: mode,
      primary: {
        main: colors.main,
      },
      background: {
        default: colors.background,
      },
      secondary: {
        main: colors.systemMessage,
      },
      action: {
        active: colors.button,
      },
    },
    typography: {
      fontFamily: "Outfit, sans-serif",
    },
  };
};

export const useMode = (): [Theme] => {
  const [mode,] = useState<'dark' | 'light'>(() => {
    const localTheme = localStorage.getItem('themeIsDark');
    return localTheme ? (localTheme === 'true' ? "dark" : "light") : "dark";
  });

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme];
};
