import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import ChatBubbleRoundedIcon from '@mui/icons-material/ChatBubbleRounded';
import { useContext, useEffect, useState } from "react";
import StatBox from "../../components/StatBox";
import SectionContainer from "../../global/SectionContainer";
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import UsersChart from "./UsersChart";
import { collection, getDocs, query, orderBy, limit } from "firebase/firestore";
import { db } from "../../services/firebase/firebase";
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import { ConfigContext } from "../../context/Config/ConfigContext";

const ClientAnalytics = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const colors = tokens(theme.palette.mode);

  const [viewMode,] = useState<'weekly' | 'monthly'>('monthly');
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalConversations, setTotalConversations] = useState(0);
  const [totalMessages, setTotalMessages] = useState(0);
  const [totalBots, setTotalBots] = useState(0);
  const [newClients, setNewClients] = useState<any[]>([]);

  useEffect(() => {
    fetchMetrics();
    fetchNewClients();
  }, []);

  const fetchMetrics = async () => {
    try {
      const currentYear = new Date().getFullYear();
      const metricsRef = collection(db, 'metrics');
      const querySnapshot = await getDocs(metricsRef);

      let totalUsers = 0;
      let totalConversations = 0;
      let totalMessages = 0;
      let totalBots = 0;

      querySnapshot.forEach((doc: any) => {
        const data = doc.data();
        const docIdParts = doc.id.split('-');

        if (parseInt(docIdParts[1]) === currentYear && docIdParts[0] === 'monthly') {
          totalUsers += data.totalUsers || 0;
          totalConversations += data.totalConversations || 0;
          totalMessages += data.totalMessages || 0;
          totalBots += data.totalBots || 0;
        }
      });

      setTotalUsers(totalUsers);
      setTotalConversations(totalConversations);
      setTotalMessages(totalMessages);
      setTotalBots(totalBots);
    } catch (error) {
      console.error('Error fetching metrics:', error);
    }
  };

  const fetchNewClients = async () => {
    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, orderBy('createdAt', 'desc'), limit(10));
      const querySnapshot = await getDocs(q);

      const clients = querySnapshot.docs.map((doc) => doc.data());
      setNewClients(clients);
    } catch (error) {
      console.error('Error fetching new clients:', error);
    }
  };

  // const lightTheme = createTheme({
  //   components: {
  //     MuiPaper: {
  //       styleOverrides: {
  //         root: {
  //           backgroundColor: '#fff',
  //           borderRadius: '15px',
  //         },
  //       },
  //     },
  //     MuiMenuItem: {
  //       styleOverrides: {
  //         root: {
  //           color: '#333',
  //         },
  //       },
  //     },
  //   },
  // });

  const { themeMode } = useContext(ConfigContext)

  // const renderViewModeControls = () => {
  //   const selectTheme = themeMode === 'light' ? lightTheme : null;
  //   return (
  //     <ThemeProvider theme={selectTheme || {}}>
  //       <FormControl sx={{
  //         width: '150px', borderRadius: '15px', mb: '20px', p: 0,
  //         boxShadow: themeMode === "dark"
  //           ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
  //           : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
  //       }}>
  //         <Select
  //           id="view-mode-select"
  //           value={viewMode}
  //           onChange={(event) => setViewMode(event.target.value as 'weekly' | 'monthly')}
  //           sx={{
  //             '& .MuiSvgIcon-root': {
  //               color: themeMode === 'dark' ? '#ffffff' : '#4a3aff',
  //             },
  //             color: themeMode === 'dark' ? '#ffffff' : '#181818',
  //             bgcolor: themeMode === 'dark' ? '#181818' : '#fff',
  //             display: 'flex',
  //             alignItems: 'center',
  //             justifyContent: 'space-between',
  //             borderRadius: '13px',
  //             maxHeight: '40px',

  //           }}
  //           MenuProps={{
  //             sx: {
  //               '& .MuiPaper-root': {
  //                 borderRadius: '15px',
  //               },
  //             },
  //           }}
  //           displayEmpty
  //           renderValue={(selected) => {
  //             if (selected === 'monthly') {
  //               return (
  //                 <Box sx={{ display: 'flex', alignItems: 'center', color: themeMode === 'dark' ? '#ffffff' : '#333' }}>
  //                   <CalendarViewMonthRoundedIcon fontSize="small" sx={{ marginRight: '10px' }} />
  //                   {t('clientSubscription.monthly')}
  //                 </Box>
  //               );
  //             } else if (selected === 'weekly') {
  //               return (
  //                 <Box sx={{ display: 'flex', alignItems: 'center', color: themeMode === 'dark' ? '#ffffff' : '#333' }}>
  //                   <CalendarViewWeekRoundedIcon fontSize="small" sx={{ marginRight: '10px' }} />
  //                   {t('clientSubscription.weekly')}
  //                 </Box>
  //               );
  //             }
  //             return '';
  //           }}
  //         >
  //           <MenuItem value="monthly">
  //             <Box sx={{ display: 'flex', alignItems: 'center' }}>
  //               <CalendarViewMonthRoundedIcon fontSize="small" sx={{ marginRight: '10px' }} />
  //               <ListItemText primary={t('clientSubscription.monthly')} sx={{ color: themeMode === 'dark' ? '#ffffff' : '#333' }} />
  //             </Box>
  //           </MenuItem>
  //           <MenuItem value="weekly">
  //             <Box sx={{ display: 'flex', alignItems: 'center' }}>
  //               <CalendarViewWeekRoundedIcon fontSize="small" sx={{ marginRight: '10px' }} />
  //               <ListItemText primary="Semanal" sx={{ color: themeMode === 'dark' ? '#ffffff' : '#333' }} />
  //             </Box>
  //           </MenuItem>
  //         </Select>
  //       </FormControl>
  //     </ThemeProvider>

  //   );
  // };

  return (
    <SectionContainer title="Estadísticas de usuarios">
      <Box position="relative">
        {/* <Stack flexDirection="row" justifyContent="flex-start">
          {renderViewModeControls()}
        </Stack> */}
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="150px"
          gap="20px"
          pb="50px"
        >
          {/* ROW 1 */}
          <Box
            gridColumn={isMobile ? "span 6" : "span 3"}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              borderRadius: '20px',
              backgroundColor: themeMode === "dark" ? "#181818" : "#fff",
            }}
          >
            <StatBox
              title={totalBots.toString()} // Número total de bots
              subtitle="Total de Bots"
              icon={
                <ChatBubbleRoundedIcon
                  sx={{ color: "#c1c1c1", fontSize: isMobile ? "20px" : "26px" }}
                />
              }
            />
          </Box>

          <Box
            gridColumn={isMobile ? "span 6" : "span 3"}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              borderRadius: '20px',
              backgroundColor: themeMode === "dark" ? "#181818" : "#fff"
            }}
          >
            <StatBox
              title={totalConversations.toString()} // Número total de conversaciones
              subtitle="Total de Conversaciones"
              icon={
                <QuestionAnswerRoundedIcon
                  sx={{ color: "#c1c1c1", fontSize: isMobile ? "20px" : "26px" }}
                />
              }
            />
          </Box>

          <Box
            gridColumn={isMobile ? "span 6" : "span 3"}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              borderRadius: '20px',
              backgroundColor: themeMode === "dark" ? "#181818" : "#fff"
            }}
          >
            <StatBox
              title={totalMessages.toString()} // Número total de mensajes
              subtitle="Total de Mensajes"
              icon={
                <ChatRoundedIcon
                  sx={{ color: "#c1c1c1", fontSize: isMobile ? "20px" : "26px" }}
                />
              }
            />
          </Box>

          <Box
            gridColumn={isMobile ? "span 6" : "span 3"}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              borderRadius: '20px',
              backgroundColor: themeMode === "dark" ? "#181818" : "#fff"
            }}
          >
            <StatBox
              title={totalUsers.toString()} // Número total de usuarios
              subtitle="Total de Usuarios"
              icon={
                <PeopleRoundedIcon
                  sx={{ color: "#c1c1c1", fontSize: isMobile ? "20px" : "26px" }}
                />
              }
            />
          </Box>

          {/* ROW 2 */}
          <Box
            gridColumn={isMobile ? "span 12" : "span 8"}
            gridRow="span 3"
            sx={{
              borderRadius: '20px',
              backgroundColor: themeMode === "dark" ? "#181818" : "#fff",
            }}
          >
            <UsersChart viewMode={viewMode} />
          </Box>

          <Box
            gridColumn={isMobile ? "span 12" : "span 4"}
            gridRow="span 3"
            overflow="auto"
            sx={{
              borderRadius: '20px',
              fontWeight: '400',
              fontFamily: 'Outfit',
              backgroundColor: themeMode === "dark" ? "#181818" : "#fff",
              scrollbarWidth: 'thin',
              scrollbarColor: 'transparent transparent',
              '&:hover': {
                scrollbarColor: 'rgba(211, 211, 211, 0.5) transparent',
              },
              '&::-webkit-scrollbar': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'transparent',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent',
              },
              '&:hover::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(211, 211, 211, 0.5)',
                borderRadius: '50px'
              },
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ backgroundColor: themeMode === "dark" ? "#181818" : "#fff", color: "#c1c1c1", fontFamily: 'Outfit' }}
              p="25px"
            >
              <Typography variant="h5" fontWeight="600" sx={{ fontFamily: 'Outfit', fontSize: '16px', fontWeight: '300', color: themeMode === "dark" ? "#fafafa" : "#181818" }}>
                Nuevos usuarios
              </Typography>
            </Box>

            {newClients.map((client: any, i: any) => (
              <Box
                key={`${client.id}-${i}`}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ px: '20px', mb: '20px' }}
              >
                <Box display="flex" alignItems="center">
                  {client.photoURL ? (
                    <img
                      src={client.photoURL}
                      alt="Avatar del cliente"
                      style={{ maxHeight: '35px', borderRadius: '50%', marginRight: '10px' }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: '35px',
                        height: '35px',
                        borderRadius: '50%',
                        backgroundColor: colors.grey[500],
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: '10px',
                        fontFamily: 'Outfit',
                        fontWeight: 'bold',
                        color: "#c1c1c1",
                      }}
                    >
                      {client.firstName.charAt(0)}{client.lastName.charAt(0)}
                    </Box>
                  )}
                  <Box>
                    <Typography color={colors.grey[400]} sx={{ fontFamily: 'Outfit', fontWeight: 'bold', fontSize: '14px' }}>
                      {client.firstName} {client.lastName}
                    </Typography>
                    <Typography color={colors.grey[400]} sx={{ fontFamily: 'Outfit', fontSize: '14px' }}>
                      {client.email}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography color={colors.grey[400]} sx={{ fontFamily: 'Outfit', fontSize: '14px', width: '100px', textAlign: 'right' }}>
                    {client.createdAt ? new Date(client.createdAt.toDate()).toLocaleDateString('es-ES', {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric'
                    }) : '-'}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </SectionContainer>
  );
};

export default ClientAnalytics;