import React, { useState } from 'react';
import darkModeLogo from "../../assets/whiteLogo.png";

type ConfigContextType = {
  logo: string;
  lightModeLogo: string;
  primaryColor: string;
  userDefaultMessage: string;
  assistantFirstMessage: string;
  systemInstructions: string;
  companyName: string;
  chatbotTone: string;
  chatbotFormality: string;
  defaultLanguage: string;
  autoResolveFAQ: boolean;
  useEmojis: boolean;
  responseTime: string;
  chatbotPersonality: string;
  nightMode: boolean;
  businessType: string;
  chatbotState: string;
  themeMode: string;
  loading: boolean;
  responseCount: number,
  startDemoDate: string,
  suggestion1: string,
  suggestion2: string,
  suggestion3: string,
  companyNumber: string,
  feedbackInstructions: string,
  updateCompanyName: (newName: string) => void;
  updateSystemInstructions: (value: string) => void;
  updateUserDefaultMessage: (value: string) => void;
  updateAssistantFirstMessage: (value: string) => void;
  updatePrimaryColor: (value: string) => void;
  updateCompanyLogo: (value: string) => void;
  updateCompanyLightModeLogo: (value: string) => void;
  updateChatbotTone: (value: string) => void;
  updateChatbotFormality: (value: string) => void;
  updateDefaultLanguage: (value: string) => void;
  updateAutoResolveFAQ: (value: boolean) => void;
  updateUseEmojis: (value: boolean) => void;
  updateResponseTime: (value: string) => void;
  updateChatbotPersonality: (value: string) => void;
  updateNightMode: (value: boolean) => void;
  updateBusinessType: (value: string) => void;
  updateChatbotState: (value: string) => void;
  toggleTheme: () => void
  setLoading: (value: boolean) => void
  updateResponseCount: (value: number) => void
  updateStartDemoDate: (value: string) => void
  updateSuggestion1: (value: string) => void
  updateSuggestion2: (value: string) => void
  updateSuggestion3: (value: string) => void
  updateCompanyNumber: (value: string) => void
  updateFeedbackInstructions: (value: string) => void
  resetConfigContext: () => void
  resetConfigContextFromSignUp: () => void
};


export const ConfigContext = React.createContext<ConfigContextType>({
  logo: darkModeLogo,
  lightModeLogo: "",
  primaryColor: '#4a3aff',
  userDefaultMessage: "¡Hola!",
  assistantFirstMessage: "¡Hola!, ¿en qué puedo ayudarte?",
  systemInstructions: "...",
  companyName: "",
  chatbotTone: "Entusiasta",
  chatbotFormality: "neutro",
  defaultLanguage: "es",
  autoResolveFAQ: false,
  useEmojis: true,
  responseTime: '1 segundo',
  chatbotPersonality: "Amigable",
  nightMode: false,
  businessType: "General",
  chatbotState: "No presentado",
  themeMode: 'light',
  loading: true,
  responseCount: 0,
  startDemoDate: '',
  suggestion1: '',
  suggestion2: '',
  suggestion3: '',
  companyNumber: '',
  feedbackInstructions: '',
  updateCompanyName: () => { },
  updateSystemInstructions: () => { },
  updateUserDefaultMessage: () => { },
  updateAssistantFirstMessage: () => { },
  updatePrimaryColor: () => { },
  updateCompanyLogo: () => { },
  updateCompanyLightModeLogo: () => { },
  updateChatbotTone: () => { },
  updateChatbotFormality: () => { },
  updateDefaultLanguage: () => { },
  updateAutoResolveFAQ: () => { },
  updateUseEmojis: () => { },
  updateResponseTime: () => { },
  updateChatbotPersonality: () => { },
  updateNightMode: () => { },
  updateBusinessType: () => { },
  updateChatbotState: () => { },
  toggleTheme: () => { },
  setLoading: () => { },
  updateResponseCount: () => { },
  updateStartDemoDate: () => { },
  updateSuggestion1: () => { },
  updateSuggestion2: () => { },
  updateSuggestion3: () => { },
  updateCompanyNumber: () => { },
  updateFeedbackInstructions: () => { },
  resetConfigContext: () => { },
  resetConfigContextFromSignUp: () => { }
});

export const ConfigProvider = ({ children }: any) => {
  const [systemInstructions, setSystemInstructions] = React.useState<string>(() => {
    const saved = localStorage.getItem('systemInstructions');
    return saved || ''; // Recupera el string sin usar JSON.parse
  });

  const [userDefaultMessage, setUserDefaultMessage] = React.useState<string>(() => {
    const saved = localStorage.getItem('userDefaultMessage');
    return saved || '¡Hola!'; // Recupera el string sin usar JSON.parse
  });

  const [assistantFirstMessage, setAssistantFirstMessage] = React.useState<string>(() => {
    const saved = localStorage.getItem('assistantFirstMessage');
    return saved || '¡Hola! Bienvenido...'; // Recupera el string sin usar JSON.parse
  });

  const [primaryColor, setPrimaryColor] = React.useState<string>(() => {
    const saved = localStorage.getItem('primaryColor');
    return saved || '#4a3aff'; // Recupera el string sin usar JSON.parse
  });

  const [companyLogo, setCompanyLogo] = React.useState<string>(() => {
    const saved = localStorage.getItem('companyLogo');
    return saved || ""; // Recupera el string sin usar JSON.parse
  });

  const [companyLightModeLogo, setCompanyLightModeLogo] = React.useState<string>(() => {
    const saved = localStorage.getItem('companyLightModeLogo');
    return saved || ""; // Valor por defecto si hay un error
  });

  const [companyName, setCompanyName] = React.useState<string>(() => {
    const saved = localStorage.getItem('companyName');
    return saved || ''; // Recupera el string sin usar JSON.parse
  });

  const [chatbotTone, setChatbotTone] = React.useState<string>(() => {
    const saved = localStorage.getItem('chatbotTone');
    return saved || 'Entusiasta'; // Recupera el string sin usar JSON.parse
  });

  const [chatbotFormality, setChatbotFormality] = React.useState<string>(() => {
    const saved = localStorage.getItem('chatbotFormality');
    return saved || 'Neutro'; // Recupera el string sin usar JSON.parse
  });

  const [defaultLanguage, setDefaultLanguage] = React.useState<string>(() => {
    const saved = localStorage.getItem('defaultLanguage');
    return saved || 'es'; // Recupera el string sin usar JSON.parse
  });

  const [autoResolveFAQ, setAutoResolveFAQ] = React.useState<boolean>(() => {
    const saved = localStorage.getItem('autoResolveFAQ');
    return saved === 'true'; // Comprueba el string guardado para booleano
  });

  const [useEmojis, setUseEmojis] = React.useState<boolean>(() => {
    const saved = localStorage.getItem('useEmojis');
    return saved === 'true'; // Comprueba el string guardado para booleano
  });

  const [responseTime, setResponseTime] = React.useState<string>(() => {
    const saved = localStorage.getItem('responseTime');
    return saved || '1 segundo'; // Recupera el string sin usar JSON.parse
  });

  const [chatbotPersonality, setChatbotPersonality] = React.useState<string>(() => {
    const saved = localStorage.getItem('chatbotPersonality');
    return saved || 'Amigable'; // Recupera el string sin usar JSON.parse
  });

  const [nightMode, setNightMode] = React.useState<boolean>(() => {
    const saved = localStorage.getItem('nightMode');
    return saved === 'true'; // Comprueba el string guardado para booleano
  });

  const [businessType, setBusinessType] = React.useState<string>(() => {
    const saved = localStorage.getItem('businessType');
    return saved || 'General'; // Recupera el string sin usar JSON.parse
  });

  const [chatbotState, setChatbotState] = React.useState<string>(() => {
    const saved = localStorage.getItem('chatbotState');
    return saved || 'active'; // Recupera el string sin usar JSON.parse
  });

  const [startDemoDate, setStartDemoDate] = React.useState<string>(() => {
    const saved = localStorage.getItem('startDemoDate');
    return saved || ''; // Recupera el string sin usar JSON.parse
  });

  const [responseCount, setResponseCount] = React.useState<number>(0); // No necesita parseo

  const [suggestion1, setSuggestion1] = React.useState<string>(() => {
    const saved = localStorage.getItem('suggestion1');
    return saved || ''; // Recupera el string sin usar JSON.parse
  });

  const [suggestion2, setSuggestion2] = React.useState<string>(() => {
    const saved = localStorage.getItem('suggestion2');
    return saved || ''; // Recupera el string sin usar JSON.parse
  });

  const [suggestion3, setSuggestion3] = React.useState<string>(() => {
    const saved = localStorage.getItem('suggestion3');
    return saved || ''; // Recupera el string sin usar JSON.parse
  });

  const [companyNumber, setCompanyNumber] = React.useState<string>(() => {
    const saved = localStorage.getItem('companyNumber');
    return saved || ''; // Recupera el string sin usar JSON.parse
  });

  const [feedbackInstructions, setFeedbackInstructions] = React.useState<string>(() => {
    const saved = localStorage.getItem('feedbackInstructions');
    return saved || ''; // Recupera el string sin usar JSON.parse
  });


  // Funciones de actualización que también guardan en localStorage
  const updateSystemInstructions = (value: string) => {
    setSystemInstructions(value);
    localStorage.setItem('systemInstructions', value); // Guardar directamente el string
  };

  const updateUserDefaultMessage = (value: string) => {
    setUserDefaultMessage(value);
    localStorage.setItem('userDefaultMessage', value); // Guardar directamente el string
  };

  // ... define aquí todas las demás funciones de actualización
  const updateAssistantFirstMessage = (value: string) => {
    setAssistantFirstMessage(value);
    localStorage.setItem('assistantFirstMessage', value); // Guardar directamente el string
  };

  const updatePrimaryColor = (value: string) => {
    setPrimaryColor(value);
    localStorage.setItem('primaryColor', value); // Guardar directamente el string
  };

  const updateCompanyLogo = (value: string) => {
    setCompanyLogo(value);
    localStorage.setItem('companyLogo', value); // Guardar directamente el string
  };

  const updateCompanyLightModeLogo = (value: string) => {
    setCompanyLightModeLogo(value);
    localStorage.setItem('companyLightModeLogo', value); // Guardar directamente el string
  };

  const updateCompanyName = (value: string) => {
    setCompanyName(value);
    localStorage.setItem('companyName', value); // Guardar directamente el string
  };

  const updateChatbotTone = (value: string) => {
    setChatbotTone(value);
    localStorage.setItem('chatbotTone', value); // Guardar directamente el string
  };

  const updateChatbotFormality = (value: string) => {
    setChatbotFormality(value);
    localStorage.setItem('chatbotFormality', value); // Guardar directamente el string
  };

  const updateDefaultLanguage = (value: string) => {
    setDefaultLanguage(value);
    localStorage.setItem('defaultLanguage', value); // Guardar directamente el string
  };

  const updateAutoResolveFAQ = (value: boolean) => {
    setAutoResolveFAQ(value);
    localStorage.setItem('autoResolveFAQ', JSON.stringify(value)); // Guardar boolean como JSON
  };

  const updateUseEmojis = (value: boolean) => {
    setUseEmojis(value);
    localStorage.setItem('useEmojis', JSON.stringify(value)); // Guardar boolean como JSON
  };

  const updateResponseTime = (value: string) => {
    setResponseTime(value);
    localStorage.setItem('responseTime', value); // Guardar directamente el string
  };

  const updateChatbotPersonality = (value: string) => {
    setChatbotPersonality(value);
    localStorage.setItem('chatbotPersonality', value); // Guardar directamente el string
  };

  const updateNightMode = (value: boolean) => {
    setNightMode(value);
    localStorage.setItem('nightMode', JSON.stringify(value)); // Guardar boolean como JSON
  };

  const updateBusinessType = (value: string) => {
    setBusinessType(value);
    localStorage.setItem('businessType', value); // Guardar directamente el string
  };

  const updateChatbotState = (value: string) => {
    setChatbotState(value);
    localStorage.setItem('chatbotState', value); // Guardar directamente el string
  };

  const storedTheme = localStorage.getItem('themeMode');
  const [themeMode, setThemeMode] = useState(storedTheme || 'light');

  const [loading, setLoading] = useState(true);

  const toggleTheme = () => {
    setThemeMode((prev) => {
      const newTheme = prev === 'light' ? 'dark' : 'light';
      localStorage.setItem('themeMode', newTheme);
      return newTheme;
    });
  };

  const updateResponseCount = (newValue?: number) => {
    if (typeof newValue !== 'undefined') {
      setResponseCount(newValue);
    }
  };

  const updateStartDemoDate = () => {
    const currentDate = new Date().toISOString();
    setStartDemoDate(currentDate);
    localStorage.setItem('startDemoDate', currentDate); // Guardar directamente el string
  };

  const updateSuggestion1 = (value: string) => {
    setSuggestion1(value);
    localStorage.setItem('suggestion1', value); // Guardar directamente el string
  };

  const updateSuggestion2 = (value: string) => {
    setSuggestion2(value);
    localStorage.setItem('suggestion2', value); // Guardar directamente el string
  };

  const updateSuggestion3 = (value: string) => {
    setSuggestion3(value);
    localStorage.setItem('suggestion3', value); // Guardar directamente el string
  };

  const updateCompanyNumber = (value: string) => {
    setCompanyNumber(value);
    localStorage.setItem('companyNumber', value); // Guardar directamente el string
  };

  const updateFeedbackInstructions = (value: string) => {
    setFeedbackInstructions(value);
    localStorage.setItem('feedbackInstructions', value); // Guardar directamente el string
  };

  const resetConfigContext = () => {
    updateDefaultLanguage('');
    updateAutoResolveFAQ(false);
    updateUseEmojis(true);
    updateResponseTime('');
    updateNightMode(false);
    updateChatbotState('active');
    setLoading(true);
    updateResponseCount(0);
    updateCompanyNumber('');
    updateFeedbackInstructions('');
  };

  const resetConfigContextFromSignUp = () => {
    setCompanyLogo('');
    setCompanyLightModeLogo('');
    setCompanyName('');
  }

  return (
    <ConfigContext.Provider value={{
      logo: companyLogo,
      lightModeLogo: companyLightModeLogo,
      primaryColor: primaryColor,
      userDefaultMessage: userDefaultMessage,
      assistantFirstMessage: assistantFirstMessage,
      systemInstructions: systemInstructions,
      companyName: companyName,
      chatbotTone: chatbotTone,
      chatbotFormality: chatbotFormality,
      defaultLanguage: defaultLanguage,
      autoResolveFAQ: autoResolveFAQ,
      useEmojis: useEmojis,
      responseTime: responseTime,
      chatbotPersonality: chatbotPersonality,
      nightMode: nightMode,
      businessType: businessType,
      chatbotState: chatbotState,
      themeMode: themeMode,
      loading: loading,
      responseCount: responseCount,
      startDemoDate: startDemoDate,
      suggestion1: suggestion1,
      suggestion2: suggestion2,
      suggestion3: suggestion3,
      companyNumber: companyNumber,
      feedbackInstructions: feedbackInstructions,
      // Actualizaciones
      updateSystemInstructions,
      updateUserDefaultMessage,
      updateAssistantFirstMessage,
      updatePrimaryColor,
      updateCompanyLogo,
      updateCompanyLightModeLogo,
      updateCompanyName,
      updateChatbotTone,
      updateChatbotFormality,
      updateDefaultLanguage,
      updateAutoResolveFAQ,
      updateUseEmojis,
      updateResponseTime,
      updateChatbotPersonality,
      updateNightMode,
      updateBusinessType,
      updateChatbotState,
      toggleTheme,
      setLoading,
      updateResponseCount,
      updateStartDemoDate,
      updateSuggestion1,
      updateSuggestion2,
      updateSuggestion3,
      updateCompanyNumber,
      updateFeedbackInstructions: updateFeedbackInstructions,
      resetConfigContext,
      resetConfigContextFromSignUp
    }}>
      {children}
    </ConfigContext.Provider>
  );
}
