import { useEffect, useState, useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { Box, Stack, Typography } from "@mui/material";
import { ConfigContext } from "../../context/Config/ConfigContext";
import { db } from '../../services/firebase/firebase';
import { collection, getDocs } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { startOfWeek, subWeeks, format } from 'date-fns';

function getWeekNumber(date: Date): number {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}

const UsersChart = (props: any) => {
  const { t } = useTranslation('global');
  const { themeMode } = useContext(ConfigContext);

  const [seriesData, setSeriesData] = useState<any[]>([]);
  const [categories, setCategories] = useState<string[]>([]);

  const fetchMetrics = async (viewMode: string) => {
    try {
      const currentYear = new Date().getFullYear();
      const metricsRef = collection(db, 'metrics');
      const querySnapshot = await getDocs(metricsRef);

      const metrics: { [key: string]: { totalUsers: number } } = {};

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const docIdParts = doc.id.split('-');

        if (parseInt(docIdParts[1]) === currentYear) {
          if (viewMode === 'monthly' && docIdParts[0] === 'monthly') {
            const month = parseInt(docIdParts[2]);
            metrics[`${month}`] = {
              totalUsers: data.totalUsers ? data.totalUsers : 0,
            };
          } else if (viewMode === 'weekly' && docIdParts[0] === 'weekly') {
            const week = parseInt(docIdParts[2]);
            metrics[`${week}`] = {
              totalUsers: data.totalUsers ? data.totalUsers : 0,
            };
          }
        }
      });

      return metrics;
    } catch (error) {
      console.error('Error fetching metrics:', error);
      return {};
    }
  };

  const generateCategories = (viewMode: string, endDate: Date, count: number) => {
    const categories: string[] = [];
    let currentDate = new Date(endDate);

    for (let i = count - 1; i >= 0; i--) {
      if (viewMode === 'monthly') {
        categories.unshift(currentDate.toLocaleString('default', { month: 'short' }) + ' ' + currentDate.getFullYear());
        currentDate.setMonth(currentDate.getMonth() - 1);
      } else if (viewMode === 'weekly') {
        const weekEndFormatted = format(currentDate, 'd MMM yy', { weekStartsOn: 1 });
        categories.unshift(weekEndFormatted);
        currentDate = subWeeks(currentDate, 1);
      }
    }

    return categories;
  };

  useEffect(() => {
    const currentDate = new Date();
    let endDate: Date;
    let count: number;

    if (props.viewMode === 'monthly') {
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      count = 8;
    } else if (props.viewMode === 'weekly') {
      endDate = startOfWeek(currentDate, { weekStartsOn: 1 });
      count = 12;
    } else {
      return;
    }

    const categories = generateCategories(props.viewMode, endDate, count);
    setCategories(categories);
  }, [props.viewMode]);

  useEffect(() => {
    const loadData = async () => {
      const metrics = await fetchMetrics(props.viewMode);

      const currentDate = new Date();
      const currentIndex = props.viewMode === 'monthly' ? currentDate.getMonth() + 1 : getWeekNumber(currentDate);
      const startIndex = currentIndex - (props.viewMode === 'monthly' ? 7 : 11);

      const displayedMetrics = Array.from({ length: props.viewMode === 'monthly' ? 8 : 12 }, (_, i) => {
        const key = `${startIndex + i}`;
        return metrics[key] || { totalUsers: 0 };
      });

      setSeriesData([
        {
          name: t('dashboard.stats.users') || "",
          data: displayedMetrics.map(m => m.totalUsers)
        },
      ]);
    };

    loadData();
  }, [props.viewMode]);

  const options: ApexOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    colors: ["#4a3aff"],
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
        columnWidth: "75%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    stroke: {
      colors: ["transparent"],
      width: 2,
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      title: {
        text: t('dashboard.stats.number') || "",
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
    tooltip: {
      theme: themeMode === 'dark' ? 'dark' : 'light',
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return `${val} Usuarios`;
        }
      },
      marker: {
        show: true,
      },
      custom: function ({ series, seriesIndex, dataPointIndex }) {
        const bgColor = themeMode === 'dark' ? '#333' : '#fff';
        const textColor = themeMode === 'dark' ? '#fff' : '#333';
        return `<div style="background: ${bgColor}; color: ${textColor}; padding: 10px; border-radius: 5px; font-family: Outfit;">
      ${series[seriesIndex][dataPointIndex]} Usuarios
    </div>`;
      }
    },
  };

  return (
    <Box sx={{ p: 3, bgcolor: themeMode === 'dark' ? '#181818' : '#fff', borderRadius: "20px" }}>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography sx={{ mb: 3, fontSize: "18px", fontWeight: "400", color: themeMode === 'dark' ? "#fff" : "#000" }}>
          {/* {t('dashboard.stats.userCount')} */}
          Usuarios creados
        </Typography>
      </Stack>
      <ReactApexChart
        options={options}
        series={seriesData}
        type="bar"
        height={350}
      />
    </Box>
  );
};

export default UsersChart;