import {
  Avatar,
  Box, Button, CircularProgress,
  Grid, IconButton, Stack, Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDocs, collection } from "firebase/firestore";
import { db } from '../../services/firebase/firebase';
import FlipMove from 'react-flip-move';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import SectionContainer from '../../global/SectionContainer';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../context/Config/ConfigContext';
import '../ConversationsList/ConversationsList.css';
import { motion } from 'framer-motion';

interface Client {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  photoURL: string;
  subscriptionType: string;
  subscriptionStatus: string;
  subscriptionStartDate: any;
  subscriptionEndDate: any;
  // Otras propiedades del cliente...
}

function ClientsList() {

  const { themeMode } = useContext(ConfigContext)

  const { t } = useTranslation('global')

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  const [clients, setClients] = useState<Client[]>([]);

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      const clientsSnapshot = await getDocs(collection(db, 'users'));
      const clientsData = clientsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Client[];
      setClients(clientsData);
      setLoading(false);
    };

    fetchClients();
  }, []);

  // const handleView = (conversationId: string, chatbotId: string) => {
  //   navigate(`/bot/${chatbotId}/conversation/${conversationId}`);
  // };

  // const [loadingDelete, setLoadingDelete] = useState<boolean>(false);

  // const handleDelete = async () => {
  //   if (selectedConversationId) {
  //     setLoadingDelete(true)
  //     const selectedConversation = conversations.find(conversation => conversation.id === selectedConversationId);

  //     if (selectedConversation) {
  //       const chatbotId = selectedConversation.chatbotId;

  //       // Marcar la conversación para la animación de salida
  //       setConversations(currentClients =>
  //         currentClients.map(conversation =>
  //           conversation.id === selectedConversationId
  //             ? { ...conversation, exiting: true }
  //             : conversation
  //         )
  //       );

  //       // Esperar a que la animación se complete antes de eliminar
  //       setTimeout(async () => {
  //         await deleteDoc(doc(db, 'chatbots', chatbotId, 'conversations', selectedConversationId));
  //         setConversations(currentClients =>
  //           currentClients.filter(conversation => conversation.id !== selectedConversationId)
  //         );
  //         setSelectedConversationId(null);
  //         setOpen(false);

  //         setLoadingDelete(false)
  //       }, 100); // Asegúrate de que este tiempo coincida con la duración de la animación CSS
  //     } else {
  //
  //       setLoadingDelete(false)
  //     }
  //   }
  // };


  // const handleClickOpen = (conversationId: string) => {
  //   setSelectedConversationId(conversationId);
  //   setOpen(true);
  // };

  const [currentPage, setCurrentPage] = useState(1);
  const clientsPerPage = 7;

  // Función para cambiar a la página anterior
  const handleClickPrevious = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  // Función para cambiar a la página siguiente
  const handleClickNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  // Calculando los índices para la paginación
  const indexOfLastConversation = currentPage * clientsPerPage;
  const indexOfFirstConversation = indexOfLastConversation - clientsPerPage;
  const currentClients = clients.slice(indexOfFirstConversation, indexOfLastConversation);

  const totalPages = Math.ceil(clients.length / clientsPerPage);

  const handlePageClick = (page: number) => {
    setCurrentPage(page);
  };

  const getStyleByState = (state: string) => {
    switch (state) {
      case "active":
        return {
          backgroundColor: themeMode === "dark" ? "#1e2c1e" : "#C8E6C9",
          color: "#388E3C",
          fontWeight: "bold",
        };
      case "cancelled":
        return {
          backgroundColor: "#FFCDD2",
          color: "#D32F2F",
          fontWeight: "bold",
        };
      case "pending_cancellation":
        return {
          backgroundColor: themeMode === "dark" ? "#343434" : "#E0E0E0",
          color: "#757575",
          fontWeight: "bold",
        };
      default:
        return {
          backgroundColor: "transparent",
          color: themeMode === "dark" ? '#fff' : '#181818',
          fontWeight: "300",
        };
    }
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const areConversationsAvailable = clients.length > 0;

  const MAX_PAGE_NUMBERS_SHOWN = isMobile ? 3 : 5;

  const calculatePageNumbersToShow = (currentPage: any, totalPages: any) => {
    let startPage, endPage;
    if (totalPages <= MAX_PAGE_NUMBERS_SHOWN) {
      startPage = 1;
      endPage = totalPages;
    } else {
      const pagesBeforeCurrentPage = Math.floor(MAX_PAGE_NUMBERS_SHOWN / 2);
      const pagesAfterCurrentPage = Math.ceil(MAX_PAGE_NUMBERS_SHOWN / 2) - 1;
      if (currentPage <= pagesBeforeCurrentPage) {
        startPage = 1;
        endPage = MAX_PAGE_NUMBERS_SHOWN;
      } else if (currentPage + pagesAfterCurrentPage >= totalPages) {
        startPage = totalPages - MAX_PAGE_NUMBERS_SHOWN + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - pagesBeforeCurrentPage;
        endPage = currentPage + pagesAfterCurrentPage;
      }
    }
    return { startPage, endPage };
  };

  const { startPage, endPage } = calculatePageNumbersToShow(currentPage, totalPages);

  const getInitials = (firstName: string, lastName: string) => {
    if (firstName && lastName) {
      return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
    } else if (firstName) {
      return firstName.charAt(0).toUpperCase();
    } else if (lastName) {
      return lastName.charAt(0).toUpperCase();
    } else {
      return '';
    }
  };

  const getSubscriptionStatusText = (status: string) => {
    switch (status) {
      case 'active':
        return 'Activo';
      case 'pending_cancellation':
        return 'Por cancelar';
      case 'canceled':
        return 'Cancelado';
      default:
        return status;
    }
  };

  function mapSubscriptionTypeToPlanName(subscriptionType: any) {
    switch (subscriptionType) {
      case "free":
        return t('clientSubscription.plans.free.name');

      case "basicMonthlyMXN":
      case "basicAnnualMXN":
      case "basicMonthlyUSD":
      case "basicAnnualUSD":
        return t('clientSubscription.plans.basic.name');

      case "premiumMonthlyMXN":
      case "premiumAnnualMXN":
      case "premiumMonthlyUSD":
      case "premiumAnnualUSD":
        return t('clientSubscription.plans.premium.name');

      case "enterpriseMonthlyMXN":
      case "enterpriseAnnualMXN":
      case "enterpriseMonthlyUSD":
      case "enterpriseAnnualUSD":
        return t('clientSubscription.plans.business.name');

      default:
        return null;
    }
  }

  return (
    <SectionContainer
      title="Clientes"
      text={t('conversationList.viewAnalysis') as string}
      icon={<EqualizerIcon />}
      onClick={() => { navigate('/dashboard'); }}
      disableButton={!areConversationsAvailable}
    >
      <Box sx={{ backgroundColor: themeMode === "dark" ? '#121212' : '#fafafa', pb: isMobile ? '70px' : '0' }}>
        {
          !isMobile && currentClients.length > 0 && (
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                color: '#fff', borderRadius: '15px', px: 1,
              }}
            >
              <Grid item xs={2}>
                <Typography sx={{ fontFamily: 'Outfit', fontSize: '18px', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818' }} variant="h6">
                  Foto
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={{ fontFamily: 'Outfit', fontSize: '18px', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818' }} variant="h6">
                  Nombre
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ fontFamily: 'Outfit', fontSize: '18px', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818' }} variant="h6">
                  Correo
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={{ fontFamily: 'Outfit', fontSize: '18px', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818' }} variant="h6">
                  Suscripción
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={{ fontFamily: 'Outfit', fontSize: '18px', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818' }} variant="h6">
                  Estado
                </Typography>
              </Grid>
            </Grid>
          )
        }

        {
          !loading ? (
            <Box sx={{ height: currentClients.length > 7 ? '750px' : 'inherit', mb: '40px' }}>
              <FlipMove
              >
                {currentClients && currentClients.length > 0 ? (
                  currentClients.map((client: any) => (
                    <Grid
                      key={client.id}
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        py: 1,
                        px: 2,
                        color: '#fff',
                        marginTop: '10px',
                        borderRadius: '25px',
                        height: '100px',
                        backgroundColor: themeMode === "dark" ? '#181818' : '#fff',
                        boxShadow: themeMode === "dark"
                          ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                          : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                      }}
                    >
                      {!isMobile ? (
                        <>
                          <Grid item xs={2}>
                            <Box sx={{ textAlign: 'center' }}>
                              <motion.div
                                initial={{ scale: 0.5 }}
                                animate={{ scale: 1 }}
                                transition={{ duration: 0.25 }}
                              >
                                <Avatar
                                  src={client.photoURL}
                                  alt="Foto del cliente"
                                  sx={{
                                    width: 40,
                                    height: 40,
                                    fontSize: '1.2rem',
                                    bgcolor: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1',
                                    color: themeMode === "dark" ? '#181818' : '#fff',
                                    mx: 'auto',
                                  }}
                                >
                                  {getInitials(client.firstName, client.lastName)}
                                </Avatar>
                              </motion.div>
                            </Box>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography sx={{ fontFamily: 'Outfit', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818', fontWeight: '300' }} variant="body1">
                              {client.firstName} {client.lastName}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography sx={{ fontFamily: 'Outfit', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818', fontWeight: '300' }} variant="body1">
                              {client.email}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              sx={{
                                fontWeight: '700',
                                fontSize: '20px',
                                background: `linear-gradient(45deg, #4a3aff, ${themeMode === "dark" ? "#fafafa" : "#c1c1c1"})`,
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                MozBackgroundClip: 'text',
                                MozTextFillColor: 'transparent',
                                textAlign: 'center'
                              }}
                              variant="h6"
                            >
                              {`${mapSubscriptionTypeToPlanName(client.subscriptionType)}`}
                            </Typography>
                          </Grid>

                          <Grid item xs={2}>
                            <Typography sx={{
                              fontFamily: 'Outfit',
                              textAlign: 'center',
                              width: '100px',
                              mx: 'auto',
                              borderRadius: '100px',
                              fontSize: '16px',
                              ...getStyleByState(client.subscriptionStatus),
                              mt: '5px',
                            }} variant="body1">
                              {getSubscriptionStatusText(client.subscriptionStatus)}
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={8}>
                            <Stack flexDirection="row" gap={2} sx={{ textAlign: 'left' }}>
                              <motion.div
                                initial={{ scale: 0.5 }}
                                animate={{ scale: 1 }}
                                transition={{ duration: 0.25 }}
                              >
                                <Avatar
                                  src={client.photoURL}
                                  alt="Foto del cliente"
                                  sx={{
                                    width: 40,
                                    height: 40,
                                    fontSize: '1.2rem',
                                    bgcolor: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1',
                                    color: themeMode === "dark" ? '#181818' : '#fff',
                                  }}
                                >
                                  {getInitials(client.firstName, client.lastName)}
                                </Avatar>
                              </motion.div>
                              <Stack flexDirection="column">
                                <Typography sx={{ fontSize: '14px', textAlign: 'left', color: themeMode === "dark" ? '#fff' : '#181818', }}>
                                  {client.firstName} {client.lastName}
                                </Typography>
                                <Typography sx={{ fontSize: '12px', textAlign: 'left', color: themeMode === "dark" ? '#aaa' : '#666', }}>
                                  {client.email}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Grid>

                          <Grid item xs={3} alignItems="end">
                            <Stack flexDirection="column" justifyContent="space-between" alignItems="center">
                              <Typography
                                sx={{
                                  fontWeight: '700',
                                  fontSize: '14px',
                                  background: `linear-gradient(45deg, #4a3aff, ${themeMode === "dark" ? "#fafafa" : "#c1c1c1"})`,
                                  WebkitBackgroundClip: 'text',
                                  WebkitTextFillColor: 'transparent',
                                  MozBackgroundClip: 'text',
                                  MozTextFillColor: 'transparent',
                                  textAlign: 'left'
                                }}
                                variant="h6"
                              >
                                {`${mapSubscriptionTypeToPlanName(client.subscriptionType)}`}
                              </Typography>
                              <Typography sx={{
                                fontFamily: 'Outfit',
                                textAlign: 'center',
                                width: '100px',
                                mx: 'auto',
                                borderRadius: '100px',
                                fontSize: '14px',
                                ...getStyleByState(client.subscriptionStatus),
                                mt: '5px',
                              }} variant="body1">
                                {getSubscriptionStatusText(client.subscriptionStatus)}
                              </Typography>
                            </Stack>
                          </Grid>

                          <Grid item xs={1} alignItems="end">
                            <IconButton
                              // onClick={() => handleView(client.id)}
                              sx={{ color: themeMode === "dark" ? '#fff' : '#181818', '&:focus': { outline: 'none' } }}
                            >
                              <ArrowForwardIosRoundedIcon />
                            </IconButton>
                          </Grid>
                        </>
                      )
                      }
                    </Grid>
                  ))
                ) : (
                  <Stack flexDirection="column" justifyContent="center" alignItems="center" gap={2} sx={{ mt: '100px' }}>
                    <ForumRoundedIcon sx={{ fontSize: '60px', color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1' }} />
                    <Box sx={{ textAlign: 'center', }}>
                      <Typography align="center" sx={{
                        fontSize: '18px', fontFamily: 'Outfit', fontWeight: '300',
                        color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1'
                      }}>
                        {t('conversationList.noConversations')}
                      </Typography>
                    </Box>
                  </Stack>
                )}
              </FlipMove>

            </Box>

          ) : (
            <Box sx={{ display: 'flex', mx: 'auto', alignItems: "center", justifyContent: 'center', mt: '100px' }}>
              <CircularProgress sx={{ color: themeMode === "dark" ? '#fafafa' : '#4a3aff' }} />
            </Box>
          )
        }

        {
          !loading && clients.length > 7 && (
            <Grid
              container
              direction={isMobile ? "column" : "row"}
              justifyContent="space-between"
              alignItems="center"
              sx={{ mt: '20px' }}
            >
              <Grid item>
                <Button
                  onClick={handleClickPrevious}
                  disabled={currentPage === 1}
                  sx={{
                    color: themeMode === "dark" ? '#fff' : '#181818',
                    borderColor: themeMode === "dark" ? '#fff' : '#eeeeee',
                    '&:focus': { outline: 'none' },
                    '&:hover': {
                      backgroundColor: themeMode === "dark" ? '#181818' : '#eeeeee',
                      borderColor: themeMode === "dark" ? '#fff' : '#cccccc',
                    },
                    borderRadius: '15px',
                    fontFamily: 'Outfit',
                    textTransform: 'none',
                  }}
                >
                  {t('conversationList.navigation.previous')}
                </Button>
                {startPage > 1 && <span style={{ color: themeMode === "dark" ? '#fff' : '#181818' }} >...</span>}
                {Array.from({ length: (endPage - startPage) + 1 }, (_, i) => startPage + i).map(page => (
                  <IconButton
                    key={page}
                    onClick={() => handlePageClick(page)}
                    sx={{
                      color: themeMode === "dark" ? '#fff' : '#181818',
                      borderColor: themeMode === "dark" ? '#fff' : '#eeeeee',
                      fontSize: '16px',
                      '&:focus': { outline: 'none' },
                      '&:hover': { borderColor: themeMode === "dark" ? '#fff' : '#cccccc' },
                      fontFamily: 'Outfit',
                      textTransform: 'none',
                      backgroundColor: page === currentPage ? themeMode === "dark" ? '#181818' : '#eeeeee' : 'transparent',
                      height: 35,
                      width: 35,
                      margin: '0 2px',
                    }}
                  >
                    {page}
                  </IconButton>
                ))}
                {endPage < totalPages && <span style={{ color: themeMode === "dark" ? '#fff' : '#181818' }} >...</span>}
                <Button
                  onClick={handleClickNext}
                  disabled={currentPage * clientsPerPage >= clients.length}
                  sx={{
                    ml: '10px',
                    color: themeMode === "dark" ? '#fff' : '#181818',
                    '&:focus': { outline: 'none' },
                    '&:hover': {
                      backgroundColor: themeMode === "dark" ? '#181818' : '#eeeeee',
                      borderColor: themeMode === "dark" ? '#fff' : '#cccccc',
                    },
                    borderRadius: '15px',
                    fontFamily: 'Outfit',
                    textTransform: 'none',
                  }}
                >
                  {t('conversationList.navigation.next')}
                </Button>
              </Grid>
              {!isMobile && (
                <Grid item>
                  <Typography variant="body1" sx={{
                    color: themeMode === "dark" ? '#fff' : '#181818',
                    fontFamily: 'Outfit',
                    fontSize: '14px',
                    mt: 2
                  }}>
                    {t('conversationList.navigation.pageIndicator', { current: currentPage, total: totalPages })}
                  </Typography>
                </Grid>
              )}
            </Grid>
          )
        }
      </Box>
    </SectionContainer >
  );
}
export default ClientsList;
