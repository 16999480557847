import { useContext, useEffect, useState } from 'react';
import {
  Box,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import cookies from 'js-cookie';
import SectionContainer from '../../global/SectionContainer';
import { ConfigContext } from '../../context/Config/ConfigContext';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../../services/firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import StoreContext from '../../context/Store/storeContext';
import { showToast } from '../../components/Toast/toast';

function Settings() {
  const { t } = useTranslation();
  const { themeMode, toggleTheme } = useContext(ConfigContext);
  const isDark = themeMode === 'dark';
  const [userAuth] = useAuthState(auth);
  const { user, fetchUser } = useContext(StoreContext);

  const [language, setLanguage] = useState(user.language || 'es');
  const [currency, setCurrency] = useState(user.currency || 'MXN');

  useEffect(() => {
    if (user.language) {
      setLanguage(user.language);
      i18next.changeLanguage(user.language);
      cookies.set('i18next', user.language, { expires: 365 });
    }
  }, [user.language]);

  useEffect(() => {
    if (user.currency) {
      setCurrency(user.currency);
    }
  }, [user.currency]);

  const handleLanguageChange = async (event: any) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    i18next.changeLanguage(newLanguage);
    cookies.set('i18next', newLanguage, { expires: 365 });

    if (userAuth) {
      const userRef = doc(db, "users", userAuth.uid);
      try {
        await setDoc(userRef, { language: newLanguage }, { merge: true });
        showToast('success', t('settings.languageUpdated'), themeMode);
        fetchUser(userAuth.uid);
      } catch (error: any) {
      }
    }
  };

  const handleCurrencyChange = async (event: any) => {
    const newCurrency = event.target.value;
    setCurrency(newCurrency);

    if (userAuth) {
      const userRef = doc(db, "users", userAuth.uid);
      try {
        await setDoc(userRef, { currency: newCurrency }, { merge: true });
        showToast('success', t('settings.currencyUpdated'), themeMode);
        fetchUser(userAuth.uid);
      } catch (error: any) {
      }
    }
  };

  return (
    <SectionContainer title={t('settings.title') as string}>
      <Box sx={{ backgroundColor: themeMode === 'dark' ? '#121212' : '#fafafa', pt: '30px' }}>
        <Grid container spacing={3} pb="25px" justifyContent="center">
          <Grid item xs={12} sm={6} md={4}>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                borderRadius: '20px',
                p: 2,
                backgroundColor: themeMode === 'dark' ? '#181818' : '#fff',
                height: '80px',
                boxShadow: themeMode === "dark"
                  ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                  : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
              }}
            >
              <Typography
                variant="h5"
                style={{
                  fontFamily: 'Outfit',
                  color: themeMode === 'dark' ? '#fff' : '#181818',
                  fontSize: '18px'
                }}
              >
                {t('settings.language')}
              </Typography>
              <Select
                value={language}
                onChange={handleLanguageChange}
                sx={{
                  height: '40px',
                  color: themeMode === 'dark' ? '#fff' : '#181818',
                  borderRadius: '10px',
                  borderColor: 'transparent',
                  '&:focus': {
                    outline: 'none'
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                    border: 'none',
                    '&:focus': {
                      borderColor: 'transparent',
                      border: 'none'
                    }
                  },
                  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent'
                  },
                  '& .MuiInputBase-input': {
                    fontFamily: 'Outfit'
                  }
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      borderRadius: '10px',
                      boxShadow: 'none',
                      backgroundColor: themeMode === 'dark' ? '#121212' : '#fff',
                      color: themeMode === 'dark' ? '#fff' : '#181818'
                    }
                  },
                  sx: {
                    '& .MuiMenuItem-root': {
                      fontFamily: 'Outfit'
                    }
                  }
                }}
              >
                <MenuItem
                  sx={{
                    '&:hover': {
                      backgroundColor: themeMode === 'dark' ? '#181818' : '#fafafa'
                    }
                  }}
                  value={'es'}
                  disabled={language === 'es'}
                >
                  Español
                </MenuItem>
                <MenuItem
                  sx={{
                    '&:hover': {
                      backgroundColor: themeMode === 'dark' ? '#181818' : '#fafafa'
                    }
                  }}
                  value={'en'}
                  disabled={language === 'en'}
                >
                  English
                </MenuItem>
              </Select>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                borderRadius: '20px',
                p: 2,
                backgroundColor: themeMode === 'dark' ? '#181818' : '#fff',
                height: '80px',
                boxShadow: themeMode === "dark"
                  ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                  : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
              }}
            >
              <Typography
                variant="h5"
                style={{
                  fontFamily: 'Outfit',
                  color: themeMode === 'dark' ? '#fff' : '#121212',
                  fontSize: '18px'
                }}
              >
                {t('settings.currency')}
              </Typography>
              <Select
                value={currency}
                onChange={handleCurrencyChange}
                sx={{
                  height: '40px',
                  color: themeMode === 'dark' ? '#fff' : '#181818',
                  borderRadius: '10px',
                  borderColor: 'transparent',
                  '&:focus': {
                    outline: 'none'
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                    border: 'none',
                    '&:focus': {
                      borderColor: 'transparent',
                      border: 'none'
                    }
                  },
                  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent'
                  },
                  '& .MuiInputBase-input': {
                    fontFamily: 'Outfit'
                  }
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      borderRadius: '10px',
                      boxShadow: 'none',
                      backgroundColor: themeMode === 'dark' ? '#121212' : '#fff',
                      color: themeMode === 'dark' ? '#fff' : '#181818'
                    }
                  },
                  sx: {
                    '& .MuiMenuItem-root': {
                      fontFamily: 'Outfit'
                    }
                  }
                }}
              >
                <MenuItem
                  sx={{
                    '&:hover': {
                      backgroundColor: themeMode === 'dark' ? '#181818' : '#fafafa'
                    }
                  }}
                  value={'USD'}
                  disabled={currency === 'USD'}
                >
                  USD
                </MenuItem>
                <MenuItem
                  sx={{
                    '&:hover': {
                      backgroundColor: themeMode === 'dark' ? '#181818' : '#fafafa'
                    }
                  }}
                  value={'MXN'}
                  disabled={currency === 'MXN'}
                >
                  MXN
                </MenuItem>
              </Select>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                borderRadius: '20px',
                p: 2,
                backgroundColor: themeMode === 'dark' ? '#181818' : '#fff',
                height: '80px',
                boxShadow: themeMode === "dark"
                  ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                  : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
              }}
            >
              <Typography
                variant="h5"
                style={{
                  fontFamily: 'Outfit',
                  color: themeMode === 'dark' ? '#fff' : '#121212',
                  fontSize: '18px'
                }}
              >
                {t('settings.theme')}
              </Typography>
              <FormControlLabel
                sx={{ color: themeMode === 'dark' ? '#fff' : '#121212' }}
                control={
                  <Switch
                    checked={isDark}
                    onChange={toggleTheme}
                    sx={{
                      '& .MuiSwitch-track': {
                        backgroundColor: themeMode === 'dark' ? '#767577' : '#e2e2e2'
                      },
                      '& .MuiSwitch-thumb': {
                        color: themeMode === 'dark' ? '#f5f5f5' : '#fff'
                      },
                      '&.Mui-checked': {
                        color: themeMode === 'dark' ? '#f5f5f5' : '#fff',
                        '& + .MuiSwitch-track': {
                          backgroundColor: themeMode === 'dark' ? '#b2dfdb' : '#81c784'
                        }
                      }
                    }}
                  />
                }
                label={isDark ? t('settings.theme_options.dark') : t('settings.theme_options.light')}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </SectionContainer>
  );
}

export default Settings;