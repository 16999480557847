import { Grow, useMediaQuery, useTheme } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import React, { useContext, useState } from 'react';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import DialogCommonHeader from './Components/DialogHeader/DialogHeader';
import DialogCommonActions from './Components/DialogActions/DialogActions';
import DialogCommonContent from './Components/DialogContent/DialogContent';
import { DialogCommonModels } from './DialogCommon.models';
import { ConfigContext } from '../../context/Config/ConfigContext';

const isMobile = window.matchMedia(`(max-width: 768px)`).matches;

const Transition = React.forwardRef((
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) => (isMobile ? <Slide direction="up" ref={ref} {...props} /> : <Grow ref={ref} {...props} />));


function DialogCommon(props: DialogCommonModels) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [scroll] = useState<DialogProps['scroll']>('paper');

  // eslint-disable-next-line no-unused-expressions
  const slideTransition = props.kSlideTransition;

  const { themeMode } = useContext(ConfigContext)

  return (
    <>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: isMobile ? props.kEnableBorderRadius ? props.kBorderRadius : 0 : props.kBorderRadius,
          },
        }}
        keepMounted
        TransitionComponent={slideTransition ? Transition : undefined}
        fullWidth
        fullScreen={isMobile ? props.kFullScreenMobile : props.kFullScreenDesktop}
        maxWidth={props.kMaxWidth}
        open={props.kOpenDialogLists}
        scroll={scroll}
        onClose={props.kOnClose}
        BackdropProps={{
          sx: {
            backgroundColor: themeMode === "dark" ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0.3)', // Ajusta estos valores según necesites
          }
        }}
        sx={{
          '.MuiDialog-paper': {
            overflow: 'visible',
            height: props.kHeight ? props.kHeight : props.kMaxHeight,
            transition: '0.25s',
            maxHeight: props.kMaxHeight,
            position: isMobile ? props.kFixedDialogPosition : 'inherit',
            bottom: isMobile ? props.kFixedDialog : 'inherit',
            left: isMobile ? props.kFixedDialog : 'inherit',
            right: isMobile ? props.kFixedDialog : 'inherit',
          },
        }}
      >

        {
          (!props.kDisableTitle)
          && (
            <DialogCommonHeader
              kTitle={props.kTitle}
              kSubTitle={props.kSubTitle}
              kOnClose={props.kOnClose}
              kHeaderComponent={props.kHeaderComponent}
              kDisableContentPaddinginY={props.kDisableContentPaddinginY}
              kCenterTitle={props.kCenterTitle}
            />
          )
        }
        <DialogCommonContent
          kContent={props.kContent}
          KDisableScroll={props.KDisableScroll}
          kDividers={props.kFullScreenMobile ?? isMobile}
          kContentPadding={props.kDisableContentPadding}
          kDisableBottomDivider={props.kDisableBottomDivider}
          kDisableContentPaddingInY={props.kDisableContentPaddinginY}
          kDisableActions={props.kDisableActions}
          kAlignItemsStart={props.kAlignItemsStart}
        />
        {
          !props.kDisableActions
          && (
            <DialogCommonActions
              kSecondaryDialogAction={props.kOnSecondary}
              kPrimaryDialogAction={props.kOnPrimary}
              kPrimaryBtnTextAction={props.kPrimaryBtnText}
              kThirdDialogAction={props.kOnThird}
              kThirdBtnTextAction={props.kPrimaryBtnText}
              kSecondaryBtnTextAction={props.kSecondaryBtnText}
              kAlignBtnAction={props.kAlignBtn}
              kElementAction={props.kElementAction}
              kDisableBtnSave={props.kDisableBtnSave}
              kKeepBtnSize={props.kKeepBtnSize}
              kMainColor={props.kMainColor}
              kIsChatModal={props.kIsChatModal}
            />
          )
        }
      </Dialog>
    </>
  );
}

DialogCommon.defaultProps = {
  kFullScreenMobile: true,
  kFullScreenDesktop: false,
};
export default DialogCommon;
