import {
  Box, Button, CircularProgress,
  Grid, IconButton, Menu, MenuItem, Stack, Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDocs, collection, doc, deleteDoc, query, where, DocumentData, limit, orderBy, QueryDocumentSnapshot, startAfter, getDoc } from "firebase/firestore";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../../services/firebase/firebase';
import FlipMove from 'react-flip-move';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import SectionContainer from '../../global/SectionContainer';
import DialogCommon from '../../components/DialogCommon/DialogCommon';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../context/Config/ConfigContext';
import '../ConversationsList/ConversationsList.css';
import { motion } from 'framer-motion';
import StoreContext from '../../context/Store/storeContext';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import { showToast } from '../../components/Toast/toast';

interface Conversation {
  id: string;
  chatbotId: string;
  chatbotLogo: string;
  userId: string;
  messages?: Message[];
  firstMessage?: string;
  firstMessageTimestamp?: any;
}

interface Message {
  message: string;
  sender: string;
  timestamp: Date;
}

function ConversationsList() {

  const { themeMode } = useContext(ConfigContext)

  const { t } = useTranslation('global')

  const [userAuth] = useAuthState(auth);
  const { user } = useContext(StoreContext);
  const [open, setOpen] = useState(false);
  const [selectedConversationId, setSelectedConversationId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [totalConversations, setTotalConversations] = useState(0);
  const [convLimit, setConvLimit] = useState(15);

  const navigate = useNavigate();
  const theme = useTheme();

  const [conversations, setConversations] = useState<Conversation[]>([]);

  const truncateMessage = (message: any, maxLength = 80) => {
    if (message?.length > maxLength) {
      return message.substring(0, maxLength) + '...';
    }
    return message;
  };

  const { chatbots, setChatbots } = useContext(StoreContext);

  const getChatbotLogo = (chatbotId: string, theme: 'dark' | 'light') => {
    const chatbot = chatbots.find((chatbot: any) => chatbot.id === chatbotId);
    if (chatbot) {
      return theme === 'dark' ? chatbot.logo : chatbot.lightModeLogo;
    }
    return '';
  };

  const fetchConversations = async (startAfterId?: QueryDocumentSnapshot<DocumentData>) => {
    setLoading(true);
    if (userAuth) {
      const conversationsRef = collection(db, 'conversations');
      let q = query(conversationsRef, where('ownerId', '==', userAuth.uid), orderBy('firstMessageTimestamp', 'desc'), limit(15));

      if (startAfterId) {
        q = query(q, startAfter(startAfterId));
      }

      const conversationsSnapshot = await getDocs(q);

      const fetchedConversations = conversationsSnapshot.docs.map((doc) => ({
        id: doc.id,
        chatbotId: doc.data().chatbotId,
        chatbotLogo: doc.data().chatbotLogo,
        userId: doc.data().userId,
        firstMessage: truncateMessage(doc.data().firstMessage),
        firstMessageTimestamp: doc.data().firstMessageTimestamp,
        analysis: doc.data().analysis
      }));

      if (startAfterId) {
        setConversations((prevConversations) => [...prevConversations, ...fetchedConversations]);
      } else {
        setConversations(fetchedConversations);
      }


      // Obtener el número total de conversaciones del dueño del chatbot
      const totalSnapshot = await getDocs(query(conversationsRef, where('ownerId', '==', userAuth.uid)));
      setTotalConversations(totalSnapshot.size);

      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchConversations();
    }
  }, [user]);

  const loadMoreConversations = async () => {
    setLoadingMore(true);
    const lastConversation = conversations[conversations.length - 1];
    if (lastConversation && lastConversation.firstMessageTimestamp) {
      const conversationsRef = collection(db, 'conversations');
      const q = query(
        conversationsRef,
        where('ownerId', '==', userAuth?.uid),
        orderBy('firstMessageTimestamp', 'desc'),
        startAfter(lastConversation.firstMessageTimestamp),
        limit(15)
      );
      const conversationsSnapshot = await getDocs(q);

      const fetchedConversations = conversationsSnapshot.docs.map((doc) => ({
        id: doc.id,
        chatbotId: doc.data().chatbotId,
        chatbotLogo: doc.data().chatbotLogo,
        userId: doc.data().userId,
        firstMessage: truncateMessage(doc.data().firstMessage),
        firstMessageTimestamp: doc.data().firstMessageTimestamp,
        analysis: doc.data().analysis
      }));

      setConversations((prevConversations) => [...prevConversations, ...fetchedConversations]);

      setConvLimit((prevConvLimit) => prevConvLimit + 15);
    }
    setLoadingMore(false);
  };

  useEffect(() => {
    const fetchChatbots = async () => {
      const userId = auth.currentUser?.uid;
      if (userId) {
        const q = query(collection(db, 'chatbots'), where('ownerId', '==', userId));
        const querySnapshot = await getDocs(q);
        const chatbotsData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setChatbots(chatbotsData);
      }
    };

    fetchChatbots();
  }, []);

  const handleView = (conversationId: string, chatbotId: string) => {
    navigate(`/bot/${chatbotId}/conversation/${conversationId}`);
  };

  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);

  const handleDelete = async () => {
    if (selectedConversationId) {
      setLoadingDelete(true)
      const selectedConversation = conversations.find(conversation => conversation.id === selectedConversationId);

      if (selectedConversation) {

        setConversations(currentConversations =>
          currentConversations.map(conversation =>
            conversation.id === selectedConversationId
              ? { ...conversation, exiting: true }
              : conversation
          )
        );

        setTimeout(async () => {
          await deleteDoc(doc(db, 'conversations', selectedConversationId));
          setConversations(currentConversations =>
            currentConversations.filter(conversation => conversation.id !== selectedConversationId)
          );
          setSelectedConversationId(null);
          setOpen(false);

          setLoadingDelete(false)
        }, 100);
      } else {
        showToast('error', t('toast.selectedConversationNotFound'), themeMode);
        setLoadingDelete(false)
      }
    }
  };


  const handleClickOpen = (conversationId: string) => {
    setSelectedConversationId(conversationId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const areConversationsAvailable = conversations.length > 0;

  const formatDateWithTime = (timestamp: any) => {
    if (!timestamp) return '-';

    const conversationDate = timestamp.toDate();
    const now = new Date();
    const currentYear = now.getFullYear();
    const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);

    const hours = conversationDate.getHours().toString().padStart(2, '0');
    const minutes = conversationDate.getMinutes().toString().padStart(2, '0');
    const timeString = `${hours}:${minutes}`;

    const isToday = conversationDate.getDate() === now.getDate() &&
      conversationDate.getMonth() === now.getMonth() &&
      conversationDate.getFullYear() === now.getFullYear();

    const isYesterday = conversationDate.getDate() === yesterday.getDate() &&
      conversationDate.getMonth() === yesterday.getMonth() &&
      conversationDate.getFullYear() === yesterday.getFullYear();

    const diff = now.getTime() - conversationDate.getTime();
    const diffInHours = diff / (1000 * 60 * 60);

    const dateOptions = {
      day: 'numeric',
      month: 'long',
      ...(conversationDate.getFullYear() !== currentYear && { year: 'numeric' })
    };

    if (diffInHours < 1) {
      return <span>{t('dashboard.now')}<br />{timeString}</span>;
    } else if (isToday) {
      return <span>{t('dashboard.today')}<br />{timeString}</span>;
    } else if (isYesterday) {
      return <span>{t('dashboard.yesterday')}<br />{timeString}</span>;
    } else {
      return (
        <>
          {conversationDate.toLocaleDateString('es-ES', dateOptions)}
          <br />
          {timeString}
        </>
      );
    }
  };

  const [conversationAnchorEls, setConversationAnchorEls] = useState<{ [key: string]: HTMLElement | null }>({});

  const handleConversationMenuOpen = (event: React.MouseEvent<HTMLElement>, conversationId: string) => {
    setConversationAnchorEls(prevState => ({
      ...prevState,
      [conversationId]: event.currentTarget,
    }));
  };

  const handleConversationMenuClose = (conversationId: string) => {
    setConversationAnchorEls(prevState => ({
      ...prevState,
      [conversationId]: null,
    }));
  };

  const [analyzingConversationId, setAnalyzingConversationId] = useState<string | null>(null);

  const [openUpdatePlan, setOpenUpdatePlan] = useState(false);

  const handleOpenUpdatePlan = () => {
    setOpenUpdatePlan(true);
  };

  const handleCloseUpdatePlan = () => {
    setOpenUpdatePlan(false);
  };

  const handleUpdatePlan = () => {
    navigate('/subscription')
    handleCloseUpdatePlan();
  };

  const handleAnalyzeConversation = async (conversationId: string) => {
    if (
      user.subscriptionType === 'basicMonthlyMXN' ||
      user.subscriptionType === 'basicAnnualMXN' ||
      user.subscriptionType === 'basicMonthlyUSD' ||
      user.subscriptionType === 'basicAnnualUSD'
    ) {
      handleOpenUpdatePlan();
      return;
    }

    setAnalyzingConversationId(conversationId);
    // Obtener los mensajes de la conversación
    const messagesRef = collection(db, 'messages');
    const messagesQuery = query(messagesRef, where('conversationId', '==', conversationId), orderBy('timestamp'));
    const messagesSnapshot = await getDocs(messagesQuery);
    const messages = messagesSnapshot.docs.map(doc => doc.data());

    // Obtener el ID del chatbot
    const conversationRef = doc(db, 'conversations', conversationId);
    const conversationSnapshot = await getDoc(conversationRef);
    const chatbotId = conversationSnapshot.data()?.chatbotId;

    // Llamar a la función de análisis de conversación
    try {
      const response = await fetch(`${process.env.VITE_APP_BACKEND_API}/analyzeConversation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          conversationId: conversationId,
          messages: messages,
          chatbotId: chatbotId,
          currentDate: new Date().toISOString(),
        }),
      });

      if (response.ok) {
        showToast('success', t('toast.conversationAnalysisCompleted'), themeMode);
        const updatedConversationSnapshot = await getDoc(doc(db, 'conversations', conversationId));
        if (updatedConversationSnapshot.exists()) {
          const updatedConversationData = updatedConversationSnapshot.data();
          setConversations((prevConversations) =>
            prevConversations.map((conversation) =>
              conversation.id === conversationId ? { ...conversation, analysis: updatedConversationData.analysis } : conversation
            )
          );

        }
      } else {
        showToast('error', t('toast.errorAnalyzingConversation'), themeMode);
      }
    } catch (error) {
      showToast('error', t('toast.errorAnalyzingConversation'), themeMode);
    }

    setAnalyzingConversationId(null);
  };

  const getStyleByState = (state: any) => {
    let resolvedState = resolveBoolean(state);
    switch (resolvedState) {
      case true:
        return {
          backgroundColor: themeMode === "dark" ? "#1e2c1e" : "#C8E6C9", // Light green
          color: "#388E3C", // Dark green
          fontWeight: "bold",
        };
      case false:
        return {
          backgroundColor: themeMode === "dark" ? "#4c1e1e" : "#FFCDD2", // Light red
          color: "#D32F2F", // Dark red
          fontWeight: "bold",
        };
      case null:
        return {
          backgroundColor: "transparent",
          color: themeMode === "dark" ? '#fff' : '#181818',
          fontWeight: "300",
        };
      default:
        return {
          backgroundColor: "transparent",
          color: themeMode === "dark" ? '#fff' : '#181818',
          fontWeight: "300",
        };
    }
  };


  const resolveBoolean = (value: any): boolean | null => {
    if (value === undefined || value === null || value === '-') {
      return null;
    }
    return value === true || value === "true";
  };


  return (
    <SectionContainer
      title={t('conversationList.title') as string}
      text={t('conversationList.viewAnalysis') as string}
      icon={<EqualizerIcon />}
      onClick={() => { navigate('/dashboard'); }}
      disableButton={!areConversationsAvailable}
    >
      <Box sx={{ backgroundColor: themeMode === "dark" ? '#121212' : '#fafafa', pb: isMobile ? '70px' : '0' }}>
        {
          !loading && !isMobile && conversations.length > 0 && (
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                color: '#fff', borderRadius: '15px', px: 1,
              }}
            >
              <Grid item xs={2}>
                <Typography sx={{ fontFamily: 'Outfit', fontSize: '18px', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818' }} variant="h6">
                  {t('conversationList.columns.chatbot')}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ fontFamily: 'Outfit', fontSize: '18px', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818' }} variant="h6">
                  {t('conversationList.columns.firstMessage')}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={{ fontFamily: 'Outfit', fontSize: '18px', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818' }} variant="h6">
                  {t('conversationList.columns.dateTime')}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={{
                  fontFamily: 'Outfit',
                  textAlign: 'center',
                  fontSize: '18px',
                  color: themeMode === "dark" ? '#fff' : '#181818',
                }} variant="h6">
                  {t('botList.header.status')}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={{ fontFamily: 'Outfit', fontSize: '18px', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818' }} variant="h6">
                  {t('conversationList.columns.actions')}
                </Typography>
              </Grid>
            </Grid>
          )
        }

        {
          !loading ? (
            <Box sx={{ height: 'auto', mb: '40px' }}>
              <FlipMove
              >
                {conversations && conversations.length > 0 ? (
                  conversations.map((conversation: any) => (
                    <Grid
                      key={conversation.id}
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        py: 1,
                        px: 2,
                        color: '#fff',
                        marginTop: '10px',
                        borderRadius: '25px',
                        height: '100px',
                        backgroundColor: themeMode === "dark" ? '#181818' : '#fff',
                        boxShadow: themeMode === "dark"
                          ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                          : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                      }}
                    >
                      {!isMobile ? (
                        <>
                          {/* Logo */}
                          <Grid item xs={2}>
                            <Box sx={{ textAlign: 'center' }}>
                              <motion.div
                                initial={{ scale: 0.5 }}
                                animate={{ scale: 1 }}
                                transition={{ duration: 0.25 }}
                              >
                                <img
                                  src={getChatbotLogo(conversation.chatbotId, themeMode === 'dark' ? 'dark' : 'light')}
                                  alt="Logo del chatbot" style={{ maxHeight: '30px', }} />
                              </motion.div>
                            </Box>
                          </Grid>
                          {/* Primer mensaje */}
                          <Grid item xs={4}>
                            <Typography sx={{ fontFamily: 'Outfit', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818', fontWeight: '300' }} variant="body1">
                              {conversation.firstMessage}
                            </Typography>
                          </Grid>
                          {/* Fecha de Creación */}
                          <Grid item xs={2}>
                            <Typography sx={{
                              fontFamily: 'Outfit',
                              textAlign: 'center',
                              color: themeMode === "dark" ? '#fff' : '#181818',
                              fontWeight: '300'
                            }} variant="body1">
                              {conversation.firstMessageTimestamp ? (
                                formatDateWithTime(conversation.firstMessageTimestamp)
                              ) : '-'}
                            </Typography>
                          </Grid>

                          {/* Duda resuelta */}
                          <Grid item xs={2}>
                            {analyzingConversationId === conversation.id ? (
                              <Box sx={{ mx: 'auto', textAlign: 'center' }}>
                                <CircularProgress size={24} />
                              </Box>
                            ) : (
                              <Typography
                                sx={{
                                  fontFamily: 'Outfit',
                                  textAlign: 'center',
                                  ...getStyleByState(conversation?.analysis?.doubtResolved),
                                  width: '120px',
                                  borderRadius: '100px',
                                  mx: 'auto'
                                }}
                                variant="body1"
                              >
                                {conversation.analysis ? (resolveBoolean(conversation.analysis.doubtResolved) ? t('conversationList.resolved') : t('conversationList.notResolved')) : '-'}
                              </Typography>
                            )}
                          </Grid>

                          {/* Acciones */}
                          <Grid item xs={2}>
                            <Box sx={{ textAlign: 'center' }}>
                              <IconButton
                                onClick={(event) => handleConversationMenuOpen(event, conversation.id)}
                                sx={{ '&:focus': { outline: 'none' }, '&:hover': { backgroundColor: themeMode === "dark" ? '#212121' : '#fafafa' } }}
                              >
                                <MoreVertRoundedIcon sx={{ color: themeMode === "dark" ? '#fff' : '#c1c1c1' }} />
                              </IconButton>
                              <Menu
                                anchorEl={conversationAnchorEls[conversation.id]}
                                open={Boolean(conversationAnchorEls[conversation.id])}
                                onClose={() => handleConversationMenuClose(conversation.id)}
                                PaperProps={{
                                  style: {
                                    backgroundColor: themeMode === "dark" ? '#000' : '#fff',
                                    color: themeMode === "dark" ? '#fff' : '#181818',
                                    borderRadius: '20px',
                                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                  },
                                }}
                              >
                                <MenuItem sx={{ fontSize: '16px', '&:hover': { backgroundColor: themeMode === "dark" ? '#232323' : '#fafafa' } }}
                                  onClick={() => { handleView(conversation.id, conversation.chatbotId); handleConversationMenuClose(conversation.id) }}>
                                  <RemoveRedEyeRoundedIcon sx={{ color: themeMode === "dark" ? '#fff' : '#c1c1c1', marginRight: '8px', fontSize: '20px' }} />
                                  {t('conversationList.actions.view')}
                                </MenuItem>
                                {!conversation.analysis && (
                                  <MenuItem
                                    disabled={analyzingConversationId !== null}
                                    sx={{
                                      fontSize: '16px',
                                      '&:hover': { backgroundColor: themeMode === "dark" ? '#232323' : '#fafafa' },
                                    }}
                                    onClick={() => { handleAnalyzeConversation(conversation.id); handleConversationMenuClose(conversation.id); }}
                                  >
                                    <QueryStatsRoundedIcon sx={{ color: themeMode === "dark" ? '#fff' : '#c1c1c1', marginRight: '8px', fontSize: '20px' }} />
                                    {t('conversationList.actions.analyze')}
                                  </MenuItem>

                                )}
                                <MenuItem sx={{ fontSize: '16px', '&:hover': { backgroundColor: themeMode === "dark" ? '#232323' : '#fafafa' } }}
                                  onClick={() => { handleClickOpen(conversation.id); handleConversationMenuClose(conversation.id); }}>
                                  <DeleteRoundedIcon sx={{ color: themeMode === "dark" ? '#fff' : '#c1c1c1', marginRight: '8px', fontSize: '20px' }} />
                                  {t('conversationList.actions.delete')}
                                </MenuItem>
                              </Menu>
                            </Box>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={8}>
                            <Box sx={{ textAlign: 'left' }}>
                              <motion.div
                                initial={{ scale: 0.5 }}
                                animate={{ scale: 1 }}
                                transition={{ duration: 0.25 }}
                              >
                                <img
                                  src={getChatbotLogo(conversation.chatbotId, themeMode === 'dark' ? 'dark' : 'light')}
                                  alt="Logo del chatbot" style={{ maxHeight: '20px', }}
                                />
                              </motion.div>
                              <Typography sx={{ fontSize: '14px', textAlign: 'left', color: themeMode === "dark" ? '#fff' : '#181818', }}>
                                {conversation.firstMessage}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={3} alignItems="end">
                            <Stack flexDirection="column" justifyContent="space-between" alignItems="end">
                              <Typography
                                sx={{
                                  fontFamily: 'Outfit',
                                  textAlign: 'center',
                                  ...getStyleByState(conversation?.analysis?.doubtResolved),
                                  width: '90px',
                                  borderRadius: '100px',
                                  fontSize: '14px'
                                }}
                                variant="body1"
                              >
                                {conversation.analysis ? (conversation.analysis.doubtResolved ? t('conversationList.resolved') : t('conversationList.notResolved')) : '-'}
                              </Typography>
                              <Typography sx={{ fontSize: '14px', textAlign: 'right', color: themeMode === "dark" ? '#fff' : '#181818', }}>
                                {conversation.firstMessageTimestamp ? formatDateWithTime(conversation.firstMessageTimestamp) : '-'}
                              </Typography>


                            </Stack>
                          </Grid>
                          <Grid item xs={1} alignItems="end">
                            <IconButton onClick={() => handleView(conversation.id, conversation.chatbotId)} sx={{ color: themeMode === "dark" ? '#fff' : '#181818', '&:focus': { outline: 'none' } }}>
                              <ArrowForwardIosRoundedIcon />
                            </IconButton>
                          </Grid>
                        </>
                      )
                      }
                    </Grid>
                  ))
                ) : (
                  <Stack flexDirection="column" justifyContent="center"
                    alignItems="center" gap={2} sx={{ mt: '100px', padding: '20px' }}
                  >
                    <ForumRoundedIcon sx={{ fontSize: '60px', color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1' }} />
                    <Box sx={{ textAlign: 'center', }}>
                      <Typography align="center" sx={{
                        fontSize: isMobile ? '16px' : '18px', fontFamily: 'Outfit', fontWeight: '300',
                        color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1'
                      }}>
                        {t('conversationList.noConversations')}
                      </Typography>
                    </Box>
                  </Stack>
                )}
              </FlipMove>

            </Box>

          ) : (
            <Box sx={{ display: 'flex', mx: 'auto', alignItems: "center", justifyContent: 'center', mt: '100px' }}>
              <CircularProgress sx={{ color: themeMode === "dark" ? '#fafafa' : '#4a3aff' }} />
            </Box>
          )
        }

        {conversations.length >= convLimit && conversations.length < totalConversations && (
          <Stack flexDirection="row" justifyContent="center" alignItems="center" sx={{ mx: 'auto' }}>
            <Button
              onClick={loadMoreConversations}
              disabled={loadingMore}
              startIcon={loadingMore && <CircularProgress size={20} />}
              sx={{
                lineHeight: 1,
                backgroundColor: loadingMore ? themeMode === 'dark' ? '' : '#e8e8e8' : '#4a3aff',
                color: loadingMore ? themeMode === 'dark' ? '#fff' : '#4c4c4c4d' : '#fff',
                '&.Mui-disabled': {
                  backgroundColor: themeMode === "dark" ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
                  color: themeMode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
                },
                fontSize: isMobile ? '16px' : "16px",
                fontWeight: "500",
                textTransform: "none",
                padding: "10px 20px",
                borderRadius: '100px',
                borderColor: "transparent",
                borderWidth: 1,
                borderStyle: 'solid',
                fontFamily: 'Outfit',
                '&:hover': {
                  borderColor: '#4a3aff',
                  color: themeMode === 'dark' ? "white" : '#181818',
                  backgroundColor: 'transparent',
                  borderWidth: 1,
                  borderStyle: 'solid',
                },
                '&:focus': {
                  outline: 'none',
                },
                mx: 'auto'
              }}
            >
              {loadingMore ? 'Cargando' : 'Ver más'}
            </Button>
          </Stack>

        )}


        <DialogCommon
          kTitle={t('conversationList.confirmation')}
          kMaxWidth="xs"
          kMaxHeight={isMobile ? '380px' : '250px'}
          kFixedDialog={0}
          kFixedDialogPosition="fixed"
          kDisableBottomDivider
          kEnableBorderRadius={true}
          kBorderRadius={isMobile ? '20px' : '30px'}
          kAlignBtn="center"
          kKeepBtnSize
          kTopDivider
          kSlideTransition
          kOpenDialogLists={open}
          kOnClose={handleClose}
          kOnPrimary={handleDelete}
          kOnSecondary={handleClose}
          kSecondaryBtnText={t('conversationList.cancel') as string}
          kDisableBtnSave={loadingDelete}
          kPrimaryBtnText={!loadingDelete ? t('conversationList.delete') as string : "Eliminando" as string}
          kContent={
            (
              <Typography sx={{
                fontWeight: '300', fontFamily: 'Outfit',
                fontSize: isMobile ? '14px' : '18px', color: themeMode === "dark" ? "#fafafa" : "#181818"
              }}>
                {t('conversationList.areYouSure')}
              </Typography>
            )
          }
        />

        <DialogCommon
          kTitle="Actualización de plan"
          kMaxWidth="sm"
          kMaxHeight={isMobile ? '380px' : '350px'}
          kFixedDialog={0}
          kFixedDialogPosition="fixed"
          kDisableBottomDivider
          kEnableBorderRadius={true}
          kBorderRadius={isMobile ? '20px' : '30px'}
          kAlignBtn="center"
          kKeepBtnSize
          kTopDivider
          kSlideTransition
          kOpenDialogLists={openUpdatePlan}
          kOnClose={handleCloseUpdatePlan}
          kOnPrimary={handleUpdatePlan}
          kOnSecondary={handleCloseUpdatePlan}
          kSecondaryBtnText={t('conversationList.cancel') as string}
          kPrimaryBtnText="Actualizar plan"
          kContent={
            (
              <Typography sx={{
                fontWeight: '300', fontFamily: 'Outfit',
                fontSize: isMobile ? '14px' : '18px', color: themeMode === "dark" ? "#fafafa" : "#181818"
              }}>
                ¡Estás a solo un clic de potenciar tus análisis! Actualiza ahora a nuestro plan Premium o Empresarial para acceder a la funcionalidad de análisis de conversaciones y otras características exclusivas. <br /> <br />Haz clic en "Actualizar plan" y empieza a transformar tu experiencia.
              </Typography>
            )
          }
        />
      </Box>
    </SectionContainer >
  );
}
export default ConversationsList;
