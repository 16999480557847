import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { useMediaQuery, useTheme } from '@mui/material';
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { ConfigContext } from '../context/Config/ConfigContext';
import { useTranslation } from 'react-i18next';


export type User = {
  firstName?: string | null;
  lastName?: string | null;
  photoURL?: string | null;
};

type AccountMenuProps = {
  onSignOut: () => void;
  user?: User | null;
};


export default function AccountMenu({ user, onSignOut }: AccountMenuProps) {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate(); // hook para navegar

  // Función para manejar el clic en cada elemento del menú
  const handleMenuClick = (path: string) => {
    navigate(path);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { themeMode } = useContext(ConfigContext)

  const { t } = useTranslation('global')

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', ml: !isMobile ? '10px' : 0 }}>
        {
          !isMobile && (
            <>
              <Typography sx={{ mr: '4px', fontSize: '16px', fontFamily: 'Outfit', color: themeMode === "dark" ? '#fafaf' : '#181818' }}>{user?.firstName}</Typography>
              <Typography sx={{ fontSize: '16px', fontFamily: 'Outfit', color: themeMode === "dark" ? '#fafaf' : '#181818' }}>{user?.lastName}</Typography>
            </>
          )
        }
        <Tooltip title="Perfil">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: !isMobile ? 1 : 0, mr: !isMobile ? 2 : 1, '&:focus': { outline: 'none' } }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar src={user?.photoURL ?? ""} sx={{ width: 35, height: 35, fontFamily: 'Outfit', color: themeMode === "dark" ? '#fafafa' : '#fafafa' }}>{user?.firstName?.charAt(0)}{user?.lastName?.charAt(0)}</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            borderRadius: '20px',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.2))',
            mt: 1.5,
            // Ajustando el color de fondo según el modo del tema
            backgroundColor: themeMode === "dark" ? '#121212' : '#fff', // Aquí cambiamos el color para modo oscuro
            color: themeMode === "dark" ? '#ffffff' : '#000000', // Ajusta el color del texto según el tema
            '& .MuiAvatar-root': {
              width: 25,
              height: 25,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              backgroundColor: themeMode === "dark" ? '#121212' : '#fff',
              // Asegurarse de que el triángulo decorativo también coincide con el modo del tema
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() => handleMenuClick('/profile')} sx={{
            fontFamily: 'Outfit', '&:hover': {
              backgroundColor: themeMode === "dark" ? '#181818' : '#fafafa',
              borderRadius: '10px 10px 0 0'
            },
          }}>
          <ListItemIcon>
            <PersonRoundedIcon fontSize="small" sx={{ color: themeMode === "dark" ? '#fafafa' : '#4a3aff' }} />
          </ListItemIcon>
          {t('accountMenu.profile')}
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuClick('/subscription')} sx={{
            fontFamily: 'Outfit', '&:hover': {
              backgroundColor: themeMode === "dark" ? '#181818' : '#fafafa'
            },
          }}>
          <ListItemIcon>
            <CreditCardRoundedIcon fontSize="small" sx={{ color: themeMode === "dark" ? '#fafaf' : '#4a3aff' }} />
          </ListItemIcon>
          {t('accountMenu.subscription')}
        </MenuItem>
        <Divider sx={{ color: themeMode === "dark" ? '#fafaf' : '#4a3aff' }} />
        <MenuItem
          onClick={() => handleMenuClick('/settings')} sx={{
            fontFamily: 'Outfit', '&:hover': {
              backgroundColor: themeMode === "dark" ? '#181818' : '#fafafa'
            },
          }}>
          <ListItemIcon>
            <Settings fontSize="small" sx={{ color: themeMode === "dark" ? '#fafaf' : '#4a3aff' }} />
          </ListItemIcon>
          {t('accountMenu.settings')}
        </MenuItem>
        <MenuItem
          onClick={onSignOut} sx={{
            fontFamily: 'Outfit', '&:hover': {
              backgroundColor: themeMode === "dark" ? '#181818' : '#fafafa',
              borderRadius: '0 0 10px 10px'
            },
          }}>
          <ListItemIcon>
            <Logout fontSize="small" sx={{ color: themeMode === "dark" ? '#fafaf' : '#4a3aff' }} />
          </ListItemIcon>
          {t('accountMenu.signOut')}
        </MenuItem>
      </Menu>

    </React.Fragment>
  );
}
