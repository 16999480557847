import { Box, Button, CircularProgress, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { useContext, useEffect, useState } from "react";
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import DialogCommon from "../../components/DialogCommon/DialogCommon";
import StatBox from "../../components/StatBox";
import SectionContainer from "../../global/SectionContainer";
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import StoreContext from "../../context/Store/storeContext";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../context/Config/ConfigContext";
import introImg from "../../assets/fastalkIntroImg.webp";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { auth, db } from "../../services/firebase/firebase";
import MessageCount from "./MessageCount";
import ChatBubbleRoundedIcon from '@mui/icons-material/ChatBubbleRounded';
import { collection, doc, getDoc, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { getISOWeek, subWeeks, getISOWeekYear } from 'date-fns';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded';
import { showToast } from "../../components/Toast/toast";

const Dashboard = (props: any) => {

  const { t } = useTranslation('global')

  const { user, fetchUser } = useContext(StoreContext);
  const [userAuth] = useAuthState(auth);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const colors = tokens(theme.palette.mode);

  const [open, setOpen] = useState(false)

  const openDialogModal = () => {
    setOpen(true);
  };

  const closeDialogModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    //
  }, [props.isSidebarIpen])

  const [pdfToDownload, setPdfToDownload] = useState('');

  useEffect(() => {
    const userId = auth.currentUser?.uid;
    fetchUser(userId)
    setPdfToDownload('')
  }, [])

  const handlePdfSelection = (type: any) => {
    setPdfToDownload(type);
  }

  useEffect(() => {
    const isNewUser = localStorage.getItem('isNewUser');
    if (isNewUser === 'true') {
      setIntroductionModal(true);
    }
  }, []);


  const [introductionModal, setIntroductionModal] = useState(false)

  const [currentScreen, setCurrentScreen] = useState(0);

  const nextScreen = () => {
    setCurrentScreen((prevScreen) => (prevScreen < 2 ? prevScreen + 1 : prevScreen));
  };

  const prevScreen = () => {
    setCurrentScreen((prevScreen) => (prevScreen > 0 ? prevScreen - 1 : prevScreen));
  };

  // const openDialogModal = () => {
  //   setOpen(true);
  // };
  const closeIntroductionModal = () => {
    setIntroductionModal(false);
    localStorage.setItem('isNewUser', 'false');
  };

  const { themeMode } = useContext(ConfigContext)

  const seriesData = [
    {
      name: t('chart.messages'),
      data: [123, 145, 164, 186, 234, 457, 521, 659, 789]
    },
    {
      name: t('chart.conversations'),
      data: [88, 113, 82, 148, 160, 312, 261, 334, 518]
    }
  ];

  const TotalMessagesOptions: ApexOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    colors: ["#4a3aff", "#CFC8FF"],
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
        columnWidth: "75%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    stroke: {
      colors: ["transparent"],
      width: 2,
    },
    xaxis: {
      categories: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep'],
    },
    yaxis: {
      title: {
        text: '',
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
    tooltip: {
      y: {
        formatter: (val) => `${val} ${t('chart.messages')}}`,
      },
    },
  };

  const [series,] = useState([100]); // Ajusta este valor según sea necesario
  const [options,] = useState<ApexOptions>({
    chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          margin: 5,
          size: '50%',
          background: 'transparent',
          image: undefined,
        },
        track: {
          background: "#fff",
          strokeWidth: '100%',
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 0,
            left: 0,
            color: '#999',
            opacity: .2,
            blur: 2
          }
        },
        dataLabels: {
          show: true,
          name: {
            show: false,
          },
          value: {
            formatter: function () {
              return t('chart.freePlanDays');
            },
            color: themeMode === 'dark' ? '#fafafa' : '#181818',
            fontSize: '36px',
            show: true,
            offsetY: 5,
            fontFamily: 'Outfit',
            fontWeight: '500'
          }
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        shadeIntensity: 0.3,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
        colorStops: [
          {
            offset: 0,
            color: "#4a3aff", // Color de inicio
            opacity: 1
          },
          {
            offset: 100,
            color: "#6f7dff", // Color de fin
            opacity: 1
          }
        ]
      },
    },
  });

  const [viewMode,] = useState<'weekly' | 'monthly'>('monthly');
  // Definiendo estados adicionales para manejar los datos recogidos y promedio de mensajes por conversación
  const [totalMessagesCurrent, setTotalMessagesCurrent] = useState(0);
  const [totalConversationsCurrent, setTotalConversationsCurrent] = useState(0);
  const [collectedDataCurrent, setCollectedDataCurrent] = useState(0);

  const [changePercentageMessages, setChangePercentageMessages] = useState<number | null>(null);
  const [changePercentageConversations, setChangePercentageConversations] = useState<number | null>(null);
  const [changePercentageExtractedData, setChangePercentageExtractedData] = useState<number | null>(null);
  const [changePercentageResponsesPerConversation, setChangePercentageResponsesPerConversation] = useState<number | null>(null);
  const [changeCurrentAvgResponsesPerConversation, setCurrentAvgResponsesPerConversation] = useState<number | null>(null);

  const getChatbotsIdsForUser = async (ownerId: string): Promise<string[]> => {
    const chatbotsRef = collection(db, 'chatbots');
    const q = query(chatbotsRef, where("ownerId", "==", ownerId));
    const querySnapshot = await getDocs(q);
    const chatbotIds: string[] = []; // Tipado explícito aquí
    querySnapshot.forEach((doc) => {
      chatbotIds.push(doc.id); // TypeScript ya sabe que doc.id es un string
    });
    return chatbotIds;
  };

  const fetchDataForPeriod = async (chatbotId: any, periodId: any) => {
    const docRef = doc(db, 'chatbots', chatbotId, 'metrics', periodId);

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return {
        totalMessages: docSnap.data().totalMessages,
        totalConversations: docSnap.data().totalConversations,
        extractedData: docSnap.data().extractedData,
      };
    } else {
      return { totalMessages: 0, totalConversations: 0, extractedData: 0 };
    }
  };

  const getCurrentAndPreviousPeriodId = (viewMode: any) => {
    const today = new Date();
    let currentPeriodId, previousPeriodId;

    if (viewMode === 'monthly') {
      const currentMonth = today.getMonth() + 1;
      const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
      const year = today.getFullYear();
      const previousYear = currentMonth === 1 ? year - 1 : year;

      currentPeriodId = `monthly-${year}-${currentMonth}`;
      previousPeriodId = `monthly-${previousYear}-${previousMonth}`;
    } else if (viewMode === 'weekly') {
      const currentWeek = getISOWeek(today);
      const previousWeekDate = subWeeks(today, 1);
      const previousWeek = getISOWeek(previousWeekDate);
      const year = getISOWeekYear(today);
      const previousYear = getISOWeekYear(previousWeekDate);

      currentPeriodId = `weekly-${year}-${currentWeek.toString().padStart(2, '0')}`;
      previousPeriodId = `weekly-${previousYear}-${previousWeek.toString().padStart(2, '0')}`;
    }

    return { currentPeriodId, previousPeriodId };
  };

  // Asegúrate de importar las funciones necesarias de Firebase y otros hooks de React

  const sumAndCalculatePercentageChange = async (ownerId: any, viewMode: any) => {
    const chatbotIds = await getChatbotsIdsForUser(ownerId);
    const { currentPeriodId, previousPeriodId } = getCurrentAndPreviousPeriodId(viewMode);

    let currentMetricsTotal = { totalMessages: 0, totalConversations: 0, extractedData: 0 };
    let previousMetricsTotal = { totalMessages: 0, totalConversations: 0, extractedData: 0 };

    for (const chatbotId of chatbotIds) {
      const currentMetrics = await fetchDataForPeriod(chatbotId, currentPeriodId);
      const previousMetrics = await fetchDataForPeriod(chatbotId, previousPeriodId);

      currentMetricsTotal.totalMessages += currentMetrics.totalMessages || 0;
      currentMetricsTotal.totalConversations += currentMetrics.totalConversations || 0;
      currentMetricsTotal.extractedData += currentMetrics.extractedData || 0; // Asumiendo que extractedData es un campo en tus documentos de métricas

      previousMetricsTotal.totalMessages += previousMetrics.totalMessages || 0;
      previousMetricsTotal.totalConversations += previousMetrics.totalConversations || 0;
      previousMetricsTotal.extractedData += previousMetrics.extractedData || 0; // Asumiendo lo mismo para el periodo anterior
    }

    // Calcula los porcentajes de cambio
    const percentageChangeMessages = ((currentMetricsTotal.totalMessages - previousMetricsTotal.totalMessages) / (previousMetricsTotal.totalMessages || 1)) * 100;
    const percentageChangeConversations = ((currentMetricsTotal.totalConversations - previousMetricsTotal.totalConversations) / (previousMetricsTotal.totalConversations || 1)) * 100;
    const percentageChangeExtractedData = ((currentMetricsTotal.extractedData - previousMetricsTotal.extractedData) / (previousMetricsTotal.extractedData || 1)) * 100;
    // Calcula el porcentaje de cambio para el promedio de respuestas por conversación
    const currentAvgResponsesPerConversation = currentMetricsTotal.totalConversations ? currentMetricsTotal.totalMessages / currentMetricsTotal.totalConversations : 0;
    const previousAvgResponsesPerConversation = previousMetricsTotal.totalConversations ? previousMetricsTotal.totalMessages / previousMetricsTotal.totalConversations : 0;
    const percentageChangeResponsesPerConversation = ((currentAvgResponsesPerConversation - previousAvgResponsesPerConversation) / (previousAvgResponsesPerConversation || 1)) * 100;

    return {
      percentageChangeMessages,
      percentageChangeConversations,
      percentageChangeExtractedData,
      currentAvgResponsesPerConversation,
      percentageChangeResponsesPerConversation,
      // Incluye también los totales actuales para actualizar el estado correspondiente
      currentMetricsTotal
    };
  };

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true)
      if (!userAuth || !userAuth.uid) {
        showToast('error', t('errors.userNotDefinedOrAuthenticated'), themeMode);
        return;
      }

      const ownerId = userAuth.uid;

      try {
        const { percentageChangeMessages, percentageChangeConversations, percentageChangeExtractedData, percentageChangeResponsesPerConversation, currentMetricsTotal, currentAvgResponsesPerConversation } = await sumAndCalculatePercentageChange(ownerId, viewMode);

        // Actualiza el estado con los nuevos porcentajes y totales
        setChangePercentageMessages(percentageChangeMessages);
        setChangePercentageConversations(percentageChangeConversations);
        setChangePercentageExtractedData(percentageChangeExtractedData);
        setChangePercentageResponsesPerConversation(percentageChangeResponsesPerConversation);
        setCurrentAvgResponsesPerConversation(currentAvgResponsesPerConversation)
        // Actualiza también los totales actuales
        setTotalMessagesCurrent(currentMetricsTotal.totalMessages);
        setTotalConversationsCurrent(currentMetricsTotal.totalConversations);
        setCollectedDataCurrent(currentMetricsTotal.extractedData); // Asumiendo que quieres usar este valor como los datos recogidos
        setIsLoading(false)
      } catch (error: any) {
        showToast('error', t('errors.errorCalculatingChangePercentage'), themeMode);
        // Considera establecer los estados a null o a un valor por defecto en caso de error
        setIsLoading(false)
      }
    };

    loadData();
  }, [userAuth, viewMode]);


  interface Conversation {
    id: string;
    chatbotId: string;
    // Añade aquí más propiedades según sean necesarias para tu implementación
  }

  const [conversations, setConversations] = useState<Conversation[]>([]);

  const formatDateWithTime = (timestamp: any) => {
    if (!timestamp) return '-';

    const conversationDate = timestamp.toDate();
    const now = new Date();
    const currentYear = now.getFullYear();
    const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);

    const hours = conversationDate.getHours().toString().padStart(2, '0');
    const minutes = conversationDate.getMinutes().toString().padStart(2, '0');
    const timeString = `${hours}:${minutes}`;

    const isToday = conversationDate.getDate() === now.getDate() &&
      conversationDate.getMonth() === now.getMonth() &&
      conversationDate.getFullYear() === now.getFullYear();

    const isYesterday = conversationDate.getDate() === yesterday.getDate() &&
      conversationDate.getMonth() === yesterday.getMonth() &&
      conversationDate.getFullYear() === yesterday.getFullYear();

    const diff = now.getTime() - conversationDate.getTime();
    const diffInHours = diff / (1000 * 60 * 60);

    const dateOptions = {
      day: 'numeric',
      month: 'long',
      ...(conversationDate.getFullYear() !== currentYear && { year: 'numeric' })
    };

    if (diffInHours < 1) {
      return <span>{t('dashboard.now')}<br />{timeString}</span>;
    } else if (isToday) {
      return <span>{t('dashboard.today')}<br />{timeString}</span>;
    } else if (isYesterday) {
      return <span>{t('dashboard.yesterday')}<br />{timeString}</span>;
    } else {
      return (
        <>
          {conversationDate.toLocaleDateString('es-ES', dateOptions)}
          <br />
          {timeString}
        </>
      );
    }
  };

  const navigate = useNavigate()

  const handleView = (conversationId: string, chatbotId: string) => {
    navigate(`/bot/${chatbotId}/conversation/${conversationId}`);
  };

  const truncateMessage = (message: any, maxLength = 50) => {
    if (message?.length > maxLength) {
      return message.substring(0, maxLength) + '...';
    }
    return message;
  };

  useEffect(() => {
    const fetchConversations = async () => {
      if (userAuth) {
        const conversationsRef = collection(db, 'conversations');
        const q = query(conversationsRef, where('ownerId', '==', userAuth.uid), orderBy('firstMessageTimestamp', 'desc'), limit(15));
        const conversationsSnapshot = await getDocs(q);

        const fetchedConversations = conversationsSnapshot.docs.map((doc) => ({
          id: doc.id,
          chatbotId: doc.data().chatbotId,
          chatbotLogo: doc.data().chatbotLogo,
          userId: doc.data().userId,
          firstMessage: truncateMessage(doc.data().firstMessage),
          firstMessageTimestamp: doc.data().firstMessageTimestamp,
        }));

        setConversations(fetchedConversations);
      }
    };

    if (userAuth) {
      fetchConversations();
    }
  }, [userAuth]);

  const enterpriseSubscriptions = ["enterpriseMonthlyMXN", "enterpriseAnnualMXN", "enterpriseMonthlyUSD", "enterpriseAnnualUSD"];
  const isEnterpriseUser = enterpriseSubscriptions.includes(user?.subscriptionType);

  const EnterpriseFeatureRequiredContent = () => (
    <div style={{ textAlign: 'center' }}>
      <h3 style={{ color: themeMode === 'dark' ? 'white' : '#181818', }}>{t('dashboard.enterpriseFeatureRequired.title')}</h3>
      <p style={{ color: themeMode === 'dark' ? 'white' : '#181818', }}>{t('dashboard.enterpriseFeatureRequired.description')}</p>
      <Button
        sx={{
          height: '50px',
          backgroundColor: '#4a3aff',
          lineHeight: 1,
          color: '#fff',
          fontSize: isMobile ? '16px' : "16px",
          fontWeight: "500",
          textTransform: "none",
          padding: "10px 20px",
          borderRadius: '100px',
          borderColor: "transparent",
          borderWidth: 1,
          borderStyle: 'solid',
          fontFamily: 'Outfit',
          '&:hover': {
            borderColor: '#4a3aff',
            color: themeMode === 'dark' ? "white" : '#181818',
            backgroundColor: 'transparent',
            borderWidth: 1,
            borderStyle: 'solid',
          },
          '&:focus': {
            outline: 'none',
          },
          ml: '10px'
        }}
        onClick={() => { navigate('/subscription') }}
      >
        {t('dashboard.enterpriseFeatureRequired.upgradeButton')}
      </Button>
    </div >
  );

  const [sentimentPercentages, setSentimentPercentages] = useState<{ [key: string]: number }>({});
  const [satisfactionPercentages, setSatisfactionPercentages] = useState<{ [key: string]: number }>({});
  const [doubtResolvedPercentages, setDoubtResolvedPercentages] = useState<{ [key: string]: number }>({});
  // const [conversationTypePercentages, setConversationTypePercentages] = useState<{ [key: string]: number }>({});
  // const [conversationEfficiencyPercentages, setConversationEfficiencyPercentages] = useState<{ [key: string]: number }>({});
  // const [totalConversations, setTotalConversations] = useState(0);

  function getPeriodId(viewMode: 'weekly' | 'monthly'): string {
    const now = new Date();
    const year = now.getFullYear();

    if (viewMode === 'weekly') {
      const weekNumber = getWeekNumber(now);
      return `weekly-${year}-${weekNumber}`;
    } else {
      const monthNumber = now.getMonth() + 1;
      return `monthly-${year}-${monthNumber}`;
    }
  }

  function getWeekNumber(date: Date): number {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  }

  useEffect(() => {
    const fetchAndCalculateMetrics = async () => {
      if (userAuth) {
        const chatbotIds = await getChatbotsIdsForUser(userAuth.uid);
        const periodId = getPeriodId(viewMode);

        let userSentimentTotal = { positive: 0, neutral: 0, negative: 0 };
        let userSatisfactionTotal = { high: 0, medium: 0, low: 0 };
        let doubtResolvedTotal = { true: 0, false: 0 };
        let conversationEfficiencyTotal = { efficient: 0, somewhat_efficient: 0, inefficient: 0 };

        for (const chatbotId of chatbotIds) {
          const metricsRef = doc(db, 'chatbots', chatbotId, 'metrics', periodId);
          const docSnapshot = await getDoc(metricsRef);

          if (docSnapshot.exists()) {
            const data = docSnapshot.data();

            // Verificar si data.userSentiment existe antes de acceder a sus propiedades
            if (data.userSentiment) {
              userSentimentTotal.positive += data.userSentiment.positive || 0;
              userSentimentTotal.neutral += data.userSentiment.neutral || 0;
              userSentimentTotal.negative += data.userSentiment.negative || 0;
            }

            // Verificar si data.userSatisfaction existe antes de acceder a sus propiedades
            if (data.userSatisfaction) {
              userSatisfactionTotal.high += data.userSatisfaction.high || 0;
              userSatisfactionTotal.medium += data.userSatisfaction.medium || 0;
              userSatisfactionTotal.low += data.userSatisfaction.low || 0;
            }

            // Verificar si data.doubtResolved existe antes de acceder a sus propiedades
            if (data.doubtResolved) {
              doubtResolvedTotal.true += data.doubtResolved.true || 0;
              doubtResolvedTotal.false += data.doubtResolved.false || 0;
            }

            // Verificar si data.conversationEfficiency existe antes de acceder a sus propiedades
            if (data.conversationEfficiency) {
              conversationEfficiencyTotal.efficient += data.conversationEfficiency.efficient || 0;
              conversationEfficiencyTotal.somewhat_efficient += data.conversationEfficiency.somewhat_efficient || 0;
              conversationEfficiencyTotal.inefficient += data.conversationEfficiency.inefficient || 0;
            }
          }
        }

        const totalSentiments = userSentimentTotal.positive + userSentimentTotal.neutral + userSentimentTotal.negative;
        const totalSatisfaction = userSatisfactionTotal.high + userSatisfactionTotal.medium + userSatisfactionTotal.low;
        const totalDoubtResolved = doubtResolvedTotal.true + doubtResolvedTotal.false;
        // const totalConversationEfficiency = conversationEfficiencyTotal.efficient + conversationEfficiencyTotal.somewhat_efficient + conversationEfficiencyTotal.inefficient;

        const userSentimentPercentages = {
          positive: totalSentiments > 0 ? (userSentimentTotal.positive / totalSentiments) * 100 : 0,
          neutral: totalSentiments > 0 ? (userSentimentTotal.neutral / totalSentiments) * 100 : 0,
          negative: totalSentiments > 0 ? (userSentimentTotal.negative / totalSentiments) * 100 : 0,
        };

        const userSatisfactionPercentages = {
          high: totalSatisfaction > 0 ? (userSatisfactionTotal.high / totalSatisfaction) * 100 : 0,
          medium: totalSatisfaction > 0 ? (userSatisfactionTotal.medium / totalSatisfaction) * 100 : 0,
          low: totalSatisfaction > 0 ? (userSatisfactionTotal.low / totalSatisfaction) * 100 : 0,
        };

        const doubtResolvedPercentages = {
          true: totalDoubtResolved > 0 ? (doubtResolvedTotal.true / totalDoubtResolved) * 100 : 0,
          false: totalDoubtResolved > 0 ? (doubtResolvedTotal.false / totalDoubtResolved) * 100 : 0,
        };

        // const conversationEfficiencyPercentages = {
        //   efficient: totalConversationEfficiency > 0 ? (conversationEfficiencyTotal.efficient / totalConversationEfficiency) * 100 : 0,
        //   somewhat_efficient: totalConversationEfficiency > 0 ? (conversationEfficiencyTotal.somewhat_efficient / totalConversationEfficiency) * 100 : 0,
        //   inefficient: totalConversationEfficiency > 0 ? (conversationEfficiencyTotal.inefficient / totalConversationEfficiency) * 100 : 0,
        // };

        setSentimentPercentages(userSentimentPercentages);
        setSatisfactionPercentages(userSatisfactionPercentages);
        setDoubtResolvedPercentages(doubtResolvedPercentages);
        // setConversationEfficiencyPercentages(conversationEfficiencyPercentages);

      }
    };

    fetchAndCalculateMetrics();
  }, [userAuth, viewMode]);

  const isEmptyData = (data: any) => {
    return Object.values(data).every(value => value === 0);
  };

  return (
    <SectionContainer
      title={t('dashboard.title') as string}
      icon={<DownloadOutlinedIcon />}
      text={t('dashboard.downloadReport') as string}
      onClick={openDialogModal}
    >
      <Stack flexDirection="column">
        {/* <Stack flexDirection="row" justifyContent="flex-start">
          {renderViewModeControls()}
        </Stack> */}

        {
          isLoading ? (
            <Box sx={{ display: 'flex', mx: 'auto', alignItems: "center", justifyContent: 'center', mt: '100px' }}>
              <CircularProgress sx={{ color: themeMode === "dark" ? '#fafafa' : '#4a3aff' }} />
            </Box>
          ) : (
            <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gridAutoRows="150px"
              gap={isMobile ? "10px" : "15px"}
              pb="50px"
            >

              {/* ROW 1 */}
              <Box
                gridColumn={isMobile ? "span 6" : "span 3"}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  borderRadius: '20px',
                  backgroundColor: themeMode === 'dark' ? "#181818" : 'white',
                  boxShadow: themeMode === "dark"
                    ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                    : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                }}
              >
                <StatBox
                  title={totalMessagesCurrent}
                  subtitle={t('dashboard.stats.messagesCount')}
                  changePercentage={changePercentageMessages}
                  icon={
                    <ChatBubbleRoundedIcon
                      sx={{ color: themeMode === 'dark' ? '#fafafa' : '#cfcfcf', fontSize: isMobile ? "20px" : "26px" }}
                    />
                  }
                />
              </Box>
              <Box
                gridColumn={isMobile ? "span 6" : "span 3"}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  borderRadius: '20px',
                  backgroundColor: themeMode === 'dark' ? "#181818" : 'white',
                  boxShadow: themeMode === "dark"
                    ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                    : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                }}
              >
                <StatBox
                  title={totalConversationsCurrent}
                  subtitle={t('dashboard.stats.conversationsCount')}
                  changePercentage={changePercentageConversations}
                  icon={
                    <QuestionAnswerRoundedIcon
                      sx={{ color: themeMode === 'dark' ? '#fafafa' : '#cfcfcf', fontSize: isMobile ? "20px" : "26px" }}
                    />
                  }
                />
              </Box>
              <Box
                gridColumn={isMobile ? "span 6" : "span 3"}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  borderRadius: '20px',
                  backgroundColor: themeMode === 'dark' ? "#181818" : 'white',
                  boxShadow: themeMode === "dark"
                    ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                    : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                }}
              >
                <StatBox
                  title={collectedDataCurrent}
                  subtitle={t('dashboard.stats.collectedData')}
                  changePercentage={changePercentageExtractedData}
                  icon={
                    <ListAltRoundedIcon
                      sx={{ color: themeMode === 'dark' ? '#fafafa' : '#cfcfcf', fontSize: isMobile ? "20px" : "26px" }}
                    />
                  }
                />
              </Box>

              <Box
                gridColumn={isMobile ? "span 6" : "span 3"}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  borderRadius: '20px',
                  backgroundColor: themeMode === 'dark' ? "#181818" : 'white',
                  boxShadow: themeMode === "dark"
                    ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                    : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                }}
              >
                <StatBox
                  title={changeCurrentAvgResponsesPerConversation?.toFixed(2) ?? 0}
                  subtitle={t('dashboard.stats.responsesPerConversation')}
                  changePercentage={changePercentageResponsesPerConversation}
                  icon={
                    <FormatListNumberedRoundedIcon
                      sx={{ color: themeMode === 'dark' ? '#fafafa' : '#cfcfcf', fontSize: isMobile ? "20px" : "26px" }}
                    />
                  }
                />
              </Box>

              {/* ROW 2 */}
              <Box
                gridColumn={isMobile ? "span 12" : "span 8"}
                gridRow="span 3"
                sx={{
                  borderRadius: '20px',
                  backgroundColor: themeMode === 'dark' ? "#181818" : 'white',
                  boxShadow: themeMode === "dark"
                    ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                    : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                }}
              >
                <MessageCount
                  viewMode={viewMode}

                />
              </Box>
              <Box
                gridColumn={isMobile ? "span 12" : "span 4"}
                gridRow="span 3"
                overflow="auto"
                sx={{
                  borderRadius: '20px',
                  fontWeight: '400',
                  fontFamily: 'Outfit',
                  backgroundColor: themeMode === 'dark' ? "#181818" : 'white',
                  boxShadow: themeMode === "dark"
                    ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                    : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                  scrollbarWidth: 'thin',
                  scrollbarColor: 'transparent transparent',
                  '&:hover': {
                    scrollbarColor: 'rgba(211, 211, 211, 0.5) transparent',
                  },
                  '&::-webkit-scrollbar': {
                    width: '6px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'transparent',
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent',
                  },
                  '&:hover::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(211, 211, 211, 0.5)',
                    borderRadius: '50px'
                  },
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    backgroundColor: themeMode === 'dark' ? "#181818" : 'white',
                    p: '15px',
                    color: themeMode === 'dark' ? '#fafafa' : '#181818', fontFamily: 'Outfit'
                  }}
                >
                  <Typography variant="h5" fontWeight="600" sx={{ fontFamily: 'Outfit', fontSize: '16px', fontWeight: '300', color: themeMode === 'dark' ? 'white' : '#181818', }}>
                    {t('dashboard.recentConversations')}
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => { navigate('/conversations') }}
                    disabled={conversations.length === 0}
                    sx={{
                      height: 'auto',
                      color: '#fff',
                      fontSize: isMobile ? '14px' : "14px",
                      fontWeight: "500",
                      textTransform: "none",
                      padding: "4px 12px",
                      borderRadius: '100px',
                      borderColor: "transparent",
                      borderWidth: 1,
                      borderStyle: 'solid',
                      fontFamily: 'Outfit',
                      backgroundColor: '#4a3aff',
                      '&:hover': {
                        borderColor: '#4a3aff',
                        color: themeMode === "dark" ? "white" : '#4a3aff',
                        backgroundColor: 'transparent',
                        borderWidth: 1,
                        borderStyle: 'solid',
                        boxShadow: 'none'
                      },
                      '&:focus': {
                        outline: 'none',
                      },
                      '&.Mui-disabled': {
                        backgroundColor: themeMode === "dark" ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
                        color: themeMode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
                      },
                    }}
                  >
                    {t('dashboard.viewAll')}
                  </Button>
                </Box>
                <Stack>
                  {
                    conversations && conversations.length > 0 ? (
                      conversations.map((conversation: any) => (
                        <Box
                          sx={{
                            p: '10px',
                            '&:hover': {
                              backgroundColor: themeMode === 'dark' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.03)',
                              cursor: 'pointer',
                            },
                          }}
                          onClick={() => handleView(conversation.id, conversation.chatbotId)}
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{
                              color: themeMode === 'dark' ? '#fff' : '#181818',
                              borderRadius: '15px',
                              px: 1,
                            }}
                          >
                            <Grid item xs={8}>
                              <Box sx={{ textAlign: 'left' }}>
                                <Typography sx={{
                                  fontSize: '14px',
                                  fontWeight: '300',
                                  textAlign: 'left',
                                  color: themeMode === 'dark' ? 'white' : '#181818',
                                }}>
                                  {truncateMessage(conversation.firstMessage)}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={4}>
                              <Stack flexDirection="column" justifyContent="space-between" alignItems="end">
                                <Typography sx={{
                                  fontSize: '14px',
                                  textAlign: 'right',
                                  color: themeMode === 'dark' ? 'white' : '#181818',
                                }}>
                                  {conversation.firstMessageTimestamp ? formatDateWithTime(conversation.firstMessageTimestamp) : '-'}
                                </Typography>

                                <Typography
                                  sx={{
                                    fontFamily: 'Outfit',
                                    textAlign: 'center',
                                    width: '100px',
                                    borderRadius: '100px',
                                    fontSize: '14px',
                                    mt: '5px',
                                    color: themeMode === 'dark' ? 'white' : '#181818',
                                  }}
                                >
                                  {conversation.chatbotState}
                                </Typography>
                              </Stack>
                            </Grid>
                          </Grid>


                        </Box>
                      ))
                    ) : (
                      <Stack flexDirection="column" justifyContent="center" alignItems="center" gap={2} sx={{ mt: '100px', maxWidth: '200px', mx: 'auto' }}>
                        <ForumRoundedIcon sx={{ fontSize: '50px', color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1' }} />
                        <Box sx={{ textAlign: 'center', }}>
                          <Typography align="center" sx={{
                            fontSize: '14px', fontFamily: 'Outfit', fontWeight: '300',
                            color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1'
                          }}>
                            {t('conversationList.noConversations')}
                          </Typography>
                        </Box>
                      </Stack>
                    )
                  }
                </Stack>
              </Box>
              {/* ROW 3 */}
              <Box gridColumn={isMobile ? "span 12" : "span 4"} gridRow="span 2" padding="30px" sx={{ borderRadius: '20px', backgroundColor: themeMode === "dark" ? '#181818' : '#fff' }}>
                <Typography variant="h5" fontWeight="600" sx={{ marginBottom: "20px", fontFamily: 'Outfit', fontWeight: '400', color: themeMode === "dark" ? '#fafafa' : '#181818', fontSize: isMobile ? '14px' : '16px' }}>
                  {t('dashboard.resolvedDoubts')}
                </Typography>
                <Box height="200px" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  {!isEmptyData(doubtResolvedPercentages) ? (
                    <>
                      <ReactApexChart
                        options={{
                          chart: { type: 'donut' },
                          colors: ['#4A3AFF', '#C1C1C1'],
                          labels: [t('dashboard.resolved'), t('dashboard.notResolved')],
                          legend: { show: false },
                          dataLabels: {
                            enabled: true,
                            style: {
                              fontSize: '12px',
                              fontFamily: 'Outfit',
                              fontWeight: '400',
                            },
                            formatter: function (_val, opts) {
                              return opts.w.config.series[opts.seriesIndex].toFixed(2) + '%';
                            },
                          },
                          stroke: { width: 0 },
                        }}
                        series={[doubtResolvedPercentages.true || 0, doubtResolvedPercentages.false || 0]}
                        type="donut"
                        width="200px"
                      />
                      <Box sx={{ marginTop: "15px" }}>
                        <Typography variant="body1" sx={{ fontWeight: 300, fontFamily: 'Outfit', color: themeMode === "dark" ? '#fafafa' : '#181818', fontSize: isMobile ? '14px' : '14px', maxWidth: '250px', textAlign: 'center' }}>
                          {`${doubtResolvedPercentages.true?.toFixed(2) || 0}% ${t('dashboard.ofDoubtsResolved')}`}
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <Box textAlign="center" p={5}>
                      <Typography variant="subtitle1" sx={{ fontSize: '14px', color: '#c1c1c1' }}>
                        {t('dashboard.noDataForPeriod')}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>

              {/* User Satisfaction */}
              <Box gridColumn={isMobile ? "span 12" : "span 4"} gridRow="span 2" padding="30px" sx={{ borderRadius: '20px', backgroundColor: themeMode === "dark" ? '#181818' : '#fff' }}>
                <Typography variant="h5" fontWeight="600" sx={{ marginBottom: "20px", fontFamily: 'Outfit', fontWeight: '400', color: themeMode === "dark" ? '#fafafa' : '#181818', fontSize: isMobile ? '14px' : '16px' }}>
                  {t('dashboard.userSatisfaction')}
                </Typography>
                <Box height="200px" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  {!isEmptyData(satisfactionPercentages) ? (
                    <>
                      <ReactApexChart
                        options={{
                          chart: { type: 'donut' },
                          colors: ['#4A3AFF', '#9687ff', '#C1C1C1'],
                          labels: [t('dashboard.high'), t('dashboard.medium'), t('dashboard.low')],
                          legend: { show: false },
                          stroke: { width: 0 },
                          dataLabels: {
                            enabled: true,
                            style: {
                              fontSize: '12px',
                              fontFamily: 'Outfit',
                              fontWeight: '400',
                            },
                            formatter: function (_val, opts) {
                              return opts.w.config.series[opts.seriesIndex].toFixed(2) + '%';
                            },
                          },
                        }}
                        series={[satisfactionPercentages.high || 0, satisfactionPercentages.medium || 0, satisfactionPercentages.low || 0]}
                        type="donut"
                        width="200px"
                      />
                      <Box sx={{ marginTop: "15px" }}>
                        <Typography variant="body1" sx={{ fontWeight: 300, fontFamily: 'Outfit', color: themeMode === "dark" ? '#fafafa' : '#181818', fontSize: isMobile ? '14px' : '14px', maxWidth: '250px', textAlign: 'center' }}>
                          {`${satisfactionPercentages.high?.toFixed(2) || 0}% ${t('dashboard.usersHighSatisfaction')}`}
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <Box textAlign="center" p={5}>
                      <Typography variant="subtitle1" sx={{ fontSize: '14px', color: '#c1c1c1' }}>
                        {t('dashboard.noDataForPeriod')}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>

              {/* User Sentiments */}
              <Box gridColumn={isMobile ? "span 12" : "span 4"} gridRow="span 2" padding="30px" sx={{ borderRadius: '20px', backgroundColor: themeMode === "dark" ? '#181818' : '#fff' }}>
                <Typography variant="h5" fontWeight="600" sx={{ marginBottom: "20px", fontFamily: 'Outfit', fontWeight: '400', color: themeMode === "dark" ? '#fafafa' : '#181818', fontSize: isMobile ? '14px' : '16px' }}>
                  {t('dashboard.userSentiments')}
                </Typography>
                <Box height="200px" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  {!isEmptyData(sentimentPercentages) ? (
                    <>
                      <ReactApexChart
                        options={{
                          chart: { type: 'donut' },
                          colors: ['#4A3AFF', '#9687ff', '#C1C1C1'],
                          labels: [t('dashboard.positive'), t('dashboard.neutral'), t('dashboard.negative')],
                          legend: { show: false },
                          dataLabels: {
                            enabled: true,
                            style: {
                              fontSize: '12px',
                              fontFamily: 'Outfit',
                              fontWeight: '400',
                            },
                            formatter: function (_val, opts) {
                              return opts.w.config.series[opts.seriesIndex].toFixed(2) + '%';
                            },
                          },
                          stroke: { width: 0 }
                        }}
                        series={[sentimentPercentages.positive, sentimentPercentages.neutral, sentimentPercentages.negative]}
                        type="donut"
                        width="200px"
                      />
                      <Box sx={{ marginTop: "15px" }}>
                        <Typography variant="body1" sx={{ fontWeight: 300, fontFamily: 'Outfit', color: themeMode === "dark" ? '#fafafa' : '#181818', fontSize: isMobile ? '14px' : '14px', maxWidth: '250px', textAlign: 'center' }}>
                          {`${sentimentPercentages.positive?.toFixed(2) || 0}% ${t('dashboard.usersPositiveSentiment')}`}
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <Box textAlign="center" p={5}>
                      {/* Aquí puedes poner el ícono que prefieras */}
                      <Typography variant="subtitle1" sx={{ fontSize: '14px', color: '#c1c1c1' }}>
                        {t('dashboard.noDataForPeriod')}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
              {/* END OF ROW 3 */}


              <DialogCommon
                kTitle={t(isEnterpriseUser ? t('dashboard.downloadReportDialog.title') : t('dashboard.upgradeNeeded.title'))}
                kMaxWidth={!isEnterpriseUser ? "xs" : "sm"}
                kMaxHeight={!isEnterpriseUser ? '350px' : isMobile ? '500px' : '350px'}
                kFixedDialog={0}
                kFixedDialogPosition="fixed"
                kDisableBottomDivider
                kEnableBorderRadius={true}
                kBorderRadius={'30px'}
                kAlignBtn="center"
                kKeepBtnSize
                kTopDivider
                kSlideTransition
                kOpenDialogLists={open}
                kOnClose={closeDialogModal}
                kOnSecondary={closeDialogModal}
                kAlignItemsStart={!isEnterpriseUser}
                kDisableActions={!isEnterpriseUser}
                kSecondaryBtnText={t('dashboard.downloadReportDialog.cancel') as string}
                kPrimaryBtnText={t('dashboard.downloadReportDialog.download') as string}
                kOnPrimary={() => closeDialogModal}
                kContent={
                  (
                    isEnterpriseUser ? (
                      <Stack flexDirection="column" justifyContent="center" alignItems="center">
                        <Typography sx={{ fontWeight: 300, fontFamily: 'Outfit', mb: '20px', color: themeMode === "dark" ? '#fafafa' : '#181818', fontSize: isMobile ? '14px' : '16px', }}>
                          {t('dashboard.downloadReportDialog.selectPeriod')}
                        </Typography>
                        <Box display="flex" flexDirection="row" gap="20px" p="10px" justifyContent="center" flexWrap="wrap"
                          alignItems="center">
                          {[t('dashboard.downloadReportDialog.periodOptions.weekly'),
                          t('dashboard.downloadReportDialog.periodOptions.monthly'),
                          t('dashboard.downloadReportDialog.periodOptions.quarterly'),
                          t('dashboard.downloadReportDialog.periodOptions.yearly')].map((pdfType) => (
                            <Stack
                              key={pdfType}
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="center"
                              gap={1}
                              sx={{
                                boxShadow: themeMode === "dark"
                                  ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                                  : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                                backgroundColor:
                                  pdfToDownload === pdfType
                                    ? colors.blueAccent[500]
                                    : themeMode === "dark" ? '#181818' : '#fafafa',
                                color:
                                  pdfToDownload === pdfType
                                    ? 'white'
                                    : themeMode === "dark" ? '#fafafa' : '#181818',
                                textAlign: 'center',
                                width: isMobile ? '35vw' : '100px',
                                height: '100px',
                                cursor: 'pointer',
                                borderRadius: '20px',
                                p: '10px'
                              }}
                              onClick={() => handlePdfSelection(pdfType)}
                            >
                              <PictureAsPdfRoundedIcon
                                sx={{
                                  fontSize: '30px',
                                  color:
                                    pdfToDownload === pdfType
                                      ? 'white'
                                      : themeMode === "dark" ? '#fafafa' : '#4a3aff',
                                }}
                              />
                              <Box
                                borderRadius="20px"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontWeight: 300,
                                    fontFamily: 'Outfit',
                                    fontSize: '16px',
                                    color:
                                      pdfToDownload === pdfType
                                        ? 'white'
                                        : themeMode === "dark" ? '#fafafa' : '#181818',
                                  }}
                                >
                                  {pdfType}
                                </Typography>
                              </Box>
                            </Stack>
                          ))}
                        </Box>
                      </Stack>
                    ) : (
                      <EnterpriseFeatureRequiredContent />
                    )
                  )
                }
              />

              <DialogCommon
                kTitle=""
                kMaxWidth="sm"
                kMaxHeight={isMobile ? '100vh' : '640px'}
                kFixedDialog={0}
                kFixedDialogPosition="fixed"
                kDisableBottomDivider
                kEnableBorderRadius={true}
                kBorderRadius={'30px'}
                kAlignBtn="center"
                kKeepBtnSize
                kTopDivider
                kSlideTransition
                kOpenDialogLists={introductionModal}
                kOnClose={closeIntroductionModal}
                kOnSecondary={prevScreen}
                kSecondaryBtnText={t('welcomeDialog.secondaryButtonText') as string}
                kPrimaryBtnText={(currentScreen === 2 ? t('welcomeDialog.primaryButtonText.finalStep') : t('welcomeDialog.primaryButtonText.nextStep')) as string}
                kOnPrimary={currentScreen === 2 ? closeIntroductionModal : nextScreen}
                kContent={
                  <>
                    {currentScreen === 0 && (
                      <Stack spacing={2} alignItems="center" justifyContent="center" sx={{ p: isMobile ? 0 : '30px' }}>
                        <img
                          src={introImg}
                          width="100%"
                          height="auto"
                          style={{
                            maxWidth: isMobile ? '80vw' : '300px',
                            textAlign: 'center',
                            filter: `drop-shadow(0 8px 24px rgba(74, 58, 255, 0.6))`,
                            borderRadius: '8px',
                            marginBottom: '40px'
                          }}
                        />
                        <Typography sx={{
                          color: themeMode === "dark" ? '#4a3aff' : '#4a3aff',
                          fontSize: '40px', textAlign: 'center', lineHeight: '45px', fontWeight: '600'
                        }} variant="h6"
                        >
                          {t('welcomeDialog.content.welcome.title')}
                        </Typography>
                        <Typography sx={{
                          color: themeMode === "dark" ? '#fafafa' : '#181818', fontSize: isMobile ? '14px' : '16px',
                          textAlign: 'center', fontWeight: '300'
                        }}>
                          {t('welcomeDialog.content.welcome.description')}
                        </Typography>
                      </Stack>



                    )}
                    {currentScreen === 1 && (
                      <Stack spacing={2} alignItems="center" justifyContent="center">
                        <ReactApexChart options={options} series={series} type="radialBar" height={350} />
                        <Typography
                          sx={{
                            color: themeMode === "dark" ? '#4a3aff' : '#4a3aff',
                            fontSize: '40px', textAlign: 'center', lineHeight: '45px', fontWeight: '600'
                          }} variant="h6"
                        >
                          {t('welcomeDialog.content.freeTrial.title')}
                        </Typography>
                        <Typography
                          sx={{
                            color: themeMode === "dark" ? '#fafafa' : '#181818', fontSize: isMobile ? '14px' : '16px',
                            textAlign: 'center', fontWeight: '300'
                          }}>
                          {t('welcomeDialog.content.freeTrial.description')}
                        </Typography>
                      </Stack>
                    )}
                    {currentScreen === 2 && (
                      <Stack spacing={2} alignItems="center" justifyContent="center" sx={{ p: isMobile ? 0 : '10px' }}>
                        <ReactApexChart
                          series={seriesData}
                          type="bar"
                          height={250}
                          options={TotalMessagesOptions}
                        />
                        <Typography
                          sx={{
                            color: themeMode === "dark" ? '#4a3aff' : '#4a3aff',
                            fontSize: '40px', textAlign: 'center', lineHeight: '45px', fontWeight: '600'
                          }} variant="h6"
                        >
                          {t('welcomeDialog.content.maximizeExperience.title')}
                        </Typography>
                        <Typography sx={{
                          color: themeMode === "dark" ? '#fafafa' : '#181818', fontSize: isMobile ? '14px' : '16px',
                          textAlign: 'center', fontWeight: '300'
                        }}>
                          {t('welcomeDialog.content.maximizeExperience.description')}

                        </Typography>
                      </Stack>
                    )}
                  </>
                }
              />
            </Box >
          )
        }
      </Stack>
    </SectionContainer >
  );
};

export default Dashboard;
