import { onAuthStateChanged } from 'firebase/auth';
import { query, collection, where, getDocs, getDoc, doc } from 'firebase/firestore';
import { useEffect, useReducer, useState } from 'react';
import { auth, db } from '../../services/firebase/firebase';
import StoreContext from './storeContext';
import storeReducer from './storeReducer';

const StoreState = ({ children }: any) => {

  type StateType = {
    chatbots: any[];
    user: any;
    needsUpdate: boolean;
  };

  const [state, dispatch] = useReducer(storeReducer, {
    chatbots: [],
    user: null,
    needsUpdate: false,
  }) as [StateType, React.Dispatch<any>];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (userAuth) => {
      if (userAuth) {
        fetchUser(userAuth.uid);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUser = async (userId: string) => {
    const userDocRef = doc(db, 'users', userId);
    const userDocSnapshot = await getDoc(userDocRef);
    if (userDocSnapshot.exists()) {
      setUser(userDocSnapshot.data());

    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (userAuth) => {
      if (userAuth) {
        const userDocRef = doc(db, 'users', userAuth.uid);
        const userDocSnapshot = await getDoc(userDocRef);
        if (userDocSnapshot.exists()) {
          setUser(userDocSnapshot.data());

          fetchChatbots(userAuth.uid);
        }
      }
    });

    // Desuscribirse al desmontar el componente
    return () => unsubscribe();
  }, []);

  const fetchChatbots = async (userId: string) => {
    const q = query(collection(db, 'chatbots'), where('ownerId', '==', userId));
    const querySnapshot = await getDocs(q);
    const chatbotsData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    setChatbots(chatbotsData);
  };

  const setChatbots = (chatbots: any[]) => {
    dispatch({ type: 'SET_CHATBOTS', payload: chatbots });
  };

  const addChatbot = (chatbot: any) => {
    dispatch({ type: 'ADD_CHATBOT', payload: chatbot });
  };

  const updateChatbot = (chatbot: any) => {
    dispatch({ type: 'UPDATE_CHATBOT', payload: chatbot });
  };

  const deleteChatbot = (chatbotId: string) => {
    dispatch({ type: 'DELETE_CHATBOT', payload: chatbotId });
  };

  const setUser = (user: any) => {
    dispatch({ type: 'SET_USER', payload: user });
  };

  const setNeedsUpdate = (value: boolean) => {
    dispatch({ type: 'SET_NEEDS_UPDATE', payload: value });
  };

  const [isChatbotOpen, setChatbotOpen] = useState(false);

  const toggleChatbot = () => {
    setChatbotOpen(!isChatbotOpen);
  };

  return (
    <StoreContext.Provider
      value={{
        chatbots: state.chatbots,
        user: state.user,
        needsUpdate: state.needsUpdate,
        isChatbotOpen: isChatbotOpen,
        setChatbots,
        addChatbot,
        updateChatbot,
        deleteChatbot,
        setUser,
        setNeedsUpdate,
        fetchChatbots,
        toggleChatbot,
        fetchUser
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreState;
