/* eslint-disable no-debugger */
import { Box, Button, Drawer, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { ConfigContext } from "../context/Config/ConfigContext";
import { useContext, useEffect, useState } from "react";
import { query, collection, where, getDocs, orderBy } from "firebase/firestore";
import { db } from "../services/firebase/firebase";
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { showToast } from "../components/Toast/toast";

type Conversation = {
  id: string;
  title: any;
}
const extractParams = (pathname: string) => {
  const segments = pathname.split('/');

  const params: { chatbotId?: string; conversationId?: string } = {};

  if (segments[1] === 'chat' && segments[2]) {
    params.chatbotId = segments[2];
  }

  if (segments[3] === 'conversations' && segments[4]) {
    params.conversationId = segments[4];
  }

  return params;
};

export default function ChatSidebar(props: any) {

  const {
    themeMode,
    defaultLanguage
  } = useContext(ConfigContext)

  const location = useLocation();
  const { chatbotId = '', conversationId = '' } = extractParams(location.pathname);

  const isOpen = true
  const navigate = useNavigate();

  const theme = useTheme();

  const formatDateWithTime = (timestamp: any) => {
    if (!timestamp) return '-';

    const conversationDate = timestamp.toDate();
    const now = new Date();
    const currentYear = now.getFullYear();
    const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);

    const hours = conversationDate.getHours().toString().padStart(2, '0');
    const minutes = conversationDate.getMinutes().toString().padStart(2, '0');
    const timeString = `${hours}:${minutes}`;

    const isToday = conversationDate.getDate() === now.getDate() &&
      conversationDate.getMonth() === now.getMonth() &&
      conversationDate.getFullYear() === now.getFullYear();

    const isYesterday = conversationDate.getDate() === yesterday.getDate() &&
      conversationDate.getMonth() === yesterday.getMonth() &&
      conversationDate.getFullYear() === yesterday.getFullYear();

    const diff = now.getTime() - conversationDate.getTime();
    const diffInHours = diff / (1000 * 60 * 60);

    const dateOptions = {
      day: 'numeric',
      month: 'long',
      ...(conversationDate.getFullYear() !== currentYear && { year: 'numeric' })
    };

    if (diffInHours < 1) {
      return <span>{defaultLanguage === 'es' ? 'Ahora' : 'Now'} <br />{timeString}</span>;
    } else if (isToday) {
      return <span>{defaultLanguage === 'es' ? 'Hoy' : 'Today'}<br />{timeString}</span>;
    } else if (isYesterday) {
      return <span>{defaultLanguage === 'es' ? 'Ayer' : 'Yesterday'}<br />{timeString}</span>;
    } else {
      return (
        <>
          {conversationDate.toLocaleDateString('es-ES', dateOptions)}
          <br />
          {timeString}
        </>
      );
    }
  };

  async function getUserConversations(chatbotId: string, userId: string): Promise<Conversation[]> {
    try {
      const conversationsRef = collection(db, 'conversations');
      const q = query(
        conversationsRef,
        where("userId", "==", userId),
        where("chatbotId", "==", chatbotId),
        orderBy("firstMessageTimestamp", "desc")
      );

      const querySnapshot = await getDocs(q);
      let userConversations: Conversation[] = [];
      querySnapshot.docs.forEach(doc => {
        const data = doc.data();
        const title = formatDateWithTime(data.firstMessageTimestamp);
        userConversations.push({
          id: doc.id,
          title: title
        });
      });
      return userConversations;
    } catch (err) {
      showToast('error', "Failed to load conversations. Please try again later.", themeMode)
      return [];
    }
  }

  const menuItems = [
    {
      text: defaultLanguage === 'es' ? "Nueva conversación" : "New chat",
      icon: <AddRoundedIcon sx={{ color: themeMode === "dark" ? "fafafa" : "1c1c1c" }} />,
      path: "/support",
    },
  ];
  // Mock data para las conversaciones
  const [chatHistory, setChatHistory] = useState<Conversation[]>([]);


  useEffect(() => {
    async function fetchConversations() {
      const userId = props.uid;
      const userConversations = await getUserConversations(chatbotId, userId);
      setChatHistory(userConversations);
    }
    if (props.uid) {
      fetchConversations();
    }
  }, [props.uid]);

  const { primaryColor } = useContext(ConfigContext);

  return (
    <Drawer
      variant={"temporary"}
      open={props.isSidebarOpenFromMobile}
      anchor="left"
      sx={{
        position: 'absolute',
        width: "230px",
        flexShrink: 0,
        border: 'none',
        "& .MuiDrawer-paper": {
          width: "230px",
          boxSizing: "border-box",
          bgcolor: themeMode === "dark" ? "#121212" : '#fff',
          overflow: "hidden",
          transition: 'width 0.25s ease',
          border: 'none'
        },
        transition: 'width 0.25s ease',
      }}
      onClose={() => props.setIsSidebarOpenFromMobile(false)}
    >
      <Stack flexDirection="column" justifyContent="space-between" alignItems="center"
        sx={{ height: '100vh', backgroundColor: themeMode === "dark" ? "#121212" : '#fff', }}>
        <Box>
          <Stack flexDirection="row"
            justifyContent="space-between" alignItems="center"
            sx={{ pt: "10px" }}
          >
            <Box sx={{ p: '30px', pr: 0 }}>

              <Typography sx={{ fontSize: '18px', fontFamily: 'Outfit', textAlign: 'left', color: themeMode === "dark" ? "white" : 'black' }}>
                {
                  defaultLanguage === 'es' ? 'Historial de chat' : 'Chat history'
                }
              </Typography>
            </Box>
            <Box sx={{ ml: '10px', mr: '10px' }}>
              <IconButton
                sx={{
                  '&:focus': { outline: 'none' },
                  '&:hover': { bgcolor: themeMode === 'dark' ? '#181818' : '#f1f1f1' },
                }}
                onClick={() => props.setIsSidebarOpenFromMobile(false)}
              >
                <ArrowBackIosNewRoundedIcon
                  sx={{
                    fontSize: '22px', color: themeMode === "dark" ? "white" : 'black', pr: '5px'

                  }}
                />
              </IconButton>
            </Box>
          </Stack>
          <Box sx={{
            overflow: 'auto',
            scrollbarWidth: 'thin',
            height: 'calc(85vh - 100px)',
            scrollbarColor: 'transparent transparent',
            '&:hover': {
              scrollbarColor: 'rgba(211, 211, 211, 0.5) transparent',
            },
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
            },
            '&:hover::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(211, 211, 211, 0.5)',
              borderRadius: '50px'
            },
            msOverflowStyle: 'none',
          }}>
            <Stack spacing={1} sx={{ ml: '20px' }}>
              {chatHistory.length > 0 ? (chatHistory.map((chat, index) => (
                <Button
                  key={index}
                  variant="contained"
                  disableElevation
                  onClick={() => { navigate(`/chat/${chatbotId}/conversations/${chat.id}`); props.setIsSidebarOpenFromMobile(false) }}
                  startIcon={<ChatRoundedIcon sx={{ color: themeMode === "dark" ? "#c1c1c1" : '#c1c1c1', }} />}
                  sx={{
                    p: "20px",
                    borderRadius: "100px",
                    width: "180px",
                    height: "50px",
                    justifyContent: "flex-start",
                    textTransform: "none",
                    color: "white",
                    backgroundColor: "transparent",
                    "&:hover": {
                      backgroundColor: themeMode === "dark" ? "#1C1C1C" : '#fafafa',
                    },
                    "&:focus": {
                      outline: "none",
                      backgroundColor: theme.palette.primary.main,
                    },
                    textAlign: 'left'
                  }}
                >
                  <Typography variant="body1" sx={{
                    ml: '20px', fontFamily: 'Outfit',
                    fontSize: '14px', color: themeMode === "dark" ? "#fafafa" : '#121212',
                    textAlign: 'left'
                  }}>
                    {chat.title}
                  </Typography>
                </Button>
              ))) : (
                <Stack flexDirection="column" alignItems="center"
                  justifyContent="center" gap={1}
                  sx={{ mt: '10px' }}
                >
                  <ForumRoundedIcon sx={{ mx: 'auto', textAlign: 'center', fontSize: '40px', color: '#c1c1c1' }} />
                  <Typography sx={{ fontFamily: 'Outfit', fontSize: '14px', textAlign: 'center', color: themeMode === "dark" ? "#fafafa" : '#1c1c1c', width: '80%' }}>
                    {
                      defaultLanguage === 'es' ? 'Aún no tienes conversaciones' : "You still don't have conversations"
                    }
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Box>
        </Box>
        <Box sx={{ mb: '30px', pt: '10px' }}>
          <Stack
            spacing={1}>
            {menuItems.map((item, index) => (
              <Button
                key={index}
                variant="contained"
                disableElevation
                onClick={() => {
                  if (conversationId) {
                    navigate(`/chat/${chatbotId}`);
                  } else {
                    window.location.reload();
                  }
                  props.setIsSidebarOpenFromMobile(false);
                }}
                startIcon={isOpen ? item.icon : null}
                sx={{
                  p: "20px",
                  borderRadius: "100px",
                  width: "180px",
                  height: "50px",
                  justifyContent: "flex-start",
                  textTransform: "none",
                  backgroundColor: primaryColor,

                  border: 'solid 1px',
                  borderColor: primaryColor,
                  fontFamily: 'Outfit', fontSize: '14px', textAlign: 'left', color: "white",
                  lineHeight: '1.3em',
                  "&:hover": {
                    backgroundColor: 'transparent',
                    border: 'solid 1px',
                    borderColor: primaryColor,
                    color: themeMode !== "dark" ? "#1C1C1C" : '#fafafa'
                  },
                  "&:focus": {
                    outline: "none",
                    backgroundColor: primaryColor,
                  },
                }}
              >
                {isOpen ? item.text : (
                  item.icon
                )}
              </Button>
            ))}
          </Stack>
        </Box>
      </Stack >
    </Drawer >
  );
}