import React, { useState } from 'react';
import axios from 'axios';
import { showToast } from '../../components/Toast/toast';

const AudioInteractionSimulator: React.FC = () => {
  const [audioFile, setAudioFile] = useState<File | null>(null);
  const [responseAudioUrl, setResponseAudioUrl] = useState<string | null>(null);
  const [chatbotId, setChatbotId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setAudioFile(e.target.files[0]);
    }
  };

  const handleChatbotIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChatbotId(e.target.value);
  };

  const sendAudioToBackend = async () => {
    if (!audioFile || !chatbotId) return;

    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', audioFile); // Cambiado de 'audio' a 'file'
    formData.append('chatbotId', chatbotId);

    try {

      const response = await axios.post('https://us-central1-fastalk-dev.cloudfunctions.net/selectPrerecordedAudio', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setResponseAudioUrl(response.data.audioUrl);
      showToast('success', 'Audio procesado exitosamente', 'dark');
    } catch (error) {
      console.error('Error al enviar audio al backend:', error);
      if (axios.isAxiosError(error) && error.response) {
        console.error('Respuesta del servidor:', error.response.data);
      }
      showToast('error', 'Error al procesar el audio', 'dark');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', maxWidth: '400px', margin: 'auto', padding: '20px' }}>
      <h2>Simulador de Interacción de Audio</h2>

      <input
        type="text"
        placeholder="ID del Chatbot"
        value={chatbotId}
        onChange={handleChatbotIdChange}
      />

      <input
        type="file"
        accept="audio/*"
        onChange={handleFileChange}
      />

      {audioFile && (
        <audio controls src={URL.createObjectURL(audioFile)}>
          Tu navegador no soporta el elemento de audio.
        </audio>
      )}

      <button onClick={sendAudioToBackend} disabled={!audioFile || !chatbotId || isLoading}>
        Enviar Audio al Backend
      </button>

      {isLoading && <p>Cargando respuesta...</p>}

      {responseAudioUrl && (
        <div>
          <h3>Respuesta del Backend:</h3>
          <audio controls src={responseAudioUrl}>
            Tu navegador no soporta el elemento de audio.
          </audio>
        </div>
      )}
    </div>
  );
};

export default AudioInteractionSimulator;