import { useMediaQuery, useTheme } from '@mui/material';
import { useContext } from 'react';
import StoreContext from '../../context/Store/storeContext';
import './fastalk-iframe.css';

const SupportChatbot = () => {
  const { isChatbotOpen, toggleChatbot, user } = useContext(StoreContext);

  const chatbotES = "https://fastalk-dashboard.web.app/chat/FjHhzfdRuVtGahE0DBxz";
  const chatbotEN = "https://fastalk-dashboard.web.app/chat/FhzcNfIDcfBdUA0Beplk";

  // Determinar URL del chatbot basado en el idioma del usuario
  const chatbotURL = user?.language === 'en' ? chatbotEN : chatbotES;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const iframeStyles: React.CSSProperties = {
    width: '350px',
    height: '700px',
    border: 'none',
    borderRadius: '20px',
    position: 'fixed',
    bottom: isMobile ? '100px' : '80px',
    right: '20px',
    zIndex: 1,
    opacity: isChatbotOpen ? 1 : 0,
    visibility: isChatbotOpen ? 'visible' : 'hidden',
    transform: isChatbotOpen ? 'scale(1)' : 'scale(0.80)',
    transition: 'opacity 0.3s ease, transform 0.3s ease, visibility 0.15s ease',
    transformOrigin: 'bottom right',
  };

  return (
    <div id="fastalk-chatbot-container">
      <button id="fastalk-chatbot-toggle" onClick={toggleChatbot}
        style={{ backgroundColor: '#4a3aff', right: isMobile ? '-5px' : '10px', bottom: isMobile ? '40px' : '10px', width: isMobile ? '55px' : '65px', height: isMobile ? '55px' : '65px' }}>
        <svg className={`ham hamRotate ham8 ${isChatbotOpen ? 'active' : ''}`} viewBox="0 0 100 100" width="80">
          <path className="line top" d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 c 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20" />
          <path className="line middle" d="m 30,50 h 40" />
          <path className="line bottom" d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20" />
          <path className="chat-tail" d="M 70, 93 L 65,75 L 45,75 Z" style={{ opacity: isChatbotOpen ? 0 : 1 }} />
        </svg>
      </button>
      <iframe
        id="fastalk-chatbot-iframe"
        src={chatbotURL}
        title="Fastalk Chatbot"
        style={iframeStyles}
      />
    </div>
  );
};

export default SupportChatbot;
