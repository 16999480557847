import { toast } from 'react-toastify';

// Tipos actualizados
type ToastType = 'info' | 'success' | 'warning' | 'error';


export const showToast = (type: ToastType, message: string, themeMode: any) => {
  const options = {
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    style: {
      background: themeMode === "dark" ? "#181818" : "#fafafa",
      color: themeMode === "dark" ? "#fff" : "#181818",
      fontFamily: 'Outfit',
      fontSize: '14px',
      borderRadius: '15px',
    },
    theme: themeMode
  };

  // Llama a la función de toast de manera segura
  if (typeof toast[type] === 'function') {
    toast[type](message, options);
  } else {
    console.error('Toast type is not valid: ' + type);
  }
};
