export const functions = [
  // {
  //   "name": "generate_whatsapp_link",
  //   "description": "Ejecuta cuando el usuario muestra interés en comprar o adquirir tras solicitar información detallada del producto o servicio. No preguntes dirección ni correo electrónico ni nada parecido, solo el nombre para poder ejecutar la función.",
  //   "parameters": {
  //     "type": "object",
  //     "properties": {
  //       "product_description": {
  //         "type": "string",
  //         "description": "Descripción detallada del producto o servicio que el cliente está interesado en adquirir o comprar. Es requerido que lo hagas de la forma más detallada."
  //       }
  //     },
  //     "required": ["product_description"]
  //   }
  // },
  {
    "name": "collect_contact_info",
    "description": "Recolecta información de contacto del usuario, como nombre, email, mensaje y número de teléfono al final de la conversación, o cuando el usuario requiera algo especializado o tal vez comprar algo.",
    "parameters": {
      "type": "object",
      "properties": {
        "name": { "type": "string", "description": "Nombre del usuario" },
        "email": { "type": "string", "description": "Correo electrónico del usuario" },
        "message": { "type": "string", "description": "Mensaje del usuario, lo puedes extraer desde los requerimientos del usuario en la conver. Por favor brinda detalladamente el mensaje dependiendo de los requerimientos del usuario. No preguntes por el número de teléfono." },
      },
      "required": ["name", "email", "message"]
    }
  },
];
