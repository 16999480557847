import { serverTimestamp, collection, addDoc } from 'firebase/firestore';
import { showToast } from '../../../components/Toast/toast';
import { db } from '../../../services/firebase/firebase';
import {
  executeContactInfoFunction,
} from './chatbotFunctions';
import { functions } from './functionDefinition';
import { fetchToOpenAI } from './OpenAIApi';

type MediaType = {
  id: string;
  url: string;
  type: string;
  title?: string;
  description?: string;
};

type IntegrationType = {
  id: string;
  platform: string;
  description: string;
  url: string;
};

async function saveMessageToFirebase(conversationId: string, messageData: any, defaultLanguage: string, themeMode: string) {
  try {
    const messagesRef = collection(db, 'messages');
    await addDoc(messagesRef, {
      ...messageData,
      conversationId,
    });
  } catch (error: any) {
    const errorMessage = defaultLanguage === 'es' ? "Error guardando el mensaje en Firebase:" : "Error saving the message to Firebase:";
    showToast('error', errorMessage, themeMode);
  }
}

export async function processMessageToChatGPT(
  chatMessages: any,
  setMessages: Function,
  setTyping: Function,
  // updateMetrics: Function,
  systemInstructions: string,
  useEmojis: boolean,
  chatbotTone: string,
  chatbotFormality: string,
  defaultLanguage: string,
  chatbotPersonality: string,
  businessType: string,
  chatbotId: string,
  currentConversationId: string | null,
  feedbackInstructions: string,
  themeMode: string,
  chatbotImages?: MediaType[],
  chatbotVideos?: MediaType[],
  chatbotIntegrations?: IntegrationType[],
  user?: any,
) {


  const firstInstructions = "Vas a actuar como una persona de servicio a cliente sumamente amable y dispuesta a ofrecer información detallada sobre el negocio. Es importante que inicies preguntando el nombre del usuario.";
  const useEmojisInstruction = useEmojis ? "Es importante que utilices emojis en tus respuestas." : "No utilices emojis.";
  const chatbotToneInstruction = `El tono del chatbot es ${chatbotTone}.`;
  const chatbotFormalityInstruction = `La formalidad del chatbot es ${chatbotFormality}.`;
  const defaultLanguageInstruction = {
    es: "El idioma predeterminado del chatbot es Español. Pero si te hablan en otro idioma no dudes en cambiar de idioma y responder con ese idioma.",
    en: "El idioma predeterminado del chatbot es Inglés.  Pero si te hablan en otro idioma no dudes en cambiar de idioma y responder con ese idioma.",
  }[defaultLanguage];
  const chatbotPersonalityInstruction = `La personalidad del chatbot es ${chatbotPersonality}.`;
  const businessTypeInstruction = `El tipo de negocio para el chatbot es ${businessType}.`;

  const qualityInstructions = 'Estás aquí para ayudar con preguntas relacionadas con el negocio o servicio específico. Si recibes preguntas que no están relacionadas con el negocio, responde amablemente que estás diseñado para ayudar con temas específicos relacionados con nuestro servicio o producto. Si no tienes la respuesta a una pregunta, es preferible decir que no sabes la respuesta en lugar de proporcionar información incorrecta o especulativa. No te involucres en temas controvertidos, y si un usuario intenta guiarte hacia uno, mantén neutralidad y redirige la conversación de vuelta al foco principal del negocio. Haz las respuestas breves y precisas. NOTA IMPORTANTE: ES IMPORTANTE QUE SIEMPRE PREGUNTES POR EL NOMBRE Y CORREO ELECTRÓNICO SI NO LO PROPORCIONAN!!';
  const fastalkSell = 'Si el cliente pregunta cómo está hecho este chatbot, menciona que está hecho por Fastalk, que se dedica a SaaS de chatbots impulsados con IA. Y dales el contacto: fastalk.app.';

  const shortMessage = 'Responde de manera breve y concisa.';

  const isImageAnalysisAllowed = user && !['basicMonthlyMXN', 'basicAnnualMXN', 'basicMonthlyUSD', 'basicAnnualUSD'].includes(user.subscriptionType);

  let imageAnalysisInstruction = '';
  if (isImageAnalysisAllowed) {
    imageAnalysisInstruction = 'Este chatbot está habilitado para recibir imágenes como parte de las consultas. Menciona que sí puedes recibir imágenes.';
  } else {
    imageAnalysisInstruction = 'Este chatbot no está habilitado para recibir ni analizar imágenes. Si te envían una imagen, responde que no puedes procesarla.';
  }

  let feedbackInstructionsPrompt = "";

  // Feedback Instructions
  if (feedbackInstructions && feedbackInstructions !== "") {
    feedbackInstructionsPrompt = `Respeta por favor las siguientes instrucciones de acuerdo al feedback del usuario: ${feedbackInstructions}.`;
  }

  const filteredChatbotImages = chatbotImages?.map((image: any) => ({
    url: image.url,
    description: image.description,
  }));

  const filteredChatbotVideos = chatbotVideos?.map((video: any) => ({
    url: video.url,
    description: video.description,
  }));

  const filteredChatbotIntegrations = chatbotIntegrations?.map((integration: any) => ({
    platform: integration.platform,
    url: integration.url,
    description: integration.description,
  }));

  // Media Instructions
  let imagesInstruction = '';
  if (filteredChatbotImages && filteredChatbotImages.length > 0) {
    imagesInstruction = `De acuerdo a esta lista de imágenes que tiene disponible el chatbot: ${JSON.stringify(filteredChatbotImages)}, por favor devuelve una etiqueta: <img src="(url de la imagen elegida)" alt="" width="100%"  style="border-radius: 15px; box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.15);" />, cuando sea necesario.`;
  }

  let videosInstruction = '';
  if (filteredChatbotVideos && filteredChatbotVideos.length > 0) {
    videosInstruction = `De acuerdo a esta lista de videos que tiene disponible el chatbot: ${JSON.stringify(filteredChatbotVideos)}, por favor devuelve una etiqueta: <iframe width="100%" height="300px" style="border-radius: 15px; box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.15);" src="(URL del video seleccionado)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>, cuando sea necesario. Nota de integración: No digas "Aquí está el enlace:" cuando pases el video, si no que menciona que "aquí te paso un video o estos videos relacionado a..."`;
  }

  // Integrations Instructions
  let platformsInstruction = '';
  if (filteredChatbotIntegrations && filteredChatbotIntegrations.length > 0) {
    platformsInstruction = `De acuerdo a esta lista de plataformas que tiene disponible el chatbot: ${JSON.stringify(filteredChatbotIntegrations)}, por favor devuelve una etiqueta: <iframe src="(URL de la plataforma seleccionada)" width="100%" height="650" style="border: 0; border-radius: 15px; overflow: hidden; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);" frameborder="0"></iframe>, cuando sea necesario. Nota: No envíes un enlace, solo el iframe, y tampoco menciones algo como "Aquí tienes el enlace para hacerlo:" ya que no es un enlace, es un iframe. Tampoco preguntes en cada mensaje estas integraciones, osea solamente hasta que el usuario diga que quiere realizar lo de las integraciones, ahí se envía el iframe. Por ejemplo no preguntes en cada mensaje si quiere agendar cita o llenar formulario. (La URL debe de ser totalmente exacta!) Nota extra: Por favor cuando mandes un iframe de una plataforma, NO MENCIONES QUE ES UN ENLACE, YA QUE LO QUE SE VISUALIZA DENTRO DEL CHAT ES UNA PANTALLA. `;
  }

  const instructionsList = [
    useEmojisInstruction,
    chatbotToneInstruction,
    chatbotFormalityInstruction,
    defaultLanguageInstruction,
    chatbotPersonalityInstruction,
    businessTypeInstruction,
    qualityInstructions,
    fastalkSell,
    shortMessage,
    feedbackInstructionsPrompt,
    imagesInstruction,
    videosInstruction,
    platformsInstruction,
    imageAnalysisInstruction
  ].filter(instruction => instruction !== '').join(' ');

  const cleanedSystemInstructions = systemInstructions.replace(/^"|"$/g, '');
  const concatenatedInstructions = `${firstInstructions} ${cleanedSystemInstructions} ${instructionsList}`;

  const systemMessage = {
    role: "system",
    content: concatenatedInstructions,
  };

  let apiMessages = chatMessages.map((messageObject: any) => {
    let role = messageObject.sender === "ChatGPT" ? 'assistant' : 'user';

    let content: any[] = [{ type: "text", text: messageObject.message }];

    if (messageObject.image) {
      content.push({
        type: "image_url",
        image_url: {
          url: messageObject.image.startsWith('data:image/')
            ? `data:image/jpeg;base64,${messageObject.image.split(',')[1]}`
            : messageObject.image,
          detail: "high"
        }
      });
    }

    return { role: role, content: content };
  });

  let apiRequestBody;
  // if (googleFormUrl && googleFormUrl !== "") {
  //   apiRequestBody = {
  //     "model": "gpt-4o-mini",
  //     "messages": [
  //       systemMessage,
  //       ...apiMessages
  //     ],
  //     "functions": functions,
  //     "function_call": "auto"
  //   };
  // } else {
  apiRequestBody = {
    "model": "gpt-4o",
    "messages": [
      systemMessage,
      ...apiMessages
    ],
    // };
  }

  try {
    const data = await fetchToOpenAI(apiRequestBody);
    let messageContent = data.choices[0].message.content;
    const functionCall = data.choices[0].message.function_call;

    type PreservedTag = string;

    const preservedTags: PreservedTag[] = [];

    // Primero, identificamos y preservamos las etiquetas HTML existentes
    messageContent = messageContent.replace(/<[^>]+>/g, (match: string): string => {
      preservedTags.push(match);
      return `__TAG${preservedTags.length - 1}__`;
    });

    // Ahora reemplazamos las URLs que no son parte de etiquetas
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    messageContent = messageContent.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');

    // Finalmente, restauramos las etiquetas originales sin modificarlas
    preservedTags.forEach((tag: PreservedTag, index: number): void => {
      messageContent = messageContent.replace(`__TAG${index}__`, tag);
    });

    if (functionCall) {
      const conversation = [].concat(chatMessages);

      const functionName = functionCall.name;
      const functionArgs = JSON.parse(functionCall.arguments);

      const functionDefinition = functions.find(func => func.name === functionName);

      const validationResults = await validateExecution(
        conversation,
        functionArgs,
        functionDefinition,
        setTyping,
        defaultLanguage,
        themeMode
      );

      if (!validationResults.validate) {
        setMessages([
          ...chatMessages,
          {
            message: validationResults.response,
            sender: "ChatGPT"
          }
        ]);

        if (chatbotId && currentConversationId) {
          const chatGPTMessageData = {
            message: validationResults.response,
            sender: "ChatGPT",
            timestamp: serverTimestamp()
          };

          await saveMessageToFirebase(currentConversationId, chatGPTMessageData, defaultLanguage, themeMode);
        }
        return false;
      }

      // handleFunctionCall(functionCall, chatMessages, setMessages,
      //   updateMetrics,
      //   chatbotId,
      //   currentConversationId,
      //   defaultLanguage,
      //   themeMode
      // );
    } else {
      setMessages([
        ...chatMessages,
        {
          message: messageContent,
          sender: "ChatGPT"
        }
      ]);

      setTyping(false);

      if (chatbotId && currentConversationId) {
        const chatGPTMessageData = {
          message: messageContent,
          sender: "ChatGPT",
          timestamp: serverTimestamp()
        };

        await saveMessageToFirebase(currentConversationId, chatGPTMessageData, defaultLanguage, themeMode);
      }
    }

    setTyping(false);
  } catch (error: any) {
    const errorMessageOpenAI = defaultLanguage === 'es' ? "Error al comunicarse con OpenAI." : "Error communicating with OpenAI.";
    showToast('error', errorMessageOpenAI, themeMode);
    setTyping(false);
  }
}


async function validateExecution(
  conversation: any,
  functionArgs: any,
  functionDefinition: any,
  setTyping: Function,
  defaultLanguage: string,
  themeMode: string,
): Promise<{ validate: boolean, response?: string }> {

  const userMessages = conversation.filter((msg: any) => msg.sender === "user").map((msg: any) => msg.message).join(' ');

  const invalidNames = ['Usuario', 'usuario', 'Nombre de usuario', 'Juan', 'User'];
  const invalidEmails = ['@example.com', 'ejemplo@correo.com', 'ejemplo', 'example'];

  // Validar nombre
  if (!userMessages.includes(functionArgs.name) || invalidNames.includes(functionArgs.name)) {
    setTyping(false);
    return {
      validate: false,
      response: defaultLanguage == "es"
        ? "Antes de continuar, me gustaría confirmar tu nombre. ¿Podrías decirme cómo te llamas?"
        : "Before we continue, I'd like to confirm your name. Could you tell me what it is?"
    };
  }

  // Validar correo electrónico
  if (!userMessages.includes(functionArgs.email) || invalidEmails.some(invalidEmail => functionArgs.email.includes(invalidEmail))) {
    setTyping(false);
    return {
      validate: false,
      response: defaultLanguage == "es"
        ? "Para seguir adelante, necesito confirmar tu dirección de correo electrónico. ¿Podrías facilitármela, por favor?"
        : "To move forward, I need to confirm your email address. Could you provide it, please?"
    };
  }


  const requiredParams = Array.isArray(functionDefinition.parameters.required) ? functionDefinition.parameters.required : [];


  type ParamNamesMapping = {
    name: string;
    email: string;
    message: string;
  };
  const paramNamesMapping: ParamNamesMapping = {
    name: "tu nombre",
    email: "tu correo electrónico",
    message: "tu mensaje"
  };
  const paramNamesMappingEnglish: ParamNamesMapping = {
    name: "your name",
    email: "your email address",
    message: "your message"
  };

  for (const paramName of requiredParams) {
    if (paramName in paramNamesMapping) {
      if (!functionArgs.hasOwnProperty(paramName) || functionArgs[paramName].trim() === '') {
        setTyping(false);
        const paramNameInSpanish = paramNamesMapping[paramName as keyof ParamNamesMapping];
        const paramNameInEnglish = paramNamesMappingEnglish[paramName as keyof ParamNamesMapping];
        return {
          validate: false,
          response: defaultLanguage == "es"
            ? `Ahora, ¿me podrías facilitar ${paramNameInSpanish}?`
            : `Now, could you provide me with ${paramNameInEnglish}?`
        };
      }
    } else {
      const errorMessageParamMapping = defaultLanguage === 'es'
        ? `El parámetro ${paramName} no está definido en paramNamesMapping.`
        : `The parameter ${paramName} is not defined in paramNamesMapping.`;
      showToast('error', errorMessageParamMapping, themeMode);

      return { validate: false, response: "Error: unknown parameter." };
    }
  }



  return { validate: true, response: "" };
}

export async function handleFunctionCall(
  functionCall: any,
  chatMessages: any,
  setMessages: Function,
  updateMetrics: Function,
  chatbotId: any,
  currentConversationId: any,
  googleFormUrl: string,
  googleFormEntryName: string,
  googleFormEntryEmail: string,
  googleFormEntryMessage: string,
  defaultLanguage: string,
  themeMode: string

) {
  const functionName = functionCall.name;
  const functionArgs = JSON.parse(functionCall.arguments);
  let responseMessage = "";

  switch (functionName) {

    case 'collect_contact_info':
      const formData = {
        name: functionArgs.name,
        email: functionArgs.email,
        message: functionArgs.message,
      };

      const ChatbotFormConfig = {
        googleFormUrl: googleFormUrl,
        googleFormEntryName: googleFormEntryName,
        googleFormEntryEmail: googleFormEntryEmail,
        googleFormEntryMessage: googleFormEntryMessage,
      }


      const executionResponse = await executeContactInfoFunction(formData, ChatbotFormConfig, defaultLanguage);
      responseMessage = executionResponse;

      if (chatbotId && currentConversationId) {
        const chatGPTMessageData = {
          message: responseMessage,
          sender: "ChatGPT",
          timestamp: serverTimestamp()
        };
        await saveMessageToFirebase(currentConversationId, chatGPTMessageData, defaultLanguage, themeMode);
        await updateMetrics(chatbotId, { extractedData: true });
      }
      break;


    default:
      responseMessage = "Función no reconocida";
  }

  setMessages([...chatMessages, { message: responseMessage, sender: "ChatGPT" }]);
}
