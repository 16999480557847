import { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { collection, doc, getDoc, getDocs, orderBy, query, where } from 'firebase/firestore';
import { db, functions } from '../../services/firebase/firebase';
import { Box, Button, CircularProgress, FormControlLabel, Radio, RadioGroup, Rating, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import SectionContainer from '../../global/SectionContainer';
import ChatConversation from '../chatConversation/ChatConversation';
import FeedbackRoundedIcon from '@mui/icons-material/FeedbackRounded';
import DialogCommon from '../../components/DialogCommon/DialogCommon';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../context/Config/ConfigContext';
import { httpsCallable } from 'firebase/functions';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import StoreContext from '../../context/Store/storeContext';
import { showToast } from '../../components/Toast/toast';

function ConversationMessages() {

  const { t } = useTranslation('global')

  const { user } = useContext(StoreContext);

  // Obteniendo los parámetros botId y conversationId de la URL
  const { botId, conversationId } = useParams();
  const [loading, setLoading] = useState(true);
  const [loadingFeedback, setLoadingFeedback] = useState(false);

  const [analysis, setAnalysis] = useState<any>(null);

  const [rating, setRating] = useState(0);
  const [understandingValue, setUnderstandingValue] = useState('');
  const [feedbackComments, setFeedbackComments] = useState('');
  const [feedbackSent, setFeedbackSent] = useState(false);

  const handleUnderstandingChange = (event: any) => {
    setUnderstandingValue(event.target.value);
  };

  const handleCommentsChange = (event: any) => {
    setFeedbackComments(event.target.value);
  };

  const handleRatingChange = (_event: any, newValue: any) => {
    if (newValue !== null) {
      setRating(newValue);
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  type CustomMessageModel = {
    message: string;
    sender: string;
    timestamp: Date;
    image?: string | null;
  };

  const [messages, setMessages] = useState<CustomMessageModel[]>([]);

  useEffect(() => {
    const fetchMessages = async () => {
      if (botId && conversationId) {
        setLoading(true);

        try {
          // Obtener la propiedad assistantFirstMessage del chatbot
          const chatbotRef = doc(db, 'chatbots', botId);
          const chatbotSnapshot = await getDoc(chatbotRef);

          let assistantFirstMessage = "";
          if (chatbotSnapshot.exists()) {
            const chatbotData = chatbotSnapshot.data();
            assistantFirstMessage = chatbotData.assistantFirstMessage;
          } else {
            showToast('error', t('conversationMessages.errors.chatbotNotFound'), themeMode);
          }

          // Obtener los mensajes de la conversación desde la colección "messages"
          const messagesRef = collection(db, 'messages');
          const messagesQuery = query(messagesRef, where('conversationId', '==', conversationId), orderBy('timestamp'));
          const messagesSnapshot = await getDocs(messagesQuery);

          // Extracción y ordenación de mensajes
          let loadedMessages = messagesSnapshot.docs.map(doc => {
            const data = doc.data();
            return {
              message: data.message,
              sender: data.sender,
              timestamp: data.timestamp.toDate(),
              image: data.image || null, // Incluir la imagen si está presente
            };
          });

          // Establecer timestamp del mensaje inicial del asistente
          if (assistantFirstMessage && loadedMessages.length > 0) {
            const firstMessageTimestamp = new Date(loadedMessages[0].timestamp.getTime() - 1000); // 1 segundo antes del primer mensaje
            loadedMessages = [{ message: assistantFirstMessage, sender: "ChatGPT", timestamp: firstMessageTimestamp, image: null }].concat(loadedMessages);
          }

          setMessages(loadedMessages);

          // Obtener el análisis de la conversación
          const conversationRef = doc(db, 'conversations', conversationId);
          const conversationSnapshot = await getDoc(conversationRef);
          if (conversationSnapshot.exists()) {
            const conversationData = conversationSnapshot.data();
            setAnalysis(conversationData.analysis);
          }
        } catch (error: any) {
          showToast('error', t('conversationMessages.errors.loadingMessagesError'), themeMode);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchMessages();
  }, [botId, conversationId]);


  const [open, setOpen] = useState(false)

  const closeDialogModal = () => {
    setOpen(false);
  };

  const { themeMode } = useContext(ConfigContext)

  const handlePrimaryClick = async () => {
    if (feedbackSent) {
      setOpen(false);
      return;
    }

    // Validar que se hayan completado todos los campos necesarios
    if (!rating || !understandingValue || !feedbackComments.trim()) {
      // Mostrar un toast de advertencia si algún campo está vacío
      showToast('error', t('conversationMessages.errors.sendingFeedbackError'), themeMode);
      return; // Salir de la función para evitar que se envíe el feedback
    }

    // Si todos los campos están completos, proceder con el envío del feedback
    const feedbackData = {
      rating,
      understandingValue,
      feedbackComments,
      botId,
      conversationId,
      messages
    };

    setLoadingFeedback(true);
    try {
      const sendFeedback = httpsCallable(functions, 'processFeedback');
      await sendFeedback(feedbackData);
      setLoadingFeedback(false);
      setFeedbackSent(true);
    } catch (error: any) {
      setLoadingFeedback(false);
      showToast('error', t('conversationMessages.warnings.completeAllRequiredFields'), themeMode);
    }
  };

  const [loadingAnalysis, setLoadingAnalysis] = useState(false);

  const handleAnalyzeConversation = async () => {

    if (isSubscriptionCanceled) {
      showToast('error', 'Tu suscripción ha sido cancelada. Actualiza tu plan para acceder al análisis de conversaciones.', themeMode);
      return;
    }

    if (
      user.subscriptionType === 'basicMonthlyMXN' ||
      user.subscriptionType === 'basicAnnualMXN' ||
      user.subscriptionType === 'basicMonthlyUSD' ||
      user.subscriptionType === 'basicAnnualUSD'
    ) {
      handleOpenUpdatePlan();
      return;
    }

    setLoadingAnalysis(true);

    // process.env.VITE_APP_BACKEND_API

    try {
      const response = await fetch(`${process.env.VITE_APP_BACKEND_API}/analyzeConversation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          conversationId: conversationId,
          messages: messages,
          chatbotId: botId,
          currentDate: new Date().toISOString(),
        }),
      });

      if (response.ok) {
        showToast('success', t('toast.conversationAnalysisCompleted'), themeMode);
        if (conversationId) {
          const updatedConversationRef = doc(db, 'conversations', conversationId);
          const updatedConversationSnapshot = await getDoc(updatedConversationRef);
          if (updatedConversationSnapshot.exists()) {
            const updatedConversationData = updatedConversationSnapshot.data();
            setAnalysis(updatedConversationData.analysis);
          }
        }
      } else {
        showToast('error', t('toast.errorAnalyzingConversation'), themeMode);
      }
    } catch (error) {
      showToast('error', t('toast.errorAnalyzingConversation'), themeMode);
    }

    setLoadingAnalysis(false);
  };

  const getUserSentimentStyle = (sentiment: string) => {
    switch (sentiment) {
      case "positive":
        return {
          backgroundColor: themeMode === "dark" ? "#1e2c1e" : "#C8E6C9", width: '150px', p: '5px', textAlign: 'center', borderRadius: '50px',
          color: "#388E3C",
          fontWeight: "bold",
        };
      case "neutral":
        return {
          backgroundColor: themeMode === "dark" ? "#303030" : "#E0E0E0", width: '150px', p: '5px', textAlign: 'center', borderRadius: '50px',
          color: "#757575",
          fontWeight: "bold",
        };
      case "negative":
        return {
          backgroundColor: themeMode === "dark" ? "#3d2525" : "#FFCDD2", width: '150px', p: '5px', textAlign: 'center', borderRadius: '50px',
          color: "#D32F2F",
          fontWeight: "bold",
        };
      default:
        return {
          backgroundColor: "transparent",
          color: themeMode === "dark" ? "#fafafa" : "#181818", width: '150px', p: '5px', textAlign: 'center', borderRadius: '50px',
          fontWeight: "300",
        };
    }
  };

  const getUserSatisfactionStyle = (satisfaction: string) => {
    switch (satisfaction) {
      case "high":
        return {
          backgroundColor: themeMode === "dark" ? "#1e2c1e" : "#C8E6C9", width: '150px', p: '5px', textAlign: 'center', borderRadius: '50px',
          color: "#388E3C",
          fontWeight: "bold",
        };
      case "medium":
        return {
          backgroundColor: themeMode === "dark" ? "#303030" : "#E0E0E0", width: '150px', p: '5px', textAlign: 'center', borderRadius: '50px',
          color: "#757575",
          fontWeight: "bold",
        };
      case "low":
        return {
          backgroundColor: themeMode === "dark" ? "#3d2525" : "#FFCDD2", width: '150px', p: '5px', textAlign: 'center', borderRadius: '50px',
          color: "#D32F2F",
          fontWeight: "bold",
        };
      default:
        return {
          backgroundColor: "transparent",
          color: themeMode === "dark" ? "#fafafa" : "#181818", width: '150px', p: '5px', textAlign: 'center', borderRadius: '50px',
          fontWeight: "300",
        };
    }
  };

  const getDoubtResolvedStyle = (resolved: any) => {
    switch (resolved) {
      case true:
      case "true":
        return {
          backgroundColor: themeMode === "dark" ? "#1e2c1e" : "#C8E6C9",
          width: '150px',
          p: '5px',
          textAlign: 'center',
          borderRadius: '50px',
          color: "#388E3C",
          fontWeight: "bold",
        };
      case false:
      case "false":
        return {
          backgroundColor: themeMode === "dark" ? "#3d2525" : "#FFCDD2",
          width: '150px',
          p: '5px',
          textAlign: 'center',
          borderRadius: '50px',
          color: "#D32F2F",
          fontWeight: "bold",
        };
      default:
        return {
          backgroundColor: "transparent",
          color: themeMode === "dark" ? "#fafafa" : "#181818",
          width: '150px',
          p: '5px',
          textAlign: 'center',
          borderRadius: '50px',
          fontWeight: "300",
        };
    }
  };

  const getConversationEfficiencyStyle = (efficiency: string) => {
    switch (efficiency) {
      case "efficient":
        return {
          backgroundColor: themeMode === "dark" ? "#1e2c1e" : "#C8E6C9", width: '150px', p: '5px', textAlign: 'center', borderRadius: '50px',
          color: "#388E3C",
          fontWeight: "bold",
        };
      case "somewhat_efficient":
        return {
          backgroundColor: themeMode === "dark" ? "#303030" : "#E0E0E0", width: '150px', p: '5px', textAlign: 'center', borderRadius: '50px',
          color: "#757575",
          fontWeight: "bold",
        };
      case "inefficient":
        return {
          backgroundColor: themeMode === "dark" ? "#3d2525" : "#FFCDD2", width: '150px', p: '5px', textAlign: 'center', borderRadius: '50px',
          color: "#D32F2F",
          fontWeight: "bold",
        };
      default:
        return {
          backgroundColor: "transparent",
          color: themeMode === "dark" ? "#fafafa" : "#181818", width: '150px', p: '5px', textAlign: 'center', borderRadius: '50px',
          fontWeight: "300",
        };
    }
  };

  const [openUpdatePlan, setOpenUpdatePlan] = useState(false);

  const handleOpenUpdatePlan = () => {
    setOpenUpdatePlan(true);
  };

  const handleCloseUpdatePlan = () => {
    setOpenUpdatePlan(false);
  };

  const navigate = useNavigate()

  const handleUpdatePlan = () => {
    navigate('/subscription')
    handleCloseUpdatePlan();
  };

  const isSubscriptionCanceled = user.subscriptionStatus === 'canceled';

  return (
    <SectionContainer
      title={t('conversationMessages.title') as string}
      text={t('conversationMessages.feedback') as string}
      icon={<FeedbackRoundedIcon />}
      onClick={() => { setOpen(true) }}
      individualChatbot
    >
      {loading ? (
        <Box sx={{ height: '80vh', backgroundColor: themeMode === "dark" ? '#121212' : '#fafafa', }}>
          <Box sx={{ display: 'flex', mx: 'auto', alignItems: 'center', justifyContent: 'center', mt: '100px' }}>
            <CircularProgress sx={{ color: themeMode === "dark" ? '#fafafa' : '#4a3aff' }} />
          </Box>
        </Box>
      ) : (
        <Stack flexDirection={isMobile ? 'column' : 'row'} justifyContent="center" alignItems="flex-start"
          gap={isMobile ? 2 : 4} sx={{
            maxWidth: '100%', mx: 'auto',
            backgroundColor: themeMode === "dark" ? '#121212' : '#fafafa',
            mb: isMobile ? '80px' : '0'
          }}
        >

          <Box maxWidth={!analysis ? "900px" : "600px"} sx={{ mx: 'auto', width: '100%', mt: isMobile ? '30px' : 0 }}>
            <ChatConversation messages={messages} />
          </Box>

          {analysis && (
            <Stack flexDirection="column" gap={2} sx={{ maxWidth: '90vw' }}>
              <Typography variant="h6" sx={{ fontWeight: '500', fontSize: '22px', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                {t('conversationMessages.analysisTitle')}
              </Typography>
              <Stack spacing={2} sx={{
                width: isMobile ? '100%' : '100%', p: 3, borderRadius: '20px',
                backgroundColor: themeMode === "dark" ? '#181818' : '#f5f5f5'
              }}>

                <Stack flexDirection="column" gap={1} sx={{ width: '200px' }}>
                  <Typography sx={{ color: themeMode === "dark" ? "#fafafa" : "#181818", fontWeight: '500' }}>
                    <strong>{t('conversationMessages.conversationTypeTitle')}:</strong>
                  </Typography>
                  <Typography sx={{ color: themeMode === "dark" ? '#fafafa' : '#181818', }}>
                    {t(`conversationMessages.conversationType.${analysis.conversationType}`)}
                  </Typography>
                </Stack>

                <Stack flexDirection="column" gap={1}>
                  <Typography sx={{ color: themeMode === "dark" ? "#fafafa" : "#181818", fontWeight: '500' }}>
                    <strong>{t('conversationMessages.mainUserIntentTitle')}:</strong>
                  </Typography>
                  <Typography sx={{ color: themeMode === "dark" ? '#fafafa' : '#181818', }}>
                    {analysis.mainUserIntent.charAt(0).toUpperCase() + analysis.mainUserIntent.slice(1)}
                  </Typography>
                </Stack>

                <Stack flexDirection="row" >
                  <Stack flexDirection="column" gap={1} sx={{ width: '200px' }}>
                    <Typography sx={{ color: themeMode === "dark" ? "#fafafa" : "#181818", fontWeight: '500' }}>
                      <strong>{t('conversationMessages.doubtResolvedTitle')}:</strong>
                    </Typography>
                    <Typography sx={{ ...getDoubtResolvedStyle(analysis.doubtResolved), }}>
                      {t(`conversationMessages.doubtResolved.${analysis.doubtResolved}`)}
                    </Typography>
                  </Stack>

                  <Stack flexDirection="column" gap={1} sx={{ width: '200px' }}>
                    <Typography sx={{ color: themeMode === "dark" ? "#fafafa" : "#181818", fontWeight: '500' }}>
                      <strong>{t('conversationMessages.conversationEfficiencyTitle')}:</strong>
                    </Typography>
                    <Typography sx={{ ...getConversationEfficiencyStyle(analysis.conversationEfficiency), }}>
                      {t(`conversationMessages.conversationEfficiency.${analysis.conversationEfficiency}`)}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack flexDirection="row" >
                  <Stack flexDirection="column" gap={1} sx={{ width: '200px' }}>
                    <Typography sx={{ color: themeMode === "dark" ? "#fafafa" : "#181818", fontWeight: '500' }}>
                      <strong>{t('conversationMessages.userSatisfactionTitle')}:</strong>
                    </Typography>
                    <Typography sx={{ ...getUserSatisfactionStyle(analysis.userSatisfaction), }}>
                      {t(`conversationMessages.userSatisfaction.${analysis.userSatisfaction}`)}
                    </Typography>
                  </Stack>

                  <Stack flexDirection="column" gap={1} sx={{ width: '200px' }}>
                    <Typography sx={{ color: themeMode === "dark" ? "#fafafa" : "#181818", fontWeight: '500' }}>
                      <strong>{t('conversationMessages.userSentimentTitle')}:</strong>
                    </Typography>
                    <Typography sx={{ ...getUserSentimentStyle(analysis.userSentiment), }}>
                      {t(`conversationMessages.userSentiment.${analysis.userSentiment}`)}
                    </Typography>
                  </Stack>
                </Stack>

                {analysis.topics && analysis.topics.length > 0 && (
                  <Box>
                    <Typography sx={{ fontWeight: 'bold', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                      {t('conversationMessages.topics')}:
                    </Typography>
                    <ul>
                      {analysis.topics.map((topic: string, index: number) => (
                        <li key={index} style={{ color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                          {topic.charAt(0).toUpperCase() + topic.slice(1)}
                        </li>
                      ))}
                    </ul>
                  </Box>
                )}
              </Stack>
            </Stack>

          )}

          {!analysis && (
            <Stack flexDirection="column" gap={2}>
              <Typography variant="h6" sx={{ fontWeight: '500', fontSize: '22px', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                {t('conversationMessages.analysisTitle')}
              </Typography>
              <Stack spacing={2} sx={{
                width: isMobile ? '100%' : '100%', p: 3, borderRadius: '20px',
                backgroundColor: themeMode === "dark" ? '#181818' : '#f5f5f5'
              }}
                justifyContent="center"
                alignItems="center"
              >
                <QueryStatsRoundedIcon sx={{ fontSize: '70px', color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1', mx: 'auto', }} />
                <Typography variant="body1" sx={{
                  mb: 2, color: themeMode === 'dark' ? '#fff' : '#181818',
                  fontFamily: 'Outfit', fontSize: '16px', fontWeight: '300',
                  textAlign: 'center'
                }}>
                  Aún no se ha generado un análisis para esta conversación. Haz clic en el botón de abajo para generar un análisis.
                </Typography>
                <Button
                  variant="contained"
                  onClick={handleAnalyzeConversation}
                  disabled={loadingAnalysis}
                  startIcon={loadingAnalysis && <CircularProgress size={25} />}
                  sx={{
                    width: '100%',
                    height: '50px',
                    backgroundColor: loadingAnalysis ? themeMode === 'dark' ? '' : '#e8e8e8' : '#4a3aff',
                    lineHeight: 1,
                    color: loadingAnalysis ? themeMode === 'dark' ? '#fff' : '#4c4c4c4d' : '#fff',
                    '&.Mui-disabled': {
                      backgroundColor: themeMode === "dark" ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
                      color: themeMode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
                    },
                    fontSize: isMobile ? '16px' : "16px",
                    fontWeight: "500",
                    textTransform: "none",
                    padding: "10px 20px",
                    borderRadius: '100px',
                    borderColor: "transparent",
                    borderWidth: 1,
                    borderStyle: 'solid',
                    fontFamily: 'Outfit',
                    '&:hover': {
                      borderColor: '#4a3aff',
                      color: themeMode === 'dark' ? "white" : '#181818',
                      backgroundColor: 'transparent',
                      borderWidth: 1,
                      borderStyle: 'solid',
                    },
                    '&:focus': {
                      outline: 'none',
                    },
                  }}
                >
                  {loadingAnalysis ? 'Analizando' : 'Generar análisis'}
                </Button>
              </Stack>
            </Stack>

          )}
        </Stack>
      )
      }

      <DialogCommon
        kTitle={t('conversationMessages.dialogTitle')}
        kMaxWidth="sm"
        kMaxHeight={feedbackSent ? '350px' : isMobile ? '80vh' : '650px'}
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kEnableBorderRadius={true}
        kBorderRadius={'30px'}
        kAlignBtn="center"
        kKeepBtnSize
        kTopDivider
        kDisableBtnSave={rating === 0 || feedbackComments === ""}
        kSlideTransition
        kOpenDialogLists={open}
        kOnClose={closeDialogModal}
        kOnSecondary={closeDialogModal}
        kSecondaryBtnText={(feedbackSent ? t('conversationMessages.close') : t('conversationMessages.cancel')) ?? ""}
        kPrimaryBtnText={(feedbackSent ? t('conversationMessages.continue') : t('conversationMessages.send')) ?? ""}
        kOnPrimary={handlePrimaryClick}
        kContent={
          feedbackSent ? (
            <Stack flexDirection="column" justifyContent="center" alignItems="center" gap={2}>
              <CheckCircleOutlineRoundedIcon sx={{ color: '#4a3aff', fontSize: '70px' }} />
              <Typography sx={{ fontFamily: 'Outfit', fontSize: '16px', fontWeight: '300', textAlign: 'center', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                {t('conversationMessages.thanksForFeedback')}
              </Typography>
            </Stack>
          ) : loadingFeedback ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', pt: 2 }}>
              <CircularProgress sx={{ color: themeMode === "dark" ? '#fafafa' : '#4a3aff' }} />
              <Typography sx={{ mt: 2, color: themeMode === "dark" ? '#fafafa' : '#181818', fontFamily: 'Outfit', fontSize: '16px', fontWeight: '300' }}>
                {t('conversationMessages.trainingAI')}
              </Typography>
            </Box>
          ) : (
            <Stack spacing={2} sx={{ width: '100%', padding: isMobile ? 0 : '16px' }}>
              {/* Rating */}
              <Typography sx={{ fontSize: '16px', fontFamily: 'Outfit', fontWeight: '300', pt: '20px', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                {t('conversationMessages.rateEffectiveness')}
              </Typography>
              <Rating
                value={rating}
                onChange={handleRatingChange}
                size="large"
                sx={{
                  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
                    color: themeMode === "dark" ? '' : '#cccccc',
                  }
                }}
              />

              {/* Pregunta sobre la comprensión */}
              <Typography sx={{ fontSize: '16px', fontFamily: 'Outfit', fontWeight: '300', pt: '20px', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                {t('conversationMessages.didUnderstand')}
              </Typography>
              <RadioGroup row value={understandingValue} onChange={handleUnderstandingChange}>
                <FormControlLabel
                  value="yes"
                  control={<Radio sx={{ color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1', '&.Mui-checked': { color: "#475BE8" }, }} />}
                  label={t('conversationMessages.yes')}
                  labelPlacement="end"
                  sx={{
                    color: themeMode === "dark" ? '#fafafa' : '#181818',
                  }}
                />
                <FormControlLabel
                  value="no"
                  control={<Radio sx={{ color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1', '&.Mui-checked': { color: "#475BE8" }, }} />}
                  label={t('conversationMessages.no')}
                  labelPlacement="end"
                  sx={{
                    color: themeMode === "dark" ? '#fafafa' : '#181818',
                  }}
                />
              </RadioGroup>

              {/* Comentarios específicos */}
              <Typography sx={{ fontSize: '16px', fontFamily: 'Outfit', fontWeight: '300', pt: '20px', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                {t('conversationMessages.specificComments')}
              </Typography>
              <TextField
                multiline
                rows={4}
                placeholder={t('conversationMessages.placeholderComments') as string}
                variant="outlined"
                fullWidth
                value={feedbackComments}
                onChange={handleCommentsChange}
                sx={{
                  borderColor: themeMode === "dark" ? '#fafafa' : '#cccccc',
                  '& .MuiInputBase-root': {
                    color: themeMode === "dark" ? '#fafafa' : '#181818',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: themeMode === "dark" ? '#fafafa' : '#cccccc',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: themeMode === "dark" ? '#ffffff' : '#4a3aff',
                  },
                  '& .MuiInputBase-inputMultiline': {
                    color: themeMode === "dark" ? '#fafafa' : '#181818',
                  }
                }}
              />

              <DialogCommon
                kTitle="Actualización de plan"
                kMaxWidth="sm"
                kMaxHeight={isMobile ? '380px' : '350px'}
                kFixedDialog={0}
                kFixedDialogPosition="fixed"
                kDisableBottomDivider
                kEnableBorderRadius={true}
                kBorderRadius={isMobile ? '20px' : '30px'}
                kAlignBtn="center"
                kKeepBtnSize
                kTopDivider
                kSlideTransition
                kOpenDialogLists={openUpdatePlan}
                kOnClose={handleCloseUpdatePlan}
                kOnPrimary={handleUpdatePlan}
                kOnSecondary={handleCloseUpdatePlan}
                kSecondaryBtnText={t('conversationList.cancel') as string}
                kPrimaryBtnText="Actualizar plan"
                kContent={
                  (
                    <Typography sx={{
                      fontWeight: '300', fontFamily: 'Outfit',
                      fontSize: isMobile ? '14px' : '18px', color: themeMode === "dark" ? "#fafafa" : "#181818"
                    }}>
                      ¡Estás a solo un clic de potenciar tus análisis! Actualiza ahora a nuestro plan Premium o Empresarial para acceder a la funcionalidad de análisis de conversaciones y otras características exclusivas. <br /> <br />Haz clic en "Actualizar plan" y empieza a transformar tu experiencia.
                    </Typography>
                  )
                }
              />
            </Stack>



          )
        }
      />

    </SectionContainer >
  );
}

export default ConversationMessages;
