// OpenAIApi.ts
const API_URL = "https://api.openai.com/v1/chat/completions";
const API_KEY = import.meta.env.VITE_APP_OPENAI_API_KEY

export async function fetchToOpenAI(apiRequestBody: any) {
  const response = await fetch(API_URL, {
    method: 'POST',
    headers: {
      "Authorization": "Bearer " + API_KEY,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(apiRequestBody)
  });

  if (!response.ok) {
    const errorBody = await response.text();
    throw new Error(`Network response was not ok: ${response.status} ${response.statusText}, Body: ${errorBody}`);
  }

  return response.json();
}
