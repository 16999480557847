import { DialogContent, DialogProps, useMediaQuery, useTheme, } from '@mui/material';
import { useContext, useState } from 'react';
import { ConfigContext } from '../../../../context/Config/ConfigContext';
import { DialogCommonContentModels } from './DialogContent.models';

function DialogCommonContent(props: DialogCommonContentModels) {
  const [scroll] = useState<DialogProps['scroll']>('paper');

  const { themeMode } = useContext(ConfigContext)

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <DialogContent
        dividers={props.kDisableBottomDivider ? false : scroll === 'paper'}
        sx={{
          position: 'relative',
          px: props.kContentPadding ? 0 : { xs: 4, sm: 10, md: 3 },
          py: props.kDisableContentPaddingInY ? 0 : 3,
          overflowY: props.KDisableScroll ? 'hidden' : 'auto',
          backgroundColor: themeMode === "dark" ? '#121212' : '#fff',
          borderRadius: props.kDisableActions ? isMobile ? 0 : '0 0 30px 30px' : 0,
          display: 'flex', alignItems: props.kAlignItemsStart ? 'start' : 'center', justifyContent: 'center', pb: 0,
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: themeMode === "dark" ? "#121212" : '#fafafa',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: themeMode !== "dark" ? 'lightgrey' : '#3A3A3A',
            borderRadius: '50px'
          }
        }}
      >
        {props.kContent}
      </DialogContent>
    </>
  );
}

export default DialogCommonContent;
