import {
  DialogActions,
  Button,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../../../context/Config/ConfigContext';
import { DialogCommonActionsModels } from './DialogActions.models';
import { useContext } from 'react';

function DialogCommonActions(props: DialogCommonActionsModels) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation('global');

  const { themeMode } = useContext(ConfigContext)

  const mainButtonStyles = {
    backgroundColor: props.kMainColor ? props.kMainColor : '#4a3aff',
    color: themeMode === "dark" ? '#fff' : '#fff',
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: 'Outfit',
    padding: "5px 10px",
    borderRadius: props.kIsChatModal ? '15px' : '100px',
    borderColor: "transparent",
    borderWidth: 1,
    borderStyle: 'solid',
    '&.Mui-disabled': {
      backgroundColor: themeMode === "dark" ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
      color: themeMode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
    },
    '&:hover': {
      color: themeMode === "dark" ? '#fff' : '#121212',
      borderColor: props.kMainColor ? props.kMainColor : '#4a3aff',
      borderWidth: 1,
      borderStyle: 'solid',
      backgroundColor: 'transparent',
    },
    '&:focus': {
      outline: 'none',
    },
  };

  const secondButtonStyles = {
    backgroundColor: 'transparent',
    color: themeMode === "dark" ? '#fff' : '#121212',
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: 'Outfit',
    padding: "5px 10px",
    borderRadius: props.kIsChatModal ? '15px' : '100px',
    borderColor: props.kMainColor ? props.kMainColor : '#4a3aff',
    borderWidth: 1,
    borderStyle: 'solid',
    '&:hover': {
      borderColor: props.kMainColor ? props.kMainColor : '#4a3aff',
      borderWidth: 1,
      borderStyle: 'solid',
    },
    '&:focus': {
      outline: 'none',
    },
  };

  return (
    <>
      <DialogActions
        sx={{
          p: 3,
          backgroundColor: themeMode === "dark" ? '#121212' : '#fff',
          borderRadius: isMobile ? '0 0 0 0' : '0px 0px 30px 30px'
        }}
      >
        <Stack
          direction="row"
          justifyContent={isMobile ? props.kAlignBtnAction : 'inherit'}
          alignItems="center"
          spacing={2}
          width="100%"
          sx={{
            Button: {
              height: { xs: props.kKeepBtnSize ? 48 : 44, sm: 48 },
              fontSize: { xs: props.kKeepBtnSize ? '1rem' : '0.875rem', sm: '1rem' },
              fontWeight: { xs: props.kKeepBtnSize ? '500' : '500', sm: '500' },
              textTransform: 'capitalize',
              minWidth: { xs: props.kKeepBtnSize ? '150px' : '120px', sm: '150px' },
              borderRadius: props.kIsChatModal ? '15px' : '100px',
            },
          }}
        >

          {props.kElementAction ? props.kElementAction : <></>}
          {props.kSecondaryDialogAction
            && (
              <Button
                className="btn-textTransform-none"
                disableElevation
                variant="outlined"
                onClick={props.kSecondaryDialogAction}
                sx={{
                  ...secondButtonStyles,
                  color: themeMode === "dark" ? 'white' : 'black'
                }}
              >
                {t(`${props.kSecondaryBtnTextAction}`)}
              </Button>
            )}
          {props.kPrimaryDialogAction
            && (
              <Button
                disabled={props.kDisableBtnSave}
                disableElevation
                variant="contained"
                onClick={props.kPrimaryDialogAction}
                sx={{
                  ...mainButtonStyles,
                }}
              >
                {t(`${props.kPrimaryBtnTextAction}`)}
              </Button>
            )}
          {props.kThirdDialogAction
            && (
              <Button
                disabled={props.kDisableBtnSave}
                className="btn-textTransform-none"
                disableElevation
                variant="contained"
                onClick={props.kThirdDialogAction}
                sx={{
                  ...secondButtonStyles,
                }}
              >
                {t(`${props.kThirdBtnTextAction}`)}
              </Button>
            )}
        </Stack>
      </DialogActions>
    </>
  );
}

DialogCommonActions.defaultProps = {
  kPrimaryBtnTextAction: 'global.save',
  kSecondaryBtnTextAction: 'global.cancel',
  kThirdBtnTextAction: 'global.continue',
  kSizeButtons: 'sm',
  kAlignBtnAction: 'flex-end',
};

export default DialogCommonActions;
