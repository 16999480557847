import { Box, Typography, useTheme, Stack, useMediaQuery } from "@mui/material";
import { useContext } from "react";
import { ConfigContext } from "../context/Config/ConfigContext";
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { useTranslation } from "react-i18next";

const StatBox = ({ title, subtitle, icon, changePercentage
  // progress, increase, series
}: any) => {

  const { t } = useTranslation('global');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { themeMode } = useContext(ConfigContext)

  return (
    <>
      <Box width="100%" m="0 20px">
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb="10px">
          <Typography sx={{
            color: themeMode === 'dark' ? 'white' : '#181818',
            fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', fontWeight: '300'
          }}>
            {subtitle}
          </Typography>
          {icon}
        </Stack>

        <Box display="flex" flexDirection="row" justifyContent="space-between" mb="10px">
          <Box>
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ color: themeMode === 'dark' ? 'white' : '#181818', fontFamily: 'Outfit', fontSize: '30px', fontWeight: '500' }}
            >
              {title}
            </Typography>
          </Box>
        </Box>
        {
          changePercentage !== null && (
            <Box display="flex" alignItems="center">
              {changePercentage > 0 ? (
                <ArrowUpwardRoundedIcon sx={{ fontSize: '14px', color: themeMode === 'dark' ? '#b3adff' : '#6e62ff' }} />
              ) : changePercentage < 0 ? (
                <ArrowDownwardRoundedIcon sx={{ fontSize: '14px', color: '#f44336' }} />
              ) : (
                // Icono opcional para cuando el porcentaje de cambio es 0
                <RemoveRoundedIcon sx={{ fontSize: '14px', color: '#757575' }} />
              )}
              <Typography
                variant="subtitle1"
                sx={{
                  color: changePercentage > 0 ? themeMode === 'dark' ? '#b3adff' : '#6e62ff' : changePercentage < 0 ? '#f44336' : '#757575',
                  fontFamily: 'Outfit',
                  fontSize: '12px',
                  fontWeight: '300',
                }}
              >
                {
                  changePercentage > 0 ? (
                    !isMobile ? t('dashboard.percentageChange.increase', { value: changePercentage.toFixed(2) }) : `${changePercentage.toFixed(2)}%`
                  ) : changePercentage < 0 ? (
                    !isMobile ? t('dashboard.percentageChange.decrease', { value: changePercentage.toFixed(2) }) : `${changePercentage.toFixed(2)}%`
                  ) : (
                    t('dashboard.percentageChange.noChange')
                  )
                }
              </Typography>
            </Box>
          )
        }

      </Box>
    </>

  );
};

export default StatBox;
