import React, { useState, useEffect, useContext } from 'react';
import {
  Box, Grid, Typography, useMediaQuery, useTheme, CircularProgress, Stack,
  IconButton, Menu, MenuItem
} from '@mui/material';
import SectionContainer from '../../global/SectionContainer';
import { ConfigContext } from '../../context/Config/ConfigContext';
import { db, auth } from '../../services/firebase/firebase';
import { collection, addDoc, getDocs, query, orderBy, limit, updateDoc, doc, where, deleteDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import FlipMove from 'react-flip-move';
import DialogCommon from '../../components/DialogCommon/DialogCommon';
import { showToast } from '../../components/Toast/toast';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import SalesMessageGenerator from '../SalesMessageGenerator/SalesMessageGenerator';
import ArrowOutwardRoundedIcon from '@mui/icons-material/ArrowOutwardRounded';

interface Prospect {
  id: string;
  companyName: string;
  companyDescription: string;
  contactMethod: string;
  contactPerson: string;
  salesAgent: string;
  generatedMessage: string;
  status: string;
  contactUrl?: string;
  createdAt: any;
}

const ProspectsList = () => {
  const { themeMode } = useContext(ConfigContext);
  const [user] = useAuthState(auth);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [prospects, setProspects] = useState<Prospect[]>([]);
  const [loading, setLoading] = useState(false);
  const [openGenerator, setOpenGenerator] = useState(false);
  const [selectedProspect, setSelectedProspect] = useState<Prospect | null>(null);
  const [refreshData, setRefreshData] = useState(false);

  // Estado para el menú de acciones
  const [anchorEls, setAnchorEls] = useState<{ [key: string]: HTMLElement | null }>({});

  useEffect(() => {
    if (user) {
      fetchProspects();
    }
  }, [user, refreshData]);

  const fetchProspects = async () => {
    setLoading(true);
    if (user) {
      try {
        const prospectsRef = collection(db, 'prospects');
        const q = query(
          prospectsRef,
          where('salesAgent', '==', user.uid),
          orderBy('createdAt', 'desc'),
          limit(50)
        );
        const querySnapshot = await getDocs(q);
        const prospectsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Prospect));
        setProspects(prospectsData);
      } catch (error) {
        showToast('error', 'Error al obtener los prospectos', themeMode);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleOpenGenerator = () => {
    setSelectedProspect(null);
    setOpenGenerator(true);
  };

  const handleCloseGenerator = () => {
    setOpenGenerator(false);
    setSelectedProspect(null);
  };

  const handleSaveProspect = async (prospectData: any) => {
    if (user) {
      try {
        const prospectsRef = collection(db, 'prospects');
        await addDoc(prospectsRef, {
          ...prospectData,
          status: 'Mensaje Enviado',
          createdAt: new Date(),
          salesAgent: user.uid,
        });
        showToast('success', 'Prospecto guardado exitosamente', themeMode);
        setRefreshData(!refreshData);
      } catch (error) {
        showToast('error', 'Error al guardar el prospecto', themeMode);
      }
    }
  };


  const updateProspectStatus = async (prospectId: string, newStatus: string) => {
    if (user) {
      try {
        const prospectDocRef = doc(db, 'prospects', prospectId);
        await updateDoc(prospectDocRef, {
          status: newStatus,
        });
        showToast('success', 'Estado actualizado', themeMode);
        setRefreshData(!refreshData);
      } catch (error) {
        showToast('error', 'Error al actualizar el estado', themeMode);
      }
    }
  };

  const handleDeleteProspect = async (prospectId: string) => {
    if (user) {
      try {
        const prospectDocRef = doc(db, 'prospects', prospectId);
        await deleteDoc(prospectDocRef);
        showToast('success', 'Prospecto eliminado', themeMode);
        setRefreshData(!refreshData);
      } catch (error) {
        showToast('error', 'Error al eliminar el prospecto', themeMode);
      }
    }
    handleMenuClose(prospectId);
  };

  // Manejo del menú de acciones
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, prospectId: string) => {
    setAnchorEls(prev => ({ ...prev, [prospectId]: event.currentTarget }));
  };

  const handleMenuClose = (prospectId: string) => {
    setAnchorEls(prev => ({ ...prev, [prospectId]: null }));
  };

  // Función para aplicar estilos según el estado
  const getStyleByState = (state: string) => {
    switch (state) {
      case "Cliente Adquirido":
        return {
          backgroundColor: themeMode === "dark" ? "#1e2c1e" : "#C8E6C9", // Light green
          color: "#388E3C", // Dark green
          fontWeight: "bold",
        };
      case "No Interesado":
        return {
          backgroundColor: themeMode === "dark" ? "#2c1d1e" : "#FFCDD2", // Light red
          color: "#D32F2F", // Dark red
          fontWeight: "bold",
        };
      case "Prueba Iniciada":
        return {
          backgroundColor: themeMode === "dark" ? "#4e4c3c" : "#FFF9C4", // Light yellow
          color: "#FBC02D", // Dark yellow
          fontWeight: "bold",
        };
      case "Interesado":
        return {
          backgroundColor: themeMode === "dark" ? "#092c64" : "#BBDEFB", // Dark blue for dark mode, light blue for light mode
          color: themeMode === "dark" ? "#BBDEFB" : "#0D47A1", // Light blue for dark mode, dark blue for light mode
          fontWeight: "bold",
        };
      default:
        return {
          backgroundColor: "transparent",
          color: themeMode === "dark" ? "#B0BEC5" : "#757575", // Gray for both modes
          ontWeight: "bold",
        };
    }
  };


  const formatDateWithTime = (timestamp: any) => {
    if (!timestamp) return '-';

    const conversationDate = timestamp.toDate();
    const now = new Date();
    const currentYear = now.getFullYear();
    const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);

    const hours = conversationDate.getHours().toString().padStart(2, '0');
    const minutes = conversationDate.getMinutes().toString().padStart(2, '0');
    const timeString = `${hours}:${minutes}`;

    const isToday = conversationDate.getDate() === now.getDate() &&
      conversationDate.getMonth() === now.getMonth() &&
      conversationDate.getFullYear() === now.getFullYear();

    const isYesterday = conversationDate.getDate() === yesterday.getDate() &&
      conversationDate.getMonth() === yesterday.getMonth() &&
      conversationDate.getFullYear() === yesterday.getFullYear();

    const diff = now.getTime() - conversationDate.getTime();
    const diffInHours = diff / (1000 * 60 * 60);

    const dateOptions = {
      day: 'numeric',
      month: 'long',
      ...(conversationDate.getFullYear() !== currentYear && { year: 'numeric' })
    };

    if (diffInHours < 1) {
      return <span>Ahora<br />{timeString}</span>;
    } else if (isToday) {
      return <span>Hoy<br />{timeString}</span>;
    } else if (isYesterday) {
      return <span>Ayer<br />{timeString}</span>;
    } else {
      return (
        <>
          {conversationDate.toLocaleDateString('es-ES', dateOptions)}
          <br />
          {timeString}
        </>
      );
    }
  };

  const statusOptions = ['Mensaje Enviado', 'Interesado', 'Prueba Iniciada', 'Cliente Adquirido', 'No Interesado'];

  return (
    <SectionContainer
      title="Lista de Prospectos"
      text="Capturar Prospecto"
      icon={<AutoAwesomeRoundedIcon />}
      onClick={handleOpenGenerator}
    >
      <Box sx={{ backgroundColor: themeMode === "dark" ? '#121212' : '#fafafa', mb: isMobile ? '60px' : 0 }}>
        {!isMobile && prospects.length > 0 && (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              color: '#fff', borderRadius: '15px', px: 1,
            }}
          >
            <Grid item xs={2}>
              <Typography sx={{ fontFamily: 'Outfit', fontSize: '18px', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818' }} variant="h6">
                Empresa
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontFamily: 'Outfit', fontSize: '18px', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818' }} variant="h6">
                Contacto
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontFamily: 'Outfit', fontSize: '18px', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818' }} variant="h6">
                Método
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontFamily: 'Outfit', fontSize: '18px', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818' }} variant="h6">
                Estado
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontFamily: 'Outfit', fontSize: '18px', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818' }} variant="h6">
                Creado
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontFamily: 'Outfit', fontSize: '18px', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818' }} variant="h6">
                Acciones
              </Typography>
            </Grid>
          </Grid>
        )}

        {
          !loading ? (
            <Box sx={{ height: 'auto', mb: '40px' }}>
              <FlipMove>
                {prospects && prospects.length > 0 ? (
                  prospects.map((prospect: Prospect) => (
                    <Grid
                      key={prospect.id}
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems={"center"}
                      sx={{
                        py: 1,
                        px: 2,
                        marginTop: '10px',
                        borderRadius: '25px',
                        height: '100px',
                        backgroundColor: themeMode === "dark" ? '#181818' : '#fff',
                        boxShadow: themeMode === "dark"
                          ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                          : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                      }}
                    >
                      {!isMobile ? (
                        <>
                          <Grid item xs={2}>
                            <Typography sx={{ fontFamily: 'Outfit', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818', fontWeight: '300' }} variant="body1">
                              {prospect.companyName}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography sx={{ fontFamily: 'Outfit', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818', fontWeight: '300' }} variant="body1">
                              {prospect.contactPerson || 'N/A'}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography sx={{ fontFamily: 'Outfit', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818', fontWeight: '300' }} variant="body1">
                              {prospect.contactMethod}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <select
                              value={prospect.status}
                              onChange={(e) => updateProspectStatus(prospect.id, e.target.value)}
                              style={{
                                ...getStyleByState(prospect.status),
                                borderRadius: '105px',
                                padding: '5px 10px',
                                fontFamily: 'Outfit',
                                fontSize: '14px',
                                outline: 'none',
                                marginLeft: 'auto', marginRight: 'auto',
                                textAlign: 'center',
                                width: '100%'
                              }}
                            >
                              {statusOptions.map(status => (
                                <option key={status} value={status}>{status}</option>
                              ))}
                            </select>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography sx={{ fontFamily: 'Outfit', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818', fontWeight: '300' }} variant="body1">
                              {formatDateWithTime(prospect.createdAt)}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Box sx={{ textAlign: 'center' }}>
                              <IconButton
                                onClick={(event) => handleMenuOpen(event, prospect.id)}
                                sx={{
                                  '&:focus': { outline: 'none' },
                                  '&:hover': { backgroundColor: themeMode === "dark" ? '#212121' : '#fafafa' }
                                }}
                              >
                                <MoreVertRoundedIcon sx={{ color: themeMode === "dark" ? '#fff' : '#c1c1c1' }} />
                              </IconButton>
                              <Menu
                                anchorEl={anchorEls[prospect.id]}
                                open={Boolean(anchorEls[prospect.id])}
                                onClose={() => handleMenuClose(prospect.id)}
                                PaperProps={{
                                  style: {
                                    backgroundColor: themeMode === "dark" ? '#000' : '#fff',
                                    color: themeMode === "dark" ? '#fff' : '#181818',
                                    borderRadius: '20px',
                                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                  },
                                }}
                              >
                                <MenuItem
                                  sx={{ fontSize: '16px', '&:hover': { backgroundColor: themeMode === "dark" ? '#232323' : '#fafafa' } }}
                                  onClick={() => {
                                    setSelectedProspect(prospect);
                                    setOpenGenerator(true);
                                    handleMenuClose(prospect.id);
                                  }}
                                >
                                  <RemoveRedEyeRoundedIcon sx={{ color: themeMode === "dark" ? '#fff' : '#c1c1c1', marginRight: '8px', fontSize: '20px' }} />
                                  Ver Mensaje
                                </MenuItem>

                                {prospect.contactUrl && (
                                  <MenuItem
                                    component="a"
                                    href={prospect.contactUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{ fontSize: '16px', '&:hover': { backgroundColor: themeMode === "dark" ? '#232323' : '#fafafa' } }}
                                  >
                                    <ArrowOutwardRoundedIcon sx={{ color: themeMode === "dark" ? '#fff' : '#c1c1c1', marginRight: '8px', fontSize: '20px' }} />
                                    Visitar Contacto
                                  </MenuItem>
                                )}

                                <MenuItem
                                  sx={{ fontSize: '16px', '&:hover': { backgroundColor: themeMode === "dark" ? '#232323' : '#fafafa' } }}
                                  onClick={() => handleDeleteProspect(prospect.id)}
                                >
                                  <DeleteRoundedIcon sx={{ color: themeMode === "dark" ? '#fff' : '#c1c1c1', marginRight: '8px', fontSize: '20px' }} />
                                  Eliminar
                                </MenuItem>
                              </Menu>

                            </Box>
                          </Grid>
                        </>
                      ) : (
                        // Versión móvil (puedes adaptar según tus necesidades)
                        <>
                          <Grid item xs={6}>
                            <Stack flexDirection="column" justifyContent="start" alignItems="start" gap="3px">
                              <Typography sx={{ fontSize: '14px', textAlign: 'left', color: themeMode === "dark" ? '#fff' : '#181818' }}>
                                {prospect.companyName}
                              </Typography>
                              <Typography sx={{ fontSize: '14px', textAlign: 'left', color: themeMode === "dark" ? '#fff' : '#181818' }}>
                                {prospect.contactPerson || 'N/A'}
                              </Typography>
                              <Typography sx={{ fontSize: '14px', textAlign: 'left', color: themeMode === "dark" ? '#fff' : '#181818' }}>
                                {prospect.contactMethod}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={6} alignItems="end">
                            <Stack flexDirection="column" justifyContent="space-between" alignItems="end" gap="6px">
                              <select
                                value={prospect.status}
                                onChange={(e) => updateProspectStatus(prospect.id, e.target.value)}
                                style={{
                                  ...getStyleByState(prospect.status),
                                  borderRadius: '105px',
                                  padding: '5px',
                                  fontFamily: 'Outfit',
                                  fontSize: '14px',
                                }}
                              >
                                {statusOptions.map(status => (
                                  <option key={status} value={status}>{status}</option>
                                ))}
                              </select>
                              <IconButton
                                onClick={(event) => handleMenuOpen(event, prospect.id)}
                                sx={{
                                  '&:focus': { outline: 'none' },
                                  '&:hover': { backgroundColor: themeMode === "dark" ? '#212121' : '#fafafa' }
                                }}
                              >
                                <MoreVertRoundedIcon sx={{ color: themeMode === "dark" ? '#fff' : '#c1c1c1' }} />
                              </IconButton>
                              <Menu
                                anchorEl={anchorEls[prospect.id]}
                                open={Boolean(anchorEls[prospect.id])}
                                onClose={() => handleMenuClose(prospect.id)}
                                PaperProps={{
                                  style: {
                                    backgroundColor: themeMode === "dark" ? '#000' : '#fff',
                                    color: themeMode === "dark" ? '#fff' : '#181818',
                                    borderRadius: '20px',
                                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                  },
                                }}
                              >
                                <MenuItem
                                  sx={{ fontSize: '16px', '&:hover': { backgroundColor: themeMode === "dark" ? '#232323' : '#fafafa' } }}
                                  onClick={() => {
                                    setSelectedProspect(prospect);
                                    setOpenGenerator(true);
                                    handleMenuClose(prospect.id);
                                  }}
                                >
                                  <RemoveRedEyeRoundedIcon sx={{ color: themeMode === "dark" ? '#fff' : '#c1c1c1', marginRight: '8px', fontSize: '20px' }} />
                                  Ver Mensaje
                                </MenuItem>

                                {prospect.contactUrl && (
                                  <MenuItem
                                    component="a"
                                    href={prospect.contactUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{ fontSize: '16px', '&:hover': { backgroundColor: themeMode === "dark" ? '#232323' : '#fafafa' } }}
                                  >
                                    <ArrowOutwardRoundedIcon sx={{ color: themeMode === "dark" ? '#fff' : '#c1c1c1', marginRight: '8px', fontSize: '20px' }} />
                                    Visitar Contacto
                                  </MenuItem>
                                )}

                                <MenuItem
                                  sx={{ fontSize: '16px', '&:hover': { backgroundColor: themeMode === "dark" ? '#232323' : '#fafafa' } }}
                                  onClick={() => handleDeleteProspect(prospect.id)}
                                >
                                  <DeleteRoundedIcon sx={{ color: themeMode === "dark" ? '#fff' : '#c1c1c1', marginRight: '8px', fontSize: '20px' }} />
                                  Eliminar
                                </MenuItem>
                              </Menu>
                            </Stack>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  ))
                ) : (
                  <Stack flexDirection="column" justifyContent="center" alignItems="center" gap={2} sx={{ mt: '100px' }}>
                    <AutoAwesomeRoundedIcon sx={{ fontSize: '60px', color: '#c1c1c1' }} />
                    <Box sx={{ textAlign: 'center', }}>
                      <Typography align="center" sx={{
                        fontSize: '18px', fontFamily: 'Outfit', fontWeight: '300',
                        color: '#c1c1c1'
                      }}>
                        No hay prospectos aún
                      </Typography>
                    </Box>
                  </Stack>
                )}
              </FlipMove>

            </Box>

          ) : (
            <Box sx={{ display: 'flex', mx: 'auto', alignItems: "center", justifyContent: 'center', mt: '100px' }}>
              <CircularProgress sx={{ color: themeMode === "dark" ? '#fafafa' : '#4a3aff' }} />
            </Box>
          )
        }

        {/* Modal con SalesMessageGenerator */}
        <DialogCommon
          kTitle="Generar Mensaje de Venta"
          kMaxWidth="md"
          kMaxHeight={isMobile ? '100vh' : '750px'}
          kFixedDialog={0}
          kFixedDialogPosition="fixed"
          kDisableBottomDivider
          kEnableBorderRadius={true}
          kBorderRadius={isMobile ? '20px' : '30px'}
          kAlignBtn="center"
          kKeepBtnSize
          kTopDivider
          kSlideTransition
          kAlignItemsStart
          kOpenDialogLists={openGenerator}
          kOnClose={handleCloseGenerator}
          kDisableActions
          kContent={
            <SalesMessageGenerator
              existingProspect={selectedProspect}
              onSave={handleSaveProspect}
            />
          }
        />
      </Box>
    </SectionContainer>
  );
};

export default ProspectsList;
