import { Typography } from '@mui/material';

const PrivacyPolicyES = ({ companyName, isMobile, themeMode }: any) => {
  return (
    <div style={{ overflowY: 'auto', maxHeight: '100%' }}>
      {/* <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        Política de Privacidad para interacciones con el chatbot
      </Typography> */}
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        Fecha de Entrada en Vigor: 28 de febrero del 2024
      </Typography>

      {/* Introducción */}
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        1. Introducción
      </Typography>
      <Typography paragraph style={{ fontWeight: '300', fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "white" : 'black' }}>
        Bienvenido a {companyName}. En {companyName}, nuestro compromiso con su privacidad y la seguridad de su información personal es fundamental. Operamos con la máxima transparencia y nos aseguramos de proteger los datos que compartes con nosotros. Esta Política de Privacidad se aplica exclusivamente a su interacción con nuestro chatbot, facilitado por nuestro socio tecnológico, Fastalk. A lo largo de esta política, detallaremos meticulosamente cómo recopilamos, procesamos, compartimos y protegemos su información personal durante su interacción con nuestro chatbot. Nuestra meta es garantizar que comprenda completamente nuestras prácticas de privacidad y se sienta seguro al utilizar nuestros servicios.
      </Typography>

      {/* Aceptación de la Política de Privacidad */}
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        2. Aceptación de la Política de Privacidad
      </Typography>
      <Typography paragraph style={{ fontWeight: '300', fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "white" : 'black' }}>
        Al acceder y utilizar nuestro chatbot, usted reconoce que ha leído, entendido y aceptado las prácticas descritas en esta Política de Privacidad. Este consentimiento abarca nuestra recopilación, uso y divulgación de su información personal según lo detallado aquí. Si, por cualquier motivo, usted no está de acuerdo con alguna parte de esta política, le instamos encarecidamente a que se abstenga de utilizar nuestro chatbot. Su privacidad es nuestra prioridad, y estamos comprometidos a respetar sus preferencias.
      </Typography>

      {/* Información que Recopilamos */}
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        3. Información que Recopilamos
      </Typography>
      <Typography paragraph style={{ fontWeight: '300', fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "white" : 'black' }}>
        Recopilamos información que nos ayuda a proporcionarle una experiencia personalizada y a mejorar nuestros servicios. Esto incluye información de identificación personal que usted nos proporciona voluntariamente, información generada a través de su interacción con nuestro chatbot, y datos técnicos relativos a su uso de nuestros servicios.
      </Typography>

      {/* Uso de su Información */}
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        4. Uso de su Información
      </Typography>
      <Typography paragraph style={{ fontWeight: '300', fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "white" : 'black' }}>
        Utilizamos su información para operar, mantener y mejorar nuestros servicios, responder a sus preguntas y solicitudes, personalizar su experiencia y comunicarnos con usted sobre servicios importantes. También podemos usar su información para fines analíticos y de mercado.
      </Typography>

      {/* Compartición de Información */}
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        5. Compartición de Información
      </Typography>
      <Typography paragraph style={{ fontWeight: '300', fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "white" : 'black' }}>
        Solo compartimos su información con terceros en circunstancias específicas, como con proveedores de servicios que nos ayudan a operar nuestro chatbot, cuando es requerido por la ley, o para proteger nuestros derechos y seguridad. Siempre buscamos asegurar que sus datos sean tratados con el mismo nivel de seguridad y confidencialidad que aplicamos nosotros mismos.
      </Typography>

      {/* Seguridad de sus Datos */}
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        6. Seguridad de sus Datos
      </Typography>
      <Typography paragraph style={{ fontWeight: '300', fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "white" : 'black' }}>
        Tomamos medidas significativas para proteger sus datos personales contra el acceso no autorizado, el uso indebido o la divulgación. Implementamos tecnologías y políticas de seguridad avanzadas para garantizar la seguridad de su información.
      </Typography>

      {/* Sus Derechos */}
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        7. Sus Derechos
      </Typography>
      <Typography paragraph style={{ fontWeight: '300', fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "white" : 'black' }}>
        Usted tiene derechos significativos sobre su información personal, incluyendo el derecho a acceder, corregir, eliminar su información, o restringir su uso. Si desea ejercer estos derechos, por favor contáctenos.
      </Typography>

      {/* Transferencias Internacionales de Datos */}
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        8. Transferencias Internacionales de Datos
      </Typography>
      <Typography paragraph style={{ fontWeight: '300', fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "white" : 'black' }}>
        Sus datos personales pueden ser transferidos y procesados en países diferentes al suyo. Nos aseguramos de que estas transferencias cumplan con la legislación aplicable y proporcionen un nivel adecuado de protección de la privacidad.
      </Typography>

      {/* Política de Cookies */}
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        9. Política de Cookies
      </Typography>
      <Typography paragraph style={{ fontWeight: '300', fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "white" : 'black' }}>
        Nuestro chatbot y sitios web asociados utilizan cookies para mejorar su experiencia de usuario, analizar el tráfico y servir publicidad dirigida. Usted tiene control sobre el uso de cookies a través de la configuración de su navegador.
      </Typography>

      {/* Edad Mínima */}
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        10. Edad Mínima
      </Typography>
      <Typography paragraph style={{ fontWeight: '300', fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "white" : 'black' }}>
        No recopilamos conscientemente información personal de menores sin el consentimiento de los padres o tutores legales. Si usted es menor de edad, por favor no utilice nuestro chatbot sin dicho consentimiento.
      </Typography>

      {/* Enlaces a Terceros */}
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        11. Enlaces a Terceros
      </Typography>
      <Typography paragraph style={{ fontWeight: '300', fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "white" : 'black' }}>
        Nuestro chatbot puede contener enlaces a sitios web de terceros. No somos responsables de las prácticas de privacidad de esos sitios. Le alentamos a que se familiarice con las políticas de privacidad de cualquier sitio web de terceros que visite.
      </Typography>

      {/* Actualizaciones de la Política */}
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        12. Actualizaciones de la Política
      </Typography>
      <Typography paragraph style={{ fontWeight: '300', fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "white" : 'black' }}>
        Nos reservamos el derecho de modificar esta política de privacidad en cualquier momento. Cualquier cambio será efectivo inmediatamente después de su publicación en nuestro sitio web o comunicación a través de nuestro chatbot. Su uso continuado de nuestros servicios después de tales cambios implica su aceptación de la nueva política de privacidad.
      </Typography>

      {/* Contacto */}
      {/* <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        13. Contacto
      </Typography>
      <Typography paragraph style={{ fontWeight: '300', fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "white" : 'black' }}>
        Si tiene preguntas, comentarios o preocupaciones sobre nuestra Política de Privacidad o prácticas de procesamiento de datos, o si desea ejercer alguno de sus derechos como sujeto de datos, por favor póngase en contacto con nosotros en:
        <br />
        [Información de Contacto]
      </Typography> */}
    </div>
  );
};

export default PrivacyPolicyES;