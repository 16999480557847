/* eslint-disable no-debugger */
import { Box, Button, Drawer, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import whiteLogo from "../assets/whiteLogo.png";
import blackLogo from "../assets/mainLogo.png";
import { useNavigate, useLocation } from "react-router-dom";
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { cloneElement, useContext } from "react";
import StoreContext from "../context/Store/storeContext";
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import CodeRoundedIcon from '@mui/icons-material/CodeRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import WebhookIcon from '@mui/icons-material/Webhook';
import ImageRoundedIcon from '@mui/icons-material/ImageRounded';
import WidgetsRoundedIcon from '@mui/icons-material/WidgetsRounded';
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../context/Config/ConfigContext";

export default function Sidebar({ isSidebarOpenFromMobile, setIsSidebarOpenFromMobile }: any) {

  const { t } = useTranslation('global')

  const navigate = useNavigate();
  const location = useLocation();

  const theme = useTheme();
  const { themeMode } = useContext(ConfigContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Accede al estado global para obtener el rol del usuario
  const { toggleChatbot } = useContext(StoreContext);

  const menuItems: { [key: string]: { text: string; icon: JSX.Element; path?: string; action?: () => void }[] } = {
    [t('sidebar.general')]: [
      {
        text: t('sidebar.dashboard'),
        icon: <DashboardRoundedIcon />,
        path: "/dashboard",
      },
      {
        text: t('sidebar.myChatbots'),
        icon: <ChatRoundedIcon />,
        path: "/my-bots",
      },
      {
        text: t('sidebar.conversations'),
        icon: <QuestionAnswerRoundedIcon />,
        path: "/conversations",
      },
    ],
    [t('sidebar.integrations')]: [
      {
        text: t('sidebar.media'),
        icon: <ImageRoundedIcon />,
        path: "/media",
      },
      {
        text: t('sidebar.platforms'),
        icon: <WidgetsRoundedIcon />,
        path: "/platforms",
      },
      {
        text: t('sidebar.functions'),
        icon: <CodeRoundedIcon />,
        path: "/functions",
      },
      {
        text: t('sidebar.webhook'),
        icon: <WebhookIcon />,
        path: "/webhook",
      },
      {
        text: t('sidebar.socialMedia'),
        icon: <PeopleRoundedIcon />,
        path: "/social-media",
      },
    ],
    [t('sidebar.helpSupport')]: [
      {
        text: t('sidebar.documentation'),
        icon: <ArticleRoundedIcon />,
        path: "/documentation",
      },
      {
        text: t('sidebar.support'),
        icon: <SupportAgentIcon />,
        action: toggleChatbot,
      },
    ],
  };

  return (
    <Drawer
      variant={isMobile ? "temporary" : "permanent"}
      open={isMobile ? isSidebarOpenFromMobile : true}
      anchor="left"
      onClose={() => setIsSidebarOpenFromMobile(false)}
      sx={{
        width: "240px",
        flexShrink: 0,
        border: 'none',
        "& .MuiDrawer-paper": {
          width: "240px",
          boxSizing: "border-box",
          bgcolor: themeMode === 'dark' ? '#121212' : '#fff',
          overflowX: "hidden",
          border: 'none',
          borderRight: themeMode === "dark" ? '1px solid #181818' : '1px solid #fafafa',
        },
      }}
    >
      <Stack flexDirection="column" justifyContent="space-between" alignItems="center"
        sx={{ height: '100vh', backgroundColor: themeMode === 'dark' ? '#121212' : '#fff' }}
      >
        <Box sx={{ width: "100%", pt: "10px", px: "10px" }}>
          <Stack flexDirection="row"
            justifyContent="center" alignItems="center"
            sx={{ pt: "10px" }}
          >
            <Box sx={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
              <img
                src={themeMode === 'dark' ? whiteLogo : blackLogo}
                alt="logo"
                height={isMobile ? "35px" : '45px'}
                style={{ transition: 'height 0.25s ease' }}
              />
            </Box>
            {
              isMobile && (
                <Box sx={{ ml: '20px' }}>
                  <IconButton
                    sx={{ '&:focus': { outline: 'none' } }}
                    onClick={() => setIsSidebarOpenFromMobile(false)}
                  >
                    <ArrowBackIosNewRoundedIcon sx={{ fontSize: '22px' }} />
                  </IconButton>
                </Box>
              )
            }
          </Stack>
          <Box
            sx={{
              mt: '10px',
              overflowY: 'auto',
              scrollbarWidth: 'thin',
              scrollbarColor: 'transparent transparent',
              '&:hover': {
                scrollbarColor: 'rgba(211, 211, 211, 0.5) transparent',
              },
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'transparent',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent',
              },
              '&:hover::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(211, 211, 211, 0.5)',
                borderRadius: '50px',
              },
              msOverflowStyle: 'none',
            }}
          >
            <Stack spacing={1} sx={{ px: isSidebarOpenFromMobile ? '15px' : '5px' }}>
              {Object.entries(menuItems).map(([section, items]) => (
                <Box key={section}>
                  <Typography variant="body1" sx={{
                    color: themeMode === 'dark' ? '#9f9f9f' : '#9f9f9f',
                    mt: '10px', mb: '10px',
                    ml: '15px',
                    textAlign: 'left',
                    fontFamily: 'Outfit', fontSize: '14px'
                  }}>
                    {section}
                  </Typography>
                  {items.map((item, index) => (
                    <Button
                      key={index}
                      variant="contained"
                      disableElevation
                      onClick={() => { item.path ? navigate(item.path) : item.action && item.action(); setIsSidebarOpenFromMobile(false); }}
                      startIcon={cloneElement(item.icon, {
                        sx: {
                          color: item.path === location.pathname ? '#ffffff' : (themeMode === 'dark' ? '#fafafa' : '#4a3aff'),

                        },
                      })}
                      sx={{
                        p: "14px 20px",
                        borderRadius: "100px",
                        width: "100%",
                        justifyContent: "flex-start",
                        textTransform: "none",
                        color: item.path === location.pathname ? '#ffffff' : (themeMode === 'dark' ? "white" : '#181818'),
                        backgroundColor: item.path === location.pathname ? '#4a3aff' : "transparent",
                        "&:hover": {
                          backgroundColor: item.path === location.pathname ? theme.palette.primary.main : (themeMode === 'dark' ? "#181818" : "#fafafa"),
                        },
                        "&:focus": {
                          outline: "none",
                          backgroundColor: theme.palette.primary.main,
                        },
                        my: '2px'
                      }}
                    >
                      <Typography variant="body1"
                        sx={{ ml: '10px', fontFamily: 'Outfit', fontSize: '14px', textAlign: 'left' }}>
                        {item.text}
                      </Typography>
                    </Button>
                  ))}
                </Box>
              ))}
            </Stack>
          </Box>
        </Box>
      </Stack>
    </Drawer>
  );
}
