import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../context/Config/ConfigContext';
import StoreContext from '../../context/Store/storeContext';
import {
  Box,
  Stack,
  Typography,
  ThemeProvider,
  createTheme,
  CircularProgress,
  TextField,
  Select,
  MenuItem,
  Grid,
  FormControl,
  IconButton, useMediaQuery,
  useTheme
} from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SectionContainer from '../../global/SectionContainer';
import DialogCommon from '../../components/DialogCommon/DialogCommon';
import { collection, addDoc, query, where, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../../services/firebase/firebase';
import { showToast } from '../../components/Toast/toast';
import Groups2RoundedIcon from '@mui/icons-material/Groups2Rounded';

function SocialMedia() {
  const { t } = useTranslation('global');
  const { themeMode } = useContext(ConfigContext);
  const { user, chatbots } = useContext(StoreContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [selectedPlatform, setSelectedPlatform] = useState<'whatsapp' | 'instagram' | 'messenger' | null>(null);
  const [integrationData, setIntegrationData] = useState({
    accessToken: '',
    pageId: '',
    chatbotId: '',
  });
  const [socialMediaIntegrations, setSocialMediaIntegrations] = useState<any[]>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedIntegrationToDelete, setSelectedIntegrationToDelete] = useState<any>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedIntegrationToEdit, setSelectedIntegrationToEdit] = useState<any>(null);
  const [activateDialogOpen, setActivateDialogOpen] = useState(false);
  const [selectedIntegrationToActivate, setSelectedIntegrationToActivate] = useState<any>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [loadingIntegrations, setLoadingIntegrations] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setIntegrationData({
      accessToken: '',
      pageId: '',
      chatbotId: '',
    });
    setTimeout(() => {
      setSelectedPlatform(null);
    }, 200);
  };

  const handleInputChange = (field: string, value: string) => {
    setIntegrationData(prev => ({ ...prev, [field]: value }));
  };

  const handleSaveIntegration = async () => {
    if (!integrationData.accessToken || !integrationData.pageId || !integrationData.chatbotId) return;

    setIsLoading(true);
    setLoadingMessage(t('socialMediaPage.savingIntegration') as string);

    try {
      const verificationToken = generateVerificationToken();

      // Construir webhookUrl basado en la plataforma seleccionada
      const webhookFunctionName = selectedPlatform === 'instagram' ? 'instagramWebhook' : '';
      const webhookUrl = `${process.env.VITE_APP_BACKEND_API}/${webhookFunctionName}`;

      const newIntegration = {
        ownerId: auth.currentUser?.uid,
        platform: selectedPlatform,
        accessToken: integrationData.accessToken,
        verificationToken: verificationToken,
        pageId: integrationData.pageId,
        chatbotId: integrationData.chatbotId,
        timestamp: new Date(),
        status: 'inactive', // Inicialmente inactiva
        webhookSubscribed: false,
        webhookUrl: webhookUrl,
        verified: false,
      };

      const docRef = await addDoc(collection(db, 'socialMediaIntegration'), newIntegration);

      setSocialMediaIntegrations(prev => [...prev, { id: docRef.id, ...newIntegration }]);
      showToast('success', t('socialMediaPage.integrationAdded'), themeMode);
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving integration:', error);
      showToast('error', t('socialMediaPage.errorAddingIntegration'), themeMode);
    } finally {
      setIsLoading(false);
      setLoadingMessage('');
    }
  };

  // Función para generar el verificationToken
  const generateVerificationToken = () => {
    // Aquí puedes implementar tu lógica para generar un token único
    return Math.random().toString(36).substring(2, 15);
  };

  useEffect(() => {
    const fetchIntegrations = async () => {
      if (user) {
        setLoadingIntegrations(true);
        try {
          const q = query(collection(db, 'socialMediaIntegration'), where('ownerId', '==', auth.currentUser?.uid));
          const querySnapshot = await getDocs(q);
          const integrations = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setSocialMediaIntegrations(integrations);
        } catch (error) {
          console.error('Error fetching integrations:', error);
        } finally {
          setLoadingIntegrations(false);
        }
      }
    };
    fetchIntegrations();
  }, [user]);

  const handleOpenDeleteDialog = (integration: any) => {
    setSelectedIntegrationToDelete(integration);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setSelectedIntegrationToDelete(null);
  };

  const handleDeleteIntegration = async () => {
    if (selectedIntegrationToDelete) {
      setIsDeleting(true);
      const integrationDocRef = doc(db, 'socialMediaIntegration', selectedIntegrationToDelete.id);

      try {
        await deleteDoc(integrationDocRef);

        setSocialMediaIntegrations(socialMediaIntegrations.filter(item => item.id !== selectedIntegrationToDelete.id));
        handleCloseDeleteDialog();
        showToast('success', t('socialMediaPage.integrationDeleted'), themeMode);
      } catch (error) {
        console.error('Error deleting integration:', error);
        showToast('error', t('socialMediaPage.errorDeletingIntegration'), themeMode);
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const handleOpenEditDialog = (integration: any) => {
    setSelectedIntegrationToEdit(integration);
    setIntegrationData({
      accessToken: integration.accessToken,
      pageId: integration.pageId,
      chatbotId: integration.chatbotId,
    });
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
    setSelectedIntegrationToEdit(null);
    setIntegrationData({
      accessToken: '',
      pageId: '',
      chatbotId: '',
    });
  };

  const handleEditIntegration = async () => {
    if (selectedIntegrationToEdit) {
      setIsEditing(true);
      const integrationDocRef = doc(db, 'socialMediaIntegration', selectedIntegrationToEdit.id);

      try {
        await updateDoc(integrationDocRef, {
          accessToken: integrationData.accessToken,
          pageId: integrationData.pageId,
          chatbotId: integrationData.chatbotId,
        });

        setSocialMediaIntegrations(socialMediaIntegrations.map(item =>
          item.id === selectedIntegrationToEdit.id
            ? { ...item, ...integrationData }
            : item
        ));
        handleCloseEditDialog();
        showToast('success', t('socialMediaPage.integrationUpdated'), themeMode);
      } catch (error) {
        console.error('Error updating integration:', error);
        showToast('error', t('socialMediaPage.errorUpdatingIntegration'), themeMode);
      } finally {
        setIsEditing(false);
      }
    }
  };

  // Funciones para manejar el modal de activación
  const handleOpenActivateDialog = (integration: any) => {
    setSelectedIntegrationToActivate(integration);
    setActivateDialogOpen(true);
  };

  const handleCloseActivateDialog = () => {
    setActivateDialogOpen(false);
    setSelectedIntegrationToActivate(null);
  };

  // Función para copiar al portapapeles
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    showToast('success', t('socialMediaPage.copiedToClipboard'), themeMode);
  };

  const themee = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            fontFamily: 'Outfit',
            border: themeMode === "dark" ? '1px solid #272727' : '1px solid #eeeeee',
            backgroundColor: themeMode === "dark" ? "#181818" : "#fafafa",
            borderRadius: 15,
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#181818" : "#fafafa",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',
            },
            "& .MuiInputBase-input": {
              fontFamily: 'Outfit',
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiInputLabel-root": {
              fontFamily: 'Outfit',
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#4a3aff" : '#4a3aff',
            },
            "& .MuiInputLabel-root.Mui-focused + .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#4a3aff" : '#4a3aff'
            },
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 15,
          },
        },
      },
    }
  });

  return (
    <SectionContainer
      title={t('socialMediaPage.title') as string}
      text={t('socialMediaPage.setupSocialMedia') as string}
      icon={<AddRoundedIcon />}
      onClick={handleOpenDialog}
      disableButton={true}
    >
      {loadingIntegrations ? (
        <Stack flexDirection="column" justifyContent="center" alignItems="center" gap={2} sx={{ mt: '100px', padding: '20px' }}>
          <CircularProgress sx={{ color: themeMode === "dark" ? '#fafafa' : '#4a3aff' }} />
        </Stack>
      ) : socialMediaIntegrations.length === 0 ? (
        <Stack flexDirection="column" justifyContent="center" alignItems="center" gap={2} sx={{ mt: '100px', padding: '20px' }}>
          <Groups2RoundedIcon sx={{ fontSize: '60px', color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1' }} />
          <Typography align="center" sx={{
            fontSize: isMobile ? '16px' : '18px', fontFamily: 'Outfit', fontWeight: '300',
            color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1', maxWidth: '600px'
          }}>
            {t('socialMediaPage.noIntegrations')}
          </Typography>
        </Stack>
      ) : (
        <Box sx={{ mt: '20px', mb: isMobile ? '70px' : '30px' }}>
          <Grid container spacing={2}>
            {socialMediaIntegrations.map((integration: any, index: number) => {
              const platformIcon =
                integration.platform === 'instagram' ? <InstagramIcon sx={{ fontSize: '70px' }} /> : null;
              const chatbotName =
                chatbots.find((chatbot: any) => chatbot.id === integration.chatbotId)?.companyName || '';

              return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box
                    sx={{
                      backgroundColor: themeMode === "dark" ? '#181818' : '#fff',
                      borderRadius: '15px',
                      padding: '16px',
                      boxShadow: themeMode === "dark"
                        ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                        : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box
                      sx={{
                        position: 'relative',
                        borderRadius: '15px',
                        overflow: 'hidden',
                        height: '200px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {platformIcon}
                      <IconButton
                        sx={{
                          position: 'absolute',
                          top: 10,
                          right: 100,
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          color: '#fff',
                          borderRadius: '50%',
                          '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                          },
                          '&:focus': {
                            outline: 'none',
                          },
                        }}
                        onClick={() => handleOpenActivateDialog(integration)}
                      >
                        {integration.verified ? <CheckCircleRoundedIcon /> : <AddRoundedIcon />}
                      </IconButton>
                      <IconButton
                        sx={{
                          position: 'absolute',
                          top: 10,
                          right: 55,
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          color: '#fff',
                          borderRadius: '50%',
                          '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                          },
                          '&:focus': {
                            outline: 'none',
                          },
                        }}
                        onClick={() => handleOpenEditDialog(integration)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        sx={{
                          position: 'absolute',
                          top: 10,
                          right: 10,
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          color: '#fff',
                          borderRadius: '50%',
                          '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                          },
                          '&:focus': {
                            outline: 'none',
                          },
                        }}
                        onClick={() => handleOpenDeleteDialog(integration)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                    <Typography
                      align="center"
                      sx={{
                        mt: 1,
                        color: themeMode === "dark" ? '#fafafa' : '#181818',
                      }}
                    >
                      {integration.platform.charAt(0).toUpperCase() + integration.platform.slice(1)}
                    </Typography>
                    <Typography
                      align="center"
                      sx={{
                        color: themeMode === "dark" ? '#fafafa' : '#181818',
                      }}
                    >
                      {chatbotName}
                    </Typography>
                    <Typography
                      align="center"
                      sx={{
                        color: integration.verified ? 'green' : 'red',
                      }}
                    >
                      {integration.verified ? t('socialMediaPage.verified') : t('socialMediaPage.notVerified')}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}

      {/* Diálogo para agregar una nueva integración */}
      <DialogCommon
        kTitle={t('socialMediaPage.addIntegration')}
        kDisableActions={!selectedPlatform || isLoading}
        kMaxWidth={isLoading ? "xs" : "sm"}
        kMaxHeight={isLoading ? '350px' : selectedPlatform ? '450px' : '400px'}
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kEnableBorderRadius={true}
        kBorderRadius={'30px'}
        kAlignBtn="center"
        kKeepBtnSize
        kTopDivider
        kSlideTransition
        kOpenDialogLists={openDialog}
        kOnClose={handleCloseDialog}
        kOnPrimary={handleSaveIntegration}
        kOnSecondary={handleCloseDialog}
        kSecondaryBtnText={t('socialMediaPage.cancel') as string}
        kPrimaryBtnText={t('socialMediaPage.save') as string}
        kDisableBtnSave={
          isLoading ||
          !selectedPlatform ||
          !integrationData.accessToken ||
          !integrationData.pageId ||
          !integrationData.chatbotId
        }
        kContent={
          isLoading ? (
            <Box sx={{ textAlign: 'center' }}>
              <CircularProgress sx={{ color: themeMode === "dark" ? '#fafafa' : '#4a3aff' }} />
              <Typography sx={{
                mt: 2,
                fontWeight: '300',
                fontFamily: 'Outfit',
                fontSize: '16px',
                color: themeMode === "dark" ? "#fafafa" : "#181818"
              }}>
                {loadingMessage}
              </Typography>
            </Box>
          ) : (
            <>
              {!selectedPlatform ? (
                <Stack flexDirection="column" justifyContent="center" alignItems="center" gap={3} sx={{ width: '100%', mb: '50px' }}>
                  <Typography sx={{ fontSize: '18px', color: themeMode === "dark" ? '#fafafa' : '#181818', textAlign: 'center', fontFamily: 'Outfit', fontWeight: '400' }}>
                    {t('socialMediaPage.selectPlatform')}
                  </Typography>

                  <Stack flexDirection="row" justifyContent="center" alignItems="center" gap={2} sx={{ width: '100%' }}>
                    <Box
                      sx={{
                        textAlign: 'center',
                        cursor: 'not-allowed',
                        padding: '20px',
                        borderRadius: '15px',
                        border: `1px solid ${themeMode === "dark" ? "#4a3aff" : "#4a3aff"}`,
                        width: '150px',
                        opacity: 0.5,
                      }}
                    >
                      <WhatsAppIcon sx={{ fontSize: '40px', color: themeMode === "dark" ? '#4a3aff' : '#4a3aff' }} />
                      <Typography sx={{ color: themeMode === "dark" ? '#fafafa' : '#181818' }}>WhatsApp</Typography>
                    </Box>
                    <Box
                      onClick={() => setSelectedPlatform('instagram')}
                      sx={{
                        textAlign: 'center',
                        cursor: 'pointer',
                        padding: '20px',
                        borderRadius: '15px',
                        border: `1px solid ${themeMode === "dark" ? "#4a3aff" : "#4a3aff"}`,
                        width: '150px',
                        transition: 'background-color 0.2s ease, color 0.2s ease',
                        '&:hover': {
                          backgroundColor: '#4a3aff',
                          '& .MuiSvgIcon-root': {
                            color: '#ffffff',
                            transition: 'color 0.3s ease',
                          },
                          '& .MuiTypography-root': {
                            color: '#ffffff',
                            transition: 'color 0.2s ease',
                          },
                        },
                      }}
                    >
                      <InstagramIcon sx={{ fontSize: '40px', color: themeMode === "dark" ? '#4a3aff' : '#4a3aff' }} />
                      <Typography sx={{ color: themeMode === "dark" ? '#fafafa' : '#181818' }}>Instagram</Typography>
                    </Box>
                    <Box
                      sx={{
                        textAlign: 'center',
                        cursor: 'not-allowed',
                        padding: '20px',
                        borderRadius: '15px',
                        border: `1px solid ${themeMode === "dark" ? "#4a3aff" : "#4a3aff"}`,
                        width: '150px',
                        opacity: 0.5,
                      }}
                    >
                      <FacebookIcon sx={{ fontSize: '40px', color: themeMode === "dark" ? '#4a3aff' : '#4a3aff' }} />
                      <Typography sx={{ color: themeMode === "dark" ? '#fafafa' : '#181818' }}>Messenger</Typography>
                    </Box>
                  </Stack>
                </Stack>
              ) : (
                <Stack spacing={2} sx={{ width: '100%' }}>
                  <ThemeProvider theme={themee}>
                    <TextField
                      label={t('socialMediaPage.accessToken')}
                      value={integrationData.accessToken}
                      onChange={(e) => handleInputChange('accessToken', e.target.value)}
                      fullWidth
                    />
                    <TextField
                      label={t('socialMediaPage.pageId')}
                      value={integrationData.pageId}
                      onChange={(e) => handleInputChange('pageId', e.target.value)}
                      fullWidth
                    />
                    <FormControl fullWidth>
                      <Select
                        value={integrationData.chatbotId}
                        onChange={(e) => handleInputChange('chatbotId', e.target.value)}
                        displayEmpty
                        sx={{
                          color: themeMode === 'dark' ? '#ffffff' : '#181818',
                          bgcolor: themeMode === 'dark' ? '#181818' : '#fafafa',
                          '& .MuiSvgIcon-root': {
                            color: themeMode === 'dark' ? '#ffffff' : '#4a3aff',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: themeMode === 'dark' ? '#272727' : '#eeeeee',
                          },
                          '& .MuiSelect-select:placeholder': {
                            color: themeMode === 'dark' ? '#666666' : '#999999',
                          },
                          borderRadius: '13px',
                        }}
                        renderValue={(selected) => {
                          if (!selected) {
                            return <span style={{ color: themeMode === 'dark' ? '#666666' : '#999999' }}>{t('socialMediaPage.selectChatbot')}</span>;
                          }
                          const chatbot = chatbots.find((chatbot: any) => chatbot.id === selected);
                          return chatbot ? chatbot.companyName : '';
                        }}
                        MenuProps={{
                          sx: {
                            '& .MuiPaper-root': {
                              bgcolor: themeMode === 'dark' ? '#181818' : '#fff',
                              color: themeMode === 'dark' ? '#ffffff' : '#333',
                              borderRadius: '15px',
                              boxShadow: themeMode === "dark"
                                ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                                : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                            },
                            '& .MuiMenu-list .MuiMenuItem-root': {
                              '&:hover': {
                                bgcolor: themeMode === 'light' ? '#fafafa' : '',
                              },
                            },
                          },
                        }}
                      >
                        <MenuItem disabled value="">
                          <em>{t('socialMediaPage.selectChatbot')}</em>
                        </MenuItem>
                        {chatbots.map((chatbot: any) => (
                          <MenuItem key={chatbot.id} value={chatbot.id}>
                            {chatbot.companyName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </ThemeProvider>
                </Stack>
              )}
            </>
          )
        }
      />

      {/* Diálogo para eliminar una integración */}
      <DialogCommon
        kTitle={t('socialMediaPage.deleteConfirm')}
        kDisableActions={isDeleting}
        kMaxWidth="xs"
        kMaxHeight="250px"
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kEnableBorderRadius={true}
        kBorderRadius="30px"
        kAlignBtn="center"
        kKeepBtnSize
        kTopDivider
        kSlideTransition
        kOpenDialogLists={deleteDialogOpen}
        kOnClose={handleCloseDeleteDialog}
        kOnPrimary={handleDeleteIntegration}
        kOnSecondary={handleCloseDeleteDialog}
        kSecondaryBtnText={t('socialMediaPage.cancel') as string}
        kPrimaryBtnText={t('socialMediaPage.delete') as string}
        kContent={
          isDeleting ? (
            <Box sx={{ textAlign: 'center' }}>
              <CircularProgress sx={{ color: themeMode === "dark" ? '#fafafa' : '#4a3aff' }} />
            </Box>
          ) : (
            <Typography sx={{ color: themeMode === "dark" ? "#fafafa" : "#181818" }}>
              {t('socialMediaPage.deleteMessage')}
            </Typography>
          )
        }
      />

      {/* Diálogo para editar una integración */}
      <DialogCommon
        kTitle={t('socialMediaPage.editIntegration')}
        kDisableActions={isEditing}
        kMaxWidth="sm"
        kMaxHeight="400px"
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kEnableBorderRadius={true}
        kBorderRadius={'30px'}
        kAlignBtn="center"
        kKeepBtnSize
        kTopDivider
        kSlideTransition
        kOpenDialogLists={editDialogOpen}
        kOnClose={handleCloseEditDialog}
        kOnPrimary={handleEditIntegration}
        kOnSecondary={handleCloseEditDialog}
        kSecondaryBtnText={t('socialMediaPage.cancel') as string}
        kPrimaryBtnText={t('socialMediaPage.save') as string}
        kDisableBtnSave={
          isEditing ||
          !integrationData.accessToken ||
          !integrationData.pageId ||
          !integrationData.chatbotId
        }
        kContent={
          isEditing ? (
            <Box sx={{ textAlign: 'center' }}>
              <CircularProgress sx={{ color: themeMode === "dark" ? '#fafafa' : '#4a3aff' }} />
            </Box>
          ) : (
            <Stack spacing={2} sx={{ width: '100%' }}>
              <ThemeProvider theme={themee}>
                <TextField
                  label={t('socialMediaPage.accessToken')}
                  value={integrationData.accessToken}
                  onChange={(e) => handleInputChange('accessToken', e.target.value)}
                  fullWidth
                />
                <TextField
                  label={t('socialMediaPage.pageId')}
                  value={integrationData.pageId}
                  onChange={(e) => handleInputChange('pageId', e.target.value)}
                  fullWidth
                />
                <FormControl fullWidth>
                  <Select
                    value={integrationData.chatbotId}
                    onChange={(e) => handleInputChange('chatbotId', e.target.value)}
                    displayEmpty
                    sx={{
                      color: themeMode === 'dark' ? '#ffffff' : '#181818',
                      bgcolor: themeMode === 'dark' ? '#181818' : '#fafafa',
                      '& .MuiSvgIcon-root': {
                        color: themeMode === 'dark' ? '#ffffff' : '#4a3aff',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: themeMode === 'dark' ? '#272727' : '#eeeeee',
                      },
                      '& .MuiSelect-select:placeholder': {
                        color: themeMode === 'dark' ? '#666666' : '#999999',
                      },
                      borderRadius: '13px',
                    }}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <span style={{ color: themeMode === 'dark' ? '#666666' : '#999999' }}>{t('socialMediaPage.selectChatbot')}</span>;
                      }
                      const chatbot = chatbots.find((chatbot: any) => chatbot.id === selected);
                      return chatbot ? chatbot.companyName : '';
                    }}
                    MenuProps={{
                      sx: {
                        '& .MuiPaper-root': {
                          bgcolor: themeMode === 'dark' ? '#181818' : '#fff',
                          color: themeMode === 'dark' ? '#ffffff' : '#333',
                          borderRadius: '15px',
                          boxShadow: themeMode === "dark"
                            ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                            : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                        },
                        '& .MuiMenu-list .MuiMenuItem-root': {
                          '&:hover': {
                            bgcolor: themeMode === 'light' ? '#fafafa' : '',
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem disabled value="">
                      <em>{t('socialMediaPage.selectChatbot')}</em>
                    </MenuItem>
                    {chatbots.map((chatbot: any) => (
                      <MenuItem key={chatbot.id} value={chatbot.id}>
                        {chatbot.companyName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </ThemeProvider>
            </Stack>
          )
        }
      />

      {/* Diálogo para activar una integración */}
      <DialogCommon
        kTitle={t('socialMediaPage.activateIntegration')}
        kDisableActions={false}
        kMaxWidth="sm"
        kMaxHeight="400px"
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kEnableBorderRadius={true}
        kBorderRadius={'30px'}
        kAlignBtn="center"
        kKeepBtnSize
        kTopDivider
        kSlideTransition
        kOpenDialogLists={activateDialogOpen}
        kOnClose={handleCloseActivateDialog}
        kOnPrimary={handleCloseActivateDialog}
        kOnSecondary={handleCloseActivateDialog}
        kSecondaryBtnText={t('socialMediaPage.close') as string}
        kPrimaryBtnText={t('socialMediaPage.done') as string}
        kContent={
          selectedIntegrationToActivate ? (
            <Stack spacing={2} sx={{ width: '100%' }}>
              <Typography sx={{ color: themeMode === "dark" ? "#fafafa" : "#181818" }}>
                {t('socialMediaPage.followInstructions')}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  label={t('socialMediaPage.webhookUrl')}
                  value={selectedIntegrationToActivate.webhookUrl}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <IconButton onClick={() => copyToClipboard(selectedIntegrationToActivate.webhookUrl)}>
                  <ContentCopyIcon />
                </IconButton>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  label={t('socialMediaPage.verificationToken')}
                  value={selectedIntegrationToActivate.verificationToken}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <IconButton onClick={() => copyToClipboard(selectedIntegrationToActivate.verificationToken)}>
                  <ContentCopyIcon />
                </IconButton>
              </Box>
              {/* Aquí puedes agregar instrucciones adicionales si es necesario */}
            </Stack>
          ) : null
        }
      />
    </SectionContainer>
  );
}

export default SocialMedia;
