import { CloseRounded } from '@mui/icons-material';
import {
  Box,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useContext } from 'react';
import { ConfigContext } from '../../../../context/Config/ConfigContext';
import { DialogCommonHeaderModels } from './DialogHeader.models';

function DialogCommonHeader(props: DialogCommonHeaderModels) {

  const { themeMode } = useContext(ConfigContext)

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <DialogTitle sx={{
        px: isMobile ? 1 : 3, py: 0.5,
        backgroundColor: themeMode === "dark" ? '#121212' : '#fff', borderRadius: '20px 20px 0px 0px'
      }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        // pl={1}
        >
          {
            props.kCenterTitle ? (
              <Box sx={{ height: '51px', width: '51px' }} />
            ) : null
          }
          {props.kHeaderComponent
            ? props.kHeaderComponent
            : (
              <Stack direction="column">
                <Typography
                  sx={{
                    textTransform: 'none', fontFamily: 'Outfit', ml: isMobile ? '20px' : 0,
                    color: themeMode === "dark" ? '#fafafa' : '#181818',
                    lineHeight: '23px'
                  }}
                  fontWeight="500"
                  fontSize="20px"
                >
                  {props.kTitle}
                </Typography>
                <Typography
                  sx={{ textTransform: 'none' }}
                  fontWeight="300"
                  fontSize="14px"
                >
                  {props.kSubTitle}
                </Typography>
              </Stack>
            )}

          <IconButton
            color="primary"
            onClick={props.kOnClose}
            sx={{
              p: '10px',
              borderRadius: '100px',
              '&:focus': { outline: 'none' },
              '&:hover': { bgcolor: themeMode === "dark" ? '#181818' : '#fafafa', }
            }}
          >
            <CloseRounded
              fontSize="medium"
              sx={{
                color: themeMode === "dark" ? '#fff' : '#121212',
                fontSize: '30px'
              }}
            />
          </IconButton>
        </Stack>
      </DialogTitle>
    </>
  );
}

export default DialogCommonHeader;
