import { useEffect, useState, useContext } from 'react';
import { Box, Stack, TextField, Typography, createTheme, ThemeProvider, IconButton, useTheme, useMediaQuery, Button, InputAdornment } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useRef } from 'react';
import { motion } from "framer-motion";
import { ConfigContext } from '../../context/Config/ConfigContext';
import ImageRoundedIcon from '@mui/icons-material/ImageRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import React from 'react';
import { Timestamp } from "firebase/firestore";
import 'swiper/swiper-bundle.css';
import { processMessageToChatGPT } from './services/VoodoChatLogic';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import ModeNightIcon from '@mui/icons-material/ModeNight';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';

type CustomMessageModel = {
  message: string | undefined;
  sender: string;
  timestamp?: Timestamp;
  image?: string | null;
};

function VoodoBotChat({ handleSignUpAndSaveBot, handleOpenConfig }: { handleSignUpAndSaveBot: () => void, handleOpenConfig: () => void }) {

  const MESSAGE_LIMIT = 30;
  const [hasReachedMessageLimit, setHasReachedMessageLimit] = useState<boolean>(false);
  const [showSuggestions, setShowSuggestions] = React.useState<boolean>(true);

  const { logo, lightModeLogo, assistantFirstMessage, primaryColor, systemInstructions,
    useEmojis, chatbotTone, chatbotFormality, defaultLanguage,
    chatbotPersonality, businessType,
    suggestion1, suggestion2, suggestion3,
    feedbackInstructions,
    toggleTheme
  } = useContext(ConfigContext);

  const { themeMode } = useContext(ConfigContext)

  const messageContainerRef = useRef<HTMLDivElement>(null);
  const [typing, setTyping] = useState(false)
  const [waitingForResponse, setWaitingForResponse] = useState(false)

  useEffect(() => {
    // Actualiza el primer mensaje del chatbot cuando assistantFirstMessage cambie
    setMessages((prevMessages) => {
      // Verifica si el primer mensaje es del ChatGPT para actualizarlo
      if (prevMessages.length > 0 && prevMessages[0].sender === "ChatGPT") {
        // Actualiza solo el primer mensaje, mantiene el resto igual
        return [
          { ...prevMessages[0], message: assistantFirstMessage },
          ...prevMessages.slice(1),
        ];
      }
      return prevMessages;
    });
  }, [assistantFirstMessage]);


  const [messages, setMessages] = useState<CustomMessageModel[]>([
    {
      "message": assistantFirstMessage,
      "sender": "ChatGPT"
    },

  ]);

  let showStartNewConversationMessage = false;


  const handleSent = async (message: any) => {
    setShowSuggestions(false);
    handleRemoveImage()
    if (messages.length >= MESSAGE_LIMIT) {
      setHasReachedMessageLimit(true);
      return;
    }

    if ((message !== '' || selectedImage) && !waitingForResponse) {
      setWaitingForResponse(true);
      const newMessage: CustomMessageModel = {
        message: message,
        sender: "user",
        image: selectedImage || null,
      };

      const newMessages = [...messages, newMessage];
      setMessages(newMessages);

      setTyping(true);
      setValue('');

      await processMessageToChatGPT(
        newMessages,
        setMessages,
        setTyping,
        systemInstructions,
        useEmojis,
        chatbotTone,
        chatbotFormality,
        defaultLanguage,
        chatbotPersonality,
        businessType,
        feedbackInstructions,
        themeMode,
      );

      setWaitingForResponse(false);
      setSelectedImage(null);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSent(value);
    }
  };

  useEffect(() => {
    messageContainerRef.current?.scrollTo({
      top: messageContainerRef.current.scrollHeight,
      behavior: 'smooth'
    });
  }, [messages]);

  const exceededTokens = false

  const [value, setValue] = useState('')

  const useResponsiveFontSize = () => {
    return '14px';
  };

  const fontSize = useResponsiveFontSize();

  const themee = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            fontFamily: 'Outfit',
            color: '#7c7c7c',
            border: themeMode === "dark" ? '1px solid #272727' : '1px solid #dcdcdc',
            backgroundColor: themeMode === "dark" ? "#181818" : "#f1eeee",
            borderRadius: 100,
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent !important" : 'transparent !important',
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',
            },
            "& .MuiInputBase-input": {
              fontFamily: 'Outfit',
              color: themeMode === "dark" ? "#fafafa" : '#121212',
              fontSize: fontSize, // Aplicación del tamaño de fuente basado en el dispositivo
            },
            "& .MuiInputLabel-root": {
              fontFamily: 'Outfit',
              color: themeMode === "dark" ? "#fafafa" : '#121212',
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: themeMode === "dark" ? "#fafafa" : '#121212',
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',
            },
            "& .MuiInputLabel-root.Mui-focused + .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',
            },
          }
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 100,
          },
        },
      },
    }
  });

  const uiTheme = useTheme();

  const isMobile = useMediaQuery(uiTheme.breakpoints.down('md'));

  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
  };

  return (
    <Box style={{
      position: "relative",
      width: "100%",
      borderRadius: '20px',
    }}
    >
      <Stack>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <Box
            sx={{
              backgroundColor: themeMode === "dark" ? "#121212" : '#fafafa',
              borderRadius: '20px 20px 0 0',
              pl: isMobile ? '10px' : '30px',
              py: '10px'
            }}
          >
            {themeMode === "dark" ? (
              logo ? (
                <img
                  src={logo}
                  alt="Logo de la empresa"
                  height="auto"
                  style={{ maxHeight: '35px', maxWidth: isMobile ? '70vw' : 'auto' }}
                />
              ) : (
                // Mostrar mensaje si no hay logo en modo oscuro
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#fafafa", fontSize: '12px', fontFamily: "Outfit",
                    maxWidth: '150px', lineHeight: '14px', mt: '10px', height: '35px'
                  }}
                >
                  Sin logo para modo oscuro
                </Typography>
              )
            ) : (
              lightModeLogo ? (
                // Mostrar logo en modo claro
                <img
                  src={lightModeLogo}
                  alt="Logo de la empresa"
                  height="auto"
                  style={{ maxHeight: '35px', maxWidth: isMobile ? '70vw' : 'auto' }}
                />
              ) : (
                // Mostrar mensaje si no hay logo en modo claro
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#121212", fontSize: '12px', fontFamily: "Outfit", maxWidth: '150px',
                    lineHeight: '14px', mt: '10px', height: '35px'
                  }}
                >
                  Sin logo para modo claro
                </Typography>
              )
            )}
          </Box>
          {
            isMobile && (
              <Stack flexDirection="row" alignItems="center" justifyContent="center">
                <IconButton onClick={() => toggleTheme()} sx={{
                  "&:focus": {
                    outline: "none"
                  },
                }}>
                  {themeMode !== "dark" ? <ModeNightIcon sx={{ color: '#c1c1c1' }} /> : <LightModeRoundedIcon sx={{ color: '#c1c1c1' }} />}
                </IconButton>
                <IconButton
                  onClick={async () => {
                    handleOpenConfig()
                  }}
                  sx={{
                    "&:focus": {
                      outline: "none"
                    },
                    mr: '6px'
                  }}>
                  <SettingsRoundedIcon sx={{ color: '#c1c1c1' }} />
                </IconButton>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={async () => {

                    await handleSignUpAndSaveBot();
                  }}
                  sx={{
                    height: '35px',
                    backgroundColor: '#4a3aff',
                    lineHeight: 1,
                    color: '#fff',
                    fontSize: isMobile ? '16px' : "16px",
                    fontWeight: "500",
                    textTransform: "none",
                    padding: "10px 20px",
                    borderRadius: '100px',
                    borderColor: "transparent",
                    borderWidth: 1,
                    borderStyle: 'solid',
                    fontFamily: 'Outfit',
                    '&:hover': {
                      borderColor: '#4a3aff',
                      color: themeMode === 'dark' ? "white" : '#181818',
                      backgroundColor: 'transparent',
                      borderWidth: 1,
                      borderStyle: 'solid',
                    },
                    '&:focus': {
                      outline: 'none',
                    },
                    width: '90%',
                    mr: '10px'
                  }}
                >
                  Guardar
                </Button>
              </Stack>
            )
          }

        </Stack>


        <Box
          style={{
            backgroundColor: themeMode === "dark" ? "#121212" : '#fafafa',
            height: isMobile ? 'calc(100vh - 59px)' : '600px',
            position: 'relative',
            borderRadius: '20px',
          }}>
          <Stack
            flexDirection="column"
            justifyContent="center"
            alignItems="inherit"
          >
            <Box
              key="message-container"
              ref={messageContainerRef}
              style={{
                backgroundColor: themeMode === "dark" ? "#121212" : '#fafafa',
                padding: 20,
                paddingBottom: 50,
                maxHeight: !isMobile ? '550px' : 'calc(100vh - 120px)',
                overflowY: 'auto',
              }}
              sx={{
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: themeMode === "dark" ? "#121212" : '#fafafa',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: themeMode !== "dark" ? 'lightgrey' : '#3A3A3A',
                  borderRadius: '50px'
                }
              }}
            >
              {messages.map((message, i) => (
                <motion.div
                  key={i}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.25 }}
                >
                  <Box
                    key={i}
                    style={{
                      background: message.sender === "ChatGPT"
                        ? (themeMode === "dark" ? "#1d1d1d" : "white")
                        : primaryColor,
                      padding: "12px",
                      marginBottom: "20px",
                      color: message.sender === "ChatGPT"
                        ? (themeMode === "dark" ? "white" : "black")
                        : "white",
                      borderRadius: message.sender === "ChatGPT" ? "15px 15px 15px 0px" : "15px 15px 0px 15px",
                      textAlign: "left",
                      maxWidth: "90%",
                      float: message.sender === "ChatGPT" ? "left" : "right",
                      clear: "both",
                      whiteSpace: "pre-wrap",
                      boxShadow: themeMode === "dark"
                        ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                        : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                    }}
                  >
                    <div
                      style={{ fontSize: '14px', fontFamily: 'Outfit', fontWeight: '300' }}
                      dangerouslySetInnerHTML={{
                        __html: (message?.message ?? "").replace(
                          /\*\*(.*?)\*\*/g,
                          '<span style="font-weight: 500;">$1</span>'
                        )
                      }}
                    />

                    {message.image && (
                      <Box mt={2}>
                        <img
                          src={message.image}
                          alt="Enviado"
                          style={{
                            maxWidth: '100%',
                            borderRadius: '10px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                  <div style={{ clear: 'both' }}></div>
                </motion.div>
              ))}
            </Box>

            <Box
              position="absolute"
              bottom="0"
              width="100%"
              sx={{ zIndex: 999 }}
            >
              {
                typing && (
                  <Box sx={{
                    display: 'flex',
                    backgroundColor: themeMode === "dark" ? "#1d1d1d" : "white",
                    borderRadius: "20px 20px 20px 0px",
                    width: '70px',
                    height: '35px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    ml: '20px',
                    mb: '10px',
                    boxShadow: themeMode === "dark"
                      ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                      : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                  }}>
                    <div style={{ display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center' }}>
                      <motion.div
                        style={{
                          width: '8px',
                          height: '8px',
                          backgroundColor: themeMode === "dark" ? '#fafafa' : 'black',
                          borderRadius: '50%',
                        }}
                        initial={{ y: 0 }}
                        animate={{ y: [0, -4, 0] }}
                        transition={{
                          duration: 0.6,
                          repeat: Infinity,
                          ease: "easeInOut",
                          delay: 0
                        }}
                      />
                      <motion.div
                        style={{
                          width: '8px',
                          height: '8px',
                          backgroundColor: themeMode === "dark" ? '#fafafa' : 'black',
                          borderRadius: '50%',
                        }}
                        initial={{ y: 0 }}
                        animate={{ y: [0, -4, 0] }}
                        transition={{
                          duration: 0.6,
                          repeat: Infinity,
                          ease: "easeInOut",
                          delay: 0.15
                        }}
                      />
                      <motion.div
                        style={{
                          width: '8px',
                          height: '8px',
                          backgroundColor: themeMode === "dark" ? '#fafafa' : 'black',
                          borderRadius: '50%',
                        }}
                        initial={{ y: 0 }}
                        animate={{ y: [0, -4, 0] }}
                        transition={{
                          duration: 0.6,
                          repeat: Infinity,
                          ease: "easeInOut",
                          delay: 0.3
                        }}
                      />
                    </div>
                  </Box>
                )
              }

              {showSuggestions && suggestion1 && (
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="end"
                  pb={2} gap={1} sx={{ width: '80%', ml: 'auto', pr: '20px' }}
                >
                  <Typography sx={{
                    fontFamily: 'Outfit', fontSize: '14px', mb: '10px',
                    width: '80vw', textAlign: 'right',
                    color: themeMode === "dark" ? 'white' : 'black'
                  }}>
                    {
                      defaultLanguage === 'es' ? 'Sugerencias de preguntas:' : 'Question suggestions:'
                    }
                  </Typography>
                  <Button variant="outlined" sx={{
                    backgroundColor: 'transparent', border: '1px solid', textTransform: 'none',
                    borderColor: primaryColor, p: '10px 20px', borderRadius: '15px', fontFamily: 'Outfit',
                    fontSize: '14px', maxWidth: '80vw', textAlign: 'left',
                    '&:hover': { borderColor: primaryColor, backgroundColor: primaryColor, color: 'white' }, '&:focus': { outline: 'none' },
                    color: themeMode === "dark" ? 'white' : 'black', fontWeight: '300'
                  }}
                    onClick={() => { handleSent(suggestion1); setShowSuggestions(false); }}>
                    {suggestion1}
                  </Button>
                  <Button variant="outlined" sx={{
                    backgroundColor: 'transparent', border: '1px solid', textTransform: 'none',
                    borderColor: primaryColor, p: '10px 20px', borderRadius: '15px', fontFamily: 'Outfit',
                    fontSize: '14px', maxWidth: '80vw', textAlign: 'left',
                    '&:hover': { borderColor: primaryColor, backgroundColor: primaryColor, color: 'white' }, '&:focus': { outline: 'none' },
                    color: themeMode === "dark" ? 'white' : 'black', fontWeight: '300'
                  }}
                    onClick={() => { handleSent(suggestion2); setShowSuggestions(false); }}>
                    {suggestion2}
                  </Button>
                  <Button variant="outlined" sx={{
                    backgroundColor: 'transparent', border: '1px solid', textTransform: 'none',
                    borderColor: primaryColor, p: '10px 20px', borderRadius: '15px', fontFamily: 'Outfit',
                    fontSize: '14px', maxWidth: '80vw', textAlign: 'left',
                    '&:hover': { borderColor: primaryColor, backgroundColor: primaryColor, color: 'white' }, '&:focus': { outline: 'none' },
                    color: themeMode === "dark" ? 'white' : 'black', fontWeight: '300'
                  }}
                    onClick={() => { handleSent(suggestion3); setShowSuggestions(false); }}>
                    {suggestion3}
                  </Button>
                </Box>
              )}

              <Stack
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                py={1}
                pb={2}
                px={1}
                sx={{ backgroundColor: themeMode === "dark" ? "#121212" : '#fafafa', borderRadius: '0 0 20px 20px' }}
              >
                {hasReachedMessageLimit ? (
                  // Lo que se mostrará si se ha alcanzado el límite de mensajes
                  <>
                    <Stack flexDirection="column" alignItems="center" justifyContent="center" gap={2}>
                      <Box>
                        <Typography sx={{
                          fontFamily: 'Outfit', fontWeight: '300',
                          textAlign: 'center', color: themeMode === "dark" ? "white" : 'black'
                        }}>
                          {defaultLanguage == "es" ? "Has alcanzado el límite de mensajes para esta conversación." : "You have reached the message limit for this conversation."}
                        </Typography>
                        <Typography sx={{
                          fontFamily: 'Outfit', fontWeight: '300',
                          textAlign: 'center', color: themeMode === "dark" ? "white" : 'black'
                        }}>
                          {defaultLanguage == "es" ? "Por favor, inicia una nueva conversación." : "Please start a new conversation."}
                        </Typography>
                      </Box>
                      <Button
                        sx={{
                          backgroundColor: primaryColor, color: 'white',
                          padding: "10px 20px", borderRadius: "100px", textTransform: 'none',
                          maxWidth: '250px',
                          border: 'solid 1px',
                          borderColor: primaryColor,
                          fontFamily: 'Outfit',
                          fontSize: '16px',
                          "&:hover": {
                            backgroundColor: 'transparent',
                            border: 'solid 1px',
                            borderColor: primaryColor,
                            color: themeMode === "dark" ? "white" : 'black'
                          },
                          "&:focus": {
                            outline: "none",
                            backgroundColor: primaryColor,
                          },
                        }}
                        onClick={() => {
                          // navigate(`/chat/${chatbotId}`)
                        }}
                      >
                        {defaultLanguage == "es" ? "Iniciar nueva conversación" : "Start new conversation"}
                      </Button>
                    </Stack>


                  </>
                ) : showStartNewConversationMessage ? (
                  // Lo que se mostrará si se muestra el mensaje de iniciar nueva conversación
                  <Stack flexDirection="column" alignItems="center" justifyContent="center" gap={2}>
                    <Button
                      sx={{
                        backgroundColor: primaryColor, color: 'white',
                        padding: "10px 20px", borderRadius: "100px", textTransform: 'none',
                        maxWidth: '250px',
                        border: 'solid 1px',
                        borderColor: primaryColor,
                        fontFamily: 'Outfit',
                        fontSize: '16px',
                        "&:hover": {
                          backgroundColor: 'transparent',
                          border: 'solid 1px',
                          borderColor: primaryColor,
                          color: themeMode === "dark" ? "white" : 'black'
                        },
                        "&:focus": {
                          outline: "none",
                          backgroundColor: primaryColor,
                        },
                      }}
                      onClick={() => {
                        // navigate(`/chat/${chatbotId}`)
                      }}
                    >
                      {defaultLanguage == "es" ? "Iniciar nueva conversación" : "Start new conversation"}
                    </Button>
                  </Stack>
                ) : (
                  <Stack flexDirection="column" sx={{ width: '100%', }}>
                    {/* Previsualización de la imagen seleccionada */}
                    {selectedImage && (
                      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2, paddingLeft: 4 }}>
                        <img src={selectedImage} alt="Preview" style={{ maxHeight: '50px', borderRadius: '10px' }} />
                        <IconButton
                          onClick={handleRemoveImage}
                          sx={{
                            top: '-25px',
                            right: '25px',
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            color: 'white',
                            '&:hover': { backgroundColor: 'rgba(0,0,0,0.7)' },
                            '&:focus': { outline: 'none' }
                          }}
                        >
                          <CloseRoundedIcon sx={{ fontSize: '20px' }} />
                        </IconButton>
                      </Box>
                    )}
                    <Stack flexDirection="row" justifyContent="center" alignItems="center" sx={{ maxWidth: '480px', width: '100%', }}>
                      <ThemeProvider theme={themee}>
                        <TextField
                          fullWidth
                          onKeyDown={handleKeyDown}
                          autoComplete="off"
                          id="outlined-basic"
                          variant="outlined"
                          type="text"
                          name="message"
                          value={value}
                          placeholder={defaultLanguage == "es" ? "Escribe cualquier pregunta" : "Write any question"}
                          error={exceededTokens}
                          onChange={(e: any) => setValue(e.target.value)}
                          sx={{
                            maxWidth: '100%',
                            width: '100%',
                            ml: '10px'
                          }}
                          inputProps={{
                            maxLength: 400,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  component="label"
                                  style={{
                                    color: themeMode === "dark" ? "white" : 'black',
                                    outline: 'none',
                                    transition: 'transform 0.3s ease, background-color 0.3s ease',
                                  }}
                                  sx={{
                                    '&:hover': {
                                      transform: 'scale(1.1)',
                                    }
                                  }}
                                >
                                  <ImageRoundedIcon sx={{ fontSize: '25px', color: themeMode === "dark" ? "#ffffff80" : "#18181880" }} />
                                  <input type="file" accept="image/*" hidden onChange={handleImageUpload} />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ThemeProvider>

                      {/* Botón para enviar el mensaje */}
                      <IconButton
                        style={{
                          color: themeMode === "dark" ? "white" : 'black',
                          outline: 'none',
                          transition: 'transform 0.3s ease, background-color 0.3s ease',
                        }}
                        sx={{
                          '&:hover': {
                            transform: 'scale(1.1)',
                          }
                        }}
                        onClick={() => handleSent(value)}
                        disabled={!value && !selectedImage || waitingForResponse}
                      >
                        {/* Aplica el estilo dinámico al SendIcon */}
                        <SendIcon sx={{
                          color: (value || selectedImage) && !waitingForResponse ? 'white' : 'lightgray',
                          border: 'none',
                          fontSize: '50px',
                          backgroundColor: (value || selectedImage) && !waitingForResponse ? primaryColor : 'transparent',
                          borderRadius: '100px',
                          p: '12px',
                          transition: 'transform 0.3s ease, background-color 0.3s ease',
                          transform: (value || selectedImage) && !waitingForResponse ? 'rotate(-20deg)' : 'none',
                        }} />
                      </IconButton>
                    </Stack>

                  </Stack>
                )}
              </Stack>

            </Box >
          </Stack>
        </Box >
      </Stack>

    </Box >
  );
}

export default VoodoBotChat;