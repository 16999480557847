/* eslint-disable max-len */
import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { useState } from 'react';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import googleLogo from '../../assets/googleLogo.png';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import ModeNightIcon from '@mui/icons-material/ModeNight';
import { InputAdornment, IconButton, useMediaQuery, Stack, Link, Divider, CircularProgress } from '@mui/material';
import whitelogo from '../../assets/whiteLogo.png';
import blackLogo from '../../assets/mainLogo.png';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from '../../services/firebase/firebase';
import bgImage from '../../assets/signInImage.svg';
import { useTranslation } from 'react-i18next';
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { doc, getDoc } from 'firebase/firestore';
import { ConfigContext } from '../../context/Config/ConfigContext';
import { showToast } from '../../components/Toast/toast';

export default function SignIn() {
  const { t } = useTranslation('global');
  const { themeMode, toggleTheme } = React.useContext(ConfigContext);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);

  const themee = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            fontFamily: 'Outfit',
            border: themeMode === "dark" ? '1px solid #272727' : '1px solid #eeeeee',
            backgroundColor: themeMode === "dark" ? "#181818" : "#fafafa",
            borderRadius: 15,
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#181818" : "#fafafa",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',
            },
            "& .MuiInputBase-input": {
              fontFamily: 'Outfit',
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiInputLabel-root": {
              fontFamily: 'Outfit',
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#4a3aff" : '#4a3aff',
            },
            "& .MuiInputLabel-root.Mui-focused + .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#4a3aff" : '#4a3aff'
            },
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 15,
          },
        },
      },
    }
  });

  const signInWithGoogle = async () => {

    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      setIsGoogleLoading(true);
      const user = result.user;

      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        showToast('error', t('signIn.toasts.noAccount'), themeMode);
        await auth.signOut();
        navigate('/signup');
      } else {
        window.location.href = '/dashboard';
      }
    } catch (error) {
      if (error instanceof Error) {
        showToast('error', t('signIn.toasts.errorGoogleSignIn'), themeMode);
      } else {
        showToast('error', t('signIn.toasts.unknownErrorGoogleSignIn'), themeMode);
      }
    } finally {
      setIsGoogleLoading(false);
    }
  };

  const translateFirebaseError = (error: Error) => {
    let errorCode = error.message;
    const matched = errorCode.match(/\(([^)]+)\)/);
    if (matched) {
      errorCode = matched[1];
    }

    switch (errorCode) {
      case 'auth/invalid-login-credentials':
        return t('firebaseErrors.invalidLoginCredentials');
      case 'auth/invalid-email':
        return t('firebaseErrors.invalidEmail');
      case 'auth/weak-password':
        return t('firebaseErrors.weakPassword');
      default:
        return error.message;
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const data = new FormData(event.currentTarget);
    const email = data.get('email') as string;
    const password = data.get('password') as string;

    if (!email || !password) {
      showToast('error', t('signIn.toasts.fillAllFields'), themeMode);
      setIsLoading(false);
      return;
    }

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setIsLoading(true);
      const user = userCredential.user;

      if (user) {
        showToast('success', t('signIn.toasts.signInSuccess'), themeMode);
        window.location.href = '/dashboard';
      }
    } catch (error) {
      if (error instanceof Error) {
        setIsLoading(false);
        showToast('error', translateFirebaseError(error), themeMode);
      } else {
        setIsLoading(false);
        throw error;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const breakpointTheme = useTheme();
  const isMobile = useMediaQuery(breakpointTheme.breakpoints.down('md'));

  return (
    <Grid container sx={{ minHeight: '100vh', backgroundColor: themeMode === "dark" ? '#121212' : "#fff", }}>
      {!isMobile && (
        <Grid item xs={6} sx={{
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${bgImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            opacity: themeMode === "dark" ? 0.7 : 1,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}>
          <Box sx={{ width: '100%', height: '100%', position: 'absolute' }} />
        </Grid>
      )}

      <IconButton onClick={() => toggleTheme()} sx={{
        "&:focus": {
          outline: "none"
        },
        position: 'fixed', top: 20, right: 20, zIndex: 1300,
      }}>
        {themeMode !== "dark" ? <ModeNightIcon sx={{ color: '#c1c1c1' }} /> : <LightModeRoundedIcon sx={{ color: '#c1c1c1' }} />}
      </IconButton>

      <Container component="main"
        maxWidth="xs" sx={{
          minHeight: '100vh',
          backgroundColor: themeMode === "dark" ? '#121212' : "#fff",
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          py: '20px'
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            component='img'
            alt='whitelogo'
            src={themeMode === "dark" ? whitelogo : blackLogo}
            sx={{
              height: '50px',
              mb: '50px'
            }}
          />
          <Typography component="h1" variant="h5" sx={{ color: themeMode === "dark" ? '#fff' : '#181818', fontFamily: 'Outfit, sans-serif' }}>
            {t('signIn.title')}
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ThemeProvider theme={themee}>
                  <TextField
                    fullWidth
                    id="email"
                    placeholder={t('signIn.emailLabel') as string}
                    name="email"
                    autoComplete="email"
                  />
                </ThemeProvider>
              </Grid>
              <Grid item xs={12}>
                <ThemeProvider theme={themee}>
                  <TextField
                    fullWidth
                    name="password"
                    placeholder={t('signIn.passwordLabel') as string}
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="new-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleTogglePasswordVisibility}
                            onMouseDown={(event) => event.preventDefault()}
                            edge="end"
                          >
                            {showPassword
                              ? <VisibilityOffRoundedIcon style={{ color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1' }} />
                              : <VisibilityRoundedIcon style={{ color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1' }} />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </ThemeProvider>
              </Grid>
            </Grid>
            <Button
              variant="outlined"
              type='submit'
              disabled={isLoading}
              startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
              sx={{
                color: '#fff',
                backgroundColor: '#4a3aff',
                fontSize: '20px',
                fontWeight: '500',
                borderRadius: '100px',
                borderColor: "transparent",
                mt: '40px',
                borderWidth: 1,
                borderStyle: 'solid',
                fontFamily: 'Outfit',
                width: '100%',
                maxWidth: '200px',
                '&:hover': {
                  borderColor: '#4a3aff',
                  backgroundColor: 'transparent',
                  color: themeMode === "dark" ? 'white' : '#181818',
                  borderWidth: 1,
                  borderStyle: 'solid',
                },
                '&:focus': {
                  outline: 'none',
                },
                '&.Mui-disabled': {
                  backgroundColor: themeMode === "dark" ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
                  color: themeMode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
                },
                textTransform: 'none'
              }}
            >
              {isLoading ? t('signIn.signingIn') : t('signIn.loginButton')}
            </Button>
            <Box sx={{ mt: '20px' }}>
              <Divider sx={{
                mb: '20px',
                backgroundColor: themeMode === "dark" ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
                maxWidth: isMobile ? '80vw' : 'auto',
                mx: 'auto'
              }} />
              <Stack flexDirection="row" justifyContent="center" alignItems="center" gap={2}>
                <Button
                  onClick={signInWithGoogle}
                  variant="outlined"
                  type="button"
                  disabled={isGoogleLoading}
                  startIcon={isGoogleLoading ? <CircularProgress size={20} color="inherit" /> : <img src={googleLogo} width="30px" />}
                  sx={{
                    color: themeMode === "dark" ? '#fafafa' : '#181818',
                    backgroundColor: 'transparent',
                    fontSize: '20px',
                    fontWeight: '500',
                    borderRadius: '100px',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    fontFamily: 'Outfit',
                    minWidth: '180px',
                    borderColor: '#4a3aff',
                    '&:hover': {
                      borderColor: '#4a3aff',
                      backgroundColor: '#fff',
                      color: '#181818',
                      borderWidth: 1,
                      borderStyle: 'solid',
                    },
                    '&:focus': {
                      outline: 'none',
                    },
                    '&.Mui-disabled': {
                      backgroundColor: themeMode === "dark" ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
                      color: themeMode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
                    },
                    textTransform: 'none'
                  }}
                >
                  {isGoogleLoading ? t('signIn.signingInWithGoogle') : t('signIn.loginGoogleButton')}
                </Button>
              </Stack>
            </Box>
            <Grid container justifyContent="center">
              <Grid item>
                <Typography sx={{ fontFamily: 'Outfit, sans-serif', color: themeMode === "dark" ? '#fff' : '#181818', mt: 3 }}>
                  {t('signIn.dontHaveAccount')}{' '}
                  <Link
                    onClick={() => { navigate('/signup') }}
                    style={{ fontWeight: 'bold', textDecoration: 'none', cursor: 'pointer' }}
                  >
                    {t('signIn.createAccount')}
                  </Link>.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Grid>
  );
}