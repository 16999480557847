import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { useEffect, useState, useContext } from 'react';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { InputAdornment, IconButton, useMediaQuery, Stack, Divider, Tooltip, Select, MenuItem, FormControl, CircularProgress } from '@mui/material';
import whitelogo from '../../assets/whiteLogo.png';
import blackLogo from '../../assets/mainLogo.png';
import { useNavigate, useParams } from 'react-router-dom';
import { auth, googleProvider } from '../../services/firebase/firebase';
import { createUserWithEmailAndPassword, signInWithPopup, } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from '../../services/firebase/firebase';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import googleLogo from '../../assets/googleLogo.png';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import ModeNightIcon from '@mui/icons-material/ModeNight';
import { ConfigContext } from '../../context/Config/ConfigContext';
import { showToast } from '../../components/Toast/toast';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import { motion } from 'framer-motion';
import { getFunctions, httpsCallable } from 'firebase/functions';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

export default function SignUp() {
  const { lang } = useParams<{ lang?: string }>();

  const { t, i18n } = useTranslation('global');
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [currency, setCurrency] = useState('MXN');
  const [language, setLanguage] = useState('es');
  const [isLoading, setIsLoading] = useState(false);
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  const {
    resetConfigContextFromSignUp
  } = useContext(ConfigContext);

  const [hasChatbotConfig, setHasChatbotConfig] = useState(false);

  useEffect(() => {
    const config = localStorage.getItem('chatbotConfig');
    if (config) {
      setHasChatbotConfig(true);
    }
  }, []);

  const handleBack = () => {
    navigate('/create');
  };

  useEffect(() => {
    if (lang) {
      const validLang = ['en', 'es'].includes(lang) ? lang : 'en';
      setLanguage(validLang);
      i18n.changeLanguage(validLang);

      setCurrency(validLang === 'es' ? 'MXN' : 'USD');
    }
  }, [lang, i18n]);

  const handleLanguageChange = (e: any) => {
    const newLang = e.target.value as string;
    setLanguage(newLang);
    i18n.changeLanguage(newLang);

    setCurrency(newLang === 'es' ? 'MXN' : 'USD');
  };

  const { themeMode, toggleTheme } = React.useContext(ConfigContext);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const breakpointTheme = useTheme();
  const isMobile = useMediaQuery(breakpointTheme.breakpoints.down('md'));

  const translateFirebaseError = (error: Error) => {
    let errorCode = error.message;
    const matched = errorCode.match(/\(([^)]+)\)/);
    if (matched) {
      errorCode = matched[1];
    }

    switch (errorCode) {
      case 'auth/invalid-login-credentials':
        return t('firebaseErrors.invalidLoginCredentials');
      case 'auth/invalid-email':
        return t('firebaseErrors.invalidEmail');
      case 'auth/weak-password':
        return t('firebaseErrors.weakPassword');
      default:
        return error.message;
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const data = new FormData(event.currentTarget);
    const email = data.get('email') as string;
    const password = data.get('password') as string;
    const firstName = data.get('firstName') as string;
    const lastName = data.get('lastName') as string;

    if (!email || !password || !firstName || !lastName) {
      showToast('error', t('signUp.toasts.fillAllFields'), themeMode);
      setIsLoading(false);
      return;
    }

    try {
      // Crear usuario con Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (user) {
        // Obtener la configuración del chatbot si existe
        const chatbotConfig = localStorage.getItem('chatbotConfig');
        let parsedConfig = null;

        if (chatbotConfig) {
          try {
            parsedConfig = JSON.parse(chatbotConfig);
          } catch (error) {
            console.error('Error parsing chatbotConfig:', error);
            showToast('error', 'Error in chatbot configuration. Please try again.', themeMode);
            setIsLoading(false);
            return;
          }
        }

        // Llamar a la Cloud Function
        const functions = getFunctions();
        const completeSignUp = httpsCallable(functions, 'completeSignUp');

        await completeSignUp({
          email,
          firstName,
          lastName,
          currency,
          language,
          chatbotConfig: parsedConfig,
        });

        // Limpiar almacenamiento local y contexto
        if (chatbotConfig) {
          localStorage.removeItem('chatbotConfig');
          resetConfigContextFromSignUp();
        }

        localStorage.setItem('isNewUser', 'true');
        window.location.href = '/dashboard';
      }
    } catch (error: any) {
      if (error.code === 'auth/email-already-in-use') {
        showToast('error', t('signUp.toasts.accountExists'), themeMode);
      } else {
        showToast('error', translateFirebaseError(error), themeMode);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const signInWithGoogle = async () => {
    try {
      setIsGoogleLoading(true);
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      if (!user.email) {
        throw new Error('No email provided by Google');
      }

      // Verificar si el usuario ya existe
      const userDocRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(userDocRef);

      if (!docSnap.exists()) {
        const name = user.displayName ? user.displayName.split(' ') : ['', ''];
        const firstName = name[0];
        const lastName = name.length > 1 ? name.slice(1).join(' ') : '';

        // Obtener la configuración del chatbot si existe
        const chatbotConfig = localStorage.getItem('chatbotConfig');
        let parsedConfig = null;

        if (chatbotConfig) {
          try {
            parsedConfig = JSON.parse(chatbotConfig);
          } catch (error) {
            console.error('Error parsing chatbotConfig:', error);
            showToast('error', 'Error in chatbot configuration. Please try again.', themeMode);
            setIsGoogleLoading(false);
            return;
          }
        }

        // Llamar a la Cloud Function completeGoogleSignIn
        const functions = getFunctions();
        const completeGoogleSignIn = httpsCallable(functions, 'completeGoogleSignIn');

        await completeGoogleSignIn({
          email: user.email,
          firstName,
          lastName,
          currency,
          language,
          chatbotConfig: parsedConfig,
        });

        // Limpiar el almacenamiento local
        if (chatbotConfig) {
          localStorage.removeItem('chatbotConfig');
          resetConfigContextFromSignUp();
        }

        localStorage.setItem('isNewUser', 'true');
        window.location.href = '/dashboard';
      } else {
        showToast('error', t('signUp.toasts.userExistsRedirecting'), themeMode);
        localStorage.setItem('isNewUser', 'false');
        window.location.href = '/dashboard';
      }
    } catch (error) {
      if (error instanceof Error) {
        showToast('error', t('signUp.toasts.errorGoogleSignIn'), themeMode);
      } else {
        showToast('error', t('signUp.toasts.unexpectedErrorGoogleSignIn'), themeMode);
      }
    } finally {
      setIsGoogleLoading(false);
    }
  };

  const [passwordStrength, setPasswordStrength] = useState<{ strength: string; suggestions: string[] }>({ strength: '', suggestions: [] });

  const evaluatePasswordStrength = (password: string) => {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
    const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

    const suggestions = [];

    if (password.length < 8) {
      suggestions.push('length');
    }
    if (!password.match(/[a-z]/)) {
      suggestions.push('lowercase');
    }
    if (!password.match(/[A-Z]/)) {
      suggestions.push('uppercase');
    }
    if (!password.match(/[0-9]/)) {
      suggestions.push('numbers');
    }
    if (!password.match(/[!@#$%^&*]/)) {
      suggestions.push('specialChars');
    }

    if (strongRegex.test(password) && password.length >= 12) {
      return { strength: 'veryStrong', suggestions };
    } else if (strongRegex.test(password)) {
      return { strength: 'strong', suggestions };
    } else if (mediumRegex.test(password)) {
      return { strength: 'moderate', suggestions };
    } else {
      return { strength: 'weak', suggestions };
    }
  };

  const getPasswordStrengthStyle = (strength: string) => {
    switch (strength) {
      case "veryStrong":
        return {
          backgroundColor: themeMode === "dark" ? "#1e2c1e" : "#C8E6C9",
          color: "#388E3C",
          fontWeight: "bold",
        };
      case "strong":
        return {
          backgroundColor: themeMode === "dark" ? "#1e2c1e" : "#C8E6C9",
          color: "#388E3C",
          fontWeight: "bold",
        };
      case "moderate":
        return {
          backgroundColor: themeMode === "dark" ? "#4a3f05" : "#FFF9C4",
          color: "#FBC02D",
          fontWeight: "bold",
        };
      case "weak":
        return {
          backgroundColor: themeMode === "dark" ? "#4a1212" : "#FFCDD2",
          color: "#D32F2F",
          fontWeight: "bold",
        };
      default:
        return {
          backgroundColor: "transparent",
          color: themeMode === "dark" ? "#fafafa" : "#757575",
          fontWeight: "300",
        };
    }
  };

  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            fontFamily: 'Outfit',
            border: themeMode === "dark" ? '1px solid #272727' : '1px solid #eeeeee',
            backgroundColor: themeMode === "dark" ? "#181818" : "#fafafa",
            borderRadius: 15,
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#181818" : "#eeeeee",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',
            },
            "& .MuiInputBase-input": {
              fontFamily: 'Outfit',
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiInputLabel-root": {
              fontFamily: 'Outfit',
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#4a3aff" : '#4a3aff',
            },
            "& .MuiInputLabel-root.Mui-focused + .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#4a3aff" : '#4a3aff'
            },
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 15,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            fontFamily: 'Outfit',
            backgroundColor: themeMode === "dark" ? "#181818" : "#fafafa",
            color: themeMode === "dark" ? "#fafafa" : '#121212',
            textAlign: 'left',
            borderRadius: 15,
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#272727" : '#eeeeee',
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#272727" : "#eeeeee",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#4a3aff" : '#4a3aff',
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: themeMode === "dark" ? "#181818" : "#fff",
            color: themeMode === "dark" ? "#fafafa" : '#121212',
            borderRadius: 15,
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
            "& .MuiMenuItem-root": {
              fontFamily: 'Outfit',

            },
          },
        },
      },
    },
  });



  return (
    <Grid container sx={{ minHeight: '100vh', backgroundColor: themeMode === "dark" ? '#121212' : "#fff", }}>
      {hasChatbotConfig && (
        <IconButton
          onClick={handleBack}
          sx={{
            "&:focus": {
              outline: "none"
            },
            position: 'fixed', top: 20, left: 20, zIndex: 1300,
          }}>
          <ArrowBackIosNewRoundedIcon sx={{ color: '#c1c1c1' }} />
        </IconButton>
      )}

      <motion.div
        initial={{ scale: 0.9, opacity: 0 }} // Animación de inicio con escala reducida y opacidad 0
        animate={{ scale: 1, opacity: 1 }} // Se anima a escala 1 y opacidad 1
        transition={{ duration: 0.25 }} // Tiempo de transición
        style={{ width: '100%' }} // Estilo del contenedor
      >
        <IconButton onClick={() => toggleTheme()} sx={{
          "&:focus": {
            outline: "none"
          },
          position: 'fixed', top: 20, right: 20, zIndex: 1300,
        }}>
          {themeMode !== "dark" ? <ModeNightIcon sx={{ color: '#c1c1c1' }} /> : <LightModeRoundedIcon sx={{ color: '#c1c1c1' }} />}
        </IconButton>
        <Container component="main"
          maxWidth="xs" sx={{
            minHeight: '100vh',
            backgroundColor: themeMode === "dark" ? '#121212' : "#fff", textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            py: '20px'
          }}
        >
          <CssBaseline />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              component='img'
              alt='whitelogo'
              src={themeMode === "dark" ? whitelogo : blackLogo}
              sx={{
                height: '50px',
                mb: '50px'
              }}
            />
            <Typography component="h1" sx={{
              color: themeMode === "dark" ? '#fff' : '#181818',
              fontFamily: 'Outfit, sans-serif',
              fontSize: isMobile ? '18px' : '22px'
            }}>
              {t('signUp.title')}
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      autoComplete="given-name"
                      name="firstName"
                      required
                      fullWidth
                      id="firstName"
                      placeholder={t('signUp.firstNameLabel') as string}
                      autoFocus
                    />
                  </ThemeProvider>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      required
                      fullWidth
                      id="lastName"
                      placeholder={t('signUp.lastNameLabel') as string}
                      name="lastName"
                      autoComplete="family-name"
                    />
                  </ThemeProvider>
                </Grid>
                <Grid item xs={12}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      placeholder={t('signUp.emailLabel') as string}
                      name="email"
                      autoComplete="email"
                    />
                  </ThemeProvider>
                </Grid>
                <Grid item xs={12}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      placeholder={t('signUp.passwordLabel') as string}
                      type={showPassword ? 'text' : 'password'}
                      id="password"
                      autoComplete="new-password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleTogglePasswordVisibility}
                              onMouseDown={(event) => event.preventDefault()}
                              edge="end"
                            >
                              {showPassword
                                ? <VisibilityOffRoundedIcon style={{ color: '#c1c1c1' }} />
                                : <VisibilityRoundedIcon style={{ color: '#c1c1c1' }} />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => setPasswordStrength(evaluatePasswordStrength(e.target.value))}
                    />
                  </ThemeProvider>
                  <Box mt={1} textAlign="left">
                    {passwordStrength.strength && (
                      <Stack flexDirection="row" justifyContent="start" alignItems="center">
                        <Typography
                          variant="body2"
                          style={getPasswordStrengthStyle(passwordStrength.strength)}
                          sx={{
                            display: "inline-block",
                            padding: "4px 8px",
                            borderRadius: "100px",
                          }}
                        >
                          {t(`signUp.passwordStrength.${passwordStrength.strength}`)}
                        </Typography>
                        <Tooltip
                          title={
                            <>
                              {t(`signUp.passwordStrength.${passwordStrength.strength}`)}
                              {passwordStrength.suggestions.length > 0 && (
                                <ul>
                                  {passwordStrength.suggestions.map((suggestion) => (
                                    <li key={suggestion}>{t(`signUp.passwordStrength.suggestions.${suggestion}`)}</li>
                                  ))}
                                </ul>
                              )}
                            </>
                          }
                          componentsProps={{
                            tooltip: {
                              sx: {
                                backgroundColor: themeMode === "dark" ? "#181818" : "#fafafa",
                                borderRadius: "20px",
                                color: themeMode !== "dark" ? "#181818" : "#fafafa",
                                padding: "12px",
                                fontSize: "14px",
                                fontWeight: "normal",
                                lineHeight: "1.5",
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                "& ul": {
                                  paddingLeft: "20px",
                                  marginTop: "8px",
                                },
                                "& li": {
                                  marginBottom: "4px",
                                },
                              },
                            },
                          }}
                        >
                          <HelpOutlineRoundedIcon sx={{ fontSize: '20px', color: '#c1c1c1' }} />
                        </Tooltip>
                      </Stack>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <ThemeProvider theme={theme}>
                    <FormControl fullWidth>
                      <Select
                        value={currency}
                        onChange={(e) => setCurrency(e.target.value)}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{
                          color: themeMode === 'dark' ? '#ffffff' : '#181818',
                          bgcolor: themeMode === 'dark' ? '#181818' : '#fafafa',
                          '& .MuiSvgIcon-root': {
                            color: themeMode === 'dark' ? '#ffffff' : '#c1c1c1',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: themeMode === 'dark' ? '#272727' : '#eeeeee',
                          },
                          borderRadius: '15px',
                        }}
                        MenuProps={{
                          sx: {
                            '& .MuiPaper-root': {
                              bgcolor: themeMode === 'dark' ? '#181818' : '#fff',
                              color: themeMode === 'dark' ? '#ffffff' : '#333',
                              borderRadius: '15px',
                              boxShadow: themeMode === "dark"
                                ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                                : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                            },
                            '& .MuiMenu-list .MuiMenuItem-root': {
                              '&:hover': {
                                bgcolor: themeMode === 'light' ? '#fafafa' : '',
                              },
                            },
                          },
                        }}
                      >
                        <MenuItem value="" disabled>
                          <em>{t('signUp.currencyLabel')}</em>
                        </MenuItem>
                        <MenuItem value="USD">USD</MenuItem>
                        <MenuItem value="MXN">MXN</MenuItem>
                      </Select>
                    </FormControl>
                  </ThemeProvider>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <ThemeProvider theme={theme}>
                    <FormControl fullWidth>
                      <Select
                        value={language}
                        onChange={handleLanguageChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{
                          color: themeMode === 'dark' ? '#ffffff' : '#181818',
                          bgcolor: themeMode === 'dark' ? '#181818' : '#fafafa',
                          '& .MuiSvgIcon-root': {
                            color: themeMode === 'dark' ? '#ffffff' : '#c1c1c1',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: themeMode === 'dark' ? '#272727' : '#eeeeee',
                          },
                          borderRadius: '15px',
                        }}
                        MenuProps={{
                          sx: {
                            '& .MuiPaper-root': {
                              bgcolor: themeMode === 'dark' ? '#181818' : '#fff',
                              color: themeMode === 'dark' ? '#ffffff' : '#333',
                              borderRadius: '15px',
                              boxShadow: themeMode === "dark"
                                ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                                : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                            },
                            '& .MuiMenu-list .MuiMenuItem-root': {
                              '&:hover': {
                                bgcolor: themeMode === 'light' ? '#fafafa' : '',
                              },
                            },
                          },
                        }}
                      >
                        <MenuItem value="" disabled>
                          <em>{t('signUp.languageLabel')}</em>
                        </MenuItem>
                        <MenuItem value="en">English</MenuItem>
                        <MenuItem value="es">Español</MenuItem>
                      </Select>
                    </FormControl>
                  </ThemeProvider>
                </Grid>
              </Grid>
              <Button
                variant="outlined"
                type='submit'
                disabled={isLoading}
                startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
                sx={{
                  color: '#fff',
                  backgroundColor: '#4a3aff',
                  fontSize: '20px',
                  fontWeight: '500',
                  borderRadius: '100px',
                  borderColor: "transparent",
                  mt: '40px',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  fontFamily: 'Outfit',
                  width: '100%',
                  maxWidth: '220px',
                  '&:hover': {
                    borderColor: '#4a3aff',
                    backgroundColor: 'transparent',
                    color: themeMode === "dark" ? 'white' : '#181818',
                    borderWidth: 1,
                    borderStyle: 'solid',
                  },
                  '&:focus': {
                    outline: 'none',
                  },
                  '&.Mui-disabled': {
                    backgroundColor: themeMode === "dark" ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
                    color: themeMode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
                  },
                  textTransform: 'none'
                }}
              >
                {isLoading ? t('signUp.creatingAccount') : t('signUp.createAccountButton')}
              </Button>
              <Box sx={{ mt: '20px' }}>
                <Divider sx={{
                  mb: '20px',
                  backgroundColor: themeMode === "dark" ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
                  maxWidth: isMobile ? '80vw' : 'auto',
                  mx: 'auto'
                }} />

                <Stack flexDirection="row" justifyContent="center" alignItems="center" gap={2}>
                  <Button
                    onClick={signInWithGoogle}
                    variant="outlined"
                    type="button"
                    disabled={isGoogleLoading}
                    sx={{
                      color: themeMode === "dark" ? '#fafafa' : '#181818',
                      backgroundColor: 'transparent',
                      fontSize: '20px',
                      fontWeight: '500',
                      borderRadius: '100px',
                      borderWidth: 1,
                      borderStyle: 'solid',
                      fontFamily: 'Outfit',
                      minWidth: '180px',
                      borderColor: '#4a3aff',
                      '&:hover': {
                        borderColor: '#4a3aff',
                        backgroundColor: '#fff',
                        color: '#181818',
                        borderWidth: 1,
                        borderStyle: 'solid',
                      },
                      '&:focus': {
                        outline: 'none',
                      },
                      '&.Mui-disabled': {
                        backgroundColor: themeMode === "dark" ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
                        color: themeMode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
                      },
                      textTransform: 'none'
                    }}
                    startIcon={
                      isGoogleLoading ?
                        <CircularProgress size={20} color="inherit" /> :
                        <img src={googleLogo} width="30px" alt="Google logo" />
                    }
                  >
                    {isGoogleLoading ? t('signUp.signingInWithGoogle') : t('signUp.createAccountGoogleButton')}
                  </Button>
                </Stack>
              </Box>
              {
                !hasChatbotConfig && (
                  <Grid container justifyContent="center">
                    <Grid item>
                      <Typography sx={{ fontFamily: 'Outfit, sans-serif', color: themeMode === "dark" ? '#fff' : '#181818', mt: 3 }}>
                        {t('signUp.alreadyHaveAccount')} <Link onClick={() => navigate('/signin')} style={{ fontWeight: 'bold', textDecoration: 'none', cursor: 'pointer' }}>
                          {t('signUp.signIn')}
                        </Link>.
                      </Typography>
                    </Grid>
                  </Grid>
                )
              }

            </Box>
          </Box>
        </Container>
      </motion.div>

    </Grid>
  );
}