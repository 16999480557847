import { Box, Typography, Button, useTheme, Stack, IconButton, useMediaQuery } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { tokens } from "../theme";
import { useNavigate } from 'react-router-dom';
import { ConfigContext } from "../context/Config/ConfigContext";

interface SectionContainerProps {
  title?: string;
  icon?: React.ReactNode;
  text?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  disableButton?: boolean;
  date?: boolean;
  individualChatbot?: boolean;
  textSecondButton?: string;
  iconSecondButton?: React.ReactNode;
  onClickSecondButton?: () => void;
}

function SectionContainer({
  title,
  icon,
  text,
  children,
  onClick,
  disableButton,
  date,
  individualChatbot,
  textSecondButton,
  iconSecondButton,
  onClickSecondButton
}: SectionContainerProps) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();


  const [currentDate, setCurrentDate] = useState('');

  useEffect(() => {
    // Función para obtener la fecha actual en el formato deseado
    const getCurrentDate = () => {
      const dateObj = new Date();
      const day = String(dateObj.getDate());
      const month = dateObj.toLocaleString('es-ES', { month: 'long' });
      const year = dateObj.getFullYear();
      const formattedDate = `${day} de ${month} del ${year}`;
      return formattedDate;
    };

    // Actualizar el estado con la fecha actual al cargar el componente
    setCurrentDate(getCurrentDate());
  }, []);

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { themeMode } = useContext(ConfigContext)

  return (
    <Box
      sx={{
        p: !isMobile ? '50px' : '10px',
        pt: '20px',
        backgroundColor: themeMode === 'dark' ? "#121212" : "#fafafa",
        height: 'calc(100vh-500px)',
        zIndex: 0
      }}
    >

      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: '20px' }}>
        <Stack flexDirection="row" alignItems="center" justifyContent="start"
          sx={{ minWidth: isMobile ? '150px' : 'auto' }}
        >
          {
            individualChatbot && (
              <IconButton
                onClick={() => navigate(-1)}
                sx={{
                  height: '40px',
                  width: '40px',
                  mr: isMobile ? '5px' : '15px',
                  '&:focus': { outline: 'none' },
                  '&:hover': { bgcolor: themeMode === 'dark' ? '#181818' : '#f1f1f1' }
                }}
              >
                <ArrowBackIosNewRoundedIcon sx={{ fontSize: '25px', mr: '4px', color: themeMode === 'dark' ? '#fff' : '#181818' }} />
              </IconButton>

            )
          }
          <Box>
            {
              date && (
                <Typography sx={{ fontSize: isMobile ? '12px' : '16px', color: 'grey', fontFamily: 'Outfit' }}>
                  {currentDate}
                </Typography>
              )
            }
            <Typography sx={{
              fontSize: isMobile ? '22px' : '30px',
              fontFamily: 'Outfit', color: themeMode === 'dark' ? 'white' : '#181818',
              lineHeight: 1.1,
              ml: '10px'
            }}>
              {title}
            </Typography>
          </Box>

        </Stack>

        <Stack flexDirection="row" justifyContent="center" alignItems="center">
          {
            text && (
              // isMobile ? (
              //   <IconButton
              //     onClick={onClick}
              //     disabled={disableButton}
              //     sx={{
              //       height: '50px',
              //       backgroundColor: disableButton ? colors.grey[800] : '#4a3aff',
              // lineHeight: 1,
              //       color: '#fff',
              //       fontSize: isMobile ? '16px' : "16px",
              //       fontWeight: "500",
              //       borderRadius: '100px',
              //       borderColor: "transparent",
              //       borderWidth: 1,
              //       borderStyle: 'solid',
              //       fontFamily: 'Outfit',
              //       '&:hover': {
              //         borderColor: '#4a3aff',
              //         color: themeMode === 'dark' ?  "white" : '#4a3aff',
              //         backgroundColor: 'transparent',
              //         borderWidth: 1,
              //         borderStyle: 'solid',
              //       },
              //       '&:focus': {
              //         outline: 'none',
              //       },
              //
              //     }}>
              //     {icon}
              //   </IconButton>
              // ) : (
              <Button
                onClick={onClick}
                disabled={disableButton}
                sx={{
                  height: '48px',
                  backgroundColor: disableButton ? themeMode === 'dark' ? colors.grey[800] : '#e8e8e8' : '#4a3aff',
                  lineHeight: 1,
                  color: disableButton ? themeMode === 'dark' ? '#fff' : '#4c4c4c4d' : '#fff',
                  '&.Mui-disabled': {
                    backgroundColor: themeMode === "dark" ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
                    color: themeMode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
                  },
                  fontSize: isMobile ? '16px' : "16px",
                  fontWeight: "500",
                  textTransform: "none",
                  padding: "10px 20px",
                  borderRadius: '100px',
                  borderColor: "transparent",
                  borderWidth: 1,
                  borderStyle: 'solid',
                  fontFamily: 'Outfit',
                  '&:hover': {
                    borderColor: '#4a3aff',
                    color: themeMode === 'dark' ? "white" : '#181818',
                    backgroundColor: 'transparent',
                    borderWidth: 1,
                    borderStyle: 'solid',
                  },
                  '&:focus': {
                    outline: 'none',
                  },
                  ml: '10px'
                }}
                startIcon={icon}
              >
                {text}
              </Button>
            )

            // )
          }
          {
            textSecondButton && (
              isMobile ? (
                <IconButton
                  onClick={onClickSecondButton}
                  sx={{
                    height: '50px',
                    backgroundColor: disableButton ? colors.grey[800] : '#4a3aff',
                    lineHeight: 1,
                    color: '#fff',
                    fontSize: isMobile ? '16px' : "16px",
                    fontWeight: "500",
                    textTransform: "none",
                    borderRadius: '100px',
                    borderColor: "transparent",
                    borderWidth: 1,
                    borderStyle: 'solid',
                    fontFamily: 'Outfit',
                    '&:hover': {
                      borderColor: '#4a3aff',
                      color: themeMode === 'dark' ? "white" : '#4a3aff',
                      backgroundColor: 'transparent',
                      borderWidth: 1,
                      borderStyle: 'solid',
                    },
                    '&:focus': {
                      outline: 'none',
                    },
                    ml: '10px'
                  }}>
                  {iconSecondButton}
                </IconButton>
              ) : (
                <Button
                  onClick={onClickSecondButton}
                  sx={{
                    height: '50px',
                    backgroundColor: '#4a3aff',
                    color: '#fff',
                    fontSize: isMobile ? '16px' : "16px",
                    fontWeight: "500",
                    textTransform: 'none',
                    padding: "10px 20px",
                    borderRadius: '100px',
                    borderColor: "transparent",
                    borderWidth: 1,
                    borderStyle: 'solid',
                    fontFamily: 'Outfit',
                    '&:hover': {
                      borderColor: '#4a3aff',
                      color: themeMode === 'dark' ? "white" : '#4a3aff',
                      backgroundColor: 'transparent',
                      borderWidth: 1,
                      borderStyle: 'solid',
                    },
                    '&:focus': {
                      outline: 'none',
                    },
                    ml: '10px'
                  }}
                  startIcon={iconSecondButton}
                >
                  {textSecondButton}
                </Button>
              )

            )
          }

        </Stack>

      </Box>
      <Box>
        {children}
      </Box>
    </Box >
  )
}

export default SectionContainer;
