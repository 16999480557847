import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import global_en from './translations/en/global.json';
import global_es from './translations/es/global.json';
import global_fr from './translations/fr/global.json';
import global_pt from './translations/pt/global.json';
import global_de from './translations/de/global.json';
import global_it from './translations/it/global.json';
import global_ko from './translations/ko/global.json';
import global_ar from './translations/ar/global.json';
import global_ru from './translations/ru/global.json';
import global_hi from './translations/hi/global.json';
import global_ja from './translations/ja/global.json';
import global_id from './translations/id/global.json';
import global_zh from './translations/zh/global.json';

export default i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    defaultNS: 'global',
    interpolation: { escapeValue: false },
    // Agregar nuevos idiomas a supportedLngs
    supportedLngs: ['en', 'es', 'fr', 'pt', 'de', 'it', 'ko', 'ar', 'ru', 'hi', 'ja', 'id', 'zh'],
    fallbackLng: 'es',
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
    },
    resources: {
      en: { global: global_en },
      es: { global: global_es },
      fr: { global: global_fr },
      pt: { global: global_pt },
      de: { global: global_de },
      it: { global: global_it },
      ko: { global: global_ko },
      ar: { global: global_ar },
      ru: { global: global_ru },
      hi: { global: global_hi },
      ja: { global: global_ja },
      id: { global: global_id },
      zh: { global: global_zh },
    },
  });
