import { useEffect, useState, useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { Box, Stack, Typography } from "@mui/material";
import { ConfigContext } from "../../context/Config/ConfigContext";
import { db, auth } from '../../services/firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { collection, getDocs, query, where } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { startOfWeek, subWeeks, format } from 'date-fns';

function getWeekNumber(date: Date): number {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}

const MessageCount = (props: any) => {
  const { t } = useTranslation('global');
  const [user] = useAuthState(auth);
  const { themeMode } = useContext(ConfigContext);

  const [seriesData, setSeriesData] = useState<any[]>([]);
  const [categories, setCategories] = useState<string[]>([]);

  const fetchMetrics = async (chatbotId: string, viewMode: string) => {
    const currentYear = new Date().getFullYear();
    const metricsRef = collection(db, 'chatbots', chatbotId, 'metrics');
    const querySnapshot = await getDocs(metricsRef);

    const metrics: { [key: string]: { totalMessages: number; totalConversations: number } } = {};

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const docIdParts = doc.id.split('-');
      if (parseInt(docIdParts[1]) === currentYear) {
        if (viewMode === 'monthly' && docIdParts[0] === 'monthly') {
          const month = parseInt(docIdParts[2]);
          metrics[`${month}`] = {
            totalMessages: data.totalMessages ? data.totalMessages : 0,
            totalConversations: data.totalConversations ? data.totalConversations : 0,
          };
        } else if (viewMode === 'weekly' && docIdParts[0] === 'weekly') {
          const week = parseInt(docIdParts[2]);
          metrics[`${week}`] = {
            totalMessages: data.totalMessages ? data.totalMessages : 0,
            totalConversations: data.totalConversations ? data.totalConversations : 0,
          };
        }
      }
    });

    return metrics;
  };

  const generateCategories = (viewMode: string, endDate: Date, count: number) => {
    const categories: string[] = [];
    let currentDate = new Date(endDate);

    for (let i = count - 1; i >= 0; i--) {
      if (viewMode === 'monthly') {
        categories.unshift(currentDate.toLocaleString('default', { month: 'short' }) + ' ' + currentDate.getFullYear());
        currentDate.setMonth(currentDate.getMonth() - 1);
      } else if (viewMode === 'weekly') {
        const weekEndFormatted = format(currentDate, 'd MMM yy', { weekStartsOn: 1 });
        categories.unshift(weekEndFormatted);
        currentDate = subWeeks(currentDate, 1);
      }
    }

    return categories;
  };

  useEffect(() => {
    const currentDate = new Date();
    let endDate: Date;
    let count: number;

    if (props.viewMode === 'monthly') {
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      count = 8;
    } else if (props.viewMode === 'weekly') {
      endDate = startOfWeek(currentDate, { weekStartsOn: 1 });
      count = 12;
    } else {
      return;
    }

    const categories = generateCategories(props.viewMode, endDate, count);
    setCategories(categories);
  }, [props.viewMode]);

  useEffect(() => {
    const loadData = async () => {
      if (!user) return;

      const chatbotsRef = collection(db, 'chatbots');
      const q = query(chatbotsRef, where("ownerId", "==", user.uid));
      const querySnapshot = await getDocs(q);

      const metrics: { [key: string]: { totalMessages: number; totalConversations: number } } = {};

      for (const doc of querySnapshot.docs) {
        const chatbotMetrics = await fetchMetrics(doc.id, props.viewMode);
        Object.keys(chatbotMetrics).forEach((key) => {
          if (!metrics[key]) {
            metrics[key] = { totalMessages: 0, totalConversations: 0 };
          }
          metrics[key].totalMessages += chatbotMetrics[key].totalMessages;
          metrics[key].totalConversations += chatbotMetrics[key].totalConversations;
        });
      }

      const currentDate = new Date();
      const currentIndex = props.viewMode === 'monthly' ? currentDate.getMonth() + 1 : getWeekNumber(currentDate);
      const startIndex = currentIndex - (props.viewMode === 'monthly' ? 7 : 11);

      const displayedMetrics = Array.from({ length: props.viewMode === 'monthly' ? 8 : 12 }, (_, i) => {
        const key = `${startIndex + i}`;
        return metrics[key] || { totalMessages: 0, totalConversations: 0 };
      });

      setSeriesData([
        {
          name: t('dashboard.stats.messages') || "",
          data: displayedMetrics.map(m => m.totalMessages)
        },
        {
          name: t('dashboard.stats.conversations') || "",
          data: displayedMetrics.map(m => m.totalConversations)
        },
      ]);
    };

    loadData();
  }, [user, props.viewMode]);

  const options: ApexOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    colors: ["#4a3aff", "#9687ff"],
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
        columnWidth: "75%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    stroke: {
      colors: ["transparent"],
      width: 2,
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      title: {
        text: t('dashboard.stats.number') || "",
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
    tooltip: {
      theme: themeMode === 'dark' ? 'dark' : 'light', // Cambia el tema del tooltip según el modo
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val, { seriesIndex }) {
          let label = seriesIndex === 0 ? t('dashboard.stats.messages') : t('dashboard.stats.conversations');
          return `${val} ${label}`;
        }
      },
      // Personalización adicional para el tooltip
      marker: {
        show: true,
      },
      // Para cambiar el color de fondo y el texto del tooltip manualmente
      custom: function ({ series, seriesIndex, dataPointIndex }) {
        const bgColor = themeMode === 'dark' ? '#333' : '#fff';
        const textColor = themeMode === 'dark' ? '#fff' : '#333';
        // Asumiendo que seriesIndex 0 es para "Mensajes" y seriesIndex 1 es para "Conversaciones"
        const label = seriesIndex === 0 ? t('dashboard.stats.messages') || "" : t('dashboard.stats.conversations') || "";
        return `<div style="background: ${bgColor}; color: ${textColor}; padding: 10px; border-radius: 5px; font-family: Outfit;">
      ${series[seriesIndex][dataPointIndex]} ${label}
    </div>`;
      }
    },
  };

  return (
    <Box sx={{ p: 3, bgcolor: themeMode === 'dark' ? '#181818' : '#fff', borderRadius: "20px" }}>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography sx={{ mb: 3, fontSize: "18px", fontWeight: "400", color: themeMode === 'dark' ? "#fff" : "#000" }}>
          {t('dashboard.stats.messageCount')}
        </Typography>
      </Stack>
      <ReactApexChart
        options={options}
        series={seriesData}
        type="bar"
        height={350}
      />
    </Box>
  );
};

export default MessageCount;