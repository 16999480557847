import React, { useState, useEffect, useRef } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getFirestore, collection, query, where, getDocs, addDoc } from 'firebase/firestore';
import axios from 'axios';
import { showToast } from '../../components/Toast/toast';
import { getAuth } from 'firebase/auth';

const AudioUploader = () => {
  const [audioFile, setAudioFile] = useState<File | null>(null);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [key, setKey] = useState('');
  const [description, setDescription] = useState('');
  const [text, setText] = useState('');
  const [voice, setVoice] = useState('alloy');
  const [chatbots, setChatbots] = useState<any[]>([]);
  const [selectedChatbotId, setSelectedChatbotId] = useState('');
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    fetchChatbots();
  }, []);

  const fetchChatbots = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      const db = getFirestore();
      const chatbotsRef = collection(db, 'chatbots');
      const q = query(chatbotsRef, where('ownerId', '==', user.uid));
      const querySnapshot = await getDocs(q);
      const chatbotsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setChatbots(chatbotsList);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setAudioFile(file);
      setAudioUrl(URL.createObjectURL(file));
    }
  };

  const generateAudio = async () => {
    try {
      const response = await axios.post('https://api.openai.com/v1/audio/speech', {
        model: "tts-1",
        voice: voice,
        input: text
      }, {
        headers: {
          'Authorization': `Bearer ${process.env.VITE_APP_OPENAI_API_KEY}`,
          'Content-Type': 'application/json'
        },
        responseType: 'arraybuffer'
      });
      const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
      const audioFile = new File([audioBlob], 'generated_audio.mp3', { type: 'audio/mpeg' });
      setAudioFile(audioFile);
      setAudioUrl(URL.createObjectURL(audioBlob));
    } catch (error) {
      console.error('Error generating audio:', error);
      showToast('error', 'Error generating audio', 'dark');
    }
  };

  const handleUpload = async () => {
    if (!audioFile || !selectedChatbotId) return;
    const storage = getStorage();
    const db = getFirestore();
    try {
      const storageRef = ref(storage, `audio_responses/${Date.now()}_${audioFile.name}`);
      await uploadBytes(storageRef, audioFile);
      const downloadURL = await getDownloadURL(storageRef);
      await addDoc(collection(db, 'audio_responses'), {
        audioUrl: downloadURL,
        key: key,
        chatbotIds: [selectedChatbotId],
        description: description
      });
      showToast('success', 'Audio subido exitosamente', 'dark');
      setAudioFile(null);
      setAudioUrl(null);
      setKey('');
      setDescription('');
      setText('');
      setSelectedChatbotId('');
    } catch (error) {
      console.error('Error al subir el audio:', error);
      showToast('error', 'Error al subir el audio', 'dark');
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', maxWidth: '600px', gap: '20px', width: '100%', padding: '100px' }}>
      <input type="file" accept="audio/*" onChange={handleFileChange} />
      <input
        type="text"
        placeholder="Clave del audio"
        value={key}
        onChange={(e) => setKey(e.target.value)}
      />
      <textarea
        placeholder="Descripción"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <textarea
        placeholder="Texto para generar audio"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <select value={voice} onChange={(e) => setVoice(e.target.value)}>
        <option value="alloy">Alloy</option>
        <option value="echo">Echo</option>
        <option value="fable">Fable</option>
        <option value="onyx">Onyx</option>
        <option value="nova">Nova</option>
        <option value="shimmer">Shimmer</option>
      </select>
      <select
        value={selectedChatbotId}
        onChange={(e) => setSelectedChatbotId(e.target.value)}
      >
        <option value="">Selecciona un chatbot</option>
        {chatbots.map(chatbot => (
          <option key={chatbot.id} value={chatbot.id}>
            {chatbot.companyName || chatbot.id}
          </option>
        ))}
      </select>
      <button onClick={generateAudio} disabled={!text}>Generar Audio</button>
      {audioUrl && (
        <audio ref={audioRef} controls src={audioUrl}>
          Tu navegador no soporta el elemento de audio.
        </audio>
      )}
      <button onClick={handleUpload} disabled={!audioFile || !key || !selectedChatbotId}>
        Subir Audio
      </button>
    </div>
  );
};

export default AudioUploader;