/* eslint-disable import/no-unresolved */
import { useContext, useState } from 'react';
import {
  Avatar, TextField, Box, Typography, Stack, IconButton, Button, useMediaQuery, useTheme, InputAdornment, CircularProgress,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import SectionContainer from '../../global/SectionContainer';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
import StoreContext from '../../context/Store/storeContext';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../context/Config/ConfigContext';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../../services/firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import DialogCommon from '../../components/DialogCommon/DialogCommon';
import { deleteUser, EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { showToast } from '../../components/Toast/toast';

function ClientProfile() {

  const { themeMode } = useContext(ConfigContext);

  const { t } = useTranslation('global')

  const { user } = useContext(StoreContext);
  const [userAuth] = useAuthState(auth);

  const [image, setImage] = useState(user?.photoURL);

  const [name, setName] = useState(user.firstName || '');
  const [lastName, setLastName] = useState(user.lastName || '');
  const [email, setEmail] = useState(user.email || '');

  const hanCambiadoLosDatos = () => {
    return name !== user.firstName || lastName !== user.lastName || email !== user.email || image !== user.photoURL
  };

  function getInitials(name: any, lastName: any) {
    return `${name[0] ?? ''}${lastName[0] ?? ''}`;
  }

  const handleImageChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const allowedExtensions = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];
      if (!allowedExtensions.includes(file.type)) {
        showToast('error', t('clientProfile.invalidFileType'), themeMode)
        return;
      }

      const reader = new FileReader();

      reader.onload = (e: any) => {
        if (e.target.readyState === FileReader.DONE) {
          const { result } = e.target;
          if (typeof result === 'string') {
            setImage(result);
          } else {
            showToast('error', t('clientProfile.errorResultNotString'), themeMode)
          }
        }
      };

      reader.onerror = () => {
        showToast('error', t('clientProfile.errorReadingFile'), themeMode)
      };

      reader.readAsDataURL(file);
    }
  };

  const uploadImageToFirebase = async () => {
    if (!userAuth || userAuth.uid === undefined) {
      showToast('error', t('clientProfile.userNotAuthenticated'), themeMode)
      return;
    }

    if (!image) {
      showToast('error', t('clientProfile.imageNotSelected'), themeMode)
      return;
    }

    try {
      const storage = getStorage();
      const storageRef = ref(storage, `profileImages/${userAuth.uid}`);
      const imageData = image.split(',')[1];
      const bytes = Uint8Array.from(atob(imageData), (char) => char.charCodeAt(0));
      await uploadBytes(storageRef, bytes);
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (error) {
      showToast('error', t('clientProfile.errorUploadingImage'), themeMode)
      return null;
    }
  };

  const navigate = useNavigate()


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  function mapSubscriptionTypeToPlanName(subscriptionType: any) {

    switch (subscriptionType) {
      case "free":
        return t('clientSubscription.plans.free.name');

      // Agrupa todos los planes mensuales y anuales de Basic bajo el mismo nombre
      case "basicMonthlyMXN":
      case "basicAnnualMXN":
      case "basicMonthlyUSD":
      case "basicAnnualUSD":
        return t('clientSubscription.plans.basic.name');

      // Agrupa todos los planes mensuales y anuales de Premium bajo el mismo nombre
      case "premiumMonthlyMXN":
      case "premiumAnnualMXN":
      case "premiumMonthlyUSD":
      case "premiumAnnualUSD":
        return t('clientSubscription.plans.premium.name');

      // Agrupa todos los planes mensuales y anuales de Business bajo el mismo nombre
      case "enterpriseMonthlyMXN":
      case "enterpriseAnnualMXN":
      case "enterpriseMonthlyUSD":
      case "enterpriseAnnualUSD":
        return t('clientSubscription.plans.business.name');

      default:
        return t('clientSubscription.plans.free.name');
    }
  }

  const themee = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            fontFamily: 'Outfit',
            color: '#7c7c7c',
            backgroundColor: themeMode === "dark" ? "#181818" : "#fafafa",
            borderRadius: 15,
            border: themeMode === "dark" ? '1px solid #272727' : '1px solid #eeeeee',
            // Establecer el fondo aquí
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',

            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#181818" : "#fafafa",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',

            },
            "& .MuiInputBase-input": {
              fontFamily: 'Outfit',
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiInputLabel-root": {
              fontFamily: 'Outfit',
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#4a3aff" : '#4a3aff',

            },
            "& .MuiInputLabel-root.Mui-focused + .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#4a3aff" : '#4a3aff'

            },
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 15,
          },
        },
      },
    }
  });

  const [openUpdatePasswordDialog, setOpenUpdatePasswordDialog] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const handleOpenUpdatePasswordDialog = () => {
    setOpenUpdatePasswordDialog(true);
  };

  const handleCloseUpdatePasswordDialog = () => {
    setOpenUpdatePasswordDialog(false);

  };

  const handleConfirmUpdatePasswordSubscription = async () => {
    try {
      if (userAuth && userAuth.email) {
        const credential = EmailAuthProvider.credential(userAuth.email, currentPassword);
        await reauthenticateWithCredential(userAuth, credential);

        if (currentPassword === newPassword) {
          showToast('error', t('clientProfile.newPasswordSameAsCurrent'), themeMode)
          return;
        }

        await updatePassword(userAuth, newPassword);
        showToast('success', t('clientProfile.passwordUpdatedSuccessfully'), themeMode)
        handleCloseUpdatePasswordDialog();
      } else {
        showToast('error', t('clientProfile.emailNotAvailable'), themeMode)
      }
    } catch (error: any) {
      showToast('error', t('clientProfile.errorUpdatingPassword'), themeMode)
      if (error.code === "auth/invalid-login-credentials") {
        showToast('error', t('clientProfile.currentPasswordIncorrect'), themeMode)
      } else {
        showToast('error', t('clientProfile.errorUpdatingPassword'), themeMode)
      }
    }
  };

  const { fetchUser } = useContext(StoreContext);

  const [isSaving, setIsSaving] = useState(false);

  const saveChanges = async () => {
    setIsSaving(true);

    if (!userAuth || userAuth.uid === undefined) {
      showToast('error', t('clientProfile.noAuthenticatedUser'), themeMode)
      return;
    }
    const userDocRef = doc(db, "users", userAuth.uid);

    let photoURL = user.photoURL ?? "";

    if (image !== user.photoURL) {
      photoURL = await uploadImageToFirebase();
    }

    try {
      await updateDoc(userDocRef, {
        firstName: name,
        lastName: lastName,
        email: email,
        photoURL: photoURL,
      });
      showToast('success', t('clientProfile.detailsUpdatedSuccessfully'), themeMode)
      fetchUser(userAuth.uid);
      setIsSaving(false);
    } catch (error: any) {
      showToast('error', t('clientProfile.errorUpdatingUserDetails'), themeMode)
      setIsSaving(false);
    }
  };


  const [openDeleteAccountDialog, setOpenDeleteAccountDialog] = useState(false);

  const handleOpenDeleteAccountDialog = () => {
    setOpenDeleteAccountDialog(true);
  };

  const handleCloseDeleteAccountDialog = () => {
    setOpenDeleteAccountDialog(false);
  };


  const deleteUserAccount = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        await deleteDoc(doc(db, 'users', user.uid));

        await deleteUser(user);

      } else {
        showToast('error', t('clientProfile.noAuthenticatedUser'), themeMode)
      }
    } catch (error: any) {
      showToast('error', t('clientProfile.errorDeletingUserAccount'), themeMode)
    }
  };

  const handleConfirmDeleteAccount = async () => {

    try {
      await deleteUserAccount();
      showToast('success', t('clientProfile.accountDeletedSuccessfully'), themeMode)
      window.location.href = '/signup';
    } catch (error) {
      showToast('error', t('clientProfile.errorDeletingUser'), themeMode)
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <SectionContainer title={t('clientProfile.title') as string} text={isSaving ? t('clientProfile.savingData') as string : t('clientProfile.save') as string}
      icon={isSaving ? <CircularProgress size="25px" /> : <SaveAltRoundedIcon />} onClick={saveChanges}
      disableButton={!hanCambiadoLosDatos() || isSaving}
    >
      <Box sx={{
        p: isMobile ? 3 : 10,
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        justifyContent: 'center',
        gap: 4,
        backgroundColor: themeMode === "dark" ? '#181818' : '#fff',
        mb: isMobile ? '30px' : '0px',
        borderRadius: '30px',
        boxShadow: themeMode === "dark"
          ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
          : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
      }}
      >
        <Box sx={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, px: '32px',
          borderRight: { md: themeMode === "dark" ? '1px solid #3c3c3c' : '1px solid #c1c1c1', xs: 'none' }, position: 'relative',
        }}
        >
          <Avatar
            alt={t('clientProfile.avatarAlt') as string}
            src={image}
            sx={{
              width: isMobile ? 150 : 190, height: isMobile ? 150 : 190, fontSize: '60px', fontFamily: 'Outfit',
              color: '#fafafa'
            }}
          >
            {getInitials(user.firstName, user.lastName)}
          </Avatar>
          <input
            accept="image/png, image/jpeg, image/jpg, image/webp"
            type="file"
            hidden
            id="raised-button-file"
            onChange={handleImageChange}
          />
          <label htmlFor="raised-button-file">
            <IconButton
              component="span"
              sx={{
                position: 'absolute',
                bottom: isMobile ? 60 : 70,
                border: '1px solid #4a3aff',
                color: '#fafafa',
                right: isMobile ? 30 : 40,
                backgroundColor: '#4a3aff',
                borderRadius: '100px',
              }}
            >
              <EditIcon color="action" />
            </IconButton>
          </label>
          <Button
            sx={{
              textTransform: 'none',
              ':focus': { outline: 'none' },
              borderRadius: '100px',
              color: themeMode === "dark" ? '#fafafa' : '#181818',
              p: '4px 16px',
              border: '1px solid #4a3aff',
              height: '30px'
            }}
            onClick={() => navigate('/subscription')}
          >
            <Typography
              variant="subtitle1"
              sx={{
                backgroundColor: 'transparent',
                color: themeMode === "dark" ? '#fafafa' : '#fff',
                fontSize: '16px',
                borderRadius: '100px',
                height: '30px',
                fontWeight: '600',
                background: `linear-gradient(45deg, #4a3aff, ${themeMode === "dark" ? "#fafafa" : "#c1c1c1"})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                MozBackgroundClip: 'text',
                MozTextFillColor: 'transparent',
                display: 'inline',
              }}
            >
              {mapSubscriptionTypeToPlanName(user.subscriptionType)}
            </Typography>
          </Button>


        </Box>
        <Box sx={{ flexGrow: 1, maxWidth: { md: '400px', xs: '600px' }, width: '100%' }}>
          <Stack spacing={3}>
            <Box>
              <Typography sx={{ textAlign: 'left', mb: '5px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                {t('clientProfile.firstName')}
              </Typography>
              <ThemeProvider theme={themee}>
                <TextField
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder={t('clientProfile.firstNamePlaceholder') as string}
                  variant="outlined"
                  fullWidth
                  sx={{ color: themeMode === "dark" ? '#fafafa' : '#181818' }}
                />
              </ThemeProvider>

            </Box>

            <Box>
              <Typography sx={{ textAlign: 'left', mb: '5px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                {t('clientProfile.lastName')}
              </Typography>
              <ThemeProvider theme={themee}>
                <TextField
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder={t('clientProfile.lastNamePlaceholder') as string}
                  variant="outlined"
                  fullWidth
                />
              </ThemeProvider>
            </Box>

            <Box>
              <Typography sx={{ textAlign: 'left', mb: '5px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                {t('clientProfile.email')}
              </Typography>
              <ThemeProvider theme={themee}>
                <TextField
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={t('clientProfile.emailPlaceholder') as string}
                  variant="outlined"
                  fullWidth
                />
              </ThemeProvider>
            </Box>
          </Stack>
        </Box>

      </Box>
      <Stack flexDirection={isMobile ? "column" : "row"} justifyContent="center" alignItems="center" gap={2} sx={{ mt: '30px', mb: '80px', width: '100%', maxWidth: '500px', mx: 'auto' }}>
        <Button
          variant="outlined"
          color="error"
          fullWidth
          onClick={handleOpenUpdatePasswordDialog}
          sx={{
            height: 'auto',
            color: '#fff',
            fontSize: isMobile ? '16px' : "16px",
            fontWeight: "500",
            textTransform: "none",
            padding: "10px 20px",
            borderRadius: '100px',
            borderColor: "transparent",
            borderWidth: 1,
            borderStyle: 'solid',
            fontFamily: 'Outfit',
            backgroundColor: '#4a3aff',
            width: '100%',
            '&:hover': {
              borderColor: '#4a3aff',
              color: themeMode === "dark" ? "white" : '#4a3aff',
              backgroundColor: 'transparent',
              borderWidth: 1,
              borderStyle: 'solid',
              boxShadow: 'none'
            },
            '&:focus': {
              outline: 'none',
            },
            '&.Mui-disabled': {
              backgroundColor: themeMode === "dark" ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
              color: themeMode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
            },
          }}
        >
          {t('clientProfile.changePassword')}
        </Button>

        <Button
          variant="outlined"
          fullWidth
          onClick={handleOpenDeleteAccountDialog}
          sx={{
            textTransform: 'none',
            color: '#c52828',
            fontSize: '16px',
            fontWeight: '300',
            backgroundColor: 'transparent',
            marginTop: '10px',
            fontFamily: 'Outfit',
            mb: '10px',
            borderRadius: '50px',
            width: '100%',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'rgba(255, 0, 0, 0.1)',
            },
            '&:focus': {
              outline: 'none',
            },
          }}
        >
          {t('clientProfile.deleteAccount')}
        </Button>
      </Stack>

      <DialogCommon
        kTitle={t('clientProfile.changePasswordTitle')}
        kMaxWidth="sm"
        kMaxHeight={isMobile ? '430px' : '400px'}
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kEnableBorderRadius={true}
        kBorderRadius={isMobile ? '20px' : '30px'}
        kAlignBtn="center"
        kKeepBtnSize
        kDisableBtnSave={newPassword === ""}
        kTopDivider
        kSlideTransition
        kOpenDialogLists={openUpdatePasswordDialog}
        kOnClose={handleCloseUpdatePasswordDialog}
        kOnPrimary={handleConfirmUpdatePasswordSubscription}
        kOnSecondary={handleCloseUpdatePasswordDialog}
        kSecondaryBtnText={t('botList.cancel') as string}
        kPrimaryBtnText={t('clientProfile.saveButton') || ""}
        kContent={
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: isMobile ? '100%' : '80%' }}>
            <Typography variant="body1" color={themeMode === "dark" ? "#fafafa" : "#181818"}>
              {t('clientProfile.currentPassword')}
            </Typography>
            <ThemeProvider theme={themee}>
              <TextField
                fullWidth
                name="password"
                placeholder={t('signIn.passwordLabel') as string}
                type={showPassword ? 'text' : 'password'}
                value={currentPassword}
                id="password"
                onChange={(e) => setCurrentPassword(e.target.value)}
                autoComplete="new-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePasswordVisibility}
                        onMouseDown={(event) => event.preventDefault()}
                        edge="end"
                      >
                        {showPassword
                          ? <VisibilityOffRoundedIcon style={{ color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1' }} />
                          : <VisibilityRoundedIcon style={{ color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1' }} />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </ThemeProvider>

            <Typography variant="body1" color={themeMode === "dark" ? "#fafafa" : "#181818"}>
              {t('clientProfile.newPassword')}
            </Typography>
            <ThemeProvider theme={themee}>
              <TextField
                type={showPassword ? 'text' : 'password'}
                value={newPassword}
                placeholder={t('signIn.passwordLabel') as string}
                onChange={(e) => setNewPassword(e.target.value)}
                variant="outlined"
                fullWidth
                id="new-password"
                autoComplete="new-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePasswordVisibility}
                        onMouseDown={(event) => event.preventDefault()}
                        edge="end"
                      >
                        {showPassword
                          ? <VisibilityOffRoundedIcon style={{ color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1' }} />
                          : <VisibilityRoundedIcon style={{ color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1' }} />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </ThemeProvider>

          </Box>
        }
      />

      <DialogCommon
        kTitle={t('clientProfile.deleteAccountTitle')}
        kMaxWidth="xs"
        kMaxHeight={isMobile ? '230px' : '250px'}
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kEnableBorderRadius={true}
        kBorderRadius={isMobile ? '20px' : '30px'}
        kAlignBtn="center"
        kKeepBtnSize
        kTopDivider
        kSlideTransition
        kOpenDialogLists={openDeleteAccountDialog}
        kOnClose={handleCloseDeleteAccountDialog}
        kOnPrimary={handleConfirmDeleteAccount}
        kOnSecondary={handleCloseDeleteAccountDialog}
        kSecondaryBtnText={t('botList.cancel') as string}
        kPrimaryBtnText={t('clientProfile.deleteAccountButton') || ""}
        kContent={

          <Typography sx={{
            fontWeight: '300', fontFamily: 'Outfit',
            fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "#fafafa" : "#181818"
          }}>
            {t('clientProfile.areYouSure')}
          </Typography>
        }
      />

    </SectionContainer>
  )
}

export default ClientProfile