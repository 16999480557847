import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../context/Config/ConfigContext';
import StoreContext from '../../context/Store/storeContext';
import { Box, Stack, Typography, CircularProgress, TextField, Select, MenuItem, Grid, createTheme, FormControl, IconButton, useMediaQuery, useTheme, ListItemIcon, ThemeProvider, InputAdornment } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import WidgetsRoundedIcon from '@mui/icons-material/WidgetsRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import SectionContainer from '../../global/SectionContainer';
import DialogCommon from '../../components/DialogCommon/DialogCommon';
import { collection, addDoc, query, where, getDocs, doc, deleteDoc, updateDoc, getDoc } from 'firebase/firestore';
import { auth, db } from '../../services/firebase/firebase';
import { showToast } from '../../components/Toast/toast';
import { useNavigate } from 'react-router';

type Platform = {
  name: string;
  description: string;
  longDescription: string;
  logo: string;
  available: boolean;
};

type Integration = {
  id: string;
  platform: string;
  url: string;
  description: string;
  chatbotIds: string[];
  timestamp: Date;
  chatbotDetails?: any[];
};


export default function Integrations() {
  const { t } = useTranslation('global');
  const { themeMode } = useContext(ConfigContext);
  const { user, chatbots } = useContext(StoreContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [, setLoadingMessage] = useState('');
  const [selectedPlatform, setSelectedPlatform] = useState<Platform | null>(null);
  const [url, setUrl] = useState('');
  const [description, setDescription] = useState('');
  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const [loadingIntegrations, setLoadingIntegrations] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedIntegrationToDelete, setSelectedIntegrationToDelete] = useState<Integration | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedIntegrationToEdit, setSelectedIntegrationToEdit] = useState<Integration | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedChatbotIds, setSelectedChatbotIds] = useState<string[]>([]);
  const [editChatbotIds, setEditChatbotIds] = useState<string[]>([]);

  const isSubscriptionCanceled = user.subscriptionStatus === 'canceled';

  const navigate = useNavigate();

  const platforms = [
    {
      name: 'Calendly',
      description: t('integrationsPage.calendlyDescription'),
      longDescription: t('integrationsPage.calendlyLongDescription'),
      logo: 'https://pentagram-production.imgix.net/12ea9364-8def-4c0a-97e3-742655c230ee/EO_Calendly_2.jpg?rect=0%2C0%2C1920%2C1080&w=640&crop=1&fm=jpg&q=70&auto=format&fit=crop&h=360',
      available: true,
    },
    {
      name: 'Google Forms',
      description: t('integrationsPage.googleFormsDescription'),
      longDescription: t('integrationsPage.googleFormsLongDescription'),
      logo: 'https://crowded-byui-edu.imgix.net/page_featured_image/c_68667_c0ab5670-c46c-492f-84e8-5e526853fed6.png?auto=format&h=900&w=1200&fit=max',
      available: true,
    },
    {
      name: 'SurveyMonkey',
      description: t('integrationsPage.surveyMonkeyDescription'),
      longDescription: t('integrationsPage.surveyMonkeyLongDescription'),
      logo: 'https://seeklogo.com/images/S/surveymonkey-logo-C435E04535-seeklogo.com.png',
      available: true,
    },
    {
      name: 'Eventbrite',
      description: t('integrationsPage.eventbriteDescription'),
      longDescription: t('integrationsPage.eventbriteLongDescription'),
      logo: 'https://upload.wikimedia.org/wikipedia/commons/9/98/Eventbrite-logo.png',
      available: true,
    },
    {
      name: 'Booking.com',
      description: t('integrationsPage.bookingComDescription'),
      longDescription: t('integrationsPage.bookingComLongDescription'),
      logo: 'https://content.presspage.com/clients/o_685.jpg',
      available: true,
    },
    {
      name: 'Doodle',
      description: t('integrationsPage.doodleDescription'),
      longDescription: t('integrationsPage.doodleLongDescription'),
      logo: 'https://logos-world.net/wp-content/uploads/2022/06/Doodle-New-Logo.png',
      available: true,
    },
    {
      name: 'Google Maps',
      description: t('integrationsPage.googleMapsDescription'),
      longDescription: t('integrationsPage.googleMapsLongDescription'),
      logo: 'https://logos-world.net/wp-content/uploads/2022/01/Google-Maps-Logo.jpg',
      available: true,
    },
    {
      name: 'Microsoft Bookings',
      description: t('integrationsPage.microsoftBookingsDescription'),
      longDescription: t('integrationsPage.microsoftBookingsLongDescription'),
      logo: 'https://www.appjetty.com/blog/wp-content/uploads/2023/02/OG_microsoft-booking.png',
      available: true,
    },
    {
      name: 'Typeform',
      description: t('integrationsPage.typeformDescription'),
      longDescription: t('integrationsPage.typeformLongDescription'),
      logo: 'https://images.ctfassets.net/a7hvy8sclsq6/3eXJm8g80iMpSBex2GvlJA/0426e4be3796c882be88ae4e83d8ce08/tf-wordmark-combo.png',
      available: true,
    },
    {
      name: 'JotForm',
      description: t('integrationsPage.jotFormDescription'),
      longDescription: t('integrationsPage.jotFormLongDescription'),
      logo: 'https://logowik.com/content/uploads/images/jotform-new-20217369.jpg',
      available: true,
    },
    {
      name: 'Acuity Scheduling',
      description: t('integrationsPage.acuitySchedulingDescription'),
      longDescription: t('integrationsPage.acuitySchedulingLongDescription'),
      logo: 'https://www.smbguide.com/wp-content/uploads/2023/12/acuity-scheduling-review.png',
      available: true,
    },
    {
      name: 'YouCanBook.me',
      description: t('integrationsPage.youCanBookMeDescription'),
      longDescription: t('integrationsPage.youCanBookMeLongDescription'),
      logo: 'https://www.bedfordindependent.co.uk/wp-content/uploads/2024/01/YouCanBookMe-banner.jpg',
      available: true,
    },
    {
      name: 'Zoho Forms',
      description: t('integrationsPage.zohoFormsDescription'),
      longDescription: t('integrationsPage.zohoFormsLongDescription'),
      logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrh5VR_CPfneZGqd5QlXwSNxJLMSXPg3zAWw&s',
      available: true,
    },
    {
      name: 'SimplyBook.me',
      description: t('integrationsPage.simplyBookMeDescription'),
      longDescription: t('integrationsPage.simplyBookMeLongDescription'),
      logo: 'https://simplybook.me/v2/images/simplybook/new/sb-facebook.png',
      available: true,
    },
    {
      name: 'Setmore',
      description: t('integrationsPage.simplyBookMeDescription'),
      longDescription: t('integrationsPage.setmoreLongDescription'),
      logo: ' https://www.business.org/app/uploads/2021/02/setmore-logo-300x188.jpg',
      available: true,
    },
  ];

  const checkSubscriptionType = () => {
    const restrictedTypes = ['basicMonthlyMXN', 'basicAnnualMXN', 'basicMonthlyUSD', 'basicAnnualUSD'];
    return restrictedTypes.includes(user.subscriptionType);
  };

  const [restrictionDialogOpen, setRestrictionDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    const integrationLimit = getIntegrationLimit(user.subscriptionType);
    if (integrations.length >= integrationLimit) {
      setMaxIntegrationsReached(true);
    } else if (checkSubscriptionType()) {
      setRestrictionDialogOpen(true);
    } else {
      setOpenDialog(true);
    }
  };


  const handleCloseRestrictionDialog = () => {
    setRestrictionDialogOpen(false)
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setUrl('');
    setDescription('');
    setSelectedChatbotIds([]);
    setTimeout(() => {
      setSelectedPlatform(null);
    }, 200)
  };

  const handleOpenDeleteDialog = (integrationItem: any) => {
    setSelectedIntegrationToDelete(integrationItem);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setSelectedIntegrationToDelete(null);
  };

  const handleDeleteIntegration = async () => {
    if (selectedIntegrationToDelete) {
      setIsDeleting(true);
      const integrationDocRef = doc(db, 'integrations', selectedIntegrationToDelete.id);

      try {
        await deleteDoc(integrationDocRef);
        setIntegrations(integrations.filter(item => item.id !== selectedIntegrationToDelete.id));
        handleCloseDeleteDialog();
        showToast('success', t('integrationsPage.integrationDeleted'), themeMode);
      } catch (error) {
        console.error('Error deleting integration:', error);
        showToast('error', t('integrationsPage.errorDeletingIntegration'), themeMode);
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const handleEditIntegration = async () => {
    if (selectedIntegrationToEdit) {
      setIsEditing(true);
      const integrationDocRef = doc(db, 'integrations', selectedIntegrationToEdit.id);
      try {
        await updateDoc(integrationDocRef, {
          description,
          url,
          chatbotIds: editChatbotIds,
        });
        setIntegrations(integrations.map(item => (item.id === selectedIntegrationToEdit.id ? { ...item, description, url, chatbotIds: editChatbotIds } : item)));
        handleCloseEditDialog();
        showToast('success', t('integrationsPage.integrationUpdated'), themeMode);
      } catch (error) {
        console.error('Error updating integration:', error);
        showToast('error', t('integrationsPage.errorUpdatingIntegration'), themeMode);
      } finally {
        setTimeout(() => {
          setIsEditing(false);
        }, 200)

      }
    }
  };

  useEffect(() => {
    const fetchIntegrations = async () => {
      if (user) {
        try {
          const q = query(collection(db, 'integrations'), where('ownerId', '==', auth.currentUser?.uid));
          const querySnapshot = await getDocs(q);
          const integrationData = querySnapshot.docs.map(async (integrationDoc) => {
            const data = integrationDoc.data();
            const chatbotDetails = await Promise.all(
              (data.chatbotIds || []).map(async (chatbotId: string) => {
                const chatbotDocRef = doc(db, 'chatbots', chatbotId);
                const chatbotDocSnapshot = await getDoc(chatbotDocRef);
                return chatbotDocSnapshot.data();
              })
            );

            return {
              id: integrationDoc.id,
              platform: data.platform,
              url: data.url,
              description: data.description,
              chatbotIds: data.chatbotIds,
              timestamp: data.timestamp.toDate(),
              chatbotDetails,
            } as Integration;
          });

          const integrationsWithChatbots = await Promise.all(integrationData);
          setIntegrations(integrationsWithChatbots);
        } catch (error) {
          console.error('Error fetching integrations:', error);
        } finally {
          setLoadingIntegrations(false);
        }
      }
    };

    fetchIntegrations();
  }, [user]);

  const handleIntegrationSelect = (platform: any) => {
    setSelectedPlatform(platform);
  };

  const handleUpload = async () => {
    if (!selectedPlatform || !url || !description || !selectedChatbotIds.length) return;

    setIsUploading(true);
    setLoadingMessage(t('integrationsPage.uploading') as string);

    try {
      const docRef = await addDoc(collection(db, 'integrations'), {
        ownerId: auth.currentUser?.uid,
        platform: selectedPlatform.name,
        url,
        description,
        chatbotIds: selectedChatbotIds,
        timestamp: new Date(),
      });

      setLoadingMessage('');
      handleCloseDialog();

      setIntegrations(prev => [
        ...prev,
        {
          id: docRef.id,
          platform: selectedPlatform.name,
          url,
          description,
          chatbotIds: selectedChatbotIds,
          timestamp: new Date(),
          chatbotDetails: [],
        }
      ]);
      showToast('success', t('integrationsPage.integrationUploaded'), themeMode);
      setIsUploading(false);

    } catch (error) {
      console.error('Error uploading integration:', error);
      setIsUploading(false);
      setLoadingMessage('');
      showToast('error', t('integrationsPage.errorUploadingIntegration'), themeMode);
    }
  };


  const [originalDescription, setOriginalDescription] = useState('');
  const [originalUrl, setOriginalUrl] = useState('');
  const [originalChatbotIds, setOriginalChatbotIds] = useState<string[]>([]);

  const handleOpenEditDialog = (integrationItem: any) => {
    setSelectedIntegrationToEdit(integrationItem);
    setDescription(integrationItem.description);
    setUrl(integrationItem.url);
    setEditChatbotIds(integrationItem.chatbotIds || []);

    // Almacenar valores originales
    setOriginalDescription(integrationItem.description);
    setOriginalUrl(integrationItem.url);
    setOriginalChatbotIds(integrationItem.chatbotIds || []);

    setEditDialogOpen(true);
  };

  const hasChanges = () => {
    return (
      description !== originalDescription ||
      url !== originalUrl ||
      editChatbotIds.length !== originalChatbotIds.length ||
      !editChatbotIds.every(id => originalChatbotIds.includes(id))
    );
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
    setTimeout(() => {
      setSelectedIntegrationToEdit(null);
      setDescription('');
      setUrl('');
      setOriginalDescription('');
      setOriginalUrl('');
      setOriginalChatbotIds([]);
    }, 200)
  };

  const themee = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            fontFamily: 'Outfit',
            border: themeMode === "dark" ? '1px solid #272727' : '1px solid #eeeeee',
            backgroundColor: themeMode === "dark" ? "#181818" : "#fafafa",
            borderRadius: 15,
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#181818" : "#fafafa",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',
            },
            "& .MuiInputBase-input": {
              fontFamily: 'Outfit',
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiInputLabel-root": {
              fontFamily: 'Outfit',
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#4a3aff" : '#4a3aff',
            },
            "& .MuiInputLabel-root.Mui-focused + .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#4a3aff" : '#4a3aff'
            },
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 15,
          },
        },
      },
    }
  });

  const CHARACTER_LIMIT = 80;

  const INTEGRATION_LIMITS = {
    basic: 2,
    premium: 10,
    enterprise: 15,
  };

  const getIntegrationLimit = (subscriptionType: any) => {
    switch (subscriptionType) {
      case 'basicMonthlyMXN':
      case 'basicAnnualMXN':
      case 'basicMonthlyUSD':
      case 'basicAnnualUSD':
        return INTEGRATION_LIMITS.basic;
      case 'premiumMonthlyMXN':
      case 'premiumAnnualMXN':
      case 'premiumMonthlyUSD':
      case 'premiumAnnualUSD':
      case 'free':
        return INTEGRATION_LIMITS.premium;
      case 'enterpriseMonthlyMXN':
      case 'enterpriseAnnualMXN':
      case 'enterpriseMonthlyUSD':
      case 'enterpriseAnnualUSD':
        return INTEGRATION_LIMITS.enterprise;
      default:
        return 0;
    }
  };


  const [maxIntegrationsReached, setMaxIntegrationsReached] = useState(false);

  const handleCloseMaxIntegrationsDialog = () => {
    setMaxIntegrationsReached(false);
  };

  const integrationLimit = getIntegrationLimit(user.subscriptionType);


  return (
    <SectionContainer
      title={t('integrationsPage.title') as string}
      text={t('integrationsPage.addPlatform') as string}
      icon={<AddRoundedIcon />}
      onClick={handleOpenDialog}
      disableButton={isSubscriptionCanceled}
    >
      {loadingIntegrations ? (
        <Stack flexDirection="column" justifyContent="center" alignItems="center" gap={2} sx={{ mt: '100px' }}>
          <CircularProgress sx={{ color: themeMode === "dark" ? '#fafafa' : '#4a3aff' }} />
        </Stack>
      ) : integrations.length === 0 ? (
        <Stack flexDirection="column" justifyContent="center" alignItems="center" gap={2} sx={{ mt: '100px', padding: '20px' }}>
          <WidgetsRoundedIcon sx={{ fontSize: '60px', color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1' }} />
          <Typography align="center" sx={{
            fontSize: isMobile ? '16px' : '18px', fontFamily: 'Outfit', fontWeight: '300',
            color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1', maxWidth: '600px'
          }}>
            {t('integrationsPage.noIntegrations')}
          </Typography>
        </Stack>
      ) : (
        <Box sx={{ mt: '20px', mb: isMobile ? '70px' : '30px' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
            <Typography variant="h6" sx={{ color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
              {t('sidebar.integrations')}
            </Typography>
            <Typography variant="body2" sx={{ color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
              {`${integrations.length}/${integrationLimit}`}
            </Typography>
          </Stack>

          <Grid container spacing={2}>
            {integrations.map((integration, index) => {
              const platform = platforms.find(p => p.name === integration.platform);

              return (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Box
                    sx={{
                      backgroundColor: themeMode === "dark" ? '#181818' : '#fff',
                      borderRadius: '15px',
                      padding: '16px',
                      boxShadow: themeMode === "dark"
                        ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                        : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      position: 'relative',
                    }}
                  >
                    {platform && (
                      <img
                        src={platform.logo}
                        alt={integration.platform}
                        style={{
                          maxWidth: '180px',
                          maxHeight: '180px',
                          margin: '0 auto 8px',
                          borderRadius: '10px',
                        }}
                      />
                    )}
                    <Typography align="center" sx={{
                      mt: 1,
                      color: themeMode === "dark" ? '#fafafa' : '#181818',
                    }}>
                      {integration.platform}
                    </Typography>
                    <Typography align="center" sx={{
                      mt: 1,
                      color: themeMode === "dark" ? '#c1c1c1' : '#666666',
                    }}>
                      {integration.description}
                    </Typography>
                    <IconButton
                      sx={{
                        position: 'absolute',
                        top: 10,
                        right: 55,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: '#fff',
                        borderRadius: '50%',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        },
                        '&:focus': {
                          outline: 'none',
                        },
                      }}
                      onClick={() => handleOpenEditDialog(integration)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      sx={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: '#fff',
                        borderRadius: '50%',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        },
                        '&:focus': {
                          outline: 'none',
                        },
                      }}
                      onClick={() => handleOpenDeleteDialog(integration)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>

      )}

      <DialogCommon
        kTitle={t('integrationsPage.addPlatform')}
        kDisableActions={isUploading || !selectedPlatform}
        kMaxWidth="md"
        kMaxHeight={isMobile ? "100vh" : "700px"}
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kEnableBorderRadius={true}
        kBorderRadius={'30px'}
        kAlignBtn="center"
        kKeepBtnSize
        kTopDivider
        kAlignItemsStart
        kSlideTransition
        kOpenDialogLists={openDialog}
        kOnClose={handleCloseDialog}
        kOnPrimary={handleUpload}
        kOnSecondary={handleCloseDialog}
        kSecondaryBtnText={t('integrationsPage.cancel') as string}
        kPrimaryBtnText={t('integrationsPage.add') as string}
        kDisableBtnSave={!selectedPlatform || !url || !description || !selectedChatbotIds.length}
        kContent={
          selectedPlatform ? (
            <ThemeProvider theme={themee}>
              <Stack flexDirection={isMobile ? "column" : "row"} justifyContent="center"
                alignItems="flex-start" sx={{ width: '100%', gap: 4, height: '100%' }}>
                <Stack flexDirection="column" justifyContent="center"
                  alignItems="center" sx={{ width: isMobile ? '100%' : '50%', paddingTop: '20px' }}>
                  <img
                    src={selectedPlatform.logo}
                    alt={selectedPlatform.name}
                    style={{ maxWidth: '150px', height: 'auto', borderRadius: '15px', marginBottom: '16px', marginRight: 'auto' }}
                  />
                  <Typography sx={{ textAlign: 'left', marginBottom: '16px', color: themeMode === "dark" ? '#fafafa' : '#181818', fontFamily: 'Outfit', }}>
                    {selectedPlatform.longDescription}
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder={t('integrationsPage.platformUrlPlaceholder') as string}
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    sx={{ mt: 2 }}
                  />
                  <TextField
                    multiline
                    rows={2}
                    fullWidth
                    placeholder={t('integrationsPage.descriptionPlaceholder') as string}
                    value={description}
                    onChange={(e) => {
                      if (e.target.value.length <= CHARACTER_LIMIT) {
                        setDescription(e.target.value);
                      }
                    }}
                    sx={{ mt: 2 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ position: 'absolute', right: 8, bottom: 8 }}>
                          <Typography variant="caption" sx={{ color: description.length >= CHARACTER_LIMIT ? 'red' : '#999' }}>
                            {`${description.length}/${CHARACTER_LIMIT}`}
                          </Typography>
                        </InputAdornment>
                      ),
                      sx: {
                        position: 'relative',
                      },
                    }}
                  />
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <Select
                      multiple
                      value={selectedChatbotIds}
                      onChange={(e) => setSelectedChatbotIds(e.target.value as string[])}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      sx={{
                        color: themeMode === 'dark' ? '#ffffff' : '#181818',
                        bgcolor: themeMode === 'dark' ? '#181818' : '#fafafa',
                        '& .MuiSvgIcon-root': {
                          color: themeMode === 'dark' ? '#ffffff' : '#4a3aff',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: themeMode === 'dark' ? '#272727' : '#eeeeee',
                        },
                        '& .MuiSelect-select:placeholder': {
                          color: themeMode === 'dark' ? '#666666' : '#999999',
                        },
                        borderRadius: '13px',
                      }}
                      renderValue={(selected) => {
                        if (!selected || selected.length === 0) {
                          return <span style={{ color: themeMode === 'dark' ? '#666666' : '#999999' }}>{t('integrationsPage.selectPlaceholder')}</span>;
                        }
                        return selected.map((id) => chatbots.find((chatbot: any) => chatbot.id === id)?.companyName).join(', ');
                      }}
                      MenuProps={{
                        sx: {
                          '& .MuiPaper-root': {
                            bgcolor: themeMode === 'dark' ? '#181818' : '#fff',
                            color: themeMode === 'dark' ? '#ffffff' : '#333',
                            borderRadius: '15px',
                            boxShadow: themeMode === "dark"
                              ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                              : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                          },
                          '& .MuiMenu-list .MuiMenuItem-root': {
                            '&:hover': {
                              bgcolor: themeMode === 'light' ? '#fafafa' : '',
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>{t('integrationsPage.selectPlaceholder')}</em>
                      </MenuItem>
                      {chatbots.map((chatbot: any) => (
                        <MenuItem key={chatbot.id} value={chatbot.id}>
                          <ListItemIcon>
                            {selectedChatbotIds.includes(chatbot.id) && (
                              <CheckCircleRoundedIcon sx={{ color: '#4a3aff' }} />
                            )}
                          </ListItemIcon>
                          {chatbot.companyName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
                <Stack
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: isMobile ? '100%' : '50%', height: '100%' }}
                >
                  <Typography sx={{ marginBottom: '16px', fontFamily: 'Outfit', textAlign: 'center', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                    {t('integrationsPage.preview')}
                  </Typography>
                  {url ? (
                    <iframe
                      src={url}
                      title="Platform Preview"
                      style={{ width: '100%', height: '700px', border: '1px solid #ccc', borderRadius: '15px', flexGrow: 1 }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        border: '1px dashed #ccc',
                        borderRadius: '15px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#999',
                        textAlign: 'center',
                        flexGrow: 1,
                      }}
                    >
                      {t('integrationsPage.previewPlaceholder')}
                    </Box>
                  )}
                </Stack>
              </Stack>
            </ThemeProvider>

          ) : (
            <Grid container spacing={2} sx={{ pb: '50px', pt: '20px' }}>
              {platforms.map((platform, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box
                    sx={{
                      padding: '16px',
                      borderRadius: '15px',
                      backgroundColor: themeMode === "dark" ? '#181818' : '#fafafa',
                      textAlign: 'center',
                      cursor: platform.available ? 'pointer' : 'not-allowed',
                      opacity: platform.available ? 1 : 0.5,
                      height: '250px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      '&:hover': platform.available ? { boxShadow: '0px 4px 12px rgba(0,0,0,0.1)' } : {},
                    }}
                    onClick={() => platform.available && handleIntegrationSelect(platform)}
                  >
                    <img
                      src={platform.logo}
                      alt={platform.name}
                      style={{
                        maxWidth: '150px',
                        height: 'auto',
                        borderRadius: '15px',
                        objectFit: 'contain'
                      }}
                    />
                    <Typography variant="h6" sx={{ color: themeMode === "dark" ? '#fafafa' : '#181818', mt: 2 }}>
                      {platform.name}
                    </Typography>
                    <Typography variant="body2" sx={{ color: themeMode === "dark" ? '#c1c1c1' : '#666666', mt: 1 }}>
                      {platform.description}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>

          )
        }
      />

      <DialogCommon
        kTitle={t('integrationsPage.deleteConfirm')}
        kDisableActions={isDeleting}
        kMaxWidth="xs"
        kMaxHeight="250px"
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kEnableBorderRadius={true}
        kBorderRadius="30px"
        kAlignBtn="center"
        kKeepBtnSize
        kTopDivider
        kSlideTransition
        kOpenDialogLists={deleteDialogOpen}
        kOnClose={handleCloseDeleteDialog}
        kOnPrimary={handleDeleteIntegration}
        kOnSecondary={handleCloseDeleteDialog}
        kSecondaryBtnText={t('integrationsPage.cancel') as string}
        kPrimaryBtnText={t('integrationsPage.delete') as string}
        kContent={
          isDeleting ? (
            <Box sx={{ textAlign: 'center' }}>
              <CircularProgress sx={{ color: themeMode === "dark" ? '#fafafa' : '#4a3aff' }} />
            </Box>
          ) : (
            <Typography sx={{ color: themeMode === "dark" ? "#fafafa" : "#181818" }}>
              {t('integrationsPage.deleteMessage')}
            </Typography>
          )
        }
      />

      <DialogCommon
        kTitle={t('integrationsPage.editDescription')}
        kDisableActions={isEditing}
        kMaxWidth="md"
        kMaxHeight={isMobile ? "100vh" : "700px"}
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kEnableBorderRadius={true}
        kBorderRadius="30px"
        kAlignBtn="center"
        kKeepBtnSize
        kTopDivider
        kSlideTransition
        kOpenDialogLists={editDialogOpen}
        kOnClose={handleCloseEditDialog}
        kOnPrimary={handleEditIntegration}
        kOnSecondary={handleCloseEditDialog}
        kSecondaryBtnText={t('integrationsPage.cancel') as string}
        kPrimaryBtnText={t('integrationsPage.save') as string}
        kDisableBtnSave={!hasChanges() || isEditing}
        kContent={
          isEditing ? (
            <Stack
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ width: '100%', height: '100%' }}
            >
              <CircularProgress sx={{ color: themeMode === "dark" ? '#fafafa' : '#4a3aff' }} />
            </Stack>
          ) : selectedIntegrationToEdit ? (
            <ThemeProvider theme={themee}>
              <Stack flexDirection={isMobile ? "column" : "row"} justifyContent="center" alignItems="flex-start" sx={{ width: '100%', gap: 4, height: '100%' }}>
                <Stack flexDirection="column" justifyContent="center" alignItems="center" sx={{ width: isMobile ? '100%' : '50%', paddingTop: '20px' }}>
                  <img
                    src={platforms.find(platform => platform.name === selectedIntegrationToEdit.platform)?.logo}
                    alt={selectedIntegrationToEdit.platform}
                    style={{ maxWidth: '150px', height: 'auto', borderRadius: '15px', marginBottom: '16px', marginRight: 'auto' }}
                  />
                  <Typography sx={{ textAlign: 'left', marginBottom: '16px', color: themeMode === "dark" ? '#fafafa' : '#181818', fontFamily: 'Outfit', }}>
                    {platforms.find(platform => platform.name === selectedIntegrationToEdit.platform)?.longDescription}
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder={t('integrationsPage.platformUrlPlaceholder') as string}
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    sx={{ mt: 2 }}
                  />
                  <TextField
                    multiline
                    rows={2}
                    fullWidth
                    placeholder={t('integrationsPage.descriptionPlaceholder') as string}
                    value={description}
                    onChange={(e) => {
                      if (e.target.value.length <= CHARACTER_LIMIT) {
                        setDescription(e.target.value);
                      }
                    }}
                    sx={{ mt: 2 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ position: 'absolute', right: 8, bottom: 8 }}>
                          <Typography variant="caption" sx={{ color: description.length >= CHARACTER_LIMIT ? 'red' : '#999' }}>
                            {`${description.length}/${CHARACTER_LIMIT}`}
                          </Typography>
                        </InputAdornment>
                      ),
                      sx: {
                        position: 'relative',
                      },
                    }}
                  />
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <Select
                      multiple
                      value={editChatbotIds}
                      onChange={(e) => setEditChatbotIds(e.target.value as string[])}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      sx={{
                        color: themeMode === 'dark' ? '#ffffff' : '#181818',
                        bgcolor: themeMode === 'dark' ? '#181818' : '#fafafa',
                        '& .MuiSvgIcon-root': {
                          color: themeMode === 'dark' ? '#ffffff' : '#4a3aff',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: themeMode === 'dark' ? '#272727' : '#eeeeee',
                        },
                        '& .MuiSelect-select:placeholder': {
                          color: themeMode === 'dark' ? '#666666' : '#999999',
                        },
                        borderRadius: '13px',
                      }}
                      renderValue={(selected) => {
                        if (!selected || selected.length === 0) {
                          return <span style={{ color: themeMode === 'dark' ? '#666666' : '#999999' }}>{t('integrationsPage.selectPlaceholder')}</span>;
                        }
                        return selected.map((id) => chatbots.find((chatbot: any) => chatbot.id === id)?.companyName).join(', ');
                      }}
                      MenuProps={{
                        sx: {
                          '& .MuiPaper-root': {
                            bgcolor: themeMode === 'dark' ? '#181818' : '#fff',
                            color: themeMode === 'dark' ? '#ffffff' : '#333',
                            borderRadius: '15px',
                            boxShadow: themeMode === "dark"
                              ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                              : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                          },
                          '& .MuiMenu-list .MuiMenuItem-root': {
                            '&:hover': {
                              bgcolor: themeMode === 'light' ? '#fafafa' : '',
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>{t('integrationsPage.selectPlaceholder')}</em>
                      </MenuItem>
                      {chatbots.map((chatbot: any) => (
                        <MenuItem key={chatbot.id} value={chatbot.id}>
                          <ListItemIcon>
                            {editChatbotIds.includes(chatbot.id) && (
                              <CheckCircleRoundedIcon sx={{ color: '#4a3aff' }} />
                            )}
                          </ListItemIcon>
                          {chatbot.companyName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
                <Stack
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: '50%', height: '100%' }}
                >
                  <Typography sx={{ marginBottom: '16px', fontFamily: 'Outfit', textAlign: 'center', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                    {t('integrationsPage.preview')}
                  </Typography>
                  {url ? (
                    <iframe
                      src={url}
                      title="Platform Preview"
                      style={{ width: '100%', height: '100%', border: '1px solid #ccc', borderRadius: '15px', flexGrow: 1 }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        border: '1px dashed #ccc',
                        borderRadius: '15px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#999',
                        textAlign: 'center',
                        flexGrow: 1,
                      }}
                    >
                      {t('integrationsPage.previewPlaceholder')}
                    </Box>
                  )}
                </Stack>
              </Stack>
            </ThemeProvider>
          ) : null
        }
      />

      <DialogCommon
        kTitle={t('integrationsPage.restrictionTitle')}
        kDisableActions={false}
        kMaxWidth="xs"
        kMaxHeight="250px"
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kEnableBorderRadius={true}
        kBorderRadius="30px"
        kAlignBtn="center"
        kKeepBtnSize
        kTopDivider
        kSlideTransition
        kOpenDialogLists={restrictionDialogOpen}
        kOnClose={handleCloseRestrictionDialog}
        kOnPrimary={() => navigate('/subscription')}
        kPrimaryBtnText={t('integrationsPage.upgrade') as string}
        kContent={
          <Typography sx={{ color: themeMode === "dark" ? "#fafafa" : "#181818" }}>
            {t('integrationsPage.restrictionMessage')}
          </Typography>
        }
      />

      <DialogCommon
        kTitle={t('integrationsPage.limitReachedTitle')}
        kDisableActions={false}
        kMaxWidth="xs"
        kMaxHeight="250px"
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kEnableBorderRadius={true}
        kBorderRadius="30px"
        kAlignBtn="center"
        kKeepBtnSize
        kTopDivider
        kSlideTransition
        kOpenDialogLists={maxIntegrationsReached}
        kOnClose={handleCloseMaxIntegrationsDialog}
        kOnPrimary={() => navigate('/subscription')}
        kPrimaryBtnText={t('integrationsPage.upgrade') as string}
        kContent={
          <Typography sx={{ color: themeMode === "dark" ? "#fafafa" : "#181818" }}>
            {t('integrationsPage.limitReachedMessage', { limit: getIntegrationLimit(user.subscriptionType) })}
          </Typography>
        }
      />

    </SectionContainer>
  );
}
