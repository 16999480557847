import {
  Box, Button, CircularProgress,
  FormControl, Grid, IconButton, Input, Menu, MenuItem, Stack, TextField, Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useNavigate } from 'react-router-dom';
import { getDocs, collection, doc, deleteDoc, query, where, getDoc, runTransaction } from "firebase/firestore";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../../services/firebase/firebase';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import StoreContext from '../../context/Store/storeContext';
import SectionContainer from '../../global/SectionContainer';
import DialogCommon from '../../components/DialogCommon/DialogCommon';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { ConfigContext } from '../../context/Config/ConfigContext';
import AddAPhotoRoundedIcon from '@mui/icons-material/AddAPhotoRounded';
import { useTranslation } from 'react-i18next';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import FlipMove from 'react-flip-move';
import { motion } from 'framer-motion';
import { showToast } from '../../components/Toast/toast';

function BotList() {

  const { themeMode } = useContext(ConfigContext)

  const themee = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            fontFamily: 'Outfit',
            color: '#7c7c7c',
            backgroundColor: themeMode === "dark" ? "#181818" : "#fff",
            borderRadius: 15,
            border: themeMode === "dark" ? '1px solid #272727' : '1px solid #eeeeee',
            // Establecer el fondo aquí
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',

            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#181818" : "#fafafa",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',

            },
            "& .MuiInputBase-input": {
              fontFamily: 'Outfit',
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiInputLabel-root": {
              fontFamily: 'Outfit',
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#4a3aff" : '#4a3aff',

            },
            "& .MuiInputLabel-root.Mui-focused + .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#4a3aff" : '#4a3aff'

            },
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 15,
          },
        },
      },
    }
  });

  const { t } = useTranslation('global')

  const {
    logo,
    companyName,
    lightModeLogo,
    primaryColor,
    updateCompanyLogo,
    updateCompanyName,
    updateCompanyLightModeLogo,
    updatePrimaryColor,
    updateSystemInstructions,
    updateSuggestion1,
    updateSuggestion2,
    updateSuggestion3,
    updateAssistantFirstMessage,
    updateBusinessType
  } = useContext(ConfigContext);

  const resetConfigContext = () => {
    updateCompanyLogo(''),
      updateCompanyName(''),
      updateCompanyLightModeLogo(''),
      updatePrimaryColor('#4a3aff'),
      updateSystemInstructions(''),
      updateSuggestion1(''),
      updateSuggestion2(''),
      updateSuggestion3(''),
      updateAssistantFirstMessage(''),
      updateBusinessType('')
  };

  useEffect(() => {
    resetConfigContext()
  }, [])

  const { user, chatbots, setChatbots, needsUpdate, setNeedsUpdate } = useContext(StoreContext);
  const [userr] = useAuthState(auth);
  const [open, setOpen] = useState(false);
  const [selectedBotId, setSelectedBotId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [openConfigModal, setOpenConfigModal] = useState(false);
  const [isContinueDisabled, setIsContinueDisabled] = useState(true);

  const isSubscriptionCanceled = user.subscriptionStatus === 'canceled';

  useEffect(() => {
    const validateStepOne = () => {
      if (logo && lightModeLogo && companyName.length > 0 && primaryColor) {
        setIsContinueDisabled(false);
      } else {
        setIsContinueDisabled(true);
      }
    };
    validateStepOne()

  }, [companyName])



  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const fetchChatbots = async () => {
      if (userr) {
        setLoading(true);

        // Usando una consulta para obtener solo los chatbots del usuario
        const userBotsQuery = query(collection(db, 'chatbots'), where("ownerId", "==", userr.uid));

        const botSnapshot = await getDocs(userBotsQuery);

        // Ya que estamos haciendo una consulta específica, no necesitamos el filtrado
        const userBots = botSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        setChatbots(userBots);
        setNeedsUpdate(false);
        setLoading(false);
      }
    };

    if (needsUpdate || chatbots.length === 0) {
      fetchChatbots();
    }
  }, [userr, needsUpdate]);



  const handleEdit = (botId: string) => {
    navigate(`/edit-bot/${botId}`);
  };

  const handleView = (botId: string) => {
    navigate(`/individual-bot/${botId}`);
  };

  const { fetchUser } = useContext(StoreContext);

  const handleDelete = async () => {
    if (selectedBotId) {
      try {
        await deleteDoc(doc(db, 'chatbots', selectedBotId));

        const updatedBots = chatbots.filter((bot: any) => bot.id !== selectedBotId);
        setChatbots(updatedBots);

        if (userr && userr.uid) {
          await updateBotCount(userr.uid, false);
          fetchUser(userr.uid)
        } else {
          showToast('error', t('botList.errors.userNotDefinedOrWithoutUID'), themeMode)
        }

        setOpen(false);
      } catch (error: any) {
        showToast('error', t('botList.errors.errorDeletingBot'), themeMode)
      }
    }
  };


  const handleClickOpen = (botId: string) => {
    setSelectedBotId(botId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const botsPerPage = 7;

  interface UserData {
    botLimit: number;
    botCount: number;
  }

  const [userData, setUserData] = useState<UserData | null>(null);

  useEffect(() => {
    // Función para obtener los detalles del usuario desde Firestore
    const fetchUserDetails = async (uid: any) => {
      const userDocRef = doc(db, "users", uid);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        // Actualiza el estado con los datos del usuario
        setUserData(userDocSnap.data() as UserData);
      } else {
        // Manejar el caso de no encontrar el documento
        setUserData(null);
      }
    };

    if (userr) {
      fetchUserDetails(userr.uid);
    }
  }, [userr]);

  const updateBotCount = async (userId: any, increment = true) => {
    const userDocRef = doc(db, "users", userId);

    try {
      await runTransaction(db, async (transaction: any) => {
        const userDoc = await transaction.get(userDocRef);
        if (!userDoc.exists()) {
          throw "El documento del usuario no existe";
        }

        const newBotCount = increment ? userDoc.data().botCount + 1 : userDoc.data().botCount - 1;
        transaction.update(userDocRef, { botCount: newBotCount });
      });
    } catch (error: any) {
      showToast('error', t('botList.errors.errorUpdatingBotCount'), themeMode)
    }
  };


  const handleClickPrevious = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleClickNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };


  const indexOfLastBot = currentPage * botsPerPage;
  const indexOfFirstBot = indexOfLastBot - botsPerPage;
  const currentBots = chatbots?.slice(indexOfFirstBot, indexOfLastBot);

  const totalPages = Math.ceil(chatbots?.length / botsPerPage);

  const isWithinLimits = userr && currentBots.length >= (userData?.botLimit ?? 10);

  const handlePageClick = (page: number) => {
    setCurrentPage(page);
  };

  const getStyleByState = (state: string) => {
    switch (state) {
      case "active":
        return {
          backgroundColor: themeMode === "dark" ? "#1e2c1e" : "#C8E6C9", // Light green
          color: "#388E3C", // Dark green
          fontWeight: "bold",
        };
      case "inactive":
      case "Permanently disabled":
        return {
          backgroundColor: themeMode === "dark" ? "#2a2a2a" : "#E0E0E0", // Light gray
          color: "#757575", // Dark gray
          fontWeight: "bold",
        };
      case "underMaintenance":
        return {
          backgroundColor: themeMode === "dark" ? "#4e4c3c" : "#FFF9C4", // Light yellow
          color: "#FBC02D", // Dark yellow
          fontWeight: "bold",
        };
      case "problematic":
        return {
          backgroundColor: themeMode === "dark" ? "#2c1d1e" : "#FFCDD2", // Light red
          color: "#D32F2F", // Dark red
          fontWeight: "bold",
        };

      // Add the rest of the existing cases here...
      default:
        return {
          backgroundColor: "transparent",
          color: "#757575", // Assuming colors.grey[100] is similar to this dark grey
          fontWeight: "300",
        };
    }
  };


  // Aquí se realiza la funcionalidad de extraer textourls
  const [urls, setUrls] = useState(['']);
  const [urlErrors, setUrlErrors] = useState([false]);
  const [step, setStep] = useState(1);
  const [initialConfigLoading, setInitialConfigLoading] = useState(false);

  const handleAddUrlField = () => {
    if (urls.length < 5) {
      setUrls([...urls, '']);
    }
  };

  const handleUrlChange = (index: any, value: any) => {
    const newUrls = [...urls];
    const newUrlErrors = [...urlErrors];
    newUrls[index] = value;
    newUrlErrors[index] = false; // Restablece el error al cambiar la URL
    setUrls(newUrls);
    setUrlErrors(newUrlErrors);
  };

  const handleRemoveUrlField = () => {
    if (urls.length > 1) {
      const newUrls = urls.slice(0, -1); // Elimina el último elemento de la lista
      setUrls(newUrls);
    }
  };

  const handleExtractText = async () => {
    const newUrlErrors = urls.map(url => url.trim() === '');
    if (newUrlErrors.includes(true)) {
      setUrlErrors(newUrlErrors);
      return null; // Asegúrate de retornar null o un indicador de fallo
    }

    setInitialConfigLoading(true);
    try {
      const response = await fetch(`${process.env.VITE_APP_BACKEND_API}/extractTextFromPage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ urls }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json()
      return data;
    } catch (error: any) {
      showToast('error', t('botList.errors.errorFetchingData'), themeMode)
      return null;
    } finally {
      setInitialConfigLoading(false);
    }
  };

  const handleProcessDescription = async (description: String) => {
    setInitialConfigLoading(true);
    try {
      const response = await fetch(`${process.env.VITE_APP_BACKEND_API}/processBusinessDescription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ description }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error: any) {
      showToast('error', t('botList.errors.errorProcessingDescription'), themeMode)
      return null;
    } finally {
      setInitialConfigLoading(false);
    }
  };

  const variants = {
    hidden: { opacity: 0, y: -10 },  // Comienza con opacidad 0 y un poco más arriba
    visible: { opacity: 1, y: 0 },   // Se vuelve completamente visible y se coloca en la posición original
    exit: { opacity: 0, y: 10 }      // Se desvanece y baja
  };

  const configurationSteps = [
    { step: t('configurationSteps.analyzingCompanyData'), duration: 1000 },
    { step: t('configurationSteps.summarizingData'), duration: 2000 },
    { step: t('configurationSteps.settingUpQuestionSuggestions'), duration: 3500 },
    { step: t('configurationSteps.finishingChatbotSetup'), duration: 1500 },
    { step: t('configurationSteps.redirectingToChatbotConfig'), duration: 6000 },
  ];

  const [currentStep, setCurrentStep] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState(configurationSteps[0].step);

  useEffect(() => {
    if (initialConfigLoading && currentStep < configurationSteps.length) {
      const timer = setTimeout(() => {
        setLoadingMessage(configurationSteps[currentStep].step);
        setCurrentStep((prevStep) => prevStep + 1);
      }, configurationSteps[currentStep].duration);

      return () => clearTimeout(timer);
    }
  }, [currentStep, initialConfigLoading]);

  const handleInitialConfig = async () => {
    if (step === 1) {
      // Si estamos en el primer paso, avanzamos al siguiente
      setStep(2);
    } else if (step === 2) {
      // Decide qué proceso seguir basado en si el usuario tiene o no una página web
      let extractedData;
      if (hasWebsite) {
        // Si el usuario tiene una página web, intentamos extraer el texto de las URLs proporcionadas

        const newUrlErrors = urls.map(url => url.trim() === '');
        if (newUrlErrors.includes(true)) {
          setUrlErrors(newUrlErrors);
          return;
        }

        extractedData = await handleExtractText();
      } else {

        // Si el usuario no tiene una página web, procesamos la descripción del negocio proporcionada
        if (!websiteDescription.trim()) {
          setWebsiteDescriptionError(true);
          return;
        }

        const businessDescription = `Nombre de la empresa: ${companyName}. Descripción: ${websiteDescription}`

        extractedData = await handleProcessDescription(businessDescription);
      }

      if (extractedData) {
        if (!userr || !userr.uid) {
          showToast('error', t('botList.errors.userNotDefinedOrWithoutUID'), themeMode)
          return;
        }

        try {
          updateSystemInstructions(extractedData.companyDescription);
          updateSuggestion1(extractedData.suggestion1);
          updateSuggestion2(extractedData.suggestion2);
          updateSuggestion3(extractedData.suggestion3);
          updateAssistantFirstMessage(extractedData.firstAssistantMessage);
          updateBusinessType(extractedData.businessType);

          navigate('/create-bot');

          setOpenConfigModal(false);

          setUrls([]);
        } catch (error: any) {
          showToast('error', t('botList.errors.errorCreatingBot'), themeMode)
        }
      } else {
        showToast('error', t('botList.errors.dataProcessingError'), themeMode)
      }
    }
  };

  const handleOpenConfigModal = () => {
    setStep(1);
    setOpenConfigModal(true);
  }
  const handleCloseConfigModal = () => {
    setOpenConfigModal(false);
  };

  const handleStepBack = () => { setStep(1) };

  const handleLogoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          updateCompanyLogo(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  }

  const handleLightModeLogoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          updateCompanyLightModeLogo(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  }

  const handleCompanyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateCompanyName(e.target.value);
  }

  const handlePrimaryColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updatePrimaryColor(e.target.value);
  }

  const StepOneContent = (
    <Stack flexDirection="column" sx={{ width: '100%', }}>
      <Stack flexDirection="column" justifyContent="start" sx={{ backgroundColor: themeMode === "dark" ? '#181818' : '#fafafa', borderRadius: '20px', padding: '20px', gap: 3, mb: '20px', mt: '20px' }}>
        <Stack flexDirection="row" justifyContent="center" alignItems="center" gap={isMobile ? 2 : 5} flexWrap={isMobile ? "wrap" : "nowrap"}>
          {/* Modo Oscuro */}
          <Stack flexDirection="column" justifyContent="center" alignItems="center">
            <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', fontWeight: '300', mb: '10px', color: themeMode === "dark" ? '#fafafa' : '#181818', }}>
              {t('botList.modes.dark')}
            </Typography>
            <Box sx={{
              position: 'relative',
              width: "300px", height: '130px', border: '1px dashed',
              display: 'flex', justifyContent: 'center', alignItems: 'center', padding: isMobile ? '20px' : '50px',
              borderRadius: '20px', backgroundColor: themeMode === "dark" ? '#181818' : '#181818',
            }}>
              {logo ? (
                <motion.div
                  initial={{ scale: 0.5 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.25 }}
                >
                  <img src={logo} alt="Logo de la empresa" height="auto" style={{ maxHeight: '50px', maxWidth: isMobile ? '70vw' : '18vw' }} />
                </motion.div>

              ) : (
                <Typography sx={{ fontFamily: 'Outfit', fontSize: '14px', color: themeMode === "dark" ? '#fafafa' : '#fafafa', }}>
                  {t('botList.selectLogo')}
                </Typography>
              )}
              {/* Subir logo */}
              <FormControl variant="outlined" margin="normal" sx={{ position: 'absolute', bottom: 0, right: 0 }}>
                <input
                  style={{ display: 'none' }}
                  id="upload-logo"
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={handleLogoUpload}
                />
                <label htmlFor="upload-logo">
                  <IconButton
                    component="span"
                    sx={{
                      color: 'white', backgroundColor: '#4a3aff',
                      borderRadius: '100px', fontWeight: 'bold',
                      padding: '15px', fontFamily: 'Outfit',
                      position: 'absolute', right: '-25px', bottom: '-25px'
                    }}
                  >
                    <AddAPhotoRoundedIcon />
                  </IconButton>
                </label>
              </FormControl>
            </Box>

          </Stack>

          {/* Modo Claro */}
          <Stack flexDirection="column" justifyContent="center" alignItems="center">
            <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '10px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818', }}>
              {t('botList.modes.light')}
            </Typography>
            <Box sx={{
              position: 'relative',
              width: "300px", height: '130px', border: themeMode === "dark" ? '1px dashed #c1c1c1' : '1px dashed #c1c1c1',
              display: 'flex', justifyContent: 'center', alignItems: 'center', padding: isMobile ? '20px' : '50px',
              borderRadius: '20px', backgroundColor: '#fff'
            }}>
              {lightModeLogo ? (
                <motion.div
                  initial={{ scale: 0.5 }}  // Iniciar con un tamaño de 0
                  animate={{ scale: 1 }} // Animar a su tamaño original
                  transition={{ duration: 0.25 }}
                >
                  <img src={lightModeLogo} alt="Logo de la empresa" height="auto" style={{ maxHeight: '50px', maxWidth: isMobile ? '70vw' : '18vw' }} />
                </motion.div>

              ) : (
                <Typography sx={{ fontFamily: 'Outfit', fontSize: '14px', color: themeMode === "dark" ? '#181818' : '#181818', }}>
                  {t('botList.selectLogo')}
                </Typography>
              )}
              <FormControl variant="outlined" margin="normal" sx={{ position: 'absolute', bottom: 0, right: 0 }}>
                <input
                  style={{ display: 'none' }}
                  id="upload-light-mode-logo"
                  type="file"
                  accept=".png, .jpg, .jpeg"  // Solo aceptar archivos PNG, JPG y JPEG
                  onChange={handleLightModeLogoUpload}
                />
                <label htmlFor="upload-light-mode-logo">
                  <IconButton
                    component="span"
                    sx={{
                      color: 'white', backgroundColor: '#4a3aff',
                      borderRadius: '100px', fontWeight: 'bold',
                      padding: '15px', fontFamily: 'Outfit',
                      position: 'absolute', right: '-25px', bottom: '-25px'
                    }}
                  >
                    <AddAPhotoRoundedIcon />
                  </IconButton>
                </label>
              </FormControl>

            </Box>

          </Stack>
        </Stack>

      </Stack>
      <Stack flexDirection="row" justifyContent="center" alignItems="start" gap={3} flexWrap={isMobile ? "wrap" : "nowrap"}>
        <Stack flexDirection="column" justifyContent="center" alignItems="start" sx={{ width: '100%', backgroundColor: themeMode === "dark" ? '#181818' : '#fafafa', borderRadius: '20px', padding: '20px', }}>
          <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', fontWeight: '300', fontSize: '16px', color: themeMode === "dark" ? '#fafafa' : '#181818', }}>
            {t('botList.companyName')}
          </Typography>
          <ThemeProvider theme={themee}>
            <TextField
              variant="outlined"
              fullWidth
              margin="normal"
              value={companyName}
              onChange={handleCompanyNameChange}
              placeholder={t('botList.enterCompanyName') as string}
            />
          </ThemeProvider>
        </Stack>
        <Stack flexDirection="column" justifyContent="center" alignItems="start" sx={{ width: '100%', backgroundColor: themeMode === "dark" ? '#181818' : '#fafafa', borderRadius: '20px', padding: '20px', }}>

          {/* Color primario */}
          <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818', }}>
            {t('botList.selectPrimaryColor')}
          </Typography>
          <FormControl variant="outlined" margin="normal" fullWidth sx={{ mb: '10px' }}>

            <Input
              id="primary-color"
              type="color"
              value={primaryColor}
              onChange={handlePrimaryColorChange}
              disableUnderline
              inputProps={{
                style: {
                  cursor: 'pointer',
                  width: "100%",
                  height: "100%",
                  opacity: 0,
                  position: 'absolute',
                }
              }}
              sx={{
                width: "100%",
                height: "100%",
                opacity: 0,
                position: 'absolute',
              }}
            />

            <Box
              sx={{
                width: "100%",
                height: "50px",
                margin: "0",
                backgroundColor: primaryColor,
                borderRadius: "10px",
                cursor: 'pointer'
              }}
            ></Box>

          </FormControl>

        </Stack>

      </Stack>


    </Stack>
  );

  const calculateHeight = () => {
    if (urls.length > 1) {
      return '600px';
    } else {
      return initialConfigLoading || step === 2 ? '500px' : '620px';
    }
  };

  const [hasWebsite, setHasWebsite] = useState(true);
  const [websiteDescription, setWebsiteDescription] = useState("");
  const [websiteDescriptionError, setWebsiteDescriptionError] = useState(false);

  const StepTwoContent = (
    <>
      {initialConfigLoading ? (
        <Stack
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={2}
          sx={{
            padding: '50px',
            backgroundColor: themeMode === "dark" ? '#121212' : '#fff'
          }}
        >
          <CircularProgress sx={{ color: themeMode === "dark" ? '#fafafa' : '#4a3aff' }} />
          <motion.div
            key={loadingMessage}
            variants={variants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.5 }}
          >
            <Typography
              sx={{
                fontWeight: '300', fontFamily: 'Outfit', textAlign: 'center',
                fontSize: isMobile ? '14px' : '18px', color: themeMode === "dark" ? '#fff' : '#181818',
                mb: '10px'
              }}>
              {loadingMessage}
            </Typography>
          </motion.div>
        </Stack>
      ) : (
        <Stack
          flexDirection="column"
          justifyContent="start"
          sx={{
            width: '100%',
            backgroundColor: themeMode === "dark" ? '#121212' : '#fff', padding: 1,
            pt: '30px',
          }}>
          {/* Sección para decidir si el usuario tiene página web */}
          <Typography sx={{ mb: 2, mx: 'auto', color: themeMode === "dark" ? '#fff' : '#181818', }}>{t('botList.haveWebsite')}</Typography>
          <Stack direction="row" spacing={2} mb={4} sx={{ mx: 'auto' }}>
            <Button
              variant={hasWebsite ? "contained" : "outlined"}
              onClick={() => setHasWebsite(true)}
              sx={{
                height: 'auto',
                color: hasWebsite ? '#fff' : (themeMode === "dark" ? '#fff' : '#181818'),
                fontSize: isMobile ? '14px' : "16px",
                fontWeight: "500",
                textTransform: "none",
                padding: "4px 12px",
                borderRadius: '100px',
                borderColor: "transparent",
                borderWidth: 1,
                borderStyle: 'solid',
                fontFamily: 'Outfit',
                boxShadow: 'none',
                backgroundColor: hasWebsite ? '#4a3aff' : 'transparent',
                '&:hover': {
                  borderColor: '#4a3aff',
                  color: themeMode === "dark" ? "white" : '#4a3aff',
                  backgroundColor: 'transparent',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  boxShadow: 'none'
                },
                '&:focus': {
                  outline: 'none',
                },
                '&.Mui-disabled': {
                  backgroundColor: themeMode === "dark" ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
                  color: themeMode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
                },
              }}
            >
              {t('botList.yes')}
            </Button>
            <Button
              variant={!hasWebsite ? "contained" : "outlined"}
              onClick={() => setHasWebsite(false)}
              sx={{
                height: 'auto',
                color: !hasWebsite ? '#fff' : themeMode === "dark" ? '#fff' : '#181818',
                fontSize: isMobile ? '14px' : "16px",
                fontWeight: "500",
                textTransform: "none",
                padding: "4px 12px",
                borderRadius: '100px',
                borderColor: "transparent",
                borderWidth: 1,
                borderStyle: 'solid',
                fontFamily: 'Outfit',
                boxShadow: 'none',
                backgroundColor: !hasWebsite ? '#4a3aff' : 'transparent',
                '&:hover': {
                  borderColor: '#4a3aff',
                  color: themeMode === "dark" ? "white" : '#4a3aff',
                  backgroundColor: 'transparent',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  boxShadow: 'none'
                },
                '&:focus': {
                  outline: 'none',
                },
                '&.Mui-disabled': {
                  backgroundColor: themeMode === "dark" ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
                  color: themeMode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
                },
              }}
            >
              {t('botList.no')}
            </Button>
          </Stack>

          {!hasWebsite ? (
            <>
              <Typography
                sx={{
                  fontWeight: '300', fontFamily: 'Outfit',
                  fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? '#fff' : '#181818',
                }}>
                {t('botList.companyDescription')}
              </Typography>
              <ThemeProvider theme={themee}>
                <TextField
                  multiline
                  rows={isMobile ? 12 : 4}
                  placeholder={t('botList.describeYourCompany') || ""}
                  fullWidth
                  margin="normal"
                  value={websiteDescription}
                  onChange={(e) => setWebsiteDescription(e.target.value)}
                />
              </ThemeProvider>
              {!hasWebsite && websiteDescriptionError && (
                <Typography variant="body2" sx={{ color: '#E34F4F', }}>
                  {t('botList.enterDescription')}
                </Typography>
              )}

            </>
          )
            : (
              <>
                <Typography
                  sx={{
                    fontWeight: '300', fontFamily: 'Outfit',
                    fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? '#fff' : '#181818',
                    mb: '10px'
                  }}>
                  {t('botList.enterWebLinks')}
                </Typography>
                <Stack spacing={2} sx={{ mb: 2, transition: 'all 0.3s ease-in-out', }}>
                  {urls.map((url, index) => (
                    <>
                      <ThemeProvider theme={themee}>
                        <TextField
                          key={index}
                          fullWidth
                          placeholder={t('botList.webPageUrl', { number: index + 1 }) as string}
                          variant="outlined"
                          value={url}
                          onChange={(e) => handleUrlChange(index, e.target.value)}
                          InputProps={{
                            style: {
                              fontFamily: 'Outfit', // Aplica el estilo al input
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              fontFamily: 'Outfit', // Aplica el estilo a la etiqueta
                            },
                          }}
                        />
                      </ThemeProvider>
                      {
                        urlErrors[index] && (
                          <Typography variant="body2" sx={{ color: '#E34F4F', }}>
                            {t('botList.pleaseCompleteField')}
                          </Typography>
                        )
                      }
                    </>
                  ))}
                </Stack>
                <Stack flexDirection="column" justifyContent="start" alignItems="center" spacing={2}>
                  <Stack flexDirection="row" justifyContent="center" alignItems="center" >
                    <IconButton
                      disabled={urls.length === 1}
                      onClick={() => handleRemoveUrlField()} sx={{
                        color: themeMode === "dark" ? '#fff' : '#181818',
                        '&:hover': {
                          backgroundColor: themeMode === "dark" ? '#181818' : '#fafafa'
                        },
                        '&:focus': {
                          outline: 'none',
                        },
                        '&.Mui-disabled': {
                          backgroundColor: themeMode === "dark" ? 'rgba(255, 255, 255, 0.01)' : 'rgba(0, 0, 0, 0.01)',
                          color: themeMode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
                        },
                      }}>
                      <RemoveOutlinedIcon />
                    </IconButton>
                    <IconButton onClick={handleAddUrlField} disabled={urls.length === 5} sx={{
                      color: themeMode === "dark" ? '#fff' : '#181818',
                      '&:hover': {
                        backgroundColor: themeMode === "dark" ? '#181818' : '#fafafa'
                      },
                      '&:focus': {
                        outline: 'none',
                      },
                      '&.Mui-disabled': {
                        backgroundColor: themeMode === "dark" ? 'rgba(255, 255, 255, 0.01)' : 'rgba(0, 0, 0, 0.01)',
                        color: themeMode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
                      },
                    }}>
                      <AddRoundedIcon />
                    </IconButton>
                  </Stack>
                </Stack>
              </>
            )}
        </Stack>
      )
      }
    </>

  )

  const LimitReachedContent = () => (
    <div style={{ textAlign: 'center', paddingTop: isMobile ? 0 : '50px' }}>
      <h2 style={{ color: themeMode === 'dark' ? 'white' : '#181818', }}>{t('botList.limitReached.title')}</h2>
      <p style={{ color: themeMode === 'dark' ? 'white' : '#181818', }}>{t('botList.limitReached.description')}</p>
      <Button
        sx={{
          height: '50px',
          backgroundColor: '#4a3aff',
          lineHeight: 1,
          color: '#fff',
          fontSize: isMobile ? '16px' : "16px",
          fontWeight: "500",
          textTransform: "none",
          padding: "10px 20px",
          borderRadius: '100px',
          borderColor: "transparent",
          borderWidth: 1,
          borderStyle: 'solid',
          fontFamily: 'Outfit',
          '&:hover': {
            borderColor: '#4a3aff',
            color: themeMode === 'dark' ? "white" : '#181818',
            backgroundColor: 'transparent',
            borderWidth: 1,
            borderStyle: 'solid',
          },
          '&:focus': {
            outline: 'none',
          },
          ml: '10px'
        }}
        onClick={() => { navigate('/subscription') }}
      >
        {t('botList.limitReached.upgradePlan')}
      </Button>
    </div>
  );

  const MAX_PAGE_NUMBERS_SHOWN = 5;

  const calculatePageNumbersToShow = (currentPage: any, totalPages: any) => {
    let startPage, endPage;
    if (totalPages <= MAX_PAGE_NUMBERS_SHOWN) {
      startPage = 1;
      endPage = totalPages;
    } else {
      const pagesBeforeCurrentPage = Math.floor(MAX_PAGE_NUMBERS_SHOWN / 2);
      const pagesAfterCurrentPage = Math.ceil(MAX_PAGE_NUMBERS_SHOWN / 2) - 1;
      if (currentPage <= pagesBeforeCurrentPage) {
        startPage = 1;
        endPage = MAX_PAGE_NUMBERS_SHOWN;
      } else if (currentPage + pagesAfterCurrentPage >= totalPages) {
        startPage = totalPages - MAX_PAGE_NUMBERS_SHOWN + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - pagesBeforeCurrentPage;
        endPage = currentPage + pagesAfterCurrentPage;
      }
    }
    return { startPage, endPage };
  };

  const { startPage, endPage } = calculatePageNumbersToShow(currentPage, totalPages);


  const [botAnchorEls, setBotAnchorEls] = useState<{ [key: string]: HTMLElement | null }>({});

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, botId: string) => {
    setBotAnchorEls(prevState => ({
      ...prevState,
      [botId]: event.currentTarget,
    }));
  };

  const handleMenuClose = (botId: string) => {
    setBotAnchorEls(prevState => ({
      ...prevState,
      [botId]: null,
    }));
  };

  const formatDate = (date: any) => {
    if (!date) return '-';

    const targetDate = new Date(date);
    const now = new Date();
    const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);

    const isToday = targetDate.getDate() === now.getDate() &&
      targetDate.getMonth() === now.getMonth() &&
      targetDate.getFullYear() === now.getFullYear();

    const isYesterday = targetDate.getDate() === yesterday.getDate() &&
      targetDate.getMonth() === yesterday.getMonth() &&
      targetDate.getFullYear() === yesterday.getFullYear();

    const diff = now.getTime() - targetDate.getTime();
    const diffInMinutes = diff / (1000 * 60);

    if (diffInMinutes < 5) {
      return <span>{t('dashboard.now')}</span>;  // or just "Ahora"
    } else if (isToday) {
      return <span>{t('dashboard.today')}</span>;  // or just "Hoy"
    } else if (isYesterday) {
      return <span>{t('dashboard.yesterday')}</span>;  // or just "Ayer"
    } else {
      return targetDate.toLocaleDateString('es-ES', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      });
    }
  };

  return (
    <SectionContainer
      title={t('botList.header.title') as string}
      text={t('botList.header.createBot') as string}
      icon={<AddRoundedIcon />}
      onClick={handleOpenConfigModal}
      disableButton={isSubscriptionCanceled}
    >
      <Box sx={{ height: '70vh' }}>
        {
          !isMobile && currentBots.length > 0 && (
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ color: '#fff', borderRadius: '15px', px: 1, }}
            >
              <Grid item xs={2}>
                <Typography sx={{ fontFamily: 'Outfit', fontSize: '18px', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818' }} variant="h6">
                  {t('botList.header.logo')}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={{ fontFamily: 'Outfit', fontSize: '18px', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818' }} variant="h6">
                  {t('botList.header.name')}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={{ fontFamily: 'Outfit', fontSize: '18px', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818' }} variant="h6">
                  {t('botList.header.createdOn')}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={{ fontFamily: 'Outfit', fontSize: '18px', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818' }} variant="h6">
                  {t('botList.header.businessType')}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={{ fontFamily: 'Outfit', fontSize: '18px', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818' }} variant="h6">
                  {t('botList.header.status')}
                </Typography>
              </Grid>
              <Grid item xs={1.5}>
                <Typography sx={{ fontFamily: 'Outfit', fontSize: '18px', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818' }} variant="h6">
                  {t('botList.header.actions')}
                </Typography>
              </Grid>
            </Grid>
          )
        }

        {
          !loading ? (
            <Box sx={{ height: currentBots.length > 7 ? '750px' : 'inherit', mb: '40px', }}>
              <FlipMove
              >
                {currentBots && currentBots.length > 0 ? (
                  currentBots.map((bot: any) => (
                    <Grid
                      key={bot.id}
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        py: 1,
                        px: 2,
                        backgroundColor: themeMode === "dark" ? '#181818' : '#fff',
                        color: themeMode === "dark" ? '#fff' : '#121212', // Ajusta el color del texto según el tema
                        marginTop: '10px',
                        borderRadius: '25px',
                        height: '100px',
                        boxShadow: themeMode === "dark"
                          ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                          : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                      }}
                    >
                      {!isMobile ? (
                        <>
                          {/* Logo */}
                          <Grid item xs={2}>
                            <Box sx={{ textAlign: 'center' }}>
                              <motion.div
                                initial={{ scale: 0.5 }}  // Iniciar con un tamaño de 0
                                animate={{ scale: 1 }} // Animar a su tamaño original
                                transition={{ duration: 0.25 }}
                              >
                                <img src={themeMode === "dark" ? bot?.logo : bot?.lightModeLogo} alt="Logo del chatbot" style={{ maxHeight: '30px', }} />
                              </motion.div>

                            </Box>
                          </Grid>
                          {/* Nombre */}
                          <Grid item xs={2}>
                            <Typography sx={{ fontFamily: 'Outfit', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818', fontWeight: '300' }} variant="body1">
                              {bot.companyName}
                            </Typography>
                          </Grid>
                          {/* Fecha de Creación */}
                          <Grid item xs={2}>
                            <Typography
                              sx={{
                                fontFamily: 'Outfit',
                                textAlign: 'center',
                                color: themeMode === "dark" ? '#fff' : '#181818',
                                fontWeight: '300',
                                maxWidth: '120px',
                                mx: 'auto'
                              }}
                              variant="body1"
                            >
                              {bot.creationDate ? formatDate(bot.creationDate instanceof Date ? bot.creationDate : bot.creationDate.toDate ? bot.creationDate.toDate() : new Date(bot.creationDate)) : '-'}
                            </Typography>
                          </Grid>

                          {/* Tipo de Negocio */}
                          <Grid item xs={2}>
                            <Typography sx={{ fontFamily: 'Outfit', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818', fontWeight: '300' }} variant="body1">
                              {bot.businessType}
                            </Typography>
                          </Grid>
                          {/* Estado */}
                          <Grid item xs={2}>
                            <Typography
                              sx={{
                                fontFamily: 'Outfit',
                                textAlign: 'center',
                                maxWidth: '120px',
                                mx: 'auto',
                                borderRadius: '100px',
                                ...getStyleByState(bot.chatbotState)
                              }}
                              variant="body1"
                            >
                              {t(`chatbotState.${bot.chatbotState}`)}
                            </Typography>
                          </Grid>
                          {/* Acciones */}
                          <Grid item xs={1.5}>
                            <Box sx={{ textAlign: 'center' }}>
                              <IconButton
                                onClick={(event) => handleMenuOpen(event, bot.id)}
                                sx={{ '&:focus': { outline: 'none' }, '&:hover': { backgroundColor: themeMode === "dark" ? '#212121' : '#fafafa' } }}
                              >
                                <MoreVertRoundedIcon sx={{ color: themeMode === "dark" ? '#fff' : '#c1c1c1' }} />
                              </IconButton>
                              <Menu
                                anchorEl={botAnchorEls[bot.id]}
                                open={Boolean(botAnchorEls[bot.id])}
                                onClose={() => handleMenuClose(bot.id)}
                                PaperProps={{
                                  style: {
                                    backgroundColor: themeMode === "dark" ? '#000' : '#fff',
                                    color: themeMode === "dark" ? '#fff' : '#181818',
                                    borderRadius: '20px',
                                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                  },
                                }}
                              >
                                <MenuItem sx={{ fontSize: '16px', '&:hover': { backgroundColor: themeMode === "dark" ? '#232323' : '#fafafa' } }} onClick={() => { handleEdit(bot.id); }}>
                                  <EditRoundedIcon sx={{ color: themeMode === "dark" ? '#fff' : '#c1c1c1', marginRight: '8px', fontSize: '20px' }} />
                                  {t('botList.actions.edit')}
                                </MenuItem>
                                <MenuItem sx={{ fontSize: '16px', '&:hover': { backgroundColor: themeMode === "dark" ? '#232323' : '#fafafa' } }} onClick={() => { handleView(bot.id); }}>
                                  <RemoveRedEyeRoundedIcon sx={{ color: themeMode === "dark" ? '#fff' : '#c1c1c1', marginRight: '8px', fontSize: '20px' }} />
                                  {t('botList.actions.view')}
                                </MenuItem>
                                <MenuItem sx={{ fontSize: '16px', '&:hover': { backgroundColor: themeMode === "dark" ? '#232323' : '#fafafa' } }} onClick={() => { handleClickOpen(bot.id); }}>
                                  <DeleteRoundedIcon sx={{ color: themeMode === "dark" ? '#fff' : '#c1c1c1', marginRight: '8px', fontSize: '20px' }} />
                                  {t('botList.actions.delete')}
                                </MenuItem>
                              </Menu>
                            </Box>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={4}>
                            <Box sx={{ textAlign: 'left' }}>
                              <motion.div
                                initial={{ scale: 0.5 }}
                                animate={{ scale: 1 }}
                                transition={{ duration: 0.25 }}
                              >
                                <img src={themeMode === "dark" ? bot?.logo : bot?.lightModeLogo} alt="Logo del chatbot" style={{ maxHeight: '20px', }} />
                              </motion.div>

                              <Typography sx={{ fontSize: '16px', textAlign: 'left' }}>
                                {bot.companyName}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={6} alignItems="end">
                            <Stack flexDirection="column" justifyContent="space-between" alignItems="end">
                              <Typography sx={{ fontSize: '14px', textAlign: 'right' }}>
                                {bot.creationDate ? formatDate(bot.creationDate instanceof Date ? bot.creationDate : bot.creationDate.toDate ? bot.creationDate.toDate() : new Date(bot.creationDate)) : '-'}
                              </Typography>
                              <Typography sx={{ fontSize: '14px', textAlign: 'right' }}>
                                {bot.businessType}
                              </Typography>
                              <Typography
                                sx={{
                                  fontFamily: 'Outfit',
                                  textAlign: 'center',
                                  width: '100px',
                                  borderRadius: '100px',
                                  fontSize: '14px',
                                  ...getStyleByState(bot.chatbotState),
                                  mt: '5px'
                                }}
                              >
                                {t(`chatbotState.${bot.chatbotState}`)}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={1} alignItems="end">
                            <IconButton onClick={() => handleView(bot.id)} sx={{ '&:focus': { outline: 'none' } }}>
                              <ArrowForwardIosRoundedIcon />
                            </IconButton>
                          </Grid>
                        </>
                      )
                      }
                    </Grid>
                  ))
                ) : (
                  <Stack flexDirection="column" justifyContent="center" alignItems="center" gap={2} sx={{ mt: '100px', padding: '20px' }}>
                    <ChatRoundedIcon sx={{ fontSize: '60px', color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1' }} />
                    <Box sx={{ textAlign: 'center', }}>
                      <Typography align="center" sx={{
                        fontSize: isMobile ? '16px' : '18px', fontFamily: 'Outfit', fontWeight: '300',
                        color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1',
                      }}>
                        {t('botList.noBotsCreated')}
                      </Typography>
                    </Box>
                  </Stack>
                )}
              </FlipMove>

            </Box>

          ) : (
            <Box sx={{ display: 'flex', mx: 'auto', alignItems: "center", justifyContent: 'center', mt: '100px' }}>
              <CircularProgress sx={{ color: themeMode === "dark" ? '#fafafa' : '#4a3aff' }} />
            </Box>
          )
        }

        {
          chatbots.length > 7 && (
            <Grid
              container
              direction={isMobile ? "column" : "row"}
              justifyContent="space-between"
              alignItems="center"
              sx={{ mt: '20px' }}
            >
              <Grid item>
                <Button
                  onClick={handleClickPrevious}
                  disabled={currentPage === 1}
                  sx={{
                    color: themeMode === "dark" ? '#fff' : '#181818',
                    borderColor: themeMode === "dark" ? '#fff' : '#eeeeee',
                    '&:focus': { outline: 'none' },
                    '&:hover': {
                      backgroundColor: themeMode === "dark" ? '#181818' : '#eeeeee',
                      borderColor: themeMode === "dark" ? '#fff' : '#cccccc',
                    },
                    borderRadius: '15px',
                    fontFamily: 'Outfit',
                    textTransform: 'none',
                  }}
                >
                  {t('conversationList.navigation.previous')}
                </Button>
                {startPage > 1 && <span style={{ color: themeMode === "dark" ? '#fff' : '#181818' }} >...</span>}
                {Array.from({ length: (endPage - startPage) + 1 }, (_, i) => startPage + i).map(page => (
                  <IconButton
                    key={page}
                    onClick={() => handlePageClick(page)}
                    sx={{
                      color: themeMode === "dark" ? '#fff' : '#181818',
                      borderColor: themeMode === "dark" ? '#fff' : '#eeeeee',
                      fontSize: '16px',
                      '&:focus': { outline: 'none' },
                      '&:hover': { borderColor: themeMode === "dark" ? '#fff' : '#cccccc' },
                      fontFamily: 'Outfit',
                      textTransform: 'none',
                      backgroundColor: page === currentPage ? themeMode === "dark" ? '#181818' : '#eeeeee' : 'transparent',
                      height: 35,
                      width: 35,
                      margin: '0 2px',
                    }}
                  >
                    {page}
                  </IconButton>
                ))}
                {endPage < totalPages && <span style={{ color: themeMode === "dark" ? '#fff' : '#181818' }} >...</span>}
                <Button
                  onClick={handleClickNext}
                  disabled={currentPage * botsPerPage >= chatbots.length}
                  sx={{
                    ml: '10px',
                    color: themeMode === "dark" ? '#fff' : '#181818',
                    '&:focus': { outline: 'none' },
                    '&:hover': {
                      backgroundColor: themeMode === "dark" ? '#181818' : '#eeeeee',
                      borderColor: themeMode === "dark" ? '#fff' : '#cccccc',
                    },
                    borderRadius: '15px',
                    fontFamily: 'Outfit',
                    textTransform: 'none',
                  }}
                >
                  {t('conversationList.navigation.next')}
                </Button>
              </Grid>
              {!isMobile && (
                <Grid item>
                  <Typography variant="body1" sx={{
                    color: themeMode === "dark" ? '#fff' : '#181818',
                    fontFamily: 'Outfit',
                    fontSize: '14px',
                    mt: 2
                  }}>
                    {t('conversationList.navigation.pageIndicator', { current: currentPage, total: totalPages })}
                  </Typography>
                </Grid>
              )}
            </Grid>
          )
        }

        <DialogCommon
          kTitle={t('botList.confirmation')}
          kMaxWidth="xs"
          kMaxHeight={isMobile ? '380px' : '250px'}
          kFixedDialog={0}
          kFixedDialogPosition="fixed"
          kDisableBottomDivider
          kEnableBorderRadius={true}
          kBorderRadius={isMobile ? '20px' : '30px'}
          kAlignBtn="center"
          kKeepBtnSize
          kTopDivider
          kSlideTransition
          kOpenDialogLists={open}
          kOnClose={handleClose}
          kOnPrimary={handleDelete}
          kOnSecondary={handleClose}
          kSecondaryBtnText={t('botList.cancel') as string}
          kPrimaryBtnText={t('botList.delete') as string}
          kContent={
            (
              <Typography sx={{
                fontWeight: '300', fontFamily: 'Outfit',
                fontSize: isMobile ? '14px' : '18px', color: themeMode === "dark" ? "#fafafa" : "#181818"
              }}>
                {t('botList.areYouSure')}
              </Typography>
            )
          }
        />

        <DialogCommon
          kTitle={t('botList.initialSetup')}
          kMaxWidth={initialConfigLoading || isWithinLimits ? "sm" : "md"}
          kMaxHeight={isMobile ? '100vh' : isWithinLimits ? '400px' : calculateHeight()}
          kFixedDialog={0}
          kFixedDialogPosition="fixed"
          kDisableBottomDivider
          kEnableBorderRadius={true}
          kBorderRadius={isMobile ? '20px' : '30px'}
          kAlignBtn="center"
          kKeepBtnSize
          kTopDivider
          kSlideTransition
          kAlignItemsStart={!isMobile && !initialConfigLoading}
          kOpenDialogLists={openConfigModal}
          kOnClose={handleCloseConfigModal}
          kOnPrimary={handleInitialConfig}
          kOnSecondary={step === 2 ? handleStepBack : handleCloseConfigModal}
          kSecondaryBtnText={(step === 2 ? t('botList.navigation.back') : t('botList.navigation.cancel')) ?? ""}
          kPrimaryBtnText={(step === 2 ? t('botList.navigation.save') : t('botList.navigation.continue')) ?? ""}
          kDisableActions={!!(initialConfigLoading || isWithinLimits)}
          kContent={isWithinLimits ? <LimitReachedContent /> : (step === 1 ? StepOneContent : StepTwoContent)}
          kDisableBtnSave={isContinueDisabled}
        />

      </Box>

    </SectionContainer>
  );
}
export default BotList;
