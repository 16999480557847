import SectionContainer from "../../global/SectionContainer";
import {
  Box, Typography, Button, useTheme, Stack, useMediaQuery,
  Divider, List, ListItem, ListItemIcon, ListItemText, ButtonGroup, CircularProgress, MenuItem, Select
} from "@mui/material";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useContext, useEffect, useState } from "react";
import StoreContext from "../../context/Store/storeContext";
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../context/Config/ConfigContext";
import { httpsCallable } from "firebase/functions";
import { db, functions } from "../../services/firebase/firebase";
import { getAuth } from "firebase/auth";
import DialogCommon from "../../components/DialogCommon/DialogCommon";
import ChatBubbleRoundedIcon from '@mui/icons-material/ChatBubbleRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import PaymentIcon from '@mui/icons-material/Payment';
import Cookies from "js-cookie";
import { deleteField, doc, updateDoc } from "firebase/firestore";
import { showToast } from "../../components/Toast/toast";
import { motion } from "framer-motion";

export default function ClientSubscription() {

  const auth = getAuth();
  const userId = auth.currentUser?.uid;

  const { user, fetchUser } = useContext(StoreContext);

  const { themeMode } = useContext(ConfigContext);
  const { t } = useTranslation('global');

  const [isGeneratingLink, setIsGeneratingLink] = useState(false);
  const [isCancelingPlan, setIsCancelingPlan] = useState(false);
  const [isReactivatingPlan, setIsReactivatingPlan] = useState(false);

  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openReactivateDialog, setOpenReactivateDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openBuyResponses, setOpenBuyResponses] = useState(false);
  const [selectedPlanKey, setSelectedPlanKey] = useState('');

  const currency = user?.currency || 'MXN';

  const [quantity, setQuantity] = useState(0);

  const addOnPriceId = currency === "MXN" ? process.env.VITE_APP_ADD_ON_PRICE_KEY_MXN : process.env.VITE_APP_ADD_ON_PRICE_KEY_USD;

  const handlePurchase = (selectedQuantity: number) => {
    setIsGeneratingLink(true);
    runLoadingSteps(buyResponsesSteps, async () => {
      const generateLink = httpsCallable<unknown, PaymentLinkResponse>(functions, 'generateAddOnPaymentLink');
      try {
        const result = await generateLink({
          quantity: selectedQuantity,
          addOnPriceId: addOnPriceId,
        });
        const linkResponse = result.data as PaymentLinkResponse;
        window.location.href = linkResponse.url;
      } catch (error) {
        showToast('error', t('clientSubscription.errors.paymentLinkGenerationError'), themeMode);
        setIsGeneratingLink(false);
      }
    });
  };

  const handleOpenCancelDialog = () => {
    setOpenCancelDialog(true);
  };

  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
  };

  const handleOpenReactivateDialog = () => {
    setOpenReactivateDialog(true);
  };

  const handleCloseReactivateDialog = () => {
    setOpenReactivateDialog(false);
  };

  const handleOpenUpdateDialog = (planKey: any) => {
    setSelectedPlanKey(planKey);
    setOpenUpdateDialog(true);
  };

  const handleCloseUpdateDialog = () => {
    setOpenUpdateDialog(false);
  };

  const handleOpenBuyResponseDialog = () => {
    setOpenBuyResponses(true);
  };

  const handleCloseBuyResponseDialog = () => {
    setOpenBuyResponses(false);
  };


  const handleConfirmCancelSubscription = () => {

    cancelSubscription()

  };

  const handleConfirmUpdateSubscription = () => {
    handlePlanSelection(selectedPlanKey);
  };
  const pricePerResponse = currency === "MXN" ? 0.3 : 0.01;
  const totalCost = quantity * pricePerResponse;

  const handleConfirmAddOnPurchase = () => {
    handlePurchase(quantity)
  };

  useEffect(() => {
    setIsGeneratingLink(false);
  }, [])

  const isAnnual = user.subscriptionType.includes('Annual');
  const [planPeriod, setPlanPeriod] = useState(isAnnual ? 'annual' : 'monthly');

  const monthlyPlans = [
    {
      name: t('clientSubscription.plans.basic.name'),
      price: currency === "MXN" ? "199" : "12",
      id: `${t('clientSubscription.plans.basic.id')}${currency}`,
      period: t('clientSubscription.plans.basic.price'),
      description: t('clientSubscription.plans.basic.description'),
      characteristics: [
        t('clientSubscription.plans.basic.characteristics.0'),
        t('clientSubscription.plans.basic.characteristics.1'),
        t('clientSubscription.plans.basic.characteristics.2', { count: 1000 }),
        t('clientSubscription.plans.basic.characteristics.3'),
        t('clientSubscription.plans.basic.characteristics.4'),
        t('clientSubscription.plans.basic.characteristics.5'),
        t('clientSubscription.plans.basic.characteristics.6'),
      ],
    },
    {
      name: t('clientSubscription.plans.premium.name'),
      price: currency === "MXN" ? "499" : "29",
      id: `${t('clientSubscription.plans.premium.id')}${currency}`,
      period: t('clientSubscription.plans.premium.price'),
      description: t('clientSubscription.plans.premium.description'),
      characteristics: [
        t('clientSubscription.plans.premium.characteristics.0'),
        t('clientSubscription.plans.premium.characteristics.1', { count: 5000 }),
        t('clientSubscription.plans.premium.characteristics.2'),
        t('clientSubscription.plans.premium.characteristics.3'),
        t('clientSubscription.plans.premium.characteristics.4'),
        t('clientSubscription.plans.premium.characteristics.5'),
      ],
    },
    {
      name: t('clientSubscription.plans.business.name'),
      price: currency === "MXN" ? "899" : "48",
      id: `${t('clientSubscription.plans.business.id')}${currency}`,
      period: t('clientSubscription.plans.business.price'),
      description: t('clientSubscription.plans.business.description'),
      characteristics: [
        t('clientSubscription.plans.business.characteristics.0'),
        t('clientSubscription.plans.business.characteristics.1', { count: 10000 }),
        t('clientSubscription.plans.business.characteristics.2'),
        t('clientSubscription.plans.business.characteristics.3'),
        t('clientSubscription.plans.business.characteristics.4'),
        t('clientSubscription.plans.business.characteristics.5'),
        t('clientSubscription.plans.business.characteristics.6'),
      ],
    },
  ];

  const anualPlans = [
    {
      name: t('clientSubscription.plans.basic.name'),
      price: currency === "MXN" ? "1,910" : "115",
      id: `${t('clientSubscription.plans.basic.stripeAnualId')}${currency}`,
      period: t('clientSubscription.plans.basic.anualPrice'),
      description: t('clientSubscription.plans.basic.description'),
      characteristics: [
        t('clientSubscription.plans.basic.characteristics.0'),
        t('clientSubscription.plans.basic.characteristics.1'),
        t('clientSubscription.plans.basic.characteristics.2', { count: 12000 }),
        t('clientSubscription.plans.basic.characteristics.3'),
        t('clientSubscription.plans.basic.characteristics.4'),
        t('clientSubscription.plans.basic.characteristics.5'),
        t('clientSubscription.plans.basic.characteristics.6'),
      ],
    },
    {
      name: t('clientSubscription.plans.premium.name'),
      price: currency === "MXN" ? "4,790" : "278",
      id: `${t('clientSubscription.plans.premium.stripeAnualId')}${currency}`,
      period: t('clientSubscription.plans.premium.anualPrice'),
      description: t('clientSubscription.plans.premium.description'),
      characteristics: [
        t('clientSubscription.plans.premium.characteristics.0'),
        t('clientSubscription.plans.premium.characteristics.1', { count: 60000 }),
        t('clientSubscription.plans.premium.characteristics.2'),
        t('clientSubscription.plans.premium.characteristics.3'),
        t('clientSubscription.plans.premium.characteristics.4'),
        t('clientSubscription.plans.premium.characteristics.5'),
      ],
    },
    {
      name: t('clientSubscription.plans.business.name'),
      price: currency === "MXN" ? "8,360" : "460",
      id: `${t('clientSubscription.plans.business.stripeAnualId')}${currency}`,
      period: t('clientSubscription.plans.business.anualPrice'),
      description: t('clientSubscription.plans.business.description'),
      characteristics: [
        t('clientSubscription.plans.business.characteristics.0'),
        t('clientSubscription.plans.business.characteristics.1', { count: 120000 }),
        t('clientSubscription.plans.business.characteristics.2'),
        t('clientSubscription.plans.business.characteristics.3'),
        t('clientSubscription.plans.business.characteristics.4'),
        t('clientSubscription.plans.business.characteristics.5'),
        t('clientSubscription.plans.business.characteristics.6'),
      ],
    },
  ];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  function mapSubscriptionTypeToPlanName(subscriptionType: any) {
    switch (subscriptionType) {
      case "free":
        return t('clientSubscription.plans.free.name');

      case "basicMonthlyMXN":
      case "basicAnnualMXN":
      case "basicMonthlyUSD":
      case "basicAnnualUSD":
        return t('clientSubscription.plans.basic.name');

      case "premiumMonthlyMXN":
      case "premiumAnnualMXN":
      case "premiumMonthlyUSD":
      case "premiumAnnualUSD":
        return t('clientSubscription.plans.premium.name');

      case "enterpriseMonthlyMXN":
      case "enterpriseAnnualMXN":
      case "enterpriseMonthlyUSD":
      case "enterpriseAnnualUSD":
        return t('clientSubscription.plans.business.name');

      default:
        return null;
    }
  }

  interface PaymentLinkResponse {
    url: string;
  }

  const [, setCurrentStep] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [, setIsProcessComplete] = useState(false);

  const paymentLinkSteps = [
    { step: t('clientSubscription.paymentSteps.generatingLink'), duration: 1500 },
    { step: t('clientSubscription.paymentSteps.finalizingProcess'), duration: 3500 },
  ];

  const buyResponsesSteps = [
    { step: t('clientSubscription.buyResponsesSteps.preparingOrder'), duration: 2000 },
    { step: t('clientSubscription.buyResponsesSteps.generatingLink'), duration: 2500 },
  ];

  const cancelPlanSteps = [
    { step: t('clientSubscription.cancelPlanSteps.processingRequest'), duration: 1500 },
    { step: t('clientSubscription.cancelPlanSteps.updatingAccount'), duration: 2000 },
    { step: t('clientSubscription.cancelPlanSteps.finalizingCancellation'), duration: 2500 },
  ];

  const reactivatePlanSteps = [
    { step: t('clientSubscription.reactivatePlanSteps.processingRequest'), duration: 1500 },
    { step: t('clientSubscription.reactivatePlanSteps.restoringAccess'), duration: 2000 },
    { step: t('clientSubscription.reactivatePlanSteps.finalizingReactivation'), duration: 2500 },
  ];

  const variants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 10 }
  };

  const runLoadingSteps = (steps: { step: string; duration: number }[], processFunction: () => Promise<void>) => {
    setCurrentStep(0);
    setLoadingMessage(steps[0].step);
    setIsProcessComplete(false);

    steps.forEach((step, index) => {
      setTimeout(() => {
        setLoadingMessage(step.step);
        setCurrentStep(index + 1);
      }, steps.slice(0, index).reduce((sum, s) => sum + s.duration, 0));
    });

    // Ejecutar la función del proceso
    processFunction().then(() => {
      setIsProcessComplete(true);
    }).catch((error) => {
      console.error("Error en el proceso:", error);
      setIsProcessComplete(true);
    });
  };

  const handlePlanSelection = (planKey: string) => {
    if (!userId) {
      showToast('error', t('clientSubscription.errors.userIdUndefined'), themeMode);
      return;
    }

    setIsGeneratingLink(true);
    runLoadingSteps(paymentLinkSteps, async () => {
      const generatePaymentLink = httpsCallable(functions, 'generatePaymentLink');
      try {
        const result = await generatePaymentLink({ planKey, userId });
        const data = result.data as PaymentLinkResponse;
        window.location.href = data.url;
      } catch (error) {
        showToast('error', t('clientSubscription.errors.paymentLinkGenerationError'), themeMode);
        setIsGeneratingLink(false);
      }
    });
  };

  useEffect(() => {
  }, [user])

  const navigate = useNavigate()

  interface CancelSubscriptionResponse {
    message: string;
  }

  const cancelSubscription = () => {
    setIsCancelingPlan(true);
    runLoadingSteps(cancelPlanSteps, async () => {
      const cancel = httpsCallable<unknown, CancelSubscriptionResponse>(functions, 'cancelSubscription');
      try {
        await cancel();
        showToast('success', t('clientSubscription.success.subscriptionWillBeCancelled'), themeMode);
        fetchUser(userId);
        setIsCancelingPlan(false);
        setOpenCancelDialog(false);
      } catch (error) {
        showToast('error', t('clientSubscription.errors.subscriptionCancellationError'), themeMode);
        setIsCancelingPlan(false);
        setOpenCancelDialog(false);
      }
    });
  };

  const planName = mapSubscriptionTypeToPlanName(selectedPlanKey);
  const confirmationMessage = `${t('clientSubscription.areYouSureUpdateBefore')}${planName}${t('clientSubscription.areYouSureUpdateAfter')}`;

  const subscriptionStartDate = user.subscriptionStartDate?.seconds;
  const subscriptionEndDate = user.subscriptionEndDate?.seconds;

  const formatDateFromTimestamp = (timestamp: any) => {
    const date = new Date(timestamp * 1000);
    const currentLang = Cookies.get('i18next') || 'es-MX';
    return new Intl.DateTimeFormat(currentLang, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(date);
  };

  const calculateDaysUntilNextCharge = (endDateTimestamp: number | undefined) => {
    if (!endDateTimestamp) {
      return '-';
    }
    const today = new Date();
    const endDate = new Date(endDateTimestamp * 1000);
    const difference = endDate.getTime() - today.getTime();
    const daysUntilNextCharge = Math.ceil(difference / (1000 * 3600 * 24));
    return daysUntilNextCharge;
  };

  const formattedStartDate = subscriptionStartDate ? formatDateFromTimestamp(subscriptionStartDate) : '-';
  const formattedEndDate = subscriptionEndDate ? formatDateFromTimestamp(subscriptionEndDate) : '-';

  const daysUntilNextCharge = subscriptionEndDate ? calculateDaysUntilNextCharge(subscriptionEndDate) : '-';

  const getPriceFromSubscriptionType = (subscriptionType: string) => {
    switch (subscriptionType) {
      case 'free':
        return t('clientSubscription.planPrices.free');

      // Precios en MXN
      case 'basicMonthlyMXN':
        return '199 MXN';
      case 'premiumMonthlyMXN':
        return '499 MXN';
      case 'enterpriseMonthlyMXN':
        return '899 MXN';
      case 'basicAnnualMXN':
        return '1,910 MXN';
      case 'premiumAnnualMXN':
        return '4,790 MXN';
      case 'enterpriseAnnualMXN':
        return '8,360 MXN';

      // Precios en USD
      case 'basicMonthlyUSD':
        return '$12 USD';
      case 'premiumMonthlyUSD':
        return '$29 USD';
      case 'enterpriseMonthlyUSD':
        return '$48 USD';
      case 'basicAnnualUSD':
        return '$115 USD';
      case 'premiumAnnualUSD':
        return '$278 USD';
      case 'enterpriseAnnualUSD':
        return '$460 USD';

      default:
        return t('clientSubscription.planPrices.unavailable');
    }
  };

  const subscriptionPrice = getPriceFromSubscriptionType(user.subscriptionType);

  useEffect(() => {
    if (user.planChangeType && !user.notificationShown) {
      handleOpenNotificationDialog()
    }
  }, [user]);

  useEffect(() => {
    if (user?.pendingNotification?.type === 'chatbotsDowngrade') {
      showChatbotsDowngradeNotification(user.pendingNotification.messageKey, user.pendingNotification.count);
    }
  }, [user]);

  const showChatbotsDowngradeNotification = (messageKey: string, count: number) => {
    const message = t(messageKey, { count });
    showToast('warning', message, themeMode);
  };

  const [openNotificationDialog, setOpenNotificationDialog] = useState(false);

  const handleOpenNotificationDialog = () => {
    setOpenNotificationDialog(true);
  };

  const handleCloseNotificationDialog = async () => {
    if (userId) {
      try {
        const userRef = doc(db, 'users', userId);
        await updateDoc(userRef, {
          notificationShown: true,
          planChangeType: null,
          pendingNotification: deleteField(),
        });
        setOpenNotificationDialog(false);
        fetchUser(userId);
      } catch (error) {
        console.error('Error updating notificationShown:', error);
      }
    } else {
      console.error('User ID is undefined');
    }
  };

  const handleConfirmNotification = async () => {
    if (userId) {
      try {
        const userRef = doc(db, 'users', userId);
        await updateDoc(userRef, { notificationShown: true, planChangeType: null });
        handleCloseNotificationDialog();
        fetchUser(userId);
      } catch (error) {
        console.error('Error updating notificationShown:', error);
      }
    } else {
      console.error('User ID is undefined');
    }
  };

  const handleReactivateSubscription = () => {
    setIsReactivatingPlan(true);
    runLoadingSteps(reactivatePlanSteps, async () => {
      const reactivate = httpsCallable(functions, 'reactivateSubscription');
      try {
        await reactivate();
        showToast('success', t('clientSubscription.success.subscriptionReactivated'), themeMode);
        fetchUser(userId);
        handleCloseReactivateDialog();
        setIsReactivatingPlan(false);
      } catch (error) {
        showToast('error', t('clientSubscription.errors.subscriptionReactivationError'), themeMode);
        setIsReactivatingPlan(false);
      }
    });
  };

  const getNotificationContent = () => {
    const plans = planPeriod === 'monthly' ? monthlyPlans : anualPlans;
    const currentPlan = plans.find((plan) => plan.id === user.subscriptionType);

    if (user.planChangeType === 'upgrade') {
      return (
        <Stack flexDirection="column" justifyContent="center" alignItems="start" sx={{ maxWidth: '500px' }}>
          <Typography sx={{ color: themeMode === "dark" ? "#fafafa" : "#181818", fontWeight: 'bold' }} variant="h6" gutterBottom>
            {t('clientSubscription.upgradeTitle')}
          </Typography>
          <Typography
            sx={{
              fontWeight: '700',
              fontSize: '40px',
              background: `linear-gradient(45deg, #4a3aff, ${themeMode === "dark" ? "#fafafa" : "#c1c1c1"})`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              MozBackgroundClip: 'text',
              MozTextFillColor: 'transparent',
              display: 'inline',
            }}
            variant="h6"
          >
            {`${mapSubscriptionTypeToPlanName(user.subscriptionType)}`}
          </Typography>
          <Typography sx={{ color: themeMode === "dark" ? "#fafafa" : "#181818" }} gutterBottom>
            {t('clientSubscription.upgradeDescription')}
          </Typography>
          <Box sx={{ my: 2 }}>
            <List>
              {currentPlan?.characteristics.map((caracteristica, idx) => (
                <ListItem key={idx} sx={{ padding: 0 }}>
                  <ListItemIcon>
                    <CheckCircleRoundedIcon sx={{ color: '#4a3aff' }} />
                  </ListItemIcon>
                  <ListItemText primary={
                    <Typography sx={{ fontFamily: 'Outfit', color: themeMode === "dark" ? "#fafafa" : "#181818", fontSize: '16px', fontWeight: '300' }} variant="body2">
                      {caracteristica}
                    </Typography>
                  } />
                </ListItem>
              ))}
            </List>
          </Box>
        </Stack>
      );
    } else if (user.planChangeType === 'downgrade') {
      return (
        <Stack flexDirection="column" justifyContent="center" alignItems="start" sx={{ maxWidth: '500px' }}>
          <Typography sx={{ color: themeMode === "dark" ? "#fafafa" : "#181818", fontWeight: 'bold' }} variant="h6" gutterBottom>
            {t('clientSubscription.downgradeTitle')}
          </Typography>
          <Typography
            sx={{
              fontWeight: '700',
              fontSize: '40px',
              background: `linear-gradient(45deg, #4a3aff, ${themeMode === "dark" ? "#fafafa" : "#c1c1c1"})`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              MozBackgroundClip: 'text',
              MozTextFillColor: 'transparent',
              display: 'inline',
            }}
            variant="h6"
          >
            {`${mapSubscriptionTypeToPlanName(user.subscriptionType)}`}
          </Typography>
          <Typography sx={{ color: themeMode === "dark" ? "#fafafa" : "#181818" }} gutterBottom>
            {t('clientSubscription.downgradeDescription')}
          </Typography>
          <Box sx={{ my: 2 }}>
            <List>
              {currentPlan?.characteristics.map((caracteristica, idx) => (
                <ListItem key={idx} sx={{ padding: 0 }}>
                  <ListItemIcon>
                    <CheckCircleRoundedIcon sx={{ color: '#4a3aff' }} />
                  </ListItemIcon>
                  <ListItemText primary={
                    <Typography sx={{ fontFamily: 'Outfit', color: themeMode === "dark" ? "#fafafa" : "#181818", fontSize: '16px', fontWeight: '300' }} variant="body2">
                      {caracteristica}
                    </Typography>
                  } />
                </ListItem>
              ))}
            </List>
          </Box>
        </Stack>
      );
    }

    return null;
  };

  return (
    <SectionContainer
      title={t('clientSubscription.title') as string}
      text={t('clientSubscription.viewPaymentHistory') as string}
      icon={<ListAltRoundedIcon />}
      onClick={() => { navigate('/payment-history') }}
    >
      {user && (
        <Box sx={{ mb: '40px' }}>
          <Stack flexDirection={"column"} justifyContent="space-between" alignItems="start" gap={5}
            sx={{
              backgroundColor: themeMode === "dark" ? "#181818" : "#fff", py: '20px', px: '20px', borderRadius: '30px',
              p: isMobile ? '20px' : '30px',
              boxShadow: themeMode === "dark"
                ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
            }}
          >
            <Stack flexDirection={isMobile ? "column" : "row"} justifyContent="space-between" alignItems="start" width="100%" gap={3}>
              <Stack>
                {
                  user.subscriptionStatus !== "canceled" && (
                    <Typography sx={{ textAlign: 'left', color: themeMode === "dark" ? "#c1c1c1" : "#181818", fontWeight: '400', fontSize: '16px', maxWidth: '200px', mb: '-15px' }} variant="body1" color="textSecondary">
                      {t('clientSubscription.labels.currentPlan')}
                    </Typography>
                  )
                }
                <Typography
                  sx={{
                    fontWeight: '700',
                    fontSize: '40px',
                    background: `linear-gradient(45deg, #4a3aff, ${themeMode === "dark" ? "#fafafa" : "#c1c1c1"})`,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    MozBackgroundClip: 'text',
                    MozTextFillColor: 'transparent',
                    display: 'inline',
                  }}
                  variant="h6"
                >
                  {
                    (user.subscriptionStatus === "canceled") ? t('clientSubscription.planDeactivated') : (`${mapSubscriptionTypeToPlanName(user.subscriptionType)}`)
                  }
                </Typography>
              </Stack>

              {
                user.subscriptionStatus === "canceled" ? (
                  <Typography sx={{ textAlign: 'left', color: themeMode === "dark" ? "#c1c1c1" : "#181818", fontWeight: '400', fontSize: '16px', maxWidth: '500px', mb: '-15px' }} variant="body1" color="textSecondary">
                    {t('clientSubscription.pausedAccessMessage')}
                  </Typography>

                ) : (
                  <Stack flexDirection={isMobile ? "column" : "row"} justifyContent="center" alignItems="start" gap={2}>
                    <CalendarMonthIcon
                      sx={{ color: themeMode === 'dark' ? '#fafafa' : '#cfcfcf', fontSize: isMobile ? "20px" : "20px" }}
                    />
                    <Typography sx={{ textAlign: 'lef16px', fontWeight: '400', color: themeMode === "dark" ? "#fafafa" : "#181818" }} variant="body1">
                      {`${formattedStartDate} - ${formattedEndDate}`}
                    </Typography>
                  </Stack>
                )
              }
            </Stack>
            {
              user.subscriptionStatus !== "canceled" && (
                <Stack flexDirection="row" justifyContent="space-between" alignItems="start" width="100%" gap={3} flexWrap={isMobile ? "wrap" : "nowrap"}>
                  <Stack flexDirection="column" justifyContent="start" alignItems="start" sx={{ width: isMobile ? '44%' : '200px' }}>
                    <ChatBubbleRoundedIcon
                      sx={{ mb: '6px', color: themeMode === 'dark' ? '#fafafa' : '#cfcfcf', fontSize: isMobile ? "20px" : "30px" }}
                    />
                    <Typography sx={{ textAlign: 'left', color: themeMode === "dark" ? "#fafafa" : "#181818", fontSize: '25px', fontWeight: '700' }} variant="body1">
                      {`${user.botCount || 0}/${user.botLimit || 250}`}
                    </Typography>
                    <Typography sx={{ textAlign: 'left', color: themeMode === "dark" ? "#c1c1c1" : "#181818", fontWeight: '400', fontSize: '14px', maxWidth: '100px' }} variant="body1" color="textSecondary">
                      {t('clientSubscription.labels.botLimit')}
                    </Typography>
                  </Stack>
                  <Stack flexDirection="column" justifyContent="start" alignItems="start" sx={{ width: isMobile ? '44%' : '200px' }}>
                    <QuestionAnswerRoundedIcon
                      sx={{ mb: '6px', color: themeMode === 'dark' ? '#fafafa' : '#cfcfcf', fontSize: isMobile ? "20px" : "30px" }}
                    />
                    <Typography sx={{ textAlign: 'left', color: themeMode === "dark" ? "#fafafa" : "#181818", fontSize: '25px', fontWeight: '700' }} variant="body1">
                      {`${user.responseCount || 0}/${user.responseLimit || 250}`}
                    </Typography>
                    <Typography sx={{ textAlign: 'left', color: themeMode === "dark" ? "#c1c1c1" : "#181818", fontWeight: '400', fontSize: '14px', maxWidth: '100px' }} variant="body1" color="textSecondary">
                      {t('clientSubscription.labels.responseLimit')}
                    </Typography>
                  </Stack>
                  <Stack flexDirection="column" justifyContent="start" alignItems="start" sx={{ width: isMobile ? '44%' : '200px' }}>
                    <PaymentIcon
                      sx={{ mb: '6px', color: themeMode === 'dark' ? '#fafafa' : '#cfcfcf', fontSize: isMobile ? "20px" : "30px" }}
                    />
                    <Typography sx={{ textAlign: 'left', color: themeMode === "dark" ? "#fafafa" : "#181818", fontSize: '25px', fontWeight: '700' }} variant="body1">
                      {subscriptionPrice}
                    </Typography>
                    <Typography sx={{ textAlign: 'left', color: themeMode === "dark" ? "#c1c1c1" : "#181818", fontWeight: '400', fontSize: '14px', maxWidth: '100px' }} variant="body1" color="textSecondary">
                      {t('clientSubscription.labels.cost')}
                    </Typography>
                  </Stack>
                  {user.subscriptionType !== 'free' ? (
                    <Stack flexDirection="column" justifyContent="start" alignItems="start" sx={{ width: isMobile ? '44%' : '200px' }}>
                      <TimelapseIcon
                        sx={{ color: themeMode === 'dark' ? '#fafafa' : '#cfcfcf', fontSize: isMobile ? "20px" : "30px" }}
                      />
                      <Typography sx={{ textAlign: 'left', color: themeMode === "dark" ? "#fafafa" : "#181818", fontSize: '25px', fontWeight: '700' }} variant="body1" color="textSecondary">
                        {`${daysUntilNextCharge} ${t('clientSubscription.days')}`}
                      </Typography>

                      <Typography sx={{ textAlign: 'left', color: themeMode === "dark" ? "#c1c1c1" : "#181818", fontWeight: '400', fontSize: '14px', maxWidth: '100px' }} variant="body1" color="textSecondary">
                        {user.subscriptionStatus === "pending_cancellation" ? t('clientSubscription.remainingDays') : t('clientSubscription.nextBilling')}
                      </Typography>
                    </Stack>
                  ) : (
                    <Stack flexDirection="column" justifyContent="start" alignItems="start" sx={{ width: isMobile ? '44%' : '200px' }}>
                      <TimelapseIcon
                        sx={{ color: themeMode === 'dark' ? '#fafafa' : '#cfcfcf', fontSize: isMobile ? "20px" : "30px" }}
                      />
                      <Typography sx={{ textAlign: 'left', color: themeMode === "dark" ? "#fafafa" : "#181818", fontSize: '25px', fontWeight: '700' }} variant="body1" color="textSecondary">
                        {`${daysUntilNextCharge} ${t('clientSubscription.days')}`}
                      </Typography>
                      <Typography sx={{ textAlign: 'left', color: themeMode === "dark" ? "#c1c1c1" : "#181818", fontWeight: '400', fontSize: '14px', maxWidth: '100px' }} variant="body1" color="textSecondary">
                        {t('clientSubscription.remainingDays')}
                      </Typography>
                    </Stack>
                  )
                  }

                  <Stack flexDirection="column" justifyContent="space-between" alignItems="center" sx={{ width: '100%', maxWidth: isMobile ? '100%' : '230px' }}>
                    <Button
                      variant="contained"
                      onClick={handleOpenBuyResponseDialog}
                      sx={{
                        height: 'auto',
                        color: '#fff',
                        fontSize: isMobile ? '16px' : "16px",
                        fontWeight: "500",
                        textTransform: "none",
                        padding: "10px 20px",
                        borderRadius: '100px',
                        borderColor: "transparent",
                        borderWidth: 1,
                        borderStyle: 'solid',
                        fontFamily: 'Outfit',
                        backgroundColor: '#4a3aff',
                        width: '100%',
                        '&:hover': {
                          borderColor: '#4a3aff',
                          color: themeMode === "dark" ? "white" : '#4a3aff',
                          backgroundColor: 'transparent',
                          borderWidth: 1,
                          borderStyle: 'solid',
                          boxShadow: 'none'
                        },
                        '&:focus': {
                          outline: 'none',
                        },
                        '&.Mui-disabled': {
                          backgroundColor: themeMode === "dark" ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
                          color: themeMode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
                        },
                      }}
                    >
                      {t('clientSubscription.buyResponses')}
                    </Button>
                    {
                      user.subscriptionType !== 'free' && (
                        <>
                          <Button
                            onClick={user.subscriptionStatus === "pending_cancellation" || user.subscriptionStatus === "canceled" ? () => { } : handleOpenCancelDialog}
                            sx={{
                              textTransform: 'none',
                              color: user.subscriptionStatus === "pending_cancellation" || user.subscriptionStatus === "canceled" ? 'grey' : '#c52828',
                              fontSize: '16px',
                              fontWeight: '300',
                              backgroundColor: 'transparent',
                              marginTop: '10px',
                              fontFamily: 'Outfit',
                              mb: '10px',
                              borderRadius: '50px',
                              width: '100%',
                              cursor: user.subscriptionStatus === "pending_cancellation" || user.subscriptionStatus === "canceled" ? 'default' : 'pointer',
                              '&:hover': {
                                backgroundColor: user.subscriptionStatus === "pending_cancellation" || user.subscriptionStatus === "canceled" ? 'transparent' : 'rgba(255, 0, 0, 0.1)',
                              },
                              '&:focus': {
                                outline: 'none',
                              },
                            }}
                          >
                            {user.subscriptionStatus === "pending_cancellation"
                              ? `${t('clientSubscription.cancelingOn')} ${formatDateFromTimestamp(user.subscriptionEndDate?.seconds)}`
                              : user.subscriptionStatus === "canceled"
                                ? t('clientSubscription.subscriptionCanceled')
                                : t('clientSubscription.cancelSubscription')
                            }
                          </Button>
                          {user.subscriptionStatus === "pending_cancellation" && (
                            <Button
                              onClick={handleOpenReactivateDialog}
                              sx={{
                                textTransform: 'none',
                                color: '#4a3aff',
                                fontSize: '16px',
                                fontWeight: '300',
                                backgroundColor: 'transparent',
                                marginTop: '10px',
                                fontFamily: 'Outfit',
                                mb: '10px',
                                borderRadius: '50px',
                                width: '100%',
                                '&:hover': {
                                  backgroundColor: 'rgba(74, 58, 255, 0.1)',
                                },
                                '&:focus': {
                                  outline: 'none',
                                },
                              }}
                            >
                              {t('clientSubscription.reactivateSubscription')}
                            </Button>
                          )}
                        </>
                      )}
                  </Stack>
                </Stack>
              )
            }
          </Stack>
        </Box>
      )
      }

      <Divider sx={{ width: '80%', mx: 'auto', mb: '30px', backgroundColor: themeMode === "dark" ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)', }} />
      <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
        <ButtonGroup
          color="primary"
          aria-label="outlined primary button group"
          sx={{
            '& .MuiButtonGroup-grouped': {
              borderColor: '#4a3aff',
              '&:not(:last-of-type)': {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              },
              '&:not(:first-of-type)': {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              },
              '&:focus': {
                outline: 'none',
              },
            },
            borderRadius: '50px',
            border: '1px solid #4a3aff',
          }}
        >
          <Button
            onClick={() => setPlanPeriod('monthly')}
            sx={{
              textTransform: 'none',
              fontSize: '16px',
              backgroundColor: planPeriod === 'monthly' ? '#4a3aff' : '',
              '&:hover': {
                backgroundColor: '#4a3aff',
                color: '#ffffff',
              },
              color: planPeriod === 'monthly' ? '#ffffff' : themeMode === "dark" ? '#ffffff' : '#181818',
              borderRadius: '50px',
              '&:focus': {
                outline: 'none',
              },
            }}
          >
            {t('clientSubscription.monthly')}
          </Button>
          <Button
            onClick={() => setPlanPeriod('annual')}
            sx={{
              textTransform: 'none',
              fontSize: '16px',
              backgroundColor: planPeriod === 'annual' ? '#4a3aff' : '',
              '&:hover': {
                backgroundColor: '#4a3aff',
                color: '#ffffff',
              },
              color: planPeriod === 'annual' ? '#ffffff' : themeMode === "dark" ? '#ffffff' : '#181818',
              borderRadius: '50px',
              '&:focus': {
                outline: 'none',
              },
              position: 'relative',
            }}
          >
            {t('clientSubscription.anual')}
            <Box
              sx={{
                position: 'absolute',
                top: '-14px',
                right: '-10px',
                backgroundColor: planPeriod === 'annual' ? '#ffffff' : '#ffffff',
                color: planPeriod === 'annual' ? '#4a3aff' : '#4a3aff',
                padding: '2px 8px',
                borderRadius: '68px',
                fontSize: '12px',
                fontWeight: 'bold',
                zIndex: 0,
                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)', // Sutil sombra
              }}
            >
              -20%
            </Box>

          </Button>


        </ButtonGroup>
      </Box>
      <Stack flexDirection={isMobile ? "column" : "row"} justifyContent="center" alignItems="center" gap={2} sx={{ mb: isMobile ? '80px' : 0 }}>
        {(planPeriod === 'monthly' ? monthlyPlans : anualPlans).map((plan) => (
          <Stack flexDirection="column" justifyContent="space-between" alignItems="start"
            sx={{
              backgroundColor: themeMode === "dark" ? "#181818" : "#fff", py: '20px', px: '20px', borderRadius: '30px',
              minHeight: isMobile ? '100%' : '650px', maxWidth: isMobile ? '100%' : '32%',
              border: plan.id === user.subscriptionType
                && (user.subscriptionStatus === "active" || user.subscriptionStatus === "pending_cancellation") ? '1px solid #4a3aff' : '1px solid transparent',
              boxShadow: themeMode === "dark"
                ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
            }}
            key={plan.name}
          >
            <Stack >
              <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                <Typography sx={{ fontFamily: 'Outfit', color: themeMode === "dark" ? "#fafafa" : "#181818", fontSize: '20px' }} gutterBottom >
                  {plan.name}
                </Typography>
                {
                  plan.name === t('clientSubscription.plans.premium.name') && (
                    <Button
                      sx={{
                        textTransform: 'none',
                        ':focus': { outline: 'none' },
                        borderRadius: '100px',
                        color: themeMode === "dark" ? '#fafafa' : '#181818',
                        p: '4px 16px',
                        border: '1px solid #4a3aff',
                        height: '30px'
                      }}
                      onClick={() => navigate('/subscription')}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{
                          backgroundColor: 'transparent',
                          color: themeMode === "dark" ? '#fafafa' : '#fff',
                          fontSize: '16px',
                          borderRadius: '100px',
                          height: '30px',
                          fontWeight: '600',
                          background: `linear-gradient(45deg, #4a3aff, ${themeMode === "dark" ? "#fafafa" : "#ffffff"})`,
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                          MozBackgroundClip: 'text',
                          MozTextFillColor: 'transparent',
                          display: 'inline',
                        }}
                      >
                        Popular
                      </Typography>
                    </Button>
                  )
                }
              </Stack>
              <Stack flexDirection="row" justifyContent="start" alignItems="flex-end" gap={1} sx={{ mb: '20px' }}>
                <Typography sx={{ fontFamily: 'Outfit', color: themeMode === "dark" ? "#fafafa" : "#181818", fontSize: isMobile ? '35px' : '40px', fontWeight: '600' }}>
                  {plan.price}
                </Typography>
                <Typography sx={{ fontFamily: 'Outfit', color: themeMode === "dark" ? "#fafafa" : "#181818", fontSize: '20px', fontWeight: '500', pb: '5px' }}>
                  {currency}{plan.period}
                </Typography>
                {planPeriod === 'annual' && (
                  <Box
                    sx={{
                      marginLeft: '8px',
                      backgroundColor: '#4a3aff33',
                      color: '#4a3aff',
                      padding: '2px 8px',
                      borderRadius: '108px',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    -20%
                  </Box>
                )}
              </Stack>

              <Typography sx={{ fontFamily: 'Outfit', color: themeMode === "dark" ? "#fafafa" : "#181818", fontSize: '16px', fontWeight: '300' }} variant="body2" gutterBottom>
                {plan.description}
              </Typography>
              <Divider sx={{ my: '10px', backgroundColor: themeMode === "dark" ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)', }} />
              <Box sx={{ my: 2 }}>
                <List>
                  {plan.characteristics.map((caracteristica, idx) => (
                    <ListItem key={idx} sx={{ padding: 0 }}>
                      <ListItemIcon>
                        <CheckCircleRoundedIcon sx={{ color: '#4a3aff' }} />
                      </ListItemIcon>
                      <ListItemText primary={
                        <Typography sx={{ fontFamily: 'Outfit', color: themeMode === "dark" ? "#fafafa" : "#181818", fontSize: '16px', fontWeight: '300' }} variant="body2">
                          {caracteristica}
                        </Typography>
                      } />
                    </ListItem>
                  ))}
                </List>
              </Box>

            </Stack>

            <Stack sx={{ width: '100%' }}>
              <Button
                variant="contained"
                onClick={() => handleOpenUpdateDialog(plan.id)}
                disabled={
                  (plan.id === user.subscriptionType &&
                    (user.subscriptionStatus === 'active' || user.subscriptionStatus === 'pending_cancellation'))
                }
                sx={{
                  height: 'auto',
                  color: '#fff',
                  fontSize: isMobile ? '16px' : "18px",
                  fontWeight: "500",
                  textTransform: "none",
                  padding: "10px 20px",
                  borderRadius: '100px',
                  borderColor: "transparent",
                  borderWidth: 1,
                  borderStyle: 'solid',
                  fontFamily: 'Outfit',
                  backgroundColor: '#4a3aff',
                  width: '100%',
                  '&:hover': {
                    borderColor: '#4a3aff',
                    color: themeMode === "dark" ? "white" : '#4a3aff',
                    backgroundColor: 'transparent',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    boxShadow: 'none'
                  },
                  '&:focus': {
                    outline: 'none',
                  },
                  '&.Mui-disabled': {
                    backgroundColor: themeMode === "dark" ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
                    color: themeMode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
                  },
                }}
              >
                {plan.id === user.subscriptionType &&
                  (user.subscriptionStatus === "active" || user.subscriptionStatus === "pending_cancellation")
                  ? t('clientSubscription.currentPlan') : t('clientSubscription.start')}
              </Button>


              <DialogCommon
                kTitle={t('botList.confirmation')}
                kDisableActions={isCancelingPlan}
                kMaxWidth="xs"
                kMaxHeight={isMobile ? '230px' : '250px'}
                kFixedDialog={0}
                kFixedDialogPosition="fixed"
                kDisableBottomDivider
                kEnableBorderRadius={true}
                kBorderRadius={isMobile ? '20px' : '30px'}
                kAlignBtn="center"
                kKeepBtnSize
                kTopDivider
                kSlideTransition
                kOpenDialogLists={openCancelDialog}
                kOnClose={handleCloseCancelDialog}
                kOnPrimary={handleConfirmCancelSubscription}
                kOnSecondary={handleCloseCancelDialog}
                kSecondaryBtnText={t('botList.cancel') as string}
                kPrimaryBtnText={t('clientSubscription.confirm') as string}
                kContent={
                  isCancelingPlan ? (
                    <Box sx={{ textAlign: 'center' }}>
                      <CircularProgress sx={{ color: themeMode === "dark" ? "#fafafa" : "#181818" }} />
                      <motion.div
                        key={loadingMessage}
                        variants={variants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{ duration: 0.5 }}
                      >
                        <Typography sx={{
                          mt: 2,
                          fontWeight: '300',
                          fontFamily: 'Outfit',
                          fontSize: '16px',
                          color: themeMode === "dark" ? "#fafafa" : "#181818"
                        }}>
                          {loadingMessage}
                        </Typography>
                      </motion.div>
                    </Box>
                  ) : (
                    <Typography sx={{
                      fontWeight: '300', fontFamily: 'Outfit',
                      fontSize: isMobile ? '14px' : '18px', color: themeMode === "dark" ? "#fafafa" : "#181818"
                    }}>
                      {t('clientSubscription.areYouSureCancel')}
                    </Typography>
                  )
                }
              />

              <DialogCommon
                kTitle="Reactivación de plan"
                kDisableActions={isReactivatingPlan}
                kMaxWidth="xs"
                kMaxHeight={isMobile ? '230px' : '250px'}
                kFixedDialog={0}
                kFixedDialogPosition="fixed"
                kDisableBottomDivider
                kEnableBorderRadius={true}
                kBorderRadius={isMobile ? '20px' : '30px'}
                kAlignBtn="center"
                kKeepBtnSize
                kTopDivider
                kSlideTransition
                kOpenDialogLists={openReactivateDialog}
                kOnClose={handleCloseReactivateDialog}
                kOnPrimary={handleReactivateSubscription}
                kOnSecondary={handleCloseReactivateDialog}
                kSecondaryBtnText={t('botList.cancel') as string}
                kPrimaryBtnText={t('clientSubscription.confirm') as string}
                kContent={
                  isReactivatingPlan ? (
                    <Box sx={{ textAlign: 'center' }}>
                      <CircularProgress sx={{ color: themeMode === "dark" ? "#fafafa" : "#181818" }} />
                      <motion.div
                        key={loadingMessage}
                        variants={variants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{ duration: 0.5 }}
                      >
                        <Typography sx={{
                          mt: 2,
                          fontWeight: '300',
                          fontFamily: 'Outfit',
                          fontSize: '16px',
                          color: themeMode === "dark" ? "#fafafa" : "#181818"
                        }}>
                          {loadingMessage}
                        </Typography>
                      </motion.div>
                    </Box>
                  ) : (
                    <Typography sx={{
                      fontWeight: '300', fontFamily: 'Outfit',
                      fontSize: isMobile ? '14px' : '18px', color: themeMode === "dark" ? "#fafafa" : "#181818"
                    }}>
                      {"Por favor, confirma la reactivación de tu plan."}
                    </Typography>
                  )
                }
              />

              <DialogCommon
                kTitle={t('botList.confirmation')}
                kDisableActions={isGeneratingLink}
                kMaxWidth="xs"
                kMaxHeight={isMobile ? '230px' : '250px'}
                kFixedDialog={0}
                kFixedDialogPosition="fixed"
                kDisableBottomDivider
                kEnableBorderRadius={true}
                kBorderRadius={isMobile ? '20px' : '30px'}
                kAlignBtn="center"
                kKeepBtnSize
                kTopDivider
                kSlideTransition
                kOpenDialogLists={openUpdateDialog}
                kOnClose={handleCloseUpdateDialog}
                kOnPrimary={handleConfirmUpdateSubscription}
                kOnSecondary={handleCloseUpdateDialog}
                kSecondaryBtnText={t('botList.cancel') as string}
                kPrimaryBtnText={t('clientSubscription.update') as string}
                kContent={
                  isGeneratingLink || isCancelingPlan || isReactivatingPlan ? (
                    <Box sx={{ textAlign: 'center' }}>
                      <CircularProgress sx={{ color: themeMode === "dark" ? "#fafafa" : "#181818" }} />
                      <motion.div
                        key={loadingMessage}
                        variants={variants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{ duration: 0.5 }}
                      >
                        <Typography sx={{
                          mt: 2,
                          fontWeight: '300',
                          fontFamily: 'Outfit',
                          fontSize: '16px',
                          color: themeMode === "dark" ? "#fafafa" : "#181818"
                        }}>
                          {loadingMessage}
                        </Typography>
                      </motion.div>
                    </Box>
                  ) : (
                    <Typography sx={{
                      fontWeight: '300',
                      fontFamily: 'Outfit',
                      fontSize: isMobile ? '14px' : '18px',
                      color: themeMode === "dark" ? "#fafafa" : "#181818"
                    }}>
                      {confirmationMessage}
                    </Typography>
                  )
                }
              />

              <DialogCommon
                kTitle={t('clientSubscription.buyResponses')}
                kDisableActions={isGeneratingLink}
                kMaxWidth="sm"
                kMaxHeight={isMobile ? '500px' : '450px'}
                kFixedDialog={0}
                kFixedDialogPosition="fixed"
                kDisableBottomDivider
                kEnableBorderRadius={true}
                kBorderRadius={isMobile ? '20px' : '30px'}
                kAlignBtn="center"
                kKeepBtnSize
                kDisableBtnSave={quantity === 0}
                kTopDivider
                kSlideTransition
                kOpenDialogLists={openBuyResponses}
                kOnClose={handleCloseBuyResponseDialog}
                kOnPrimary={handleConfirmAddOnPurchase}
                kOnSecondary={handleCloseBuyResponseDialog}
                kSecondaryBtnText={t('botList.cancel') as string}
                kPrimaryBtnText={t('clientSubscription.buy') as string}
                kContent={
                  (
                    isGeneratingLink || isCancelingPlan || isReactivatingPlan ? (
                      <Box sx={{ textAlign: 'center' }}>
                        <CircularProgress sx={{ color: themeMode === "dark" ? "#fafafa" : "#181818" }} />
                        <motion.div
                          key={loadingMessage}
                          variants={variants}
                          initial="hidden"
                          animate="visible"
                          exit="exit"
                          transition={{ duration: 0.5 }}
                        >
                          <Typography sx={{
                            mt: 2,
                            fontWeight: '300',
                            fontFamily: 'Outfit',
                            fontSize: '16px',
                            color: themeMode === "dark" ? "#fafafa" : "#181818"
                          }}>
                            {loadingMessage}
                          </Typography>
                        </motion.div>
                      </Box>
                    ) : (
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                        <Typography variant="h6" sx={{ textAlign: 'center', mb: 1, color: themeMode === "dark" ? "#fafafa" : "#181818" }}>
                          {t('clientSubscription.selectResponsesQuantity')}
                        </Typography>
                        <Typography sx={{ textAlign: 'center', mb: 1, color: themeMode === "dark" ? "#fafafa" : "#181818" }}>
                          {t('clientSubscription.eachResponseCost', { currency: currency === "MXN" ? "0.3 MXN" : "0.01 USD" })}
                        </Typography>
                        <Select
                          displayEmpty
                          value={quantity}
                          onChange={(e) => setQuantity(Number(e.target.value))}
                          sx={{
                            color: themeMode === 'dark' ? '#ffffff' : '#181818',
                            bgcolor: themeMode === 'dark' ? '#181818' : '#fafafa',
                            '& .MuiSvgIcon-root': {
                              color: themeMode === 'dark' ? '#ffffff' : '#4a3aff',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: themeMode === 'dark' ? '#272727' : '#eeeeee',
                            },
                            borderRadius: '13px',
                            mb: 2, width: '250px'
                          }}
                          MenuProps={{
                            sx: {
                              '& .MuiPaper-root': {
                                bgcolor: themeMode === 'dark' ? '#181818' : '#fff',
                                color: themeMode === 'dark' ? '#ffffff' : '#333',
                                borderRadius: '15px',
                                boxShadow: themeMode === "dark"
                                  ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                                  : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                              },
                              '& .MuiMenu-list .MuiMenuItem-root': {
                                '&:hover': {
                                  bgcolor: themeMode === 'light' ? '#fafafa' : '',
                                },
                              },
                            },
                          }}
                          renderValue={(selected) => {
                            if (selected === 0) {
                              return <em>{t('clientSubscription.selectQuantity')}</em>;
                            }
                            return selected;
                          }}
                        >
                          <MenuItem disabled value={0}>
                            <em>{t('clientSubscription.selectQuantity')}</em>
                          </MenuItem>
                          {[...Array(10)].map((_, index) => (
                            <MenuItem key={index} value={(index + 1) * 100}>
                              {(index + 1) * 100} {t('clientSubscription.responses')}
                            </MenuItem>
                          ))}
                        </Select>


                        <Typography sx={{
                          textAlign: 'center', color: '#4a3aff', fontSize: '25px', fontWeight: '700',
                          background: `linear-gradient(45deg, #4a3aff, ${themeMode === "dark" ? "#fafafa" : "#c1c1c1"})`,
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                          MozBackgroundClip: 'text',
                          MozTextFillColor: 'transparent',
                          display: 'inline',
                        }}
                        >
                          {totalCost.toFixed(2)} {currency}
                        </Typography>
                        <Typography sx={{ textAlign: 'center', mt: '-15px', color: themeMode === "dark" ? "#fafafa" : "#181818" }}>
                          Total
                        </Typography>
                      </Box>
                    )
                  )
                }
              />

              <DialogCommon
                kTitle=""
                kMaxWidth="sm"
                kMaxHeight={isMobile ? '100vh' : '580px'}
                kFixedDialog={0}
                kFixedDialogPosition="fixed"
                kDisableBottomDivider
                kEnableBorderRadius={true}
                kBorderRadius={isMobile ? '20px' : '30px'}
                kAlignBtn="center"
                kKeepBtnSize
                kTopDivider
                kSlideTransition
                kOpenDialogLists={openNotificationDialog}
                kOnClose={handleCloseNotificationDialog}
                kOnPrimary={handleConfirmNotification}
                kPrimaryBtnText="Continuar"
                kContent={getNotificationContent()}
              />
            </Stack>
          </Stack>
        ))}

      </Stack>
    </SectionContainer >
  );
}
