import React, { useContext, useEffect, useState } from 'react';
import { ConfigContext } from '../../context/Config/ConfigContext';
import {
  TextField,
  Input,
  FormControl,
  Typography,
  Box, useTheme,
  useMediaQuery,
  Select,
  MenuItem,
  Switch, Stack,
  IconButton,
  Grid
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { extractColors } from 'extract-colors';
import AddAPhotoRoundedIcon from '@mui/icons-material/AddAPhotoRounded';
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useTranslation } from 'react-i18next';
import VoodoBotChat from './VoodoBotChat';
import { AnimatePresence, motion } from 'framer-motion';
import DialogCommon from '../../components/DialogCommon/DialogCommon';
import MmsRoundedIcon from '@mui/icons-material/MmsRounded'; //Para beneficios de envío de imagenes
import ImageSearchRoundedIcon from '@mui/icons-material/ImageSearchRounded'; // Para beneficios de que puede analizar imagenes
import WebhookRoundedIcon from '@mui/icons-material/WebhookRounded'; //Para beneficios de conexión a sistemas
import WidgetsRoundedIcon from '@mui/icons-material/WidgetsRounded'; // Para beneficios de integracion de plataformas
import TuneRoundedIcon from '@mui/icons-material/TuneRounded'; // Para beneficios de personalización de marca
import TroubleshootRoundedIcon from '@mui/icons-material/TroubleshootRounded'; // Para beneficios de análisis de rendimiento y sentimientos
import { useNavigate } from 'react-router-dom';

type Benefit = {
  title: string;
  description: string;
  icon: keyof typeof iconMap;
};

const iconMap = {
  MmsRoundedIcon: <MmsRoundedIcon style={{ color: '#4a3aff', textAlign: 'left', fontSize: '42px' }} />,
  ImageSearchRoundedIcon: <ImageSearchRoundedIcon style={{ color: '#4a3aff', textAlign: 'left', fontSize: '42px' }} />,
  WebhookRoundedIcon: <WebhookRoundedIcon style={{ color: '#4a3aff', textAlign: 'left', fontSize: '42px' }} />,
  WidgetsRoundedIcon: <WidgetsRoundedIcon style={{ color: '#4a3aff', textAlign: 'left', fontSize: '42px' }} />,
  TuneRoundedIcon: <TuneRoundedIcon style={{ color: '#4a3aff', textAlign: 'left', fontSize: '42px' }} />,
  TroubleshootRoundedIcon: <TroubleshootRoundedIcon style={{ color: '#4a3aff', textAlign: 'left', fontSize: '42px' }} />,
};

function VoodoBot() {

  const { t } = useTranslation()

  const {
    logo,
    lightModeLogo,
    primaryColor,
    assistantFirstMessage,
    systemInstructions,
    companyName,
    chatbotTone,
    chatbotFormality,
    defaultLanguage,
    useEmojis,
    chatbotPersonality,
    nightMode,
    businessType,
    suggestion1,
    suggestion2,
    suggestion3,
    updateSystemInstructions,
    updateAssistantFirstMessage,
    updatePrimaryColor,
    updateCompanyLogo,
    updateCompanyLightModeLogo,
    updateCompanyName,
    updateChatbotTone,
    updateChatbotFormality,
    updateDefaultLanguage,
    updateUseEmojis,
    updateChatbotPersonality,
    updateBusinessType,
    updateSuggestion1,
    updateSuggestion2,
    updateSuggestion3,
  } = useContext(ConfigContext);


  const [extractedColors, setExtractedColors] = useState<string[]>([]);
  const [dynamicColor, setDynamicColor] = useState("#4a3aff");
  const [showDynamicColor, setShowDynamicColor] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [open, setOpen] = useState(false);
  const [openConfig, setOpenConfig] = useState(false);
  const [benefits, setBenefits] = useState<Benefit[]>([]); // Usamos el tipo Benefit[]

  useEffect(() => {
    if (logo) {
      extractColorsFromImage(logo);
    } else if (lightModeLogo) {
      extractColorsFromImage(lightModeLogo);
    }
  }, [logo, lightModeLogo]);

  // Función para abrir el modal
  const handleOpen = () => {
    setOpen(true);
  };

  // Función para cerrar el modal
  const handleClose = () => {
    setOpen(false);
  };

  // Función para abrir el modal
  const handleOpenConfig = () => {
    setOpenConfig(true);
  };

  // Función para cerrar el modal
  const handleCloseConfig = () => {
    setOpenConfig(false);
  };

  useEffect(() => {
    const storedBenefits = localStorage.getItem('benefitsData');
    updateDefaultLanguage('es')
    if (storedBenefits) {
      setBenefits(JSON.parse(storedBenefits));
      handleOpen()
    }
  }, []);

  const extractColorsFromImage = async (imageSrc: string) => {
    try {
      const colors = await extractColors(imageSrc);
      if (colors.length > 0) {
        updatePrimaryColor(colors[0].hex);
        setExtractedColors(colors.map((color: any) => color.hex));
      }
    } catch (error) {
      console.error("Error al extraer colores del logo:", error);
    }
  };


  const handleColorSelect = (color: string) => {
    updatePrimaryColor(color);
  };

  const handleLogoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          updateCompanyLogo(reader.result);
          extractColorsFromImage(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };


  const handleLightModeLogoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          updateCompanyLightModeLogo(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  <Switch
    sx={{
      '&.Mui-checked .MuiSwitch-thumb': {
        backgroundColor: primaryColor,
      },
      '&.Mui-checked + .MuiSwitch-track': {
        backgroundColor: primaryColor,
      }
    }}
    checked={useEmojis}
    onChange={() => updateUseEmojis(!useEmojis)}
  />


  const { themeMode } = useContext(ConfigContext)

  const themee = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            fontFamily: 'Outfit',
            color: '#7c7c7c',
            backgroundColor: themeMode === "dark" ? "#181818" : "#fafafa",
            borderRadius: 15,
            border: themeMode === "dark" ? '1px solid #272727' : '1px solid #eeeeee',
            // Establecer el fondo aquí
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',

            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#181818" : "#fafafa",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',

            },
            "& .MuiInputBase-input": {
              fontFamily: 'Outfit',
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiInputLabel-root": {
              fontFamily: 'Outfit',
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#4a3aff" : '#4a3aff',

            },
            "& .MuiInputLabel-root.Mui-focused + .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#4a3aff" : '#4a3aff'

            },
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 15,
          },
        },
      },
    }
  });

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);

    const file = event.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          updateCompanyLogo(reader.result);
          extractColorsFromImage(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDropLightMode = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);

    const file = event.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          updateCompanyLightModeLogo(reader.result);
          extractColorsFromImage(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const navigate = useNavigate()

  const handleSignUpAndSaveBot = async () => {
    const chatbotConfig = {
      logo,
      lightModeLogo,
      primaryColor,
      assistantFirstMessage,
      systemInstructions,
      companyName,
      chatbotTone,
      chatbotFormality,
      defaultLanguage,
      useEmojis,
      chatbotPersonality,
      nightMode,
      businessType,
      suggestion1,
      suggestion2,
      suggestion3,
    };

    // Guardar la configuración en localStorage
    localStorage.setItem('chatbotConfig', JSON.stringify(chatbotConfig));

    setTimeout(() => {
      navigate('/signup');
    }, 100);
  };

  return (
    <Box sx={{
      display: 'flex', // Agregar display flex para el contenedor
      backgroundColor: themeMode === "dark" ? '#121212' : '#fafafa',
      justifyContent: 'center',
      overflow: 'hidden', // Ocultar cualquier desbordamiento
    }}>

      <Stack flexDirection="row" alignItems="start" justifyContent="center" gap={5} sx={{ mt: isMobile ? 0 : '40px' }} >
        {
          !isMobile && (
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}  // Empieza con escala menor y opacidad 0
              animate={{ opacity: 1, scale: 1 }}    // Se anima a escala 1 y opacidad 1
              exit={{ opacity: 0, scale: 0.9 }}     // Al salir, vuelve a escala menor
              transition={{ duration: 0.25 }}
            >
              <Box
                sx={{
                  width: '100%',
                  maxWidth: '500px',
                  height: '100%',
                  paddingRight: '20px',
                  paddingBottom: '40px',
                  paddingTop: '40px',
                  position: 'relative',
                  overflowY: 'overlay', // Esto permite que el primer Stack sea scrollable
                  '&::-webkit-scrollbar': {
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: themeMode === 'light' ? '#e0e0e0' : '#555',
                    borderRadius: '10px',
                    opacity: 0,
                    transition: 'opacity 0.3s ease',
                  },
                  '&:hover::-webkit-scrollbar-thumb': {
                    opacity: 1,
                    backgroundColor: themeMode === 'light' ? '#c0c0c0' : '#888',
                  },
                }}
              >
                <Typography sx={{ fontFamily: 'Outfit', fontSize: isMobile ? '20px' : '25px', mx: 'auto', textAlign: 'left', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                  ¡Termina de configurar tu chatbot!
                </Typography>


                <Stack flexDirection="column" justifyContent="start" sx={{
                  backgroundColor: themeMode === "dark" ? '#181818' : '#fff',
                  marginTop: '20px',
                  boxShadow: themeMode === "dark"
                    ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                    : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)', borderRadius: '20px', padding: isMobile ? '20px' : '30px', gap: 3, mb: '20px'
                }}>
                  <Typography sx={{ fontFamily: 'Outfit', fontSize: isMobile ? '20px' : '20px', fontWeight: 'bold', textAlign: 'left', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                    {t('createBot.logos.title')}
                  </Typography>

                  <Stack flexDirection="column" justifyContent="center" alignItems="center" gap={5} flexWrap={isMobile ? "wrap" : "nowrap"}>
                    {/* Dark Mode Logo */}
                    <Stack flexDirection="column" justifyContent="center" alignItems="center">
                      <Box
                        sx={{
                          position: "relative",
                          width: "300px",
                          height: "150px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "50px",
                          borderRadius: "20px",
                          backgroundColor: "#121212", // Fondo oscuro constante para mostrar el logo en modo oscuro
                          cursor: "pointer",
                          border: isDragging ? "1px dashed #4a3aff" : "1px dashed",
                          borderColor: isDragging ? "#4a3aff" : themeMode === "dark" ? "#ffffff" : "#000000",
                          transition: "border-color 0.2s ease-in-out",
                        }}
                        onClick={() => document.getElementById("upload-logo")?.click()} // Click para abrir el selector de archivos
                        onDragOver={handleDragOver}
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                      >
                        {logo ? (
                          <motion.div
                            key={logo}
                            initial={{ scale: 0.5 }}
                            animate={{ scale: 1 }}
                            transition={{ duration: 0.25 }}
                          >
                            <img
                              src={logo}
                              alt="Logo de la empresa"
                              style={{
                                maxHeight: "100px",
                                maxWidth: "100%",
                                objectFit: "contain",
                                borderRadius: "4px",
                              }}
                            />
                          </motion.div>
                        ) : (
                          <Stack flexDirection="column" alignItems="center" justifyContent="center">
                            <AddRoundedIcon
                              sx={{
                                fontSize: "48px",
                                color: themeMode === "dark" ? "#fafafa" : "#7c7c7c", // Color de ícono acorde al tema
                              }}
                            />
                            <Typography
                              sx={{
                                fontFamily: "Outfit",
                                fontSize: "14px",
                                color: themeMode === "dark" ? "#fafafa" : "#7c7c7c",
                                mt: 1,
                              }}
                            >
                              Arrastra y suelta tu logo aquí, o haz clic para seleccionar
                            </Typography>
                          </Stack>
                        )}

                        {/* Subir logo */}
                        <input
                          style={{ display: "none" }}
                          id="upload-logo"
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={handleLogoUpload}
                        />
                        <IconButton
                          component="span"
                          sx={{
                            color: "white",
                            backgroundColor: "#4a3aff",
                            borderRadius: "100px",
                            fontWeight: "bold",
                            padding: "15px",
                            fontFamily: "Outfit",
                            position: "absolute",
                            right: "-25px",
                            bottom: "-25px",
                            "&:hover": {
                              backgroundColor: "#4a3aff",
                            },
                          }}
                          onClick={(event) => {
                            event.stopPropagation(); // Evita que el evento se propague al contenedor padre
                            document.getElementById("upload-logo")?.click(); // Abre el selector de archivos
                          }}
                        >
                          <AddAPhotoRoundedIcon />
                        </IconButton>
                      </Box>
                      <Typography
                        sx={{
                          textAlign: "left",
                          fontFamily: "Outfit",
                          mt: "10px",
                          fontWeight: "300",
                          color: themeMode === "dark" ? "#fafafa" : "#181818",
                        }}
                      >
                        {t('createBot.logos.darkMode')}
                      </Typography>
                    </Stack>


                    {/* Light Mode Logo */}
                    <Stack flexDirection="column" justifyContent="center" alignItems="center">
                      <Box
                        sx={{
                          position: "relative",
                          width: "300px",
                          height: "150px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "50px",
                          borderRadius: "20px",
                          backgroundColor: "#ffffff",
                          cursor: "pointer",
                          border: isDragging ? "1px dashed #4a3aff" : "1px dashed",
                          borderColor: isDragging ? "#4a3aff" : "#cbcbcb",
                          transition: "border-color 0.2s ease-in-out",
                        }}
                        onClick={() => document.getElementById("upload-light-mode-logo")?.click()} // Click para abrir el selector de archivos
                        onDragOver={handleDragOver}
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDropLightMode}  // Nueva función específica para manejar el "drop" del logo en modo light
                      >
                        {lightModeLogo ? (
                          <motion.div
                            key={lightModeLogo}
                            initial={{ scale: 0.5 }}
                            animate={{ scale: 1 }}
                            transition={{ duration: 0.25 }}
                          >
                            <img
                              src={lightModeLogo}
                              alt="Logo de la empresa para versión light"
                              style={{
                                maxHeight: "100px",
                                maxWidth: "100%",
                                objectFit: "contain",
                                borderRadius: "4px",
                              }}
                            />
                          </motion.div>
                        ) : (
                          <Stack flexDirection="column" alignItems="center" justifyContent="center">
                            <AddRoundedIcon sx={{ fontSize: "48px", color: "#7c7c7c" }} />
                            <Typography
                              sx={{
                                fontFamily: "Outfit",
                                fontSize: "14px",
                                color: "#7c7c7c",
                                mt: 1,
                                textAlign: "center",
                              }}
                            >
                              Arrastra y suelta tu logo aquí, o haz clic para seleccionar
                            </Typography>
                          </Stack>
                        )}

                        {/* Input para subir logo versión light */}
                        <input
                          style={{ display: "none" }}
                          id="upload-light-mode-logo"
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={handleLightModeLogoUpload}
                        />
                        <IconButton
                          component="span"
                          sx={{
                            color: "white",
                            backgroundColor: "#4a3aff",
                            borderRadius: "100px",
                            fontWeight: "bold",
                            padding: "15px",
                            fontFamily: "Outfit",
                            position: "absolute",
                            right: "-25px",
                            bottom: "-25px",
                            "&:hover": {
                              backgroundColor: "#4a3aff",
                            },
                          }}
                          onClick={(event) => {
                            event.stopPropagation(); // Evita que el evento se propague al contenedor padre
                            document.getElementById("upload-light-mode-logo")?.click(); // Abre el selector de archivos
                          }}
                        >
                          <AddAPhotoRoundedIcon />
                        </IconButton>
                      </Box>
                      <Typography
                        sx={{
                          textAlign: "left",
                          fontFamily: "Outfit",
                          mt: "10px",
                          fontWeight: "300",
                          color: themeMode === "dark" ? "#fafafa" : "#181818",
                        }}
                      >
                        {t('createBot.logos.lightMode')}
                      </Typography>
                    </Stack>

                    {extractedColors.length > 0 && (
                      <AnimatePresence>
                        <motion.div
                          initial={{ opacity: 0, scale: 0.9 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0, scale: 0.9 }}
                          transition={{ duration: 0.3, ease: "easeOut" }}
                        >
                          <Typography
                            sx={{
                              color: themeMode === "dark" ? "#fafafa" : "#181818",
                              fontFamily: "Outfit",
                              fontWeight: '300'
                            }}
                          >
                            Selecciona el color principal
                          </Typography>
                          <Grid
                            direction="row"
                            gap={isMobile ? 1 : 2}
                            sx={{
                              justifyContent: "center", mt: 1, alignItems: "center", display: 'flex',
                              flexWrap: 'wrap', maxWidth: '80vw'
                            }}  // Añadido alignItems para centrar verticalmente
                          >
                            {extractedColors.map((color, index) => (
                              <Box
                                key={index}
                                sx={{
                                  width: "40px",
                                  height: "40px",
                                  backgroundColor: color,
                                  borderRadius: "50%",
                                  cursor: "pointer",
                                  border:
                                    primaryColor === color
                                      ? "3px solid #4a3aff"
                                      : "2px solid transparent",
                                  transition: "all 0.15s ease",  // Transición suave para todos los cambios de estilo
                                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",  // Sombra suave inicial
                                  "&:hover": {
                                    transform: "scale(1.2)",  // Agranda un poco el botón al hacer hover
                                    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",  // Expande la sombra al hacer hover
                                  },
                                }}
                                onClick={() => handleColorSelect(color)}
                              />
                            ))}

                            {/* Añadir selector de color con fondo blanco y comportamiento consistente */}
                            <Box
                              sx={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",  // Hace el botón redondo
                                backgroundColor: "#fff",  // Fondo blanco
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",  // Asegura que el cursor sea pointer en todo el botón
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",  // Sombra suave inicial
                                transition: "transform 0.3s ease, box-shadow 0.3s ease",  // Transición suave de escalado y sombra
                                "&:hover": {
                                  transform: "scale(1.1)",  // Agranda un poco el botón al hacer hover
                                  boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",  // Expande la sombra al hacer hover
                                },
                                position: "relative",  // Necesario para posicionar el input absolutamente
                                overflow: "hidden",  // Asegura que el input no sobresalga
                              }}
                            >
                              {/* Icono de añadir color */}
                              <AddRoundedIcon
                                sx={{
                                  color: "#4a3aff",  // Color del icono
                                  fontSize: "20px",
                                  zIndex: 1,  // Asegura que el icono esté por encima del input
                                  pointerEvents: "none",  // Deshabilita eventos en el icono para que el input maneje los clics
                                }}
                              />

                              {/* Input de color invisible pero dentro del contenedor */}
                              <Input
                                type="color"
                                value={dynamicColor}  // Usa el color dinámico para el valor del input
                                onChange={(e: any) => {
                                  const newColor = e.target.value;
                                  setDynamicColor(newColor);  // Cambia el color dinámico al seleccionar

                                  setExtractedColors((prevColors) => {
                                    // Verifica si hay un color dinámico añadido anteriormente
                                    if (showDynamicColor) {
                                      // Verifica si el nuevo color es diferente al actual
                                      if (prevColors[prevColors.length - 1] !== newColor) {
                                        // Actualiza el último color añadido (color dinámico)
                                        const updatedColors = [...prevColors];
                                        updatedColors[updatedColors.length - 1] = newColor;
                                        return updatedColors;
                                      } else {
                                        return prevColors;  // No hace nada si el color es el mismo
                                      }
                                    } else {
                                      // Agrega un nuevo color a la lista solo la primera vez
                                      setShowDynamicColor(true);
                                      return [...prevColors, newColor];
                                    }
                                  });

                                  updatePrimaryColor(newColor);  // También cambia el color principal del sistema
                                }}
                                disableUnderline
                                sx={{
                                  position: "absolute",  // Posiciona absolutamente dentro del contenedor
                                  top: 0,  // Cubre todo el contenedor
                                  left: 0,  // Cubre todo el contenedor
                                  width: "100%",  // Cubre todo el contenedor
                                  height: "100%",  // Cubre todo el contenedor
                                  opacity: 0,  // Invisible
                                  cursor: "pointer",  // Asegura que el cursor sea pointer en todo el área del input
                                  zIndex: 9,  // Asegura que el input esté por encima del fondo pero debajo del icono,
                                  '& input': {  // Aplica estilos directamente al input dentro del componente Input de MUI
                                    cursor: 'pointer',  // Asegura que el cursor sea pointer dentro del input
                                  },
                                }}
                                id="color-input"  // ID para referenciar el input
                              />
                            </Box>


                          </Grid>
                        </motion.div>
                      </AnimatePresence>
                    )}

                  </Stack>


                </Stack>

                <Stack flexDirection="column" justifyContent="start"
                  sx={{
                    backgroundColor: themeMode === "dark" ? '#181818' : '#fff',
                    boxShadow: themeMode === "dark"
                      ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                      : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                    borderRadius: '20px', padding: isMobile ? '20px' : '30px', gap: 3, mb: '20px'
                  }}>
                  <Typography sx={{ fontFamily: 'Outfit', fontSize: isMobile ? '20px' : '20px', fontWeight: 'bold', textAlign: 'left', color: themeMode === "dark" ? '#fafafa' : '#181818', }}>
                    {t('createBot.companyData.title')}
                  </Typography>
                  <Stack flexDirection="column" justifyContent="center" alignItems="center" flexWrap={isMobile ? "wrap" : "nowrap"}>
                    <Stack flexDirection="column" justifyContent="center" alignItems="start" sx={{ width: '100%' }}>
                      <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-10px', mt: '10px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818', }}>
                        {t('createBot.companyData.name')}
                      </Typography>
                      <ThemeProvider theme={themee}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={companyName}
                          onChange={(e) => updateCompanyName(e.target.value)}
                          placeholder={t('createBot.companyData.namePlaceholder') as string}
                          inputProps={{ maxLength: 50 }}
                        />
                      </ThemeProvider>
                      <Typography
                        sx={{
                          textAlign: 'right',
                          mr: '10px',
                          width: '100%',
                          fontSize: '12px',
                          color: '#c1c1c1',
                        }}
                      >
                        {`${companyName.length}/50`}
                      </Typography>


                      <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-10px', mt: '10px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818', }}>
                        {t('createBot.companyData.businessType')}
                      </Typography>
                      <ThemeProvider theme={themee}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={businessType}
                          onChange={(e) => updateBusinessType(e.target.value)}
                          placeholder={t('createBot.companyData.businessTypePlaceholder') as string}
                          inputProps={{ maxLength: 50 }}
                        />
                      </ThemeProvider>
                      <Typography
                        sx={{
                          textAlign: 'right',
                          mr: '10px',
                          width: '100%',
                          fontSize: '12px',
                          color: '#c1c1c1',
                        }}
                      >
                        {`${businessType.length}/50`}
                      </Typography>

                    </Stack>
                    <Stack flexDirection="column" justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
                      <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-10px', mt: '10px', fontWeight: '300', width: '100%', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                        {t('createBot.companyData.info')}
                      </Typography>
                      <ThemeProvider theme={themee}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={10}
                          margin="normal"
                          value={systemInstructions}
                          onChange={(e) => updateSystemInstructions(e.target.value)}
                          placeholder={t('createBot.companyData.infoPlaceholder') as string}
                          inputProps={{ maxLength: 10000 }}
                        />
                      </ThemeProvider>
                      <Typography
                        sx={{
                          textAlign: 'right',
                          mr: '10px',
                          width: '100%',
                          fontSize: '12px',
                          color: '#c1c1c1',
                        }}
                      >
                        {`${systemInstructions.length}/10000`}
                      </Typography>

                    </Stack>
                  </Stack>
                </Stack>

                <Stack
                  flexDirection="column" justifyContent="start"
                  sx={{
                    backgroundColor: themeMode === "dark" ? '#181818' : '#fff',
                    boxShadow: themeMode === "dark"
                      ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                      : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                    borderRadius: '20px', padding: isMobile ? '20px' : '30px', gap: 3, mb: '20px'
                  }}>
                  <Typography sx={{ fontFamily: 'Outfit', fontSize: isMobile ? '20px' : '20px', fontWeight: 'bold', textAlign: 'left', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                    {t('createBot.questions.title')}
                  </Typography>
                  <Stack>

                    <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-10px', mt: '10px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                      {t('createBot.questions.suggestion1')}
                    </Typography>
                    <ThemeProvider theme={themee}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={2}
                        margin="normal"
                        value={suggestion1}
                        onChange={(e) => updateSuggestion1(e.target.value)}
                        placeholder={t('createBot.questions.suggestion1Placeholder') as string}
                        inputProps={{ maxLength: 150 }}
                      />
                    </ThemeProvider>
                    <Typography
                      sx={{
                        textAlign: 'right',
                        mr: '10px',
                        fontSize: '12px',
                        color: '#c1c1c1',
                      }}
                    >
                      {`${suggestion1.length}/150`}
                    </Typography>



                    <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-10px', mt: '10px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                      {t('createBot.questions.suggestion2')}
                    </Typography>
                    <ThemeProvider theme={themee}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={2}
                        margin="normal"
                        value={suggestion2}
                        onChange={(e) => updateSuggestion2(e.target.value)}
                        placeholder={t('createBot.questions.suggestion1Placeholder') as string}
                        inputProps={{ maxLength: 150 }}
                      />
                    </ThemeProvider>
                    <Typography
                      sx={{
                        textAlign: 'right',
                        mr: '10px',
                        fontSize: '12px',
                        color: '#c1c1c1',
                      }}
                    >
                      {`${suggestion2.length}/150`}
                    </Typography>


                    <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-10px', mt: '10px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                      {t('createBot.questions.suggestion3')}
                    </Typography>
                    <ThemeProvider theme={themee}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={2}
                        margin="normal"
                        value={suggestion3}
                        onChange={(e) => updateSuggestion3(e.target.value)}
                        placeholder={t('createBot.questions.suggestion1Placeholder') as string}
                        inputProps={{ maxLength: 150 }}
                      />
                    </ThemeProvider>
                    <Typography
                      sx={{
                        textAlign: 'right',
                        mr: '10px',
                        fontSize: '12px',
                        color: '#c1c1c1',
                      }}
                    >
                      {`${suggestion3.length}/150`}
                    </Typography>

                  </Stack>

                </Stack>

                <Stack
                  flexDirection="column" justifyContent="start"
                  sx={{
                    borderRadius: '20px', padding: isMobile ? '20px' : '30px', gap: 3, mb: '20px',
                    backgroundColor: themeMode === "dark" ? '#181818' : '#fff',
                    boxShadow: themeMode === "dark"
                      ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                      : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                  }}>
                  <Typography sx={{ fontFamily: 'Outfit', fontSize: isMobile ? '20px' : '20px', fontWeight: 'bold', textAlign: 'left', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                    {t('createBot.chatbotCustomization.title')}
                  </Typography>
                  <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-30px', mt: '10px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                    {t('createBot.chatbotCustomization.firstMessage')}
                  </Typography>
                  <Box>
                    <ThemeProvider theme={themee}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={isMobile ? 5 : 5}
                        margin="normal"
                        value={assistantFirstMessage}
                        onChange={(e) => updateAssistantFirstMessage(e.target.value)}
                        placeholder={t('createBot.chatbotCustomization.firstMessagePlaceholder') as string}
                        inputProps={{ maxLength: 200 }}
                      />
                    </ThemeProvider>
                    <Typography
                      sx={{
                        textAlign: 'right',
                        mr: '10px',
                        fontSize: '12px',
                        color: '#c1c1c1',
                      }}
                    >
                      {`${assistantFirstMessage.length}/200`}
                    </Typography>
                  </Box>


                  <Stack flexDirection="column" justifyContent="center" alignItems="start" flexWrap={isMobile ? "wrap" : "nowrap"}>
                    <Stack flexDirection="column" justifyContent="center" alignItems="start" sx={{ width: '100%' }}>

                      {/* Tonos, formalidades y demás opciones */}
                      <FormControl variant="outlined" margin="normal" fullWidth>
                        <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', fontWeight: '300', mb: '10px', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                          {t('createBot.chatbotCustomization.tone')}
                        </Typography>
                        <Select
                          displayEmpty
                          value={chatbotTone}
                          onChange={(e) => updateChatbotTone(e.target.value)}
                          sx={{
                            color: themeMode === 'dark' ? '#ffffff' : '#181818',
                            bgcolor: themeMode === 'dark' ? '#181818' : '#fafafa',
                            '& .MuiSvgIcon-root': {
                              color: themeMode === 'dark' ? '#ffffff' : '#4a3aff',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: themeMode === 'dark' ? '#272727' : '#eeeeee',
                            },
                            borderRadius: '13px',
                          }}
                          MenuProps={{
                            sx: {
                              '& .MuiPaper-root': {
                                bgcolor: themeMode === 'dark' ? '#181818' : '#fff',
                                color: themeMode === 'dark' ? '#ffffff' : '#333',
                                borderRadius: '15px',
                                boxShadow: themeMode === "dark"
                                  ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                                  : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                              },
                              '& .MuiMenu-list .MuiMenuItem-root': {
                                '&:hover': {
                                  bgcolor: themeMode === 'light' ? '#fafafa' : '',
                                },
                              },
                            },
                          }}
                          renderValue={(selected) => {
                            if (selected === '') {
                              return <Typography sx={{ fontFamily: 'Outfit', fontWeight: '300' }}>{t('createBot.chatbotCustomization.selectTone')}</Typography>;
                            }
                            const capitalizedText = selected.charAt(0).toUpperCase() + selected.slice(1);
                            return <Typography sx={{ fontFamily: 'Outfit', fontWeight: '300' }}>{capitalizedText}</Typography>;
                          }}
                        >
                          <MenuItem sx={{ fontFamily: 'Outfit' }} value="entusiasta">{t('createBot.chatbotCustomization.tones.enthusiastic')}</MenuItem>
                          <MenuItem sx={{ fontFamily: 'Outfit' }} value="motivador">{t('createBot.chatbotCustomization.tones.motivational')}</MenuItem>
                          <MenuItem sx={{ fontFamily: 'Outfit' }} value="neutral">{t('createBot.chatbotCustomization.tones.neutral')}</MenuItem>
                        </Select>
                      </FormControl>


                      <FormControl variant="outlined" margin="normal" fullWidth>
                        <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', fontWeight: '300', mb: '10px', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                          {t('createBot.chatbotCustomization.formality')}
                        </Typography>
                        <Select
                          displayEmpty
                          value={chatbotFormality}
                          onChange={(e) => updateChatbotFormality(e.target.value)}
                          sx={{
                            color: themeMode === 'dark' ? '#ffffff' : '#181818',
                            bgcolor: themeMode === 'dark' ? '#181818' : '#fafafa',
                            '& .MuiSvgIcon-root': {
                              color: themeMode === 'dark' ? '#ffffff' : '#4a3aff',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: themeMode === 'dark' ? '#272727' : '#eeeeee',
                            },
                            borderRadius: '13px',
                          }}
                          MenuProps={{
                            sx: {
                              '& .MuiPaper-root': {
                                bgcolor: themeMode === 'dark' ? '#181818' : '#fff',
                                color: themeMode === 'dark' ? '#ffffff' : '#333',
                                borderRadius: '15px',
                                boxShadow: themeMode === "dark"
                                  ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                                  : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                              },
                              '& .MuiMenu-list .MuiMenuItem-root': {
                                '&:hover': {
                                  bgcolor: themeMode === 'light' ? '#fafafa' : '',
                                },
                              },
                            },
                          }}
                          renderValue={(selected) => {
                            if (selected === '') {
                              return <Typography sx={{ fontFamily: 'Outfit', fontWeight: '300' }}>{t('createBot.chatbotCustomization.selectFormality')}</Typography>;
                            }
                            const capitalizedText = selected.charAt(0).toUpperCase() + selected.slice(1);
                            return <Typography sx={{ fontFamily: 'Outfit', fontWeight: '300' }}>{capitalizedText}</Typography>;
                          }}
                        >
                          <MenuItem sx={{ fontFamily: 'Outfit' }} value="formal">{t('createBot.chatbotCustomization.formalityy.formal')}</MenuItem>
                          <MenuItem sx={{ fontFamily: 'Outfit' }} value="neutro">{t('createBot.chatbotCustomization.formalityy.neutral')}</MenuItem>
                          <MenuItem sx={{ fontFamily: 'Outfit' }} value="informal">{t('createBot.chatbotCustomization.formalityy.informal')}</MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl variant="outlined" margin="normal" fullWidth>
                        <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', fontWeight: '300', mb: '10px', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                          {t('createBot.chatbotCustomization.personality')}
                        </Typography>
                        <Select
                          displayEmpty
                          value={chatbotPersonality}
                          onChange={(e) => updateChatbotPersonality(e.target.value)}
                          sx={{
                            color: themeMode === 'dark' ? '#ffffff' : '#181818',
                            bgcolor: themeMode === 'dark' ? '#181818' : '#fafafa',
                            '& .MuiSvgIcon-root': {
                              color: themeMode === 'dark' ? '#ffffff' : '#4a3aff',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: themeMode === 'dark' ? '#272727' : '#eeeeee',
                            },
                            borderRadius: '13px',
                          }}
                          MenuProps={{
                            sx: {
                              '& .MuiPaper-root': {
                                bgcolor: themeMode === 'dark' ? '#181818' : '#fff',
                                color: themeMode === 'dark' ? '#ffffff' : '#333',
                                borderRadius: '15px',
                                boxShadow: themeMode === "dark"
                                  ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                                  : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                              },
                              '& .MuiMenu-list .MuiMenuItem-root': {
                                '&:hover': {
                                  bgcolor: themeMode === 'light' ? '#fafafa' : '',
                                },
                              },
                            },
                          }}
                          renderValue={(selected) => {
                            if (selected === '') {
                              return <Typography sx={{ fontFamily: 'Outfit', fontWeight: '300' }}>{t('createBot.chatbotCustomization.selectPersonality')}</Typography>;
                            }
                            const capitalizedText = selected.charAt(0).toUpperCase() + selected.slice(1);
                            return <Typography sx={{ fontFamily: 'Outfit', fontWeight: '300' }}>{capitalizedText}</Typography>;
                          }}
                        >
                          <MenuItem sx={{ fontFamily: 'Outfit' }} value="amigable">{t('createBot.chatbotCustomization.personalityy.friendly')}</MenuItem>
                          <MenuItem sx={{ fontFamily: 'Outfit' }} value="serio">{t('createBot.chatbotCustomization.personalityy.serious')}</MenuItem>
                          <MenuItem sx={{ fontFamily: 'Outfit' }} value="divertido">{t('createBot.chatbotCustomization.personalityy.funny')}</MenuItem>
                        </Select>
                      </FormControl>

                    </Stack>
                    <Stack flexDirection="column" justifyContent="start" alignItems="space-between" sx={{ width: '100%' }}>
                      <FormControl variant="outlined" margin="normal" fullWidth sx={{ mb: '50px' }}>
                        <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', fontWeight: '300', mb: '10px', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>{t('createBot.chatbotCustomization.defaultLanguageTitle')}</Typography>
                        <Select
                          displayEmpty
                          value={defaultLanguage !== "" ? defaultLanguage : 'es'}
                          onChange={(e) => updateDefaultLanguage(e.target.value)}
                          sx={{
                            color: themeMode === 'dark' ? '#ffffff' : '#181818',
                            bgcolor: themeMode === 'dark' ? '#181818' : '#fafafa',
                            '& .MuiSvgIcon-root': {
                              color: themeMode === 'dark' ? '#ffffff' : '#4a3aff',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: themeMode === 'dark' ? '#272727' : '#eeeeee',
                            },
                            borderRadius: '13px',
                          }}
                          MenuProps={{
                            sx: {
                              '& .MuiPaper-root': {
                                bgcolor: themeMode === 'dark' ? '#181818' : '#fff',
                                color: themeMode === 'dark' ? '#ffffff' : '#333',
                                borderRadius: '15px',
                                boxShadow: themeMode === "dark"
                                  ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                                  : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                              },
                              '& .MuiMenu-list .MuiMenuItem-root': {
                                '&:hover': {
                                  bgcolor: themeMode === 'light' ? '#fafafa' : '',
                                },
                              },
                            },
                          }}
                          renderValue={(selected) => {
                            if (selected === '') {
                              return <Typography sx={{ fontFamily: 'Outfit', fontWeight: '300' }}>{t('createBot.chatbotCustomization.selectLanguage')}</Typography>;
                            }
                            let languageFull;
                            switch (selected) {
                              case 'es':
                                languageFull = 'Español';
                                break;
                              case 'en':
                                languageFull = 'English';
                                break;
                              default:
                                languageFull = '';
                            }
                            return <Typography sx={{ fontFamily: 'Outfit', fontWeight: '300' }}>{languageFull}</Typography>;
                          }}
                        >
                          <MenuItem sx={{ fontFamily: 'Outfit' }} value="es">Español</MenuItem>
                          <MenuItem sx={{ fontFamily: 'Outfit' }} value="en">English</MenuItem>
                        </Select>
                      </FormControl>

                      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mb: '20px' }}>
                        <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>{t('createBot.options.useEmojis')}</Typography>
                        <Switch
                          checked={useEmojis}
                          onChange={() => updateUseEmojis(!useEmojis)}
                          sx={{
                            '& .MuiSwitch-thumb': {
                              backgroundColor: useEmojis ? "#4a3aff !important" : '#E0E0E0', // Cambia el color del thumb dependiendo del estado
                            },
                            '& .MuiSwitch-track': {
                              backgroundColor: useEmojis ? "#4a3aff !important" : '#BDBDBD', // Cambia el color del track dependiendo del estado
                              mt: '0px', // Margen superior para alinear
                            },
                            '&.Mui-checked .MuiSwitch-thumb': {
                              backgroundColor: "#4a3aff", // Color del thumb cuando está activado
                            },
                            '&.Mui-checked + .MuiSwitch-track': {
                              backgroundColor: "#4a3aff", // Color del track cuando está activado
                            },
                          }}
                        />

                      </Box>
                    </Stack>
                  </Stack>
                </Stack>


              </Box>
            </motion.div>

          )
        }

        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}  // Empieza con escala menor y opacidad 0
          animate={{ opacity: 1, scale: 1 }}    // Se anima a escala 1 y opacidad 1
          exit={{ opacity: 0, scale: 0.9 }}     // Al salir, vuelve a escala menor
          transition={{ duration: 0.25 }}
        >
          <Box sx={{
            width: '100%',
            maxWidth: '500px',
            position: 'sticky', // Hace que el contenedor sea sticky
            top: isMobile ? 0 : '30px', // Espacio superior para la posición sticky
            overflowY: 'auto', // Permite el desplazamiento interno si el contenido se desborda
            boxShadow: themeMode === "dark"
              ? '0 24px 48px rgba(0, 0, 0, 0.25), 0 16px 32px rgba(0, 0, 0, 0.1)' // Sombra negra suave en modo oscuro
              : '0 24px 48px rgba(0, 0, 0, 0.15), 0 16px 32px rgba(0, 0, 0, 0.1)', // Sombra suave en modo claro
            borderRadius: isMobile ? 0 : '30px',
            marginRight: isMobile ? 0 : '20px', // Añadir margen a la derecha para que se vea más limpio
            marginTop: isMobile ? 0 : '70px',
            border: themeMode === "dark"
              ? "1px solid #434343"
              : "1px solid #cfcfcf",
          }}>
            <VoodoBotChat
              handleSignUpAndSaveBot={handleSignUpAndSaveBot}
              handleOpenConfig={handleOpenConfig}
            />
          </Box>
        </motion.div>


      </Stack>



      <DialogCommon
        kTitle={`Beneficios de Fastalk para ${companyName}`}
        kMaxWidth="md"
        kMaxHeight={window.innerWidth <= 768 ? "100vh" : "670px"} // Example of isMobile
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kEnableBorderRadius={true}
        kBorderRadius="40px"
        kAlignBtn="center"
        kKeepBtnSize
        kTopDivider
        kSlideTransition
        kOpenDialogLists={open}
        kOnClose={handleClose}
        kOnPrimary={handleClose}
        kPrimaryBtnText="Ver chatbot"
        kAlignItemsStart
        kContent={
          <Grid container spacing={3} justifyContent="center" sx={{ padding: '20px', pl: '40px', mt: '10px' }}>
            {benefits.length > 0 ? (
              benefits.map((benefit, index) => (
                <Grid item xs={12} md={3.8} key={index} sx={{
                  padding: '20px', background: themeMode === "dark" ? '#151515' : '#fafafa',
                  borderRadius: '20px', m: '5px', maxHeight: '230px'
                }}>
                  {/* Renderiza el ícono correspondiente */}
                  {iconMap[benefit.icon]}
                  <Typography sx={{ color: themeMode === "dark" ? '#fafafa' : '#181818', fontWeight: 'bold', mb: 1, fontSize: '18px', lineHeight: '20px', fontFamily: 'Outfit' }}>
                    {benefit.title}
                  </Typography>
                  <Typography sx={{ color: themeMode === "dark" ? '#fafafa' : '#181818', fontSize: '14px', lineHeight: '16px', fontFamily: 'Outfit' }}>
                    {benefit.description}
                  </Typography>
                </Grid>
              ))
            ) : (
              <Typography variant="body1">No hay beneficios disponibles.</Typography>
            )}
          </Grid>
        }
      />

      <DialogCommon
        kTitle="¡Termina de configurar tu chatbot!"
        kMaxWidth="md"
        kMaxHeight={window.innerWidth <= 768 ? "100vh" : "670px"} // Example of isMobile
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kEnableBorderRadius={true}
        kBorderRadius="40px"
        kDisableContentPadding
        kAlignBtn="center"
        kKeepBtnSize
        kTopDivider
        kSlideTransition
        kOpenDialogLists={openConfig}
        kOnClose={handleCloseConfig}
        kOnSecondary={handleCloseConfig}
        kSecondaryBtnText="Cerrar"
        kAlignItemsStart
        kContent={
          <Box
            sx={{
              width: '100%',
              maxWidth: '500px',
              height: '100%',
              px: '10px',
              paddingBottom: '40px',
              paddingTop: '40px',
              position: 'relative', // Necesario para el efecto hover
            }}
          >
            <Stack flexDirection="column" justifyContent="start" sx={{
              backgroundColor: themeMode === "dark" ? '#181818' : '#fff',
              boxShadow: themeMode === "dark"
                ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)', borderRadius: '20px', padding: isMobile ? '20px' : '30px', gap: 3, mb: '20px'
            }}>
              <Typography sx={{ fontFamily: 'Outfit', fontSize: isMobile ? '20px' : '20px', fontWeight: 'bold', textAlign: 'left', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                {t('createBot.logos.title')}
              </Typography>

              <Stack flexDirection="column" justifyContent="center" alignItems="center" gap={5} flexWrap={isMobile ? "wrap" : "nowrap"}>
                {/* Dark Mode Logo */}
                <Stack flexDirection="column" justifyContent="center" alignItems="center">
                  <Box
                    sx={{
                      position: "relative",
                      width: "300px",
                      height: "150px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "50px",
                      borderRadius: "20px",
                      backgroundColor: "#121212", // Fondo oscuro constante para mostrar el logo en modo oscuro
                      cursor: "pointer",
                      border: isDragging ? "1px dashed #4a3aff" : "1px dashed",
                      borderColor: isDragging ? "#4a3aff" : themeMode === "dark" ? "#ffffff" : "#000000",
                      transition: "border-color 0.2s ease-in-out",
                    }}
                    onClick={() => document.getElementById("upload-logo")?.click()} // Click para abrir el selector de archivos
                    onDragOver={handleDragOver}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                  >
                    {logo ? (
                      <motion.div
                        key={logo}
                        initial={{ scale: 0.5 }}
                        animate={{ scale: 1 }}
                        transition={{ duration: 0.25 }}
                      >
                        <img
                          src={logo}
                          alt="Logo de la empresa"
                          style={{
                            maxHeight: "100px",
                            maxWidth: "100%",
                            objectFit: "contain",
                            borderRadius: "4px",
                          }}
                        />
                      </motion.div>
                    ) : (
                      <Stack flexDirection="column" alignItems="center" justifyContent="center">
                        <AddRoundedIcon
                          sx={{
                            fontSize: "48px",
                            color: themeMode === "dark" ? "#fafafa" : "#7c7c7c", // Color de ícono acorde al tema
                          }}
                        />
                        <Typography
                          sx={{
                            fontFamily: "Outfit",
                            fontSize: "14px",
                            color: themeMode === "dark" ? "#fafafa" : "#7c7c7c",
                            mt: 1,
                          }}
                        >
                          Arrastra y suelta tu logo aquí, o haz clic para seleccionar
                        </Typography>
                      </Stack>
                    )}

                    {/* Subir logo */}
                    <input
                      style={{ display: "none" }}
                      id="upload-logo"
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      onChange={handleLogoUpload}
                    />
                    <IconButton
                      component="span"
                      sx={{
                        color: "white",
                        backgroundColor: "#4a3aff",
                        borderRadius: "100px",
                        fontWeight: "bold",
                        padding: "15px",
                        fontFamily: "Outfit",
                        position: "absolute",
                        right: "-25px",
                        bottom: "-25px",
                        "&:hover": {
                          backgroundColor: "#4a3aff",
                        },
                      }}
                      onClick={(event) => {
                        event.stopPropagation(); // Evita que el evento se propague al contenedor padre
                        document.getElementById("upload-logo")?.click(); // Abre el selector de archivos
                      }}
                    >
                      <AddAPhotoRoundedIcon />
                    </IconButton>
                  </Box>
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontFamily: "Outfit",
                      mt: "10px",
                      fontWeight: "300",
                      color: themeMode === "dark" ? "#fafafa" : "#181818",
                    }}
                  >
                    {t('createBot.logos.darkMode')}
                  </Typography>
                </Stack>


                {/* Light Mode Logo */}
                <Stack flexDirection="column" justifyContent="center" alignItems="center">
                  <Box
                    sx={{
                      position: "relative",
                      width: "300px",
                      height: "150px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "50px",
                      borderRadius: "20px",
                      backgroundColor: "#ffffff",
                      cursor: "pointer",
                      border: isDragging ? "1px dashed #4a3aff" : "1px dashed",
                      borderColor: isDragging ? "#4a3aff" : "#cbcbcb",
                      transition: "border-color 0.2s ease-in-out",
                    }}
                    onClick={() => document.getElementById("upload-light-mode-logo")?.click()} // Click para abrir el selector de archivos
                    onDragOver={handleDragOver}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDropLightMode}  // Nueva función específica para manejar el "drop" del logo en modo light
                  >
                    {lightModeLogo ? (
                      <motion.div
                        key={lightModeLogo}
                        initial={{ scale: 0.5 }}
                        animate={{ scale: 1 }}
                        transition={{ duration: 0.25 }}
                      >
                        <img
                          src={lightModeLogo}
                          alt="Logo de la empresa para versión light"
                          style={{
                            maxHeight: "100px",
                            maxWidth: "100%",
                            objectFit: "contain",
                            borderRadius: "4px",
                          }}
                        />
                      </motion.div>
                    ) : (
                      <Stack flexDirection="column" alignItems="center" justifyContent="center">
                        <AddRoundedIcon sx={{ fontSize: "48px", color: "#7c7c7c" }} />
                        <Typography
                          sx={{
                            fontFamily: "Outfit",
                            fontSize: "14px",
                            color: "#7c7c7c",
                            mt: 1,
                            textAlign: "center",
                          }}
                        >
                          Arrastra y suelta tu logo aquí, o haz clic para seleccionar
                        </Typography>
                      </Stack>
                    )}

                    {/* Input para subir logo versión light */}
                    <input
                      style={{ display: "none" }}
                      id="upload-light-mode-logo"
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      onChange={handleLightModeLogoUpload}
                    />
                    <IconButton
                      component="span"
                      sx={{
                        color: "white",
                        backgroundColor: "#4a3aff",
                        borderRadius: "100px",
                        fontWeight: "bold",
                        padding: "15px",
                        fontFamily: "Outfit",
                        position: "absolute",
                        right: "-25px",
                        bottom: "-25px",
                        "&:hover": {
                          backgroundColor: "#4a3aff",
                        },
                      }}
                      onClick={(event) => {
                        event.stopPropagation(); // Evita que el evento se propague al contenedor padre
                        document.getElementById("upload-light-mode-logo")?.click(); // Abre el selector de archivos
                      }}
                    >
                      <AddAPhotoRoundedIcon />
                    </IconButton>
                  </Box>
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontFamily: "Outfit",
                      mt: "10px",
                      fontWeight: "300",
                      color: themeMode === "dark" ? "#fafafa" : "#181818",
                    }}
                  >
                    {t('createBot.logos.lightMode')}
                  </Typography>
                </Stack>

                {extractedColors.length > 0 && (
                  <AnimatePresence>
                    <motion.div
                      initial={{ opacity: 0, scale: 0.9 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.9 }}
                      transition={{ duration: 0.3, ease: "easeOut" }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: themeMode === "dark" ? "#fafafa" : "#181818",
                          fontFamily: "Outfit",
                        }}
                      >
                        Selecciona el color principal
                      </Typography>
                      <Grid
                        direction="row"
                        gap={isMobile ? 1 : 2}
                        sx={{
                          justifyContent: "center", mt: 1, alignItems: "center", display: 'flex',
                          flexWrap: 'wrap', maxWidth: '80vw'
                        }}  // Añadido alignItems para centrar verticalmente
                      >
                        {extractedColors.map((color, index) => (
                          <Box
                            key={index}
                            sx={{
                              width: "40px",
                              height: "40px",
                              backgroundColor: color,
                              borderRadius: "50%",
                              cursor: "pointer",
                              border:
                                primaryColor === color
                                  ? "3px solid #4a3aff"
                                  : "2px solid transparent",
                              transition: "all 0.15s ease",  // Transición suave para todos los cambios de estilo
                              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",  // Sombra suave inicial
                              "&:hover": {
                                transform: "scale(1.2)",  // Agranda un poco el botón al hacer hover
                                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",  // Expande la sombra al hacer hover
                              },
                            }}
                            onClick={() => handleColorSelect(color)}
                          />
                        ))}

                        {/* Añadir selector de color con fondo blanco y comportamiento consistente */}
                        <Box
                          sx={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",  // Hace el botón redondo
                            backgroundColor: "#fff",  // Fondo blanco
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",  // Asegura que el cursor sea pointer en todo el botón
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",  // Sombra suave inicial
                            transition: "transform 0.3s ease, box-shadow 0.3s ease",  // Transición suave de escalado y sombra
                            "&:hover": {
                              transform: "scale(1.1)",  // Agranda un poco el botón al hacer hover
                              boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",  // Expande la sombra al hacer hover
                            },
                            position: "relative",  // Necesario para posicionar el input absolutamente
                            overflow: "hidden",  // Asegura que el input no sobresalga
                          }}
                        >
                          {/* Icono de añadir color */}
                          <AddRoundedIcon
                            sx={{
                              color: "#4a3aff",  // Color del icono
                              fontSize: "20px",
                              zIndex: 1,  // Asegura que el icono esté por encima del input
                              pointerEvents: "none",  // Deshabilita eventos en el icono para que el input maneje los clics
                            }}
                          />

                          {/* Input de color invisible pero dentro del contenedor */}
                          <Input
                            type="color"
                            value={dynamicColor}  // Usa el color dinámico para el valor del input
                            onChange={(e: any) => {
                              const newColor = e.target.value;
                              setDynamicColor(newColor);  // Cambia el color dinámico al seleccionar

                              setExtractedColors((prevColors) => {
                                // Verifica si hay un color dinámico añadido anteriormente
                                if (showDynamicColor) {
                                  // Verifica si el nuevo color es diferente al actual
                                  if (prevColors[prevColors.length - 1] !== newColor) {
                                    // Actualiza el último color añadido (color dinámico)
                                    const updatedColors = [...prevColors];
                                    updatedColors[updatedColors.length - 1] = newColor;
                                    return updatedColors;
                                  } else {
                                    return prevColors;  // No hace nada si el color es el mismo
                                  }
                                } else {
                                  // Agrega un nuevo color a la lista solo la primera vez
                                  setShowDynamicColor(true);
                                  return [...prevColors, newColor];
                                }
                              });

                              updatePrimaryColor(newColor);  // También cambia el color principal del sistema
                            }}
                            disableUnderline
                            sx={{
                              position: "absolute",  // Posiciona absolutamente dentro del contenedor
                              top: 0,  // Cubre todo el contenedor
                              left: 0,  // Cubre todo el contenedor
                              width: "100%",  // Cubre todo el contenedor
                              height: "100%",  // Cubre todo el contenedor
                              opacity: 0,  // Invisible
                              cursor: "pointer",  // Asegura que el cursor sea pointer en todo el área del input
                              zIndex: 9,  // Asegura que el input esté por encima del fondo pero debajo del icono,
                              '& input': {  // Aplica estilos directamente al input dentro del componente Input de MUI
                                cursor: 'pointer',  // Asegura que el cursor sea pointer dentro del input
                              },
                            }}
                            id="color-input"  // ID para referenciar el input
                          />
                        </Box>


                      </Grid>
                    </motion.div>
                  </AnimatePresence>
                )}
              </Stack>


            </Stack>

            <Stack flexDirection="column" justifyContent="start"
              sx={{
                backgroundColor: themeMode === "dark" ? '#181818' : '#fff',
                boxShadow: themeMode === "dark"
                  ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                  : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                borderRadius: '20px', padding: isMobile ? '20px' : '30px', gap: 3, mb: '20px'
              }}>
              <Typography sx={{ fontFamily: 'Outfit', fontSize: isMobile ? '20px' : '20px', fontWeight: 'bold', textAlign: 'left', color: themeMode === "dark" ? '#fafafa' : '#181818', }}>
                {t('createBot.companyData.title')}
              </Typography>
              <Stack flexDirection="column" justifyContent="center" alignItems="center" flexWrap={isMobile ? "wrap" : "nowrap"}>
                <Stack flexDirection="column" justifyContent="center" alignItems="start" sx={{ width: '100%' }}>
                  <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-10px', mt: '10px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818', }}>
                    {t('createBot.companyData.name')}
                  </Typography>
                  <ThemeProvider theme={themee}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={companyName}
                      onChange={(e) => updateCompanyName(e.target.value)}
                      placeholder={t('createBot.companyData.namePlaceholder') as string}
                      inputProps={{ maxLength: 50 }}
                    />
                  </ThemeProvider>
                  <Typography
                    sx={{
                      textAlign: 'right',
                      mr: '10px',
                      width: '100%',
                      fontSize: '12px',
                      color: '#c1c1c1',
                    }}
                  >
                    {`${companyName.length}/50`}
                  </Typography>


                  <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-10px', mt: '10px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818', }}>
                    {t('createBot.companyData.businessType')}
                  </Typography>
                  <ThemeProvider theme={themee}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={businessType}
                      onChange={(e) => updateBusinessType(e.target.value)}
                      placeholder={t('createBot.companyData.businessTypePlaceholder') as string}
                      inputProps={{ maxLength: 50 }}
                    />
                  </ThemeProvider>
                  <Typography
                    sx={{
                      textAlign: 'right',
                      mr: '10px',
                      width: '100%',
                      fontSize: '12px',
                      color: '#c1c1c1',
                    }}
                  >
                    {`${businessType.length}/50`}
                  </Typography>

                </Stack>
                <Stack flexDirection="column" justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
                  <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-10px', mt: '10px', fontWeight: '300', width: '100%', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                    {t('createBot.companyData.info')}
                  </Typography>
                  <ThemeProvider theme={themee}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={10}
                      margin="normal"
                      value={systemInstructions}
                      onChange={(e) => updateSystemInstructions(e.target.value)}
                      placeholder={t('createBot.companyData.infoPlaceholder') as string}
                      inputProps={{ maxLength: 10000 }}
                    />
                  </ThemeProvider>
                  <Typography
                    sx={{
                      textAlign: 'right',
                      mr: '10px',
                      width: '100%',
                      fontSize: '12px',
                      color: '#c1c1c1',
                    }}
                  >
                    {`${systemInstructions.length}/10000`}
                  </Typography>

                </Stack>
              </Stack>
            </Stack>

            <Stack
              flexDirection="column" justifyContent="start"
              sx={{
                backgroundColor: themeMode === "dark" ? '#181818' : '#fff',
                boxShadow: themeMode === "dark"
                  ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                  : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                borderRadius: '20px', padding: isMobile ? '20px' : '30px', gap: 3, mb: '20px'
              }}>
              <Typography sx={{ fontFamily: 'Outfit', fontSize: isMobile ? '20px' : '20px', fontWeight: 'bold', textAlign: 'left', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                {t('createBot.questions.title')}
              </Typography>
              <Stack>

                <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-10px', mt: '10px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                  {t('createBot.questions.suggestion1')}
                </Typography>
                <ThemeProvider theme={themee}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={2}
                    margin="normal"
                    value={suggestion1}
                    onChange={(e) => updateSuggestion1(e.target.value)}
                    placeholder={t('createBot.questions.suggestion1Placeholder') as string}
                    inputProps={{ maxLength: 150 }}
                  />
                </ThemeProvider>
                <Typography
                  sx={{
                    textAlign: 'right',
                    mr: '10px',
                    fontSize: '12px',
                    color: '#c1c1c1',
                  }}
                >
                  {`${suggestion1.length}/150`}
                </Typography>



                <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-10px', mt: '10px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                  {t('createBot.questions.suggestion2')}
                </Typography>
                <ThemeProvider theme={themee}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={2}
                    margin="normal"
                    value={suggestion2}
                    onChange={(e) => updateSuggestion2(e.target.value)}
                    placeholder={t('createBot.questions.suggestion1Placeholder') as string}
                    inputProps={{ maxLength: 150 }}
                  />
                </ThemeProvider>
                <Typography
                  sx={{
                    textAlign: 'right',
                    mr: '10px',
                    fontSize: '12px',
                    color: '#c1c1c1',
                  }}
                >
                  {`${suggestion2.length}/150`}
                </Typography>


                <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-10px', mt: '10px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                  {t('createBot.questions.suggestion3')}
                </Typography>
                <ThemeProvider theme={themee}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={2}
                    margin="normal"
                    value={suggestion3}
                    onChange={(e) => updateSuggestion3(e.target.value)}
                    placeholder={t('createBot.questions.suggestion1Placeholder') as string}
                    inputProps={{ maxLength: 150 }}
                  />
                </ThemeProvider>
                <Typography
                  sx={{
                    textAlign: 'right',
                    mr: '10px',
                    fontSize: '12px',
                    color: '#c1c1c1',
                  }}
                >
                  {`${suggestion3.length}/150`}
                </Typography>

              </Stack>

            </Stack>

            <Stack
              flexDirection="column" justifyContent="start"
              sx={{
                borderRadius: '20px', padding: isMobile ? '20px' : '30px', gap: 3, mb: '20px',
                backgroundColor: themeMode === "dark" ? '#181818' : '#fff',
                boxShadow: themeMode === "dark"
                  ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                  : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
              }}>
              <Typography sx={{ fontFamily: 'Outfit', fontSize: isMobile ? '20px' : '20px', fontWeight: 'bold', textAlign: 'left', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                {t('createBot.chatbotCustomization.title')}
              </Typography>
              <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-30px', mt: '10px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                {t('createBot.chatbotCustomization.firstMessage')}
              </Typography>
              <Box>
                <ThemeProvider theme={themee}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={isMobile ? 5 : 5}
                    margin="normal"
                    value={assistantFirstMessage}
                    onChange={(e) => updateAssistantFirstMessage(e.target.value)}
                    placeholder={t('createBot.chatbotCustomization.firstMessagePlaceholder') as string}
                    inputProps={{ maxLength: 200 }}
                  />
                </ThemeProvider>
                <Typography
                  sx={{
                    textAlign: 'right',
                    mr: '10px',
                    fontSize: '12px',
                    color: '#c1c1c1',
                  }}
                >
                  {`${assistantFirstMessage.length}/200`}
                </Typography>
              </Box>


              <Stack flexDirection="column" justifyContent="center" alignItems="start" flexWrap={isMobile ? "wrap" : "nowrap"}>
                <Stack flexDirection="column" justifyContent="center" alignItems="start" sx={{ width: '100%' }}>


                  {/* Tonos, formalidades y demás opciones */}
                  <FormControl variant="outlined" margin="normal" fullWidth>
                    <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', fontWeight: '300', mb: '10px', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                      {t('createBot.chatbotCustomization.tone')}
                    </Typography>
                    <Select
                      displayEmpty
                      value={chatbotTone}
                      onChange={(e) => updateChatbotTone(e.target.value)}
                      sx={{
                        color: themeMode === 'dark' ? '#ffffff' : '#181818',
                        bgcolor: themeMode === 'dark' ? '#181818' : '#fafafa',
                        '& .MuiSvgIcon-root': {
                          color: themeMode === 'dark' ? '#ffffff' : '#4a3aff',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: themeMode === 'dark' ? '#272727' : '#eeeeee',
                        },
                        borderRadius: '13px',
                      }}
                      MenuProps={{
                        sx: {
                          '& .MuiPaper-root': {
                            bgcolor: themeMode === 'dark' ? '#181818' : '#fff',
                            color: themeMode === 'dark' ? '#ffffff' : '#333',
                            borderRadius: '15px',
                            boxShadow: themeMode === "dark"
                              ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                              : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                          },
                          '& .MuiMenu-list .MuiMenuItem-root': {
                            '&:hover': {
                              bgcolor: themeMode === 'light' ? '#fafafa' : '',
                            },
                          },
                        },
                      }}
                      renderValue={(selected) => {
                        if (selected === '') {
                          return <Typography sx={{ fontFamily: 'Outfit', fontWeight: '300' }}>{t('createBot.chatbotCustomization.selectTone')}</Typography>;
                        }
                        const capitalizedText = selected.charAt(0).toUpperCase() + selected.slice(1);
                        return <Typography sx={{ fontFamily: 'Outfit', fontWeight: '300' }}>{capitalizedText}</Typography>;
                      }}
                    >
                      <MenuItem sx={{ fontFamily: 'Outfit' }} value="entusiasta">{t('createBot.chatbotCustomization.tones.enthusiastic')}</MenuItem>
                      <MenuItem sx={{ fontFamily: 'Outfit' }} value="motivador">{t('createBot.chatbotCustomization.tones.motivational')}</MenuItem>
                      <MenuItem sx={{ fontFamily: 'Outfit' }} value="neutral">{t('createBot.chatbotCustomization.tones.neutral')}</MenuItem>
                    </Select>
                  </FormControl>


                  <FormControl variant="outlined" margin="normal" fullWidth>
                    <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', fontWeight: '300', mb: '10px', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                      {t('createBot.chatbotCustomization.formality')}
                    </Typography>
                    <Select
                      displayEmpty
                      value={chatbotFormality}
                      onChange={(e) => updateChatbotFormality(e.target.value)}
                      sx={{
                        color: themeMode === 'dark' ? '#ffffff' : '#181818',
                        bgcolor: themeMode === 'dark' ? '#181818' : '#fafafa',
                        '& .MuiSvgIcon-root': {
                          color: themeMode === 'dark' ? '#ffffff' : '#4a3aff',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: themeMode === 'dark' ? '#272727' : '#eeeeee',
                        },
                        borderRadius: '13px',
                      }}
                      MenuProps={{
                        sx: {
                          '& .MuiPaper-root': {
                            bgcolor: themeMode === 'dark' ? '#181818' : '#fff',
                            color: themeMode === 'dark' ? '#ffffff' : '#333',
                            borderRadius: '15px',
                            boxShadow: themeMode === "dark"
                              ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                              : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                          },
                          '& .MuiMenu-list .MuiMenuItem-root': {
                            '&:hover': {
                              bgcolor: themeMode === 'light' ? '#fafafa' : '',
                            },
                          },
                        },
                      }}
                      renderValue={(selected) => {
                        if (selected === '') {
                          return <Typography sx={{ fontFamily: 'Outfit', fontWeight: '300' }}>{t('createBot.chatbotCustomization.selectFormality')}</Typography>;
                        }
                        const capitalizedText = selected.charAt(0).toUpperCase() + selected.slice(1);
                        return <Typography sx={{ fontFamily: 'Outfit', fontWeight: '300' }}>{capitalizedText}</Typography>;
                      }}
                    >
                      <MenuItem sx={{ fontFamily: 'Outfit' }} value="formal">{t('createBot.chatbotCustomization.formalityy.formal')}</MenuItem>
                      <MenuItem sx={{ fontFamily: 'Outfit' }} value="neutro">{t('createBot.chatbotCustomization.formalityy.neutral')}</MenuItem>
                      <MenuItem sx={{ fontFamily: 'Outfit' }} value="informal">{t('createBot.chatbotCustomization.formalityy.informal')}</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl variant="outlined" margin="normal" fullWidth>
                    <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', fontWeight: '300', mb: '10px', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                      {t('createBot.chatbotCustomization.personality')}
                    </Typography>
                    <Select
                      displayEmpty
                      value={chatbotPersonality}
                      onChange={(e) => updateChatbotPersonality(e.target.value)}
                      sx={{
                        color: themeMode === 'dark' ? '#ffffff' : '#181818',
                        bgcolor: themeMode === 'dark' ? '#181818' : '#fafafa',
                        '& .MuiSvgIcon-root': {
                          color: themeMode === 'dark' ? '#ffffff' : '#4a3aff',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: themeMode === 'dark' ? '#272727' : '#eeeeee',
                        },
                        borderRadius: '13px',
                      }}
                      MenuProps={{
                        sx: {
                          '& .MuiPaper-root': {
                            bgcolor: themeMode === 'dark' ? '#181818' : '#fff',
                            color: themeMode === 'dark' ? '#ffffff' : '#333',
                            borderRadius: '15px',
                            boxShadow: themeMode === "dark"
                              ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                              : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                          },
                          '& .MuiMenu-list .MuiMenuItem-root': {
                            '&:hover': {
                              bgcolor: themeMode === 'light' ? '#fafafa' : '',
                            },
                          },
                        },
                      }}
                      renderValue={(selected) => {
                        if (selected === '') {
                          return <Typography sx={{ fontFamily: 'Outfit', fontWeight: '300' }}>{t('createBot.chatbotCustomization.selectPersonality')}</Typography>;
                        }
                        const capitalizedText = selected.charAt(0).toUpperCase() + selected.slice(1);
                        return <Typography sx={{ fontFamily: 'Outfit', fontWeight: '300' }}>{capitalizedText}</Typography>;
                      }}
                    >
                      <MenuItem sx={{ fontFamily: 'Outfit' }} value="amigable">{t('createBot.chatbotCustomization.personalityy.friendly')}</MenuItem>
                      <MenuItem sx={{ fontFamily: 'Outfit' }} value="serio">{t('createBot.chatbotCustomization.personalityy.serious')}</MenuItem>
                      <MenuItem sx={{ fontFamily: 'Outfit' }} value="divertido">{t('createBot.chatbotCustomization.personalityy.funny')}</MenuItem>
                    </Select>
                  </FormControl>

                </Stack>
                <Stack flexDirection="column" justifyContent="start" alignItems="space-between" sx={{ width: '100%' }}>
                  <FormControl variant="outlined" margin="normal" fullWidth sx={{ mb: '50px' }}>
                    <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', fontWeight: '300', mb: '10px', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>{t('createBot.chatbotCustomization.defaultLanguageTitle')}</Typography>
                    <Select
                      displayEmpty
                      value={defaultLanguage !== "" ? defaultLanguage : 'es'}
                      onChange={(e) => updateDefaultLanguage(e.target.value)}
                      sx={{
                        color: themeMode === 'dark' ? '#ffffff' : '#181818',
                        bgcolor: themeMode === 'dark' ? '#181818' : '#fafafa',
                        '& .MuiSvgIcon-root': {
                          color: themeMode === 'dark' ? '#ffffff' : '#4a3aff',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: themeMode === 'dark' ? '#272727' : '#eeeeee',
                        },
                        borderRadius: '13px',
                      }}
                      MenuProps={{
                        sx: {
                          '& .MuiPaper-root': {
                            bgcolor: themeMode === 'dark' ? '#181818' : '#fff',
                            color: themeMode === 'dark' ? '#ffffff' : '#333',
                            borderRadius: '15px',
                            boxShadow: themeMode === "dark"
                              ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                              : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                          },
                          '& .MuiMenu-list .MuiMenuItem-root': {
                            '&:hover': {
                              bgcolor: themeMode === 'light' ? '#fafafa' : '',
                            },
                          },
                        },
                      }}
                      renderValue={(selected) => {
                        if (selected === '') {
                          return <Typography sx={{ fontFamily: 'Outfit', fontWeight: '300' }}>{t('createBot.chatbotCustomization.selectLanguage')}</Typography>;
                        }
                        let languageFull;
                        switch (selected) {
                          case 'es':
                            languageFull = 'Español';
                            break;
                          case 'en':
                            languageFull = 'English';
                            break;
                          default:
                            languageFull = '';
                        }
                        return <Typography sx={{ fontFamily: 'Outfit', fontWeight: '300' }}>{languageFull}</Typography>;
                      }}
                    >
                      <MenuItem sx={{ fontFamily: 'Outfit' }} value="es">Español</MenuItem>
                      <MenuItem sx={{ fontFamily: 'Outfit' }} value="en">English</MenuItem>
                    </Select>
                  </FormControl>

                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mb: '20px' }}>
                    <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>{t('createBot.options.useEmojis')}</Typography>
                    <Switch
                      checked={useEmojis}
                      onChange={() => updateUseEmojis(!useEmojis)}
                      sx={{
                        '& .MuiSwitch-thumb': {
                          backgroundColor: useEmojis ? "#4a3aff !important" : '#E0E0E0', // Cambia el color del thumb dependiendo del estado
                        },
                        '& .MuiSwitch-track': {
                          backgroundColor: useEmojis ? "#4a3aff !important" : '#BDBDBD', // Cambia el color del track dependiendo del estado
                          mt: '0px', // Margen superior para alinear
                        },
                        '&.Mui-checked .MuiSwitch-thumb': {
                          backgroundColor: "#4a3aff", // Color del thumb cuando está activado
                        },
                        '&.Mui-checked + .MuiSwitch-track': {
                          backgroundColor: "#4a3aff", // Color del track cuando está activado
                        },
                      }}
                    />

                  </Box>
                </Stack>
              </Stack>
            </Stack>

          </Box>
        }
      />

    </Box>
  )
}

export default VoodoBot;
