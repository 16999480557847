import { useState, useContext } from 'react';
import { Box, Stack, useTheme, useMediaQuery } from '@mui/material';
import { useRef } from 'react';
import { motion } from "framer-motion";
import DialogCommon from '../../components/DialogCommon/DialogCommon';
import { ConfigContext } from '../../context/Config/ConfigContext';

type CustomMessageModel = {
  message: string;
  sender: string;
  timestamp: Date;
  image?: string | null;
};


function ChatConversation({ messages }: { messages: CustomMessageModel[] }) {

  const {
    primaryColor,
  } = useContext(ConfigContext);

  const messageContainerRef = useRef<HTMLDivElement>(null);

  const uiTheme = useTheme();

  const isMobile = useMediaQuery(uiTheme.breakpoints.down('md'));

  const [open, setOpen] = useState(false)

  const closeDialogModal = () => {
    setOpen(false);
  };

  const sortedMessages = [...messages].sort((a, b) => {
    const dateA = new Date(a.timestamp);
    const dateB = new Date(b.timestamp);

    return dateA.getTime() - dateB.getTime();

  });

  const { themeMode } = useContext(ConfigContext)

  return (
    <Box style={{
      position: "relative",
      width: "100%",
    }}
    >
      <Box
        style={{
          backgroundColor: themeMode === "dark" ? '#121212' : '#fafafa',
          // height: '80vh',
          position: 'relative',
        }}>
        <Stack
          flexDirection="column"
          justifyContent="center"
          alignItems="inherit"
        >
          <Box
            key="message-container"
            ref={messageContainerRef}
            style={{
              backgroundColor: themeMode === "dark" ? '#121212' : '#fafafa',
              padding: isMobile ? 0 : 20,
              paddingBottom: isMobile ? 30 : 60,
              borderRadius: '20px',
              maxHeight: '75vh',
              overflowY: 'auto',
            }}
            sx={{
              ...(isMobile ? {} : {
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: themeMode === "dark" ? '#121212' : '#fafafa',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: themeMode === "dark" ? '#3A3A3A' : '#c1c1c1',
                  borderRadius: '50px'
                }
              })
            }}
          >
            {sortedMessages.map((message, i) => (
              <motion.div
                key={i}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.25 }}
              >
                <Box
                  key={i}
                  style={{
                    background: message.sender === "ChatGPT"
                      ? (themeMode === "dark" ? "#1d1d1d" : "white")
                      : primaryColor,
                    padding: "12px",
                    marginBottom: "20px",
                    color: message.sender === "ChatGPT"
                      ? (themeMode === "dark" ? "white" : "black")
                      : "white",
                    borderRadius: message.sender === "ChatGPT" ? "15px 15px 15px 0px" : "15px 15px 0px 15px",
                    textAlign: "left",
                    maxWidth: isMobile ? "90%" : "90%",
                    float: message.sender === "ChatGPT" ? "left" : "right",
                    clear: "both",
                    whiteSpace: "pre-wrap",
                    boxShadow: themeMode === "dark"
                      ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                      : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                  }}
                >
                  <div
                    style={{ fontSize: isMobile ? '14px' : '16px', fontFamily: 'Outfit', fontWeight: '300' }}
                    dangerouslySetInnerHTML={{
                      __html: (message?.message ?? "").replace(
                        /\*\*(.*?)\*\*/g,
                        '<span style="font-weight: 500;">$1</span>'
                      )
                    }}
                  />
                  {message.image && (
                    <Box mt={2}>
                      <img
                        src={message.image}
                        alt="Enviado"
                        style={{
                          maxWidth: '100%',
                          borderRadius: '10px',
                          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                        }}
                      />
                    </Box>
                  )}
                </Box>
                <div style={{ clear: 'both' }}></div>
              </motion.div>
            ))}

          </Box>
          <Box
            position="absolute"
            bottom="0"
            width="100%"
            pl="5px"
          >
            {/* <Stack
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              py={1}
              pb={2}
              px={1}
              sx={{ backgroundColor: '#121212' }}
            >
              <ThemeProvider theme={themee}>
                <TextField
                  fullWidth
                  onKeyDown={handleKeyDown}
                  autoComplete="off"
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  name="message"
                  value={value}
                  label="Mensaje"
                  error={exceededTokens}
                  onChange={(e: any) => setValue(e.target.value)}
                  sx={{
                    width: '100vw',
                  }}
                  inputProps={{
                    maxLength: 400,
                  }}
                />
              </ThemeProvider>
              <IconButton
                style={{ color: theme.palette.mode === "dark" ? "white" : 'black', outline: 'none' }}
                onClick={() => handleSent(value)}
                disabled={!value}

              >
                <SendIcon sx={{
                  color: !value ? 'lightgray' : 'white', border: 'none', fontSize: '40px',
                  backgroundColor: !value ? 'transparent' : primaryColor, borderRadius: '10px', p: 1,
                }} />
              </IconButton>
            </Stack> */}
          </Box >
        </Stack>
      </Box>
      <DialogCommon
        kTitle="Seleccionar horario"
        kMaxWidth="sm"
        kMaxHeight={isMobile ? '100vh' : '650px'}
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kEnableBorderRadius={true}
        kBorderRadius={'30px'}
        kAlignBtn="center"
        kKeepBtnSize
        kTopDivider
        kSlideTransition
        kOpenDialogLists={open}
        kOnClose={closeDialogModal}
        kOnSecondary={closeDialogModal}
        kSecondaryBtnText="Cancelar"
        kPrimaryBtnText="Seleccionar"
        kOnPrimary={() => closeDialogModal}
        kContent={
          (
            <>
            </>

          )
        }
      />
    </Box>
  );
}

export default ChatConversation;