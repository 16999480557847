import { Typography } from '@mui/material';

const PrivacyPolicyEN = ({ companyName, isMobile, themeMode }: any) => {
  return (
    <div style={{ overflowY: 'auto', maxHeight: '100%', }}>
      {/* <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        Privacy Policy for Chatbot Interactions
      </Typography> */}
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        Effective Date: February 28, 2024
      </Typography>

      {/* Introduction */}
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        1. Introduction
      </Typography>
      <Typography paragraph style={{ fontWeight: '300', fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "white" : 'black' }}>
        Welcome to {companyName}. At {companyName}, our commitment to your privacy and the security of your personal information is paramount. We operate with the utmost transparency and ensure to protect the data you share with us. This Privacy Policy applies exclusively to your interaction with our chatbot, facilitated by our technological partner, Fastalk. Throughout this policy, we will detail meticulously how we collect, process, share, and protect your personal information during your interaction with our chatbot. Our goal is to ensure you fully understand our privacy practices and feel secure using our services.
      </Typography>

      {/* Acceptance of the Privacy Policy */}
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        2. Acceptance of the Privacy Policy
      </Typography>
      <Typography paragraph style={{ fontWeight: '300', fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "white" : 'black' }}>
        By accessing and using our chatbot, you acknowledge that you have read, understood, and accepted the practices described in this Privacy Policy. This consent encompasses our collection, use, and disclosure of your personal information as detailed herein. If, for any reason, you do not agree with any part of this policy, we strongly urge you to refrain from using our chatbot. Your privacy is our priority, and we are committed to respecting your preferences.
      </Typography>

      {/* Information We Collect */}
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        3. Information We Collect
      </Typography>
      <Typography paragraph style={{ fontWeight: '300', fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "white" : 'black' }}>
        We collect information that helps us provide you with a personalized experience and improve our services. This includes personally identifiable information that you voluntarily provide to us, information generated through your interaction with our chatbot, and technical data related to your use of our services.
      </Typography>

      {/* Use of Your Information */}
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        4. Use of Your Information
      </Typography>
      <Typography paragraph style={{ fontWeight: '300', fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "white" : 'black' }}>
        We use your information to operate, maintain, and improve our services, respond to your questions and requests, personalize your experience, and communicate with you about important services. We may also use your information for analytical and market purposes.
      </Typography>

      {/* Sharing of Information */}
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        5. Sharing of Information
      </Typography>
      <Typography paragraph style={{ fontWeight: '300', fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "white" : 'black' }}>
        We only share your information with third parties in specific circumstances, such as with service providers who assist us in operating our chatbot, when required by law, or to protect our rights and safety. We always seek to ensure that your data is treated with the same level of security and confidentiality that we apply ourselves.
      </Typography>

      {/* Security of Your Data */}
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        6. Security of Your Data
      </Typography>
      <Typography paragraph style={{ fontWeight: '300', fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "white" : 'black' }}>
        We take significant measures to protect your personal data against unauthorized access, misuse, or disclosure. We implement advanced security technologies and policies to ensure the security of your information.
      </Typography>

      {/* Your Rights */}
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        7. Your Rights
      </Typography>
      <Typography paragraph style={{ fontWeight: '300', fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "white" : 'black' }}>
        You have significant rights over your personal information, including the right to access, correct, delete your information, or restrict its use. If you wish to exercise these rights, please contact us.
      </Typography>

      {/* International Data Transfers */}
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        8. International Data Transfers
      </Typography>
      <Typography paragraph style={{ fontWeight: '300', fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "white" : 'black' }}>
        Your personal data may be transferred and processed in countries other than your own. We ensure that these transfers comply with applicable law and provide an adequate level of privacy protection.
      </Typography>

      {/* Cookie Policy */}
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        9. Cookie Policy
      </Typography>
      <Typography paragraph style={{ fontWeight: '300', fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "white" : 'black' }}>
        Our chatbot and associated websites use cookies to enhance your user experience, analyze traffic, and serve targeted advertising. You have control over the use of cookies through your browser settings.
      </Typography>

      {/* Minimum Age */}
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        10. Minimum Age
      </Typography>
      <Typography paragraph style={{ fontWeight: '300', fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "white" : 'black' }}>
        We do not knowingly collect personal information from minors without the consent of parents or legal guardians. If you are under age, please do not use our chatbot without such consent.
      </Typography>

      {/* Links to Third Parties */}
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        11. Links to Third Parties
      </Typography>
      <Typography paragraph style={{ fontWeight: '300', fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "white" : 'black' }}>
        Our chatbot may contain links to third-party websites. We are not responsible for the privacy practices of those sites. We encourage you to familiarize yourself with the privacy policies of any third-party website you visit.
      </Typography>

      {/* Policy Updates */}
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        12. Policy Updates
      </Typography>
      <Typography paragraph style={{ fontWeight: '300', fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "white" : 'black' }}>
        We reserve the right to modify this privacy policy at any time. Any changes will be effective immediately upon posting on our website or communication through our chatbot. Your continued use of our services after such changes constitutes your acceptance of the new privacy policy.
      </Typography>

      {/* Contacto */}
      {/* <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: themeMode === "dark" ? "white" : 'black' }}>
        13. Contacto
      </Typography>
      <Typography paragraph style={{ fontWeight: '300', fontFamily: 'Outfit', fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "white" : 'black' }}>
        Si tiene preguntas, comentarios o preocupaciones sobre nuestra Política de Privacidad o prácticas de procesamiento de datos, o si desea ejercer alguno de sus derechos como sujeto de datos, por favor póngase en contacto con nosotros en:
        <br />
        [Información de Contacto]
      </Typography> */}
    </div>
  );
};

export default PrivacyPolicyEN;