import React, { useContext, useEffect, useState } from 'react';
import { ConfigContext } from '../../context/Config/ConfigContext';
import {
  TextField,
  Input,
  FormControl,
  Typography,
  Box, useTheme,
  useMediaQuery,
  Select,
  MenuItem,
  Switch,
  CircularProgress,
  Stack,
  IconButton
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SectionContainer from '../../global/SectionContainer';
import { collection, doc, runTransaction, updateDoc, addDoc, getDocs, query, where } from 'firebase/firestore';
import { auth, db, storage } from '../../services/firebase/firebase';
import { useNavigate, useParams } from 'react-router-dom';
import { getDoc } from 'firebase/firestore';
import { DocumentData } from 'firebase/firestore';
import { DownloadOutlined } from '@mui/icons-material';
import StoreContext from '../../context/Store/storeContext';
import AddAPhotoRoundedIcon from '@mui/icons-material/AddAPhotoRounded';
import { getDownloadURL, ref, uploadString } from 'firebase/storage';
import { useTranslation } from 'react-i18next';
import { updateGlobalMetrics } from '../../services/metrics/metricsUtils';
import { showToast } from '../../components/Toast/toast';

function CreateBot() {

  const { t } = useTranslation()

  const [isSaving, setIsSaving] = useState(false);

  const {
    logo,
    lightModeLogo,
    primaryColor,
    assistantFirstMessage,
    systemInstructions,
    companyName,
    chatbotTone,
    chatbotFormality,
    defaultLanguage,
    useEmojis,
    chatbotPersonality,
    nightMode,
    businessType,
    chatbotState,
    startDemoDate,
    responseCount,
    suggestion1,
    suggestion2,
    suggestion3,
    updateSystemInstructions,
    updateAssistantFirstMessage,
    updatePrimaryColor,
    updateCompanyLogo,
    updateCompanyLightModeLogo,
    updateCompanyName,
    updateChatbotTone,
    updateChatbotFormality,
    updateDefaultLanguage,
    updateUseEmojis,
    updateChatbotPersonality,
    updateNightMode,
    updateBusinessType,
    updateChatbotState,
    updateStartDemoDate,
    updateResponseCount,
    updateSuggestion1,
    updateSuggestion2,
    updateSuggestion3,
    resetConfigContext
  } = useContext(ConfigContext);

  const { fetchUser, user } = useContext(StoreContext);

  const navigate = useNavigate()
  const [chatbotFetchData, setChatbotFetchData] = useState<DocumentData | null>(null);

  const { setNeedsUpdate } = useContext(StoreContext);

  const currentCreationDate = chatbotFetchData?.creationDate ? chatbotFetchData?.creationDate : new Date().toISOString();

  const updateBotCount = async (userId: any, increment = true) => {
    const userDocRef = doc(db, "users", userId);

    try {
      await runTransaction(db, async (transaction: any) => {
        const userDoc = await transaction.get(userDocRef);
        if (!userDoc.exists()) {
          throw "El documento del usuario no existe";
        }

        const newBotCount = increment ? userDoc.data().botCount + 1 : userDoc.data().botCount - 1;
        transaction.update(userDocRef, { botCount: newBotCount });
        fetchUser(userId)
      });
    } catch (error: any) {
      showToast('error', t('createBot.toasts.errorUpdatingBotCount'), themeMode);
    }
  };

  const saveBotToFirestore = async () => {
    // Iniciar el indicador de carga.
    setIsSaving(true);

    // Comprobar si hay un usuario autenticado.
    if (!auth.currentUser) {
      showToast('error', t('createBot.toasts.noUserLoggedIn'), themeMode);
      setIsSaving(false);
      return;
    }

    const userId = auth.currentUser.uid;

    // Obtener el documento del usuario actual
    const userDoc = await getDoc(doc(db, 'users', userId));
    const userData = userDoc.data();

    if (!userData) {
      showToast('error', t('createBot.toasts.userDataNotFound'), themeMode);
      setIsSaving(false);
      return;
    }

    const botLimit = userData.botLimit || 0;

    // Contar chatbots activos del usuario
    const chatbotsSnapshot = await getDocs(
      query(
        collection(db, 'chatbots'),
        where('ownerId', '==', userId),
        where('chatbotState', '==', 'active')
      )
    );

    const activeChatbotsCount = chatbotsSnapshot.size;

    // Verificar si se está intentando activar un nuevo chatbot o cambiar uno existente a activo
    if (chatbotState === 'active') {
      if (!id) {
        // Nuevo chatbot
        if (activeChatbotsCount >= botLimit) {
          showToast('error', t('createBot.toasts.botLimitReached'), themeMode);
          setIsSaving(false);
          return;
        }
      } else {
        // Chatbot existente
        const existingChatbotDoc = await getDoc(doc(db, 'chatbots', id));
        const existingChatbotData = existingChatbotDoc.data();
        if (existingChatbotData && existingChatbotData.chatbotState !== 'active' && activeChatbotsCount >= botLimit) {
          showToast('error', t('createBot.toasts.botLimitReached'), themeMode);
          setIsSaving(false);
          return;
        }
      }
    }

    let currentStartDemoDate = startDemoDate;
    if (chatbotState === "En demo" && !startDemoDate) {
      currentStartDemoDate = new Date().toISOString();
      updateStartDemoDate(currentStartDemoDate);
    }

    if (!id) {
      updateResponseCount(0);
    }

    let logoURL = logo;
    let lightModeLogoURL = lightModeLogo;

    // Verifica si el logo tiene el formato 'data:' al principio (indicando que es un data_url)
    if (logo.startsWith('data:')) {
      // Subir el logo al Storage.
      const logoStorageRef = ref(storage, `logos/${auth.currentUser.uid}/${Date.now()}_logo.png`);
      await uploadString(logoStorageRef, logo, 'data_url');
      logoURL = await getDownloadURL(logoStorageRef);
    }

    // La lógica adicional para manejar lightModeLogo debería ser similar a la del logo.
    if (lightModeLogo && lightModeLogo.startsWith('data:')) {
      // Subir el lightModeLogo al Storage.
      const lightModeLogoStorageRef = ref(storage, `logos/${auth.currentUser.uid}/${Date.now()}_light_mode_logo.png`);
      await uploadString(lightModeLogoStorageRef, lightModeLogo, 'data_url');
      lightModeLogoURL = await getDownloadURL(lightModeLogoStorageRef);
    }

    const chatbotData = {
      ownerId: auth.currentUser.uid,
      logo: logoURL,
      lightModeLogo: lightModeLogoURL || "",
      primaryColor: primaryColor,
      assistantFirstMessage: assistantFirstMessage,
      systemInstructions: systemInstructions,
      companyName: companyName,
      chatbotTone: chatbotTone,
      chatbotFormality: chatbotFormality,
      defaultLanguage: defaultLanguage || "es",
      useEmojis: useEmojis,
      chatbotPersonality: chatbotPersonality,
      nightMode: nightMode,
      businessType: businessType,
      creationDate: currentCreationDate,
      chatbotState: chatbotState,
      responseCount: responseCount || 0,
      suggestion1: suggestion1 || '',
      suggestion2: suggestion2 || '',
      suggestion3: suggestion3 || '',
    };

    // Referencia a la colección principal de chatbots.
    const botsCollection = collection(db, 'chatbots');

    try {
      if (id) {
        // Si hay un id, actualizamos el chatbot.
        const chatbotDocRef = doc(botsCollection, id);
        await updateDoc(chatbotDocRef, chatbotData);
        showToast('success', t('createBot.toasts.botUpdatedSuccessfully'), themeMode);
        setNeedsUpdate(true); // Indicamos que se necesita actualizar la lista de chatbots.
      } else {
        // Si no hay un id, creamos un nuevo chatbot.
        await addDoc(botsCollection, chatbotData); // Asegúrate de esperar a que la promesa se resuelva
        await updateBotCount(auth.currentUser.uid); // Espera a que la actualización del conteo termine
        showToast('success', t('createBot.toasts.botCreatedSuccessfully'), themeMode);
        setNeedsUpdate(true); // Indicamos que se necesita actualizar la lista de chatbots.
        await updateGlobalMetrics({ newChatbot: true });
      }
    } catch (error: any) {
      // Si hay un error, lo mostramos en la consola.
      showToast('error', t('createBot.toasts.errorSavingBot'), themeMode);
    } finally {
      // Finalizar el indicador de carga y redirigir al usuario.
      setIsSaving(false);
      navigate('/my-bots');
    }
  };

  const handleLogoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          updateCompanyLogo(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  }

  const handleLightModeLogoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          updateCompanyLightModeLogo(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  <Switch
    sx={{
      '&.Mui-checked .MuiSwitch-thumb': {
        backgroundColor: primaryColor,
      },
      '&.Mui-checked + .MuiSwitch-track': {
        backgroundColor: primaryColor,
      }
    }}
    checked={useEmojis}
    onChange={() => updateUseEmojis(!useEmojis)}
  />

  function CustomSwitch(props: any) {
    return (
      <Switch
        {...props}
        sx={{
          '& .MuiSwitch-thumb': {
            backgroundColor: props.checked ? '#4a3aff' : '#E0E0E0',
            width: '25px',
            height: '25px',
          },
          '& .MuiSwitch-track': {
            backgroundColor: props.checked ? '#4a3aff' : '#BDBDBD',
            mt: '2px'
          }
        }}
      />
    );
  }

  const { id } = useParams();

  const [isFetchingChatbot, setIsFetchingChatbot] = useState(true);


  const { themeMode } = useContext(ConfigContext)

  useEffect(() => {
    if (!id) {
      resetConfigContext();
      setIsFetchingChatbot(false);
    } else {
      const fetchChatbot = async () => {
        if (!auth.currentUser || !id) {
          showToast('error', t('createBot.toasts.noAuthenticatedUserOrUndefinedBotId'), themeMode);
          setIsFetchingChatbot(false);
          return;
        }

        setIsFetchingChatbot(true);

        try {
          const chatbotDocRef = doc(db, 'chatbots', id);
          const chatbotDocSnapshot = await getDoc(chatbotDocRef);

          if (chatbotDocSnapshot.exists()) {
            const chatbotData = chatbotDocSnapshot.data();
            setChatbotFetchData(chatbotData);
            updateCompanyLogo(chatbotData.logo);
            updateCompanyLightModeLogo(chatbotData.lightModeLogo);
            updatePrimaryColor(chatbotData.primaryColor);
            updateAssistantFirstMessage(chatbotData.assistantFirstMessage);
            updateSystemInstructions(chatbotData.systemInstructions);
            updateCompanyName(chatbotData.companyName);
            updateChatbotTone(chatbotData.chatbotTone);
            updateChatbotFormality(chatbotData.chatbotFormality);
            updateDefaultLanguage(chatbotData.defaultLanguage);
            updateUseEmojis(chatbotData.useEmojis);
            updateChatbotPersonality(chatbotData.chatbotPersonality);
            updateNightMode(chatbotData.nightMode);
            updateBusinessType(chatbotData.businessType);
            updateChatbotState(chatbotData.chatbotState);
            updateResponseCount(chatbotData.responseCount);
            updateSuggestion1(chatbotData.suggestion1);
            updateSuggestion2(chatbotData.suggestion2);
            updateSuggestion3(chatbotData.suggestion3);
          } else {
            showToast('error', t('createBot.toasts.noBotFoundWithThatId'), themeMode);
          }
        } catch (error: any) {
          showToast('error', t('createBot.toasts.errorFetchingChatbotInfo'), themeMode);
        } finally {
          setIsFetchingChatbot(false);
        }
      };

      fetchChatbot();
    }
  }, [id, auth.currentUser]);

  const themee = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            fontFamily: 'Outfit',
            color: '#7c7c7c',
            backgroundColor: themeMode === "dark" ? "#181818" : "#fafafa",
            borderRadius: 15,
            border: themeMode === "dark" ? '1px solid #272727' : '1px solid #eeeeee',
            // Establecer el fondo aquí
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',

            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#181818" : "#fafafa",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',

            },
            "& .MuiInputBase-input": {
              fontFamily: 'Outfit',
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiInputLabel-root": {
              fontFamily: 'Outfit',
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#4a3aff" : '#4a3aff',

            },
            "& .MuiInputLabel-root.Mui-focused + .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#4a3aff" : '#4a3aff'

            },
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 15,
          },
        },
      },
    }
  });

  return (
    <SectionContainer
      title={id ? t('createBot.title.edit') as string : t('createBot.title.create') as string}
      text={isSaving ? t('createBot.savingBot') as string : (id ? t('createBot.button.save') as string : t('createBot.button.saveAndCreate') as string)}
      icon={isSaving ? <CircularProgress size="25px" /> : <DownloadOutlined />}
      onClick={saveBotToFirestore}
      individualChatbot
      disableButton={isSaving}
    >

      {isFetchingChatbot ? (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: themeMode === "dark" ? '#121212' : '#fafafa'
          }}
        >
          <CircularProgress sx={{ color: themeMode === "dark" ? '#fafafa' : '#4a3aff' }} />
        </Box>
      )
        :
        (
          <Box sx={{ backgroundColor: themeMode === "dark" ? '#121212' : '#fafafa', }}>
            <Box sx={{ maxWidth: isMobile ? '90vw' : '1200px', textAlign: 'center', mx: 'auto', }}>
              <Stack flexDirection="column" justifyContent="start" sx={{
                backgroundColor: themeMode === "dark" ? '#181818' : '#fff', boxShadow: themeMode === "dark"
                  ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                  : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)', borderRadius: '20px', padding: isMobile ? '20px' : '30px', gap: 3, mb: '20px'
              }}>
                <Typography sx={{ fontFamily: 'Outfit', fontSize: isMobile ? '20px' : '25px', textAlign: 'left', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                  {t('createBot.logos.title')}
                </Typography>

                <Stack flexDirection="row" justifyContent="center" alignItems="center" gap={5} flexWrap={isMobile ? "wrap" : "nowrap"}>
                  <Stack flexDirection="column" justifyContent="center" alignItems="center" >
                    {/* Visualización del logo */}
                    <Box sx={{
                      position: 'relative',
                      width: "300px", height: '130px',
                      display: 'flex', justifyContent: 'center', alignItems: 'center', padding: isMobile ? '20px' : '50px',
                      borderRadius: '20px',
                      backgroundColor: themeMode === "dark" ? '#121212' : '#121212'
                    }}>
                      <img src={logo} alt="Logo de la empresa" height="auto" style={{ maxHeight: '50px', maxWidth: isMobile ? '70vw' : 'auto' }} />
                      {/* Subir logo */}
                      <FormControl variant="outlined" margin="normal" sx={{ position: 'absolute', bottom: 0, right: 0 }}>
                        <input
                          style={{ display: 'none' }}
                          id="upload-logo"
                          type="file"
                          onChange={handleLogoUpload}
                        />
                        <label htmlFor="upload-logo">
                          <IconButton
                            component="span"
                            sx={{
                              color: 'white', backgroundColor: '#4a3aff',
                              borderRadius: '100px', fontWeight: 'bold',
                              padding: '15px', fontFamily: 'Outfit',
                              position: 'absolute', right: '-25px', bottom: '-25px'
                            }}
                          >
                            <AddAPhotoRoundedIcon />
                          </IconButton>
                        </label>
                      </FormControl>
                    </Box>
                    <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mt: '10px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                      {t('createBot.logos.darkMode')}
                    </Typography>
                  </Stack>
                  <Stack flexDirection="column" justifyContent="center" alignItems="center" >


                    <Box sx={{
                      position: 'relative',
                      width: "300px", height: '130px',
                      display: 'flex', justifyContent: 'center', alignItems: 'center', padding: isMobile ? '20px' : '50px',
                      borderRadius: '20px', backgroundColor: themeMode === "dark" ? '#fafafa' : '#fafafa'
                    }}>
                      <img src={lightModeLogo} alt="Logo de la empresa para versión light" height="auto" style={{ maxHeight: '50px', maxWidth: isMobile ? '70vw' : 'auto' }} />
                      {/* Subir logo versión light */}
                      <FormControl variant="outlined" margin="normal" sx={{ position: 'absolute', bottom: 0, right: 0 }}>
                        <input
                          style={{ display: 'none' }}
                          id="upload-light-mode-logo"
                          type="file"
                          onChange={handleLightModeLogoUpload}
                        />
                        <label htmlFor="upload-light-mode-logo">
                          {/* Aquí puedes usar cualquier componente o estilo que desees para tu botón personalizado */}
                          <IconButton
                            component="span"
                            sx={{
                              color: 'white', backgroundColor: '#4a3aff',
                              borderRadius: '100px', fontWeight: 'bold',
                              padding: '15px', fontFamily: 'Outfit',
                              position: 'absolute', right: '-25px', bottom: '-25px'
                            }}
                          >
                            <AddAPhotoRoundedIcon />
                          </IconButton>
                        </label>
                      </FormControl>
                    </Box>
                    <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mt: '10px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                      {t('createBot.logos.lightMode')}
                    </Typography>

                  </Stack>
                </Stack>
              </Stack>

              <Stack flexDirection="column" justifyContent="center"
                sx={{
                  backgroundColor: themeMode === "dark" ? '#181818' : '#fff',
                  boxShadow: themeMode === "dark"
                    ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                    : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                  borderRadius: '20px', padding: isMobile ? '20px' : '30px', mb: '20px',
                }}>
                <Typography sx={{ fontFamily: 'Outfit', fontSize: isMobile ? '20px' : '25px', textAlign: 'left', color: themeMode === "dark" ? '#fafafa' : '#181818', }}>
                  {t('createBot.companyData.title')}
                </Typography>
                <Stack flexDirection="column" justifyContent="center" alignItems="center" sx={{ mt: '20px' }}>
                  <Stack flexDirection="row" justifyContent="center" alignItems="center" sx={{ width: '100%' }} gap={isMobile ? 1 : 5} flexWrap={isMobile ? "wrap" : "nowrap"}>
                    <Box sx={{ width: '100%' }}>
                      <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-10px', mt: '10px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818', }}>

                        {t('createBot.companyData.name')}
                      </Typography>
                      <ThemeProvider theme={themee}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={companyName}
                          onChange={(e) => updateCompanyName(e.target.value)}
                          placeholder={t('createBot.companyData.namePlaceholder') as string}
                          inputProps={{ maxLength: 50 }}
                        />
                      </ThemeProvider>
                      <Typography
                        sx={{
                          textAlign: 'right',
                          mr: '10px',
                          width: '100%',
                          fontSize: '12px',
                          color: '#c1c1c1',
                        }}
                      >
                        {`${companyName.length}/50`}
                      </Typography>
                    </Box>
                    <Box sx={{ width: '100%' }}>

                      <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-10px', mt: '10px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818', }}>
                        {t('createBot.companyData.businessType')}
                      </Typography>
                      <ThemeProvider theme={themee}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={businessType}
                          onChange={(e) => updateBusinessType(e.target.value)}
                          placeholder={t('createBot.companyData.businessTypePlaceholder') as string}
                          inputProps={{ maxLength: 50 }}
                        />
                      </ThemeProvider>
                      <Typography
                        sx={{
                          textAlign: 'right',
                          mr: '10px',
                          width: '100%',
                          fontSize: '12px',
                          color: '#c1c1c1',
                        }}
                      >
                        {`${businessType.length}/50`}
                      </Typography>

                    </Box>





                    {/* <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-10px', mt: '10px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                      {t('createBot.companyData.phoneNumber')}
                    </Typography>
                    <ThemeProvider theme={themee}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={companyNumber}
                        onChange={(e) => updateCompanyNumber(e.target.value)}
                        placeholder={t('createBot.companyData.phoneNumberPlaceholder') as string}
                        inputProps={{ maxLength: 10 }}
                      />
                    </ThemeProvider>
                    <Typography
                      sx={{
                        textAlign: 'right',
                        mr: '10px',
                        width: '100%',
                        fontSize: '12px',
                        color: '#c1c1c1',
                      }}
                    >
                      {`${companyNumber.length}/10`}
                    </Typography> */}


                  </Stack>
                  <Stack flexDirection="column" justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
                    <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-10px', mt: '10px', fontWeight: '300', width: '100%', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                      {t('createBot.companyData.info')}
                    </Typography>
                    <ThemeProvider theme={themee}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={10}
                        margin="normal"
                        value={systemInstructions}
                        onChange={(e) => updateSystemInstructions(e.target.value)}
                        placeholder={t('createBot.companyData.infoPlaceholder') as string}
                        inputProps={{ maxLength: 10000 }}
                      />
                    </ThemeProvider>
                    <Typography
                      sx={{
                        textAlign: 'right',
                        mr: '10px',
                        width: '100%',
                        fontSize: '12px',
                        color: '#c1c1c1',
                      }}
                    >
                      {`${systemInstructions.length}/10000`}
                    </Typography>

                  </Stack>
                </Stack>
              </Stack>

              <Stack
                flexDirection="column" justifyContent="start"
                sx={{
                  backgroundColor: themeMode === "dark" ? '#181818' : '#fff',
                  boxShadow: themeMode === "dark"
                    ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                    : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                  borderRadius: '20px', padding: isMobile ? '20px' : '30px', gap: 3, mb: '20px'
                }}>
                <Typography sx={{ fontFamily: 'Outfit', fontSize: isMobile ? '20px' : '25px', textAlign: 'left', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                  {t('createBot.questions.title')}
                </Typography>
                <Stack>

                  <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-10px', mt: '10px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                    {t('createBot.questions.suggestion1')}
                  </Typography>
                  <ThemeProvider theme={themee}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={isMobile ? 2 : 1}
                      margin="normal"
                      value={suggestion1}
                      onChange={(e) => updateSuggestion1(e.target.value)}
                      placeholder={t('createBot.questions.suggestion1Placeholder') as string}
                      inputProps={{ maxLength: 150 }}
                    />
                  </ThemeProvider>
                  <Typography
                    sx={{
                      textAlign: 'right',
                      mr: '10px',
                      fontSize: '12px',
                      color: '#c1c1c1',
                    }}
                  >
                    {`${suggestion1.length}/150`}
                  </Typography>



                  <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-10px', mt: '10px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                    {t('createBot.questions.suggestion2')}
                  </Typography>
                  <ThemeProvider theme={themee}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={isMobile ? 2 : 1}
                      margin="normal"
                      value={suggestion2}
                      onChange={(e) => updateSuggestion2(e.target.value)}
                      placeholder={t('createBot.questions.suggestion1Placeholder') as string}
                      inputProps={{ maxLength: 150 }}
                    />
                  </ThemeProvider>
                  <Typography
                    sx={{
                      textAlign: 'right',
                      mr: '10px',
                      fontSize: '12px',
                      color: '#c1c1c1',
                    }}
                  >
                    {`${suggestion2.length}/150`}
                  </Typography>


                  <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-10px', mt: '10px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                    {t('createBot.questions.suggestion3')}
                  </Typography>
                  <ThemeProvider theme={themee}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={isMobile ? 2 : 1}
                      margin="normal"
                      value={suggestion3}
                      onChange={(e) => updateSuggestion3(e.target.value)}
                      placeholder={t('createBot.questions.suggestion1Placeholder') as string}
                      inputProps={{ maxLength: 150 }}
                    />
                  </ThemeProvider>
                  <Typography
                    sx={{
                      textAlign: 'right',
                      mr: '10px',
                      fontSize: '12px',
                      color: '#c1c1c1',
                    }}
                  >
                    {`${suggestion3.length}/150`}
                  </Typography>

                </Stack>

              </Stack>

              <Stack
                flexDirection="column" justifyContent="start"
                sx={{
                  borderRadius: '20px', padding: isMobile ? '20px' : '30px', gap: 3, mb: '20px',
                  backgroundColor: themeMode === "dark" ? '#181818' : '#fff',
                  boxShadow: themeMode === "dark"
                    ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                    : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                }}>
                <Typography sx={{ fontFamily: 'Outfit', fontSize: isMobile ? '20px' : '25px', textAlign: 'left', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                  {t('createBot.chatbotCustomization.title')}
                </Typography>
                <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-30px', mt: '10px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                  {t('createBot.chatbotCustomization.firstMessage')}
                </Typography>
                <Box>
                  <ThemeProvider theme={themee}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={isMobile ? 3 : 2}
                      margin="normal"
                      value={assistantFirstMessage}
                      onChange={(e) => updateAssistantFirstMessage(e.target.value)}
                      placeholder={t('createBot.chatbotCustomization.firstMessagePlaceholder') as string}
                      inputProps={{ maxLength: 200 }}
                    />
                  </ThemeProvider>
                  <Typography
                    sx={{
                      textAlign: 'right',
                      mr: '10px',
                      fontSize: '12px',
                      color: '#c1c1c1',
                    }}
                  >
                    {`${assistantFirstMessage.length}/200`}
                  </Typography>
                </Box>


                <Stack flexDirection="row" justifyContent="center" alignItems="start" gap={isMobile ? 1 : 5} flexWrap={isMobile ? "wrap" : "nowrap"}>
                  <Stack flexDirection="column" justifyContent="center" alignItems="start" sx={{ width: '100%' }}>

                    {/* Color primario */}
                    <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                      {t('createBot.chatbotCustomization.primaryColor')}
                    </Typography>
                    <FormControl variant="outlined" margin="normal" fullWidth sx={{ mb: '10px' }}>
                      <Input
                        id="primary-color"
                        type="color"
                        value={primaryColor}
                        onChange={(e) => updatePrimaryColor(e.target.value)}
                        disableUnderline
                        inputProps={{
                          style: {
                            cursor: 'pointer',
                            width: "100%",
                            height: "100%",
                            opacity: 0,
                            position: 'absolute',
                          }
                        }}
                        sx={{
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          position: 'absolute',
                        }}
                      />
                      <Box
                        sx={{
                          width: "100%",
                          height: "50px",
                          margin: "0",
                          backgroundColor: primaryColor,
                          borderRadius: "10px",
                          cursor: 'pointer'
                        }}
                      ></Box>

                    </FormControl>

                    {/* Tonos, formalidades y demás opciones */}
                    <FormControl variant="outlined" margin="normal" fullWidth>
                      <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', fontWeight: '300', mb: '10px', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                        {t('createBot.chatbotCustomization.tone')}
                      </Typography>
                      <Select
                        displayEmpty
                        value={chatbotTone}
                        onChange={(e) => updateChatbotTone(e.target.value)}
                        sx={{
                          color: themeMode === 'dark' ? '#ffffff' : '#181818',
                          bgcolor: themeMode === 'dark' ? '#181818' : '#fafafa',
                          '& .MuiSvgIcon-root': {
                            color: themeMode === 'dark' ? '#ffffff' : '#4a3aff',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: themeMode === 'dark' ? '#272727' : '#eeeeee',
                          },
                          borderRadius: '13px',
                        }}
                        MenuProps={{
                          sx: {
                            '& .MuiPaper-root': {
                              bgcolor: themeMode === 'dark' ? '#181818' : '#fff',
                              color: themeMode === 'dark' ? '#ffffff' : '#333',
                              borderRadius: '15px',
                              boxShadow: themeMode === "dark"
                                ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                                : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                            },
                            '& .MuiMenu-list .MuiMenuItem-root': {
                              '&:hover': {
                                bgcolor: themeMode === 'light' ? '#fafafa' : '',
                              },
                            },
                          },
                        }}
                        renderValue={(selected) => {
                          if (selected === '') {
                            return <Typography sx={{ fontFamily: 'Outfit', fontWeight: '300' }}>{t('createBot.chatbotCustomization.selectTone')}</Typography>;
                          }
                          const capitalizedText = selected.charAt(0).toUpperCase() + selected.slice(1);
                          return <Typography sx={{ fontFamily: 'Outfit', fontWeight: '300' }}>{capitalizedText}</Typography>;
                        }}
                      >
                        <MenuItem value="entusiasta">{t('createBot.chatbotCustomization.tones.enthusiastic')}</MenuItem>
                        <MenuItem value="motivador">{t('createBot.chatbotCustomization.tones.motivational')}</MenuItem>
                        <MenuItem value="neutral">{t('createBot.chatbotCustomization.tones.neutral')}</MenuItem>
                      </Select>
                    </FormControl>


                    <FormControl variant="outlined" margin="normal" fullWidth>
                      <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', fontWeight: '300', mb: '10px', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                        {t('createBot.chatbotCustomization.formality')}
                      </Typography>
                      <Select
                        displayEmpty
                        value={chatbotFormality}
                        onChange={(e) => updateChatbotFormality(e.target.value)}
                        sx={{
                          color: themeMode === 'dark' ? '#ffffff' : '#181818',
                          bgcolor: themeMode === 'dark' ? '#181818' : '#fafafa',
                          '& .MuiSvgIcon-root': {
                            color: themeMode === 'dark' ? '#ffffff' : '#4a3aff',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: themeMode === 'dark' ? '#272727' : '#eeeeee',
                          },
                          borderRadius: '13px',
                        }}
                        MenuProps={{
                          sx: {
                            '& .MuiPaper-root': {
                              bgcolor: themeMode === 'dark' ? '#181818' : '#fff',
                              color: themeMode === 'dark' ? '#ffffff' : '#333',
                              borderRadius: '15px',
                              boxShadow: themeMode === "dark"
                                ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                                : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                            },
                            '& .MuiMenu-list .MuiMenuItem-root': {
                              '&:hover': {
                                bgcolor: themeMode === 'light' ? '#fafafa' : '',
                              },
                            },
                          },
                        }}
                        renderValue={(selected) => {
                          if (selected === '') {
                            return <Typography sx={{ fontFamily: 'Outfit', fontWeight: '300' }}>{t('createBot.chatbotCustomization.selectFormality')}</Typography>;
                          }
                          const capitalizedText = selected.charAt(0).toUpperCase() + selected.slice(1);
                          return <Typography sx={{ fontFamily: 'Outfit', fontWeight: '300' }}>{capitalizedText}</Typography>;
                        }}
                      >
                        <MenuItem value="formal">{t('createBot.chatbotCustomization.formalityy.formal')}</MenuItem>
                        <MenuItem value="neutro">{t('createBot.chatbotCustomization.formalityy.neutral')}</MenuItem>
                        <MenuItem value="informal">{t('createBot.chatbotCustomization.formalityy.informal')}</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl variant="outlined" margin="normal" fullWidth>
                      <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', fontWeight: '300', mb: '10px', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                        {t('createBot.chatbotCustomization.personality')}
                      </Typography>
                      <Select
                        displayEmpty
                        value={chatbotPersonality}
                        onChange={(e) => updateChatbotPersonality(e.target.value)}
                        sx={{
                          color: themeMode === 'dark' ? '#ffffff' : '#181818',
                          bgcolor: themeMode === 'dark' ? '#181818' : '#fafafa',
                          '& .MuiSvgIcon-root': {
                            color: themeMode === 'dark' ? '#ffffff' : '#4a3aff',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: themeMode === 'dark' ? '#272727' : '#eeeeee',
                          },
                          borderRadius: '13px',
                        }}
                        MenuProps={{
                          sx: {
                            '& .MuiPaper-root': {
                              bgcolor: themeMode === 'dark' ? '#181818' : '#fff',
                              color: themeMode === 'dark' ? '#ffffff' : '#333',
                              borderRadius: '15px',
                              boxShadow: themeMode === "dark"
                                ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                                : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                            },
                            '& .MuiMenu-list .MuiMenuItem-root': {
                              '&:hover': {
                                bgcolor: themeMode === 'light' ? '#fafafa' : '',
                              },
                            },
                          },
                        }}
                        renderValue={(selected) => {
                          if (selected === '') {
                            return <Typography sx={{ fontFamily: 'Outfit', fontWeight: '300' }}>{t('createBot.chatbotCustomization.selectPersonality')}</Typography>;
                          }
                          const capitalizedText = selected.charAt(0).toUpperCase() + selected.slice(1);
                          return <Typography sx={{ fontFamily: 'Outfit', fontWeight: '300' }}>{capitalizedText}</Typography>;
                        }}
                      >
                        <MenuItem value="amigable">{t('createBot.chatbotCustomization.personalityy.friendly')}</MenuItem>
                        <MenuItem value="serio">{t('createBot.chatbotCustomization.personalityy.serious')}</MenuItem>
                        <MenuItem value="divertido">{t('createBot.chatbotCustomization.personalityy.funny')}</MenuItem>
                      </Select>
                    </FormControl>

                  </Stack>
                  <Stack flexDirection="column" justifyContent="start" alignItems="space-between" sx={{ width: '100%' }}>
                    <FormControl variant="outlined" margin="normal" fullWidth sx={{ mb: '50px' }}>
                      <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', fontWeight: '300', mb: '10px', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>{t('createBot.chatbotCustomization.defaultLanguageTitle')}</Typography>
                      <Select
                        displayEmpty
                        value={defaultLanguage !== "" ? defaultLanguage : (user?.language || 'es')}
                        onChange={(e) => updateDefaultLanguage(e.target.value)}
                        sx={{
                          color: themeMode === 'dark' ? '#ffffff' : '#181818',
                          bgcolor: themeMode === 'dark' ? '#181818' : '#fafafa',
                          '& .MuiSvgIcon-root': {
                            color: themeMode === 'dark' ? '#ffffff' : '#4a3aff',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: themeMode === 'dark' ? '#272727' : '#eeeeee',
                          },
                          borderRadius: '13px',
                        }}
                        MenuProps={{
                          sx: {
                            '& .MuiPaper-root': {
                              bgcolor: themeMode === 'dark' ? '#181818' : '#fff',
                              color: themeMode === 'dark' ? '#ffffff' : '#333',
                              borderRadius: '15px',
                              boxShadow: themeMode === "dark"
                                ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                                : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                            },
                            '& .MuiMenu-list .MuiMenuItem-root': {
                              '&:hover': {
                                bgcolor: themeMode === 'light' ? '#fafafa' : '',
                              },
                            },
                          },
                        }}
                        renderValue={(selected) => {
                          if (selected === '') {
                            return <Typography sx={{ fontFamily: 'Outfit', fontWeight: '300' }}>{t('createBot.chatbotCustomization.selectLanguage')}</Typography>;
                          }
                          let languageFull;
                          switch (selected) {
                            case 'es':
                              languageFull = 'Español';
                              break;
                            case 'en':
                              languageFull = 'English';
                              break;
                            default:
                              languageFull = '';
                          }
                          return <Typography sx={{ fontFamily: 'Outfit', fontWeight: '300' }}>{languageFull}</Typography>;
                        }}
                      >
                        <MenuItem value="es">Español</MenuItem>
                        <MenuItem value="en">English</MenuItem>
                      </Select>
                    </FormControl>

                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mb: '20px' }}>
                      <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '-10px', mt: '10px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>{t('createBot.options.useEmojis')}</Typography>
                      <CustomSwitch checked={useEmojis} onChange={() => updateUseEmojis(!useEmojis)} />
                    </Box>

                  </Stack>
                </Stack>
              </Stack>

              <Stack
                flexDirection="column" justifyContent="start"
                sx={{
                  borderRadius: '20px',
                  backgroundColor: themeMode === "dark" ? '#181818' : '#fff',
                  boxShadow: themeMode === "dark"
                    ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                    : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                  padding: '30px', gap: 3,
                  mb: isMobile ? '80px' : '20px'
                }}>
                <Typography sx={{ fontFamily: 'Outfit', fontSize: isMobile ? '20px' : '25px', textAlign: 'left', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                  {t('createBot.chatbotState.title')}
                </Typography>
                <Stack flexDirection="row" justifyContent="center" alignItems="center" gap={5} sx={{ mb: isMobile ? 0 : '80px' }}>
                  <Stack flexDirection="column" sx={{ width: '100%' }}>
                    <FormControl variant="outlined" margin="normal" fullWidth>
                      <Typography sx={{ textAlign: 'left', fontFamily: 'Outfit', mb: '10px', fontWeight: '300', color: themeMode === "dark" ? '#fafafa' : '#181818' }}>
                        {t('createBot.chatbotState.state')}
                      </Typography>
                      <Select value={chatbotState} onChange={(e) => updateChatbotState(e.target.value)}
                        sx={{
                          color: themeMode === 'dark' ? '#ffffff' : '#181818',
                          bgcolor: themeMode === 'dark' ? '#181818' : '#fafafa',
                          '& .MuiSvgIcon-root': {
                            color: themeMode === 'dark' ? '#ffffff' : '#4a3aff',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: themeMode === 'dark' ? '#272727' : '#eeeeee',
                          },
                          borderRadius: '13px',
                        }}
                        MenuProps={{
                          sx: {
                            '& .MuiPaper-root': {
                              bgcolor: themeMode === 'dark' ? '#181818' : '#fff',
                              color: themeMode === 'dark' ? '#ffffff' : '#333',
                              borderRadius: '15px',
                              boxShadow: themeMode === "dark"
                                ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                                : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                            },
                            '& .MuiMenu-list .MuiMenuItem-root': {
                              '&:hover': {
                                bgcolor: themeMode === 'light' ? '#fafafa' : '',
                              },
                            },
                          },
                        }}
                      >
                        <MenuItem value="active">{t('createBot.chatbotState.active')}</MenuItem>
                        <MenuItem value="inactive">{t('createBot.chatbotState.inactive')}</MenuItem>
                        <MenuItem value="underMaintenance">{t('createBot.chatbotState.underMaintenance')}</MenuItem>
                        <MenuItem value="problematic">{t('createBot.chatbotState.problematic')}</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                  {
                    !isMobile && (
                      <Stack flexDirection="column" sx={{ width: '100%' }}>

                      </Stack>
                    )
                  }
                </Stack>


              </Stack>
            </Box>
          </Box>
        )
      }
    </SectionContainer >
  )
}

export default CreateBot;
