import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import { ConfigContext } from '../context/Config/ConfigContext';
import { useTranslation } from 'react-i18next';

function BottomNav() {

  const { t } = useTranslation('global')

  const {
    themeMode,
  } = useContext(ConfigContext)

  const location = useLocation();
  const [value, setValue] = useState('home');
  const navigate = useNavigate();

  useEffect(() => {
    const { pathname } = location;
    const pathValue = pathname.split('/')[1];
    setValue(pathValue);
  }, [location]);

  const getIconColor = (iconValue: any) => {
    return value === iconValue ? '#4a3aff' : themeMode === "dark" ? '#fafafa' : '#c1c1c1';
  };

  useEffect(() => {
    const { pathname } = location;
    switch (pathname) {
      case '/dashboard':
        setValue('dashboard');
        break;
      case '/my-bots':
        setValue('my-bots');
        break;
      case '/conversations':
        setValue('conversations');
        break;
      case '/subscription':
        setValue('subscription');
        break;
      case '/profile':
        setValue('profile');
        break;
      default:
        setValue('');
        break;
    }
  }, [location]);

  return (
    <BottomNavigation
      value={value}
      onChange={(_event, newValue) => {
        setValue(newValue);
        navigate(`/${newValue}`);
      }}
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        backgroundColor: themeMode === "dark" ? '#121212' : "#fff",
        zIndex: 5,
        borderTop: themeMode === "dark" ? '1px solid #181818' : '1px solid #eee',
      }}
    >
      <BottomNavigationAction
        style={{ outline: 'none' }}
        label={t('bottomNav.dashboard')}
        icon={(
          <DashboardRoundedIcon
            sx={{}}
            style={{ fontSize: '30px', color: getIconColor('dashboard') }}
          />
        )
        }
        sx={{
          color: themeMode === "dark" ? '#fafafa' : '#181818',
          fontWeight: 'normal',
          '&.Mui-focusVisible': {
            outline: 'none',
          },
          '& .MuiBottomNavigationAction-label': {
            fontSize: '12px', // Establece el tamaño de la fuente de los labels a 8px
          },
        }}
        value="dashboard"
      />
      <BottomNavigationAction
        style={{ outline: 'none' }}
        label={t('bottomNav.chatbots')}
        icon={(
          <ChatRoundedIcon
            sx={{}}
            style={{ fontSize: '30px', color: getIconColor('my-bots') }}
          />
        )
        }
        sx={{
          color: themeMode === "dark" ? '#fafafa' : '#181818',
          fontWeight: 'normal',
          '&.Mui-focusVisible': {
            outline: 'none',
          },
          '& .MuiBottomNavigationAction-label': {
            fontSize: '12px', // Establece el tamaño de la fuente de los labels a 8px
          },
        }}
        value="my-bots"
      />
      <BottomNavigationAction
        style={{ outline: 'none' }}
        label={t('bottomNav.chats')}
        icon={((
          <QuestionAnswerRoundedIcon
            sx={{}}
            style={{ fontSize: '30px', color: getIconColor('conversations') }}
          />
        )
        )
        }
        sx={{
          color: themeMode === "dark" ? '#fafafa' : '#181818',
          fontWeight: 'normal',
          '&.Mui-focusVisible': {
            outline: 'none',
          },
          '& .MuiBottomNavigationAction-label': {
            fontSize: '12px', // Establece el tamaño de la fuente de los labels a 8px
          },
        }}
        value="conversations"
      />
      <BottomNavigationAction
        style={{ outline: 'none' }}
        label="Suscripción"
        icon={(
          <AccountBalanceWalletRoundedIcon
            sx={{}}
            style={{ fontSize: '30px', color: getIconColor('subscription') }}
          />
        )
        }
        sx={{
          color: themeMode === "dark" ? '#fafafa' : '#181818',
          fontWeight: 'normal',
          '&.Mui-focusVisible': {
            outline: 'none',
          },
          '& .MuiBottomNavigationAction-label': {
            fontSize: '12px', // Establece el tamaño de la fuente de los labels a 8px
          },
        }}
        value="subscription"
      />
      <BottomNavigationAction
        style={{ outline: 'none' }}
        label={t('bottomNav.profile')}
        icon={(
          <PersonRoundedIcon
            sx={{}}
            style={{ fontSize: '30px', color: getIconColor('profile') }}
          />
        )
        }
        sx={{
          color: themeMode === "dark" ? '#fafafa' : '#181818',
          fontWeight: 'normal',
          '&.Mui-focusVisible': {
            outline: 'none',
          },
          '& .MuiBottomNavigationAction-label': {
            fontSize: '12px', // Establece el tamaño de la fuente de los labels a 8px
          },
        }}
        value="profile"
      />
    </BottomNavigation>
  );
}

export default BottomNav;
