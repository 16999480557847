import { collection, doc, writeBatch, increment } from 'firebase/firestore';
import { db } from '../firebase/firebase';

export function getDocumentIds(date = new Date()) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const week = getWeekNumber(date);

  return {
    dailyId: `daily-${year}-${month}-${day}`,
    weeklyId: `weekly-${year}-${week}`,
    monthlyId: `monthly-${year}-${month}`
  };
}

export async function updateGlobalMetrics({ newUser = false, newChatbot = false, newMessage = false, newConversation = false }) {
  const { dailyId, weeklyId, monthlyId } = getDocumentIds();
  const metricsRef = collection(db, 'metrics');

  const dailyMetricsRef = doc(metricsRef, dailyId);
  const weeklyMetricsRef = doc(metricsRef, weeklyId);
  const monthlyMetricsRef = doc(metricsRef, monthlyId);

  const updateData = {
    ...(newUser && { totalUsers: increment(1) }),
    ...(newChatbot && { totalChatbots: increment(1) }),
    ...(newMessage && { totalMessages: increment(1) }),
    ...(newConversation && { totalConversations: increment(1) })
  };

  const batch = writeBatch(db);
  batch.set(dailyMetricsRef, updateData, { merge: true });
  batch.set(weeklyMetricsRef, updateData, { merge: true });
  batch.set(monthlyMetricsRef, updateData, { merge: true });

  await batch.commit();
}

function getWeekNumber(d: any) {
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  const weekNo = Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
  return weekNo;
}