import { BrowserRouter, Routes, Route, useLocation, useNavigate } from "react-router-dom";
import {
  Box, CssBaseline, ThemeProvider, CircularProgress, useMediaQuery, useTheme,
} from "@mui/material";
import { useMode } from "./chatTheme";
import Topbar from "./global/Topbar";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState, useContext } from "react";
import Chat from "./pages/chat/Chat";
import ConfigPage from "./pages/CreateBot/CreateBot";
import SignIn from "./pages/SignIn/SignIn";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { getDoc, doc } from "firebase/firestore";
import { db } from "./services/firebase/firebase";
import StoreState from "./context/Store/storeState";
import StoreContext from "./context/Store/storeContext";
import { ConfigContext, ConfigProvider } from "./context/Config/ConfigContext";
import Sidebar from "./global/Sidebar";
import BotList from "./pages/BotList/BotList";
import Dashboard from "./pages/Dashboard/Dashboard";
import IndividualChatbot from "./pages/IndividualChatbot/IndividualChatbot";
import CreateBot from "./pages/CreateBot/CreateBot";
import DemoTopbar from "./global/ChatTopbar";
import DemoSidebar from "./global/ChatSidebar";
import { v4 as uuidv4 } from 'uuid';
import ClientProfile from "./pages/ClientProfile/ClientProfile";
import ClientSubscription from "./pages/ClientSubscription/ClientSubscription";
import ConversationHistory from "./pages/ConversationsList/ConversationsList";
import Settings from "./pages/settings/Settings";
import ConversationMessages from "./pages/ConversationMessages/ConversationMessages";
import PaymentHistory from "./pages/PaymentHistory/PaymentHistory";
import './i18n';
import SignUp from "./pages/SignUp/SignUp";
import i18next from "i18next";
import BottomNav from "./global/BottomNav";
import { Toaster } from 'sonner';
import Cookies from "js-cookie";
import Functions from "./pages/Functions/Functions";
import SupportChatbot from "./global/SupportChatbot/SupportChatbot";
import ClientsList from "./pages/ClientsList/ClientsList";
import ClientAnalytics from "./pages/ClientAnalytics/ClientAnalytics";
import 'swiper/swiper-bundle.css';
import AudioUploader from "./pages/AudioUploader/AudioUploader";
import AudioInteractionSimulator from "./pages/AudioInteraction/AudioInteraction";
import Media from "./pages/Media/Media";
import Webhook from "./pages/Webhook/Webhook";
import Platforms from "./pages/Integrations/Integrations";
import SocialMedia from "./pages/SocialMedia/SocialMedia";
import Documentation from "./pages/Documentation/Documentation";
import VoodooPage from "./pages/VoodoPage/VoodoPage";
import ProspectsList from "./pages/ProspectsList/ProspectsList";

function AppBody() {
  const auth = getAuth(); // Mover esta línea al inicio
  const [authUser, setAuthUser] = useState<User | null>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const { setUser } = useContext(StoreContext);

  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (userAuth) => {
      if (userAuth) {
        const userDoc = await getDoc(doc(db, "users", userAuth.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserRole(userData.role);

          setUser({
            firstName: userData?.firstName || "Usuario anónimo",
            lastName: userData?.lastName || "",
            role: userData?.role || "client",
            language: userData?.language || "es"
          });

          if (userData?.language) {
            i18next.changeLanguage(userData.language);
            Cookies.set('i18next', userData.language, { expires: 365 });
          }

          setAuthUser(userAuth);
        } else {
          setUser(null);
          setAuthUser(null);
        }
      } else {
        setUser(null);
        setAuthUser(null);
      }
      setInitialized(true);
    });

    return () => unsubscribe();
  }, []);

  const [uid, setUid] = useState("");
  const location = useLocation();

  useEffect(() => {
    const isPublicChatRoute = location.pathname.startsWith('/chat');

    if (auth.currentUser) {
      setUid(auth.currentUser.uid);
    } else if (isPublicChatRoute && !authUser) {
      let storedUid: string | null = localStorage.getItem("anonUid");
      if (!storedUid) {
        storedUid = uuidv4() as string;
        localStorage.setItem("anonUid", storedUid);
      }

      setUid(storedUid);

    }
  }, [location.pathname, authUser]);

  useEffect(() => {
    let appLang = Cookies.get('i18next');

    if (!appLang) {
      const browserLang = navigator.language;
      const langCode = browserLang.split('-')[0];

      appLang = ['en', 'es'].includes(langCode) ? langCode : 'en';

      Cookies.set('i18next', appLang, { expires: 365 });
    }

    i18next.changeLanguage(appLang);
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const isInChat = location.pathname.startsWith('/chat');

  const [isSidebarOpenFromMobile, setIsSidebarOpenFromMobile] = useState(false);

  const { themeMode } = useContext(ConfigContext)

  const navigate = useNavigate();

  useEffect(() => {
    if (!authUser &&
      !location.pathname.startsWith('/chat') &&
      !['/signin', '/signup', '/signin/es', '/signin/en', '/signup/es', '/signup/en', '/create'].includes(location.pathname) &&
      initialized) {
      navigate('/signin');
    }
  }, [authUser, location.pathname, initialized, navigate]);


  const renderRoutesBasedOnRole = () => {
    switch (userRole) {
      case 'client':
        return (
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/conversations" element={<ConversationHistory />} />
            <Route path="/bot/:botId/conversation/:conversationId" element={<ConversationMessages />} />
            <Route path="/my-bots" element={<BotList />} />
            <Route path="/individual-bot/:id" element={<IndividualChatbot />} />
            <Route path="/create-bot" element={<CreateBot />} />
            <Route path="/edit-bot/:id?" element={<CreateBot />} />
            <Route path="/profile" element={<ClientProfile />} />
            <Route path="/subscription" element={<ClientSubscription />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/payment-history" element={<PaymentHistory />} />
            <Route path="/media" element={<Media />} />
            <Route path="/functions" element={<Functions />} />
            <Route path="/webhook" element={<Webhook />} />
            <Route path="/platforms" element={<Platforms />} />
            <Route path="/social-media" element={<SocialMedia />} />
            <Route path="/documentation" element={<Documentation />} />
            <Route path="/audio-uploader" element={<AudioUploader />} />
            <Route path="/audio-interaction" element={<AudioInteractionSimulator />} />
            <Route path="/sales" element={<ProspectsList />} />
          </Routes>
        );
      case 'admin':
        return (
          <Routes>
            <Route path="/" element={<BotList />} />
            <Route path="/create-bot" element={<ConfigPage />} />
            <Route path="/my-bots" element={<BotList />} />
            <Route path="/create-bot/:id?" element={<CreateBot />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/individual-bot/:id" element={<IndividualChatbot />} />
            <Route path="/settings" element={<Settings />} />
          </Routes>
        );
      case 'owner':
        return (
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/conversations" element={<ConversationHistory />} />
            <Route path="/bot/:botId/conversation/:conversationId" element={<ConversationMessages />} />
            <Route path="/my-bots" element={<BotList />} />
            <Route path="/individual-bot/:id" element={<IndividualChatbot />} />
            <Route path="/create-bot" element={<CreateBot />} />
            <Route path="/edit-bot/:id?" element={<CreateBot />} />
            <Route path="/profile" element={<ClientProfile />} />
            <Route path="/subscription" element={<ClientSubscription />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/payment-history" element={<PaymentHistory />} />
            <Route path="/functions" element={<Functions />} />
            <Route path="/clients" element={<ClientsList />} />
            <Route path="/clients-analytics" element={<ClientAnalytics />} />
          </Routes>
        );
      default:
        return (
          <Routes>
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
          </Routes>
        );
    }
  };


  if (!initialized) {
    return (
      <Box sx={{
        display: 'flex', mx: 'auto', alignItems: "center", justifyContent: 'center',
        backgroundColor: themeMode === "dark" ? '#121212' : '#fafafa',
        width: '100%', height: '100vh'
      }}>
        <CircularProgress sx={{ color: themeMode === "dark" ? '#c1c1c1' : '#c1c1c1' }} />
      </Box>
    );
  } else if (isInChat) {
    return (
      <Box sx={{ display: 'flex', height: '100vh' }}>
        <ToastContainer />
        <DemoSidebar isOpen={isSidebarOpen}
          setIsOpen={setIsSidebarOpen}
          isSidebarOpenFromMobile={isSidebarOpenFromMobile}
          setIsSidebarOpenFromMobile={setIsSidebarOpenFromMobile}
          uid={uid}
        />
        <Box component="main" sx={{ flexGrow: 1, overflow: 'auto' }}>
          <Box sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
            <DemoTopbar isSidebarOpen={isSidebarOpen} isSidebarOpenFromMobile={isSidebarOpenFromMobile} setIsSidebarOpenFromMobile={setIsSidebarOpenFromMobile} />
          </Box>
          <Routes>
            <Route path="/chat/:chatbotId" element={<Chat uid={uid} />} />
            <Route path="/chat/:chatbotId/conversations/:conversationId" element={<Chat uid={uid} />} />
          </Routes>
        </Box>
      </Box>
    );
  } else if (!authUser) {
    return (
      <Box sx={{ mx: 'auto', pt: 0, backgroundColor: themeMode === "dark" ? "#121212" : "#fff" }}>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signin/:lang" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signup/:lang" element={<SignUp />} />
          <Route path="/create" element={<VoodooPage />} />
        </Routes>
      </Box>
    );
  } else {
    return (
      <Box sx={{ display: 'flex', height: '100vh', backgroundColor: themeMode === "dark" ? '#121212' : '#fafafa', }}>
        <ToastContainer />
        {!isInChat && (
          <Sidebar
            isSidebarOpenFromMobile={isSidebarOpenFromMobile}
            setIsSidebarOpenFromMobile={setIsSidebarOpenFromMobile}
          />
        )}

        <Box component="main" sx={{ flexGrow: 1, overflow: 'auto', backgroundColor: themeMode === "dark" ? '#121212' : '#fafafa', }}>
          <Box sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
            {
              isInChat ? (
                <DemoTopbar />
              ) : (
                <Topbar setIsSidebarOpenFromMobile={setIsSidebarOpenFromMobile} />
              )
            }
          </Box>
          {renderRoutesBasedOnRole()}
          <SupportChatbot />
        </Box>
        {isMobile && <BottomNav />}
      </Box>
    );
  }
}

function App() {
  const [theme] = useMode();

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <ConfigProvider>
          <StoreState>
            <CssBaseline />
            <AppBody />
            <Toaster
              richColors expand={false} visibleToasts={9} position="top-right"
              style={{ fontFamily: 'Outfit' }} closeButton
            />
          </StoreState>
        </ConfigProvider>
      </ThemeProvider>
    </BrowserRouter>

  );
}
export default App;
