import {
  Box, Button, CircularProgress,
  Grid, Stack, Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { collection, getDocs, orderBy, query, limit, startAfter } from 'firebase/firestore';
import SectionContainer from '../../global/SectionContainer';
import DialogCommon from '../../components/DialogCommon/DialogCommon';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../context/Config/ConfigContext';
import FlipMove from 'react-flip-move';
import { auth, db } from '../../services/firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { showToast } from '../../components/Toast/toast';

function PaymentHistory() {

  const { themeMode } = useContext(ConfigContext)
  const { t } = useTranslation('global')
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [totalPayments, setTotalPayments] = useState(0);
  // const [currentPage, setCurrentPage] = useState(1);
  const [paymentLimit, setPaymentLimit] = useState(15);
  const [payments, setPayments] = useState<Payment[]>([]);
  const theme = useTheme();
  const [user] = useAuthState(auth);

  interface Payment {
    id: string;
    amount_paid: number;
    currency: string;
    hosted_invoice_url?: string;
    invoice_pdf?: string;
    receipt_url?: string;
    payment_intent: string;
    status: string;
    type: "subscription" | "add-on";
    paid_at: {
      seconds: number;
      nanoseconds: number;
    };
  }

  useEffect(() => {
    fetchPayments();
  }, [user]);

  const fetchPayments = async (startAfterId?: any) => {
    setLoading(true);
    if (user) {
      try {
        const userId = user.uid;
        const paymentsRef = collection(db, `users/${userId}/payments`);
        let q = query(paymentsRef, orderBy('paid_at', 'desc'), limit(paymentLimit));

        if (startAfterId) {
          q = query(q, startAfter(startAfterId));
        }

        const querySnapshot = await getDocs(q);
        const paymentsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Payment));

        if (startAfterId) {
          setPayments((prevPayments) => [...prevPayments, ...paymentsData]);
        } else {
          setPayments(paymentsData);
        }

        const totalSnapshot = await getDocs(query(paymentsRef, orderBy('paid_at', 'desc')));
        setTotalPayments(totalSnapshot.size);
      } catch (error) {
        showToast('error', t('toast.errorFetchingUserPayments'), themeMode);
      } finally {
        setLoading(false);
      }
    }
  };

  const loadMorePayments = async () => {
    setLoadingMore(true);
    const lastPayment = payments[payments.length - 1];
    if (lastPayment && lastPayment.paid_at) {
      await fetchPayments(lastPayment.paid_at);
      setPaymentLimit((prevLimit) => prevLimit + 15);
    }
    setLoadingMore(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const arePaymentsAvailable = payments.length > 0;

  const getPaymentStatusStyle = (status: string) => {
    const displayStatus = (status === "succeeded") ? "paid" : status;
    switch (displayStatus) {
      case "paid":
        return {
          backgroundColor: themeMode === "dark" ? "#1e2c1e" : "#C8E6C9",
          color: "#388E3C",
          fontWeight: "bold",
        };
      case "pending":
        return {
          backgroundColor: "#FFF9C4",
          color: "#FBC02D",
          fontWeight: "bold",
        };
      case "failed":
        return {
          backgroundColor: "#FFCDD2",
          color: "#D32F2F",
          fontWeight: "bold",
        };
      default:
        return {
          backgroundColor: "transparent",
          color: "#757575",
          fontWeight: "300",
        };
    }
  };

  const displayPaymentStatus = (status: string) => {
    switch (status) {
      case "succeeded":
      case "paid":
        return t('paymentHistory.paid');
      case "pending":
        return t('paymentHistory.pending');
      case "failed":
        return t('paymentHistory.failed');
      default:
        return status.charAt(0).toUpperCase() + status.slice(1);
    }
  };

  return (
    <SectionContainer title={t('paymentHistory.paymentHistoryTitle') as string} individualChatbot disableButton={arePaymentsAvailable}>
      <Box sx={{ backgroundColor: themeMode === "dark" ? '#121212' : '#fafafa', mb: isMobile ? '60px' : 0 }}>
        {!isMobile && payments.length > 0 && (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              color: '#fff', borderRadius: '15px', px: 1,
            }}
          >
            <Grid item xs={2}>
              <Typography sx={{ fontFamily: 'Outfit', fontSize: '18px', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818' }} variant="h6">
                {t('paymentHistory.paymentType')}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontFamily: 'Outfit', fontSize: '18px', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818' }} variant="h6">
                {t('paymentHistory.amount')}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontFamily: 'Outfit', fontSize: '18px', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818' }} variant="h6">
                {t('paymentHistory.paymentStatus')}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontFamily: 'Outfit', fontSize: '18px', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818' }} variant="h6">
                {t('paymentHistory.date')}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontFamily: 'Outfit', fontSize: '18px', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818' }} variant="h6">
                {t('paymentHistory.actions')}
              </Typography>
            </Grid>
          </Grid>

        )}

        {
          !loading ? (
            <Box sx={{ height: 'auto', mb: '40px' }}>
              <FlipMove>
                {payments && payments.length > 0 ? (
                  payments.map((payment: any) => (
                    <Grid
                      key={payment.id}
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems={"center"}
                      sx={{
                        py: 1,
                        px: 2,
                        color: '#fff',
                        marginTop: '10px',
                        borderRadius: '25px',
                        height: '100px',
                        backgroundColor: themeMode === "dark" ? '#181818' : '#fff',
                        boxShadow: themeMode === "dark"
                          ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                          : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                      }}

                    >
                      {!isMobile ? (
                        <>
                          {/* Tipo de pago */}
                          <Grid item xs={2}>
                            <Typography sx={{ fontFamily: 'Outfit', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818', fontWeight: '300' }} variant="body1">
                              {payment.type === "subscription" ? t('paymentHistory.subscription') : t('paymentHistory.responses')}
                            </Typography>
                          </Grid>
                          {/* Monto pagado */}
                          <Grid item xs={2}>
                            <Typography sx={{ fontFamily: 'Outfit', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818', fontWeight: '300' }} variant="body1">
                              ${payment.amount_paid / 100} {payment.currency.toUpperCase()}
                            </Typography>
                          </Grid>
                          {/* Estado del pago */}
                          <Grid item xs={2}>
                            <Typography sx={{
                              fontFamily: 'Outfit',
                              textAlign: 'center',
                              maxWidth: '120px',
                              mx: 'auto',
                              borderRadius: '100px',
                              fontSize: '16px',
                              ...getPaymentStatusStyle(payment.status)
                            }} variant="body1">
                              {displayPaymentStatus(payment.status)}
                            </Typography>

                          </Grid>
                          {/* Fecha de pago */}
                          <Grid item xs={2}>
                            <Typography sx={{ fontFamily: 'Outfit', textAlign: 'center', color: themeMode === "dark" ? '#fff' : '#181818', fontWeight: '300' }} variant="body1">
                              {new Date(payment.paid_at.seconds * 1000).toLocaleDateString()}<br />
                              {new Date(payment.paid_at.seconds * 1000).toLocaleTimeString()}
                            </Typography>
                          </Grid>

                          {/* Acciones */}
                          <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                              variant="contained"
                              startIcon={<ReceiptRoundedIcon />}
                              onClick={() => window.open(payment.type === "add-on" ? payment.receipt_url : payment.hosted_invoice_url, '_blank')}
                              sx={{
                                height: 'auto',
                                color: '#fff',
                                fontSize: isMobile ? '14px' : "14px",
                                fontWeight: "400",
                                textTransform: "none",
                                padding: "8px 15px",
                                borderRadius: '100px',
                                borderColor: "transparent",
                                borderWidth: 1,
                                borderStyle: 'solid',
                                fontFamily: 'Outfit',
                                backgroundColor: '#4a3aff',
                                textAlign: 'center',
                                mx: 'auto',
                                '&:hover': {
                                  borderColor: '#4a3aff',
                                  color: themeMode === "dark" ? "white" : '#4a3aff',
                                  backgroundColor: 'transparent',
                                  borderWidth: 1,
                                  borderStyle: 'solid',
                                  boxShadow: 'none'
                                },
                                '&:focus': {
                                  outline: 'none',
                                },

                              }}
                            >
                              {payment.type === "add-on" ? t('paymentHistory.viewReceipt') : t('paymentHistory.viewInvoice')}
                            </Button>
                          </Grid>
                        </>

                      ) : (
                        <>
                          <Grid item xs={6}>
                            <Stack flexDirection="column" justifyContent="start" alignItems="start" gap="3px">
                              <Typography sx={{ fontSize: '14px', textAlign: 'left', color: themeMode === "dark" ? '#fff' : '#181818' }}>
                                {payment.type === "subscription" ? t('paymentHistory.subscription') : t('paymentHistory.responses')}
                              </Typography>
                              <Typography sx={{ fontSize: '14px', textAlign: 'left', color: themeMode === "dark" ? '#fff' : '#181818' }}>
                                ${payment.amount_paid / 100} {payment.currency.toUpperCase()}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '14px', textAlign: 'center',
                                  fontFamily: 'Outfit',
                                  width: '100%',
                                  maxWidth: '80px',
                                  borderRadius: '100px',
                                  ...getPaymentStatusStyle(payment.status),
                                  color: themeMode === "dark" ? '#fff' : '#181818'
                                }}
                              >
                                {displayPaymentStatus(payment.status)}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={6} alignItems="end">
                            <Stack flexDirection="column" justifyContent="space-between" alignItems="end" gap="6px">
                              <Typography sx={{ fontSize: '14px', textAlign: 'right', color: themeMode === "dark" ? '#fff' : '#181818' }}>
                                {new Date(payment.paid_at.seconds * 1000).toLocaleDateString()} {' '}
                                {new Date(payment.paid_at.seconds * 1000).toLocaleTimeString()}
                              </Typography>
                              <Button
                                variant="contained"
                                startIcon={<ReceiptRoundedIcon />}
                                onClick={() => window.open(payment.type === "add-on" ? payment.receipt_url : payment.hosted_invoice_url, '_blank')}
                                sx={{
                                  height: 'auto',
                                  color: '#fff',
                                  fontSize: isMobile ? '14px' : "14px",
                                  fontWeight: "400",
                                  textTransform: "none",
                                  padding: "5px 10px",
                                  borderRadius: '100px',
                                  borderColor: "transparent",
                                  borderWidth: 1,
                                  borderStyle: 'solid',
                                  fontFamily: 'Outfit',
                                  backgroundColor: '#4a3aff',
                                  textAlign: 'center',
                                  '&:hover': {
                                    borderColor: '#4a3aff',
                                    color: themeMode === "dark" ? "white" : '#4a3aff',
                                    backgroundColor: 'transparent',
                                    borderWidth: 1,
                                    borderStyle: 'solid',
                                    boxShadow: 'none'
                                  },
                                  '&:focus': {
                                    outline: 'none',
                                  },

                                }}
                              >
                                {payment.type === "add-on" ? t('paymentHistory.viewReceipt') : t('paymentHistory.viewInvoice')}
                              </Button>
                            </Stack>
                          </Grid>
                        </>

                      )
                      }
                    </Grid>
                  ))
                ) : (
                  <Stack flexDirection="column" justifyContent="center" alignItems="center" gap={2} sx={{ mt: '100px' }}>
                    <ReceiptRoundedIcon sx={{ fontSize: '60px', color: '#c1c1c1' }} />
                    <Box sx={{ textAlign: 'center', }}>
                      <Typography align="center" sx={{
                        fontSize: '18px', fontFamily: 'Outfit', fontWeight: '300',
                        color: '#c1c1c1'
                      }}>
                        {t('paymentHistory.notPaymentsYet')}
                      </Typography>
                    </Box>
                  </Stack>
                )}
              </FlipMove>

            </Box>

          ) : (
            <Box sx={{ display: 'flex', mx: 'auto', alignItems: "center", justifyContent: 'center', mt: '100px' }}>
              <CircularProgress sx={{ color: themeMode === "dark" ? '#fafafa' : '#4a3aff' }} />
            </Box>
          )
        }

        {payments.length >= paymentLimit && payments.length < totalPayments && (
          <Stack flexDirection="row" justifyContent="center" alignItems="center" sx={{ mx: 'auto' }}>
            <Button
              onClick={loadMorePayments}
              disabled={loadingMore}
              startIcon={loadingMore && <CircularProgress size={20} />}
              sx={{
                lineHeight: 1,
                backgroundColor: loadingMore ? themeMode === 'dark' ? '' : '#e8e8e8' : '#4a3aff',
                color: loadingMore ? themeMode === 'dark' ? '#fff' : '#4c4c4c4d' : '#fff',
                '&.Mui-disabled': {
                  backgroundColor: themeMode === "dark" ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
                  color: themeMode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
                },
                fontSize: isMobile ? '16px' : "16px",
                fontWeight: "500",
                textTransform: "none",
                padding: "10px 20px",
                borderRadius: '100px',
                borderColor: "transparent",
                borderWidth: 1,
                borderStyle: 'solid',
                fontFamily: 'Outfit',
                '&:hover': {
                  borderColor: '#4a3aff',
                  color: themeMode === 'dark' ? "white" : '#181818',
                  backgroundColor: 'transparent',
                  borderWidth: 1,
                  borderStyle: 'solid',
                },
                '&:focus': {
                  outline: 'none',
                },
                mx: 'auto'
              }}
            >
              {loadingMore ? 'Cargando' : 'Ver más'}
            </Button>
          </Stack>
        )}

        <DialogCommon
          kTitle={t('conversationList.confirmation')}
          kMaxWidth="xs"
          kMaxHeight={isMobile ? '380px' : '250px'}
          kFixedDialog={0}
          kFixedDialogPosition="fixed"
          kDisableBottomDivider
          kEnableBorderRadius={true}
          kBorderRadius={isMobile ? '20px' : '30px'}
          kAlignBtn="center"
          kKeepBtnSize
          kTopDivider
          kSlideTransition
          kOpenDialogLists={open}
          kOnClose={handleClose}
          kOnSecondary={handleClose}
          kSecondaryBtnText={t('conversationList.cancel') as string}
          kPrimaryBtnText={t('conversationList.delete') as string}
          kContent={
            (
              <Typography sx={{
                fontWeight: '300', fontFamily: 'Outfit',
                fontSize: isMobile ? '14px' : '18px', color: themeMode === "dark" ? "#fafafa" : "#181818"
              }}>
                {t('conversationList.areYouSure')}
              </Typography>
            )
          }
        />
      </Box>
    </SectionContainer>
  );
}

export default PaymentHistory;
